import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import MDButton from "components/MDButton";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import {
  CREATE_CATEGORY_MUTATION,
  UPDATE_CATEGORY_MUTATION,
  CREATE_OR_UPDATE_CATEGORY_PRODUCT_ATTRIBUTE,
  GET_CATEGORIES,
} from "services/product-category-service";
import { GET_ATTRIBUTE_LIST } from "services/master-attribute-service";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import FileUploader from "components/MDFileUpload";
import { useNotification } from "context";
import Loader from "components/MDLoader";
import useDebounce from "./useDebounce";
import { ginesysAttribute } from "layouts/master-attribute/atrributeForm";
import dayjs from "dayjs";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Category Name is required")
    .max(30, "Maximum 30 characters allowed"),
  metaTitle: Yup.string()
    .required("Title is required")
    .max(30, "Maximum 30 characters allowed"),
  displayOrder: Yup.number()
    .required("This field is required.")
    .positive("A number must be positive.")
    .integer("A number must be an integer.")
    .typeError("You must specify a number"),
    parentId: Yup.mixed().nullable().when("isNodeItem", {
      is: true,
      then: (schema) => schema.required("Parent Category is required when Node Item is false"),
      otherwise: (schema) => schema.nullable(),
    }),
  isDeleted: Yup.bool(),
  isMenuItem: Yup.bool(),
  imagepath: Yup.string().required("Please select category image to upload"),
  isNodeItem: Yup.boolean(),
  categoryProductAttribute: Yup.array().when("isNodeItem", {
    is: true,
    then: () =>
      Yup.array()
        .of(
          Yup.object().shape({
            ginesysAttribute: Yup.string().required(
              "Ginesys Attribute is required"
            ),
            name: Yup.string().required("Name is required"),
            type: Yup.string().required("Type is required"),
            values: Yup.array().when("type", (type, yup) =>
              type === "Open"
                ? Yup.array().of(Yup.string()).min(1, "This Field is Required")
                : Yup.array().of(Yup.string())
            ),
            isVariant: Yup.bool().required("Variant is required"),
            isMandatory: Yup.bool().required("Mandatory is required"),
          })
        )
        .required("At least one node field is required")
        .min(1, "At least one node field is required"),
  }),
});

const useCategoryMutation = (
  categoryData,
  handleClose,
  setNotification,
  refetch,
  isNewForm,
  mutation
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
  };

  const [createCategoryMutation, { loading: creatingLoading }] = useMutation(
    CREATE_CATEGORY_MUTATION,
    mutationOptions
  );
  const [updateCategoryMutation, { loading: updatingLoading }] = useMutation(
    UPDATE_CATEGORY_MUTATION,
    mutationOptions
  );
  const [createOrUpdateCategoryProductAttributes] = useMutation(
    CREATE_OR_UPDATE_CATEGORY_PRODUCT_ATTRIBUTE,
    mutationOptions
  );
  const isLoading = creatingLoading || updatingLoading;

  const handleSubmit = (values) => {
    const payload = {
      title: values?.title,
      metaTitle: values?.metaTitle,
      displayOrder: +values?.displayOrder,
      parentId: values?.parentId?.id || "",
      isDeleted: !values?.isDeleted,
      imagepath: values?.imagepath,
      isMenuItem: values?.isMenuItem,
      isNodeItem: values?.isNodeItem,
    };

    const graphqlMutation = (mutation === 'update')
      ? updateCategoryMutation
      : createCategoryMutation;
    const mutationInput = (mutation === 'update')
      ? { updateCategoryProductInput: { id: values.id, ...payload } }
      : { createCategoryProductInput: payload };

    graphqlMutation({ variables: mutationInput })
      .then((res) => {
        if (values?.isNodeItem && values?.categoryProductAttribute.length) {
          const attributePayload = {
            variables: {
              request: {
                categoryProductAttributeInput:
                  values?.categoryProductAttribute?.map((attr) => {
                    if (!isNewForm && attr.id) {
                      return {
                        id: attr.id || "",
                        categoryProductId:
                          res.data[
                            !isNewForm
                              ? "updateCategoryProduct"
                              : "createCategoryProduct"
                          ].id,
                        fieldName: attr.ginesysAttribute,
                        attributeName: attr.name,
                        fieldType: attr.type,
                        values: attr.values,
                        attributeType: attr.isVariant
                          ? "variant"
                          : "not_variant",
                        isMandatory: attr.isMandatory,
                        isDeleted: attr?.isDeleted || false,
                      };
                    } else {
                      return {
                        categoryProductId:
                          res.data[
                            !isNewForm
                              ? "updateCategoryProduct"
                              : "createCategoryProduct"
                          ].id,
                        fieldName: attr.ginesysAttribute,
                        attributeName: attr.name,
                        fieldType: attr.type,
                        values: attr.values,
                        attributeType: attr.isVariant
                          ? "variant"
                          : "not_variant",
                        isMandatory: attr.isMandatory,
                        isDeleted: attr?.isDeleted || false,
                      };
                    }
                  }),
              },
            },
          };
          createOrUpdateCategoryProductAttributes(attributePayload)
            .then((res) => {
              handleClose();
              setNotification({
                color: "success",
                isVisible: true,
                message: !isNewForm
                  ? "Updated successfully"
                  : "Created successfully",
              });
            })
            .catch((err) => {
              setNotification({
                color: "error",
                isVisible: true,
                message: err.message || "Something went wrong",
              });
            })
            // .finally(() => refetch());
        } else {
          handleClose();
          setNotification({
            color: "success",
            isVisible: true,
            message: !isNewForm
              ? "Updated successfully"
              : "Created successfully",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      })
      .finally(() => refetch());
  };

  return [handleSubmit, isLoading];
};

const CategoryDropDown = ({
  categoryList,
  setSearchValues,
  loading,
  ...props
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="parentId"
      value={props.values.parentId || null}
      options={
        categoryList?.map((item) => ({
          id: item.id,
          label: `${
            item.parent?.title ? `${item.parent?.title} /` : ""
          } ${item.title}`,
          metaTitle: item.metaTitle,
        })) || []
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        props.setFieldValue(
          "parentId",
          newValue ? { id: newValue.id, label: newValue.label } : null
        );
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Parent Category"
          helperText={props.touched.parentId ? props.errors.parentId : ""}
          error={props.touched.parentId && Boolean(props.errors.parentId)}
        />
      )}
    />
  </Grid>
);

const ProductCategoryFormComponent = ({
  handleClose,
  categoryData,
  refetch,
  isNewForm,
}) => {
  const [searachValues, setSearchValues] = useState("");
  const [allCatSearchValues, setAllCatSearchValues] = useState("");
  const debouncedSearchTerm = useDebounce(searachValues, 300);
  const allCatDebouncedSearchTerm = useDebounce(allCatSearchValues, 300);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [mutation, setMutation] = useState('create');
  const [getCategories, { called, loading, data, error }] = useLazyQuery(
    GET_CATEGORIES,
    {
      client: dashboardServiceClient,
      variables: {
        listCategoryProductFilter: {
          take: 20,
          skip: 0,
          search: debouncedSearchTerm,
          filter: { isNodeItem: false },
        },
      },
    }
  );
  const [
    getAllCategories,
    {
      called: allCataCalled,
      loading: allCatLoading,
      data: allCatData,
      error: allCatError,
    },
  ] = useLazyQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 20,
        skip: 0,
        search: allCatDebouncedSearchTerm,
        filter: {},
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setAllCategoryList(data?.categoryProduct?.results);
    },
    onError: (error) => {
      console.error(error);
    },
  });
  
  const {
    data: attributeListData,
    loading: loadingAttributes,
    error: errorAttributes,
  } = useQuery(GET_ATTRIBUTE_LIST, {
    client: dashboardServiceClient,
    variables: { take: 1000, skip: 0, search: "" }, // Adjust the variables as needed
  });

  const [valueOptions, setValueOptions] = React.useState([]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);
  useEffect(() => {
    getAllCategories({
      variables: {
        listCategoryProductFilter: {
          take: 20,
          skip: 0,
          search: allCatDebouncedSearchTerm,
          filter: {},
        },
      },
    });
  }, [getAllCategories]);
  useEffect(() => {
    if (debouncedSearchTerm) {
      getCategories(); // Trigger the search when debounced term changes
    }
  }, [debouncedSearchTerm, getCategories]);
  useEffect(() => {
    if (allCatDebouncedSearchTerm) {
      getAllCategories();
    }
  }, [allCatDebouncedSearchTerm, getAllCategories]);
  const { setNotification } = useNotification();
  const [handleSubmit, isLoading] = useCategoryMutation(
    categoryData,
    handleClose,
    setNotification,
    refetch,
    isNewForm,
    mutation
  );
  if (isLoading) return <Loader />;
  // if ( isLoading) return <Loader />;
  if (error) return <p>Error: {error?.message}</p>;
  if (loadingAttributes) return <Loader />;
  if (errorAttributes) return <p>Error: {errorAttributes.message}</p>;

  const handleNameChange = (index, newValue, formikProps, ginesysAttribute) => {
    const selectedAttribute = attributeListData.masterAttributes.results.find(
      (attr) => attr.name === newValue
    );
    if (selectedAttribute) {
      formikProps.setFieldValue(
        `categoryProductAttribute[${index}].type`,
        selectedAttribute.fieldType
      );
      formikProps.setFieldValue(
        `categoryProductAttribute[${index}].ginesysAttribute`,
        selectedAttribute.ginesysAttribute
      );
      setValueOptions(selectedAttribute.values); // Store the values array
    }
  };

  const getValueOptionsForAttribute = (attributeName = "") => {
    return attributeName
      ? attributeListData?.masterAttributes?.results.find(
          (attr) => attr?.name === attributeName
        )?.values || []
      : [];
  };

  const categoryList = data?.categoryProduct.results;

  const getCategoryNodeForm = (categoryData = {}) => {
    const findKeyFromObject = (key) => {
      const categoryProductAttributesForKey =
        categoryData?.categoryProductAttribute?.filter(
          (attribute) =>
            attribute.fieldName === ginesysAttribute[key] &&
            !attribute.isDeleted
        ) || [];
      categoryProductAttributesForKey.sort((a, b) =>
        dayjs(a.createdAt).diff(dayjs(b.createdAt))
      );
      return categoryProductAttributesForKey;
    };

    return Object.keys(ginesysAttribute).flatMap((key, index) => {
      const datas = findKeyFromObject(key);
      return datas?.length > 0
        ? datas.map((data) => ({
            categoryProductId: categoryData?.id || null,
            id: data?.id || null,
            name: data?.attributeName || "",
            type: data?.fieldType || "",
            isVariant: data?.attributeType === "variant" ? true : false,
            isMandatory: data?.isMandatory || false,
            values: data?.values || [],
            isDeleted: false,
            ginesysAttribute: key,
          }))
        : {
            categoryProductId: categoryData?.id || null,
            id: null,
            name: "",
            type: "",
            isVariant: false,
            isMandatory: false,
            values: [],
            isDeleted: false,
            ginesysAttribute: key,
          };
    });
  };
  const handleInputChange = (data) => {
    const formattedData = data.includes("/")
      ? data.split("/").pop().trim()
      : data;

    const categoryExists = allCategoryList?.some(
      (category) => category.title === formattedData
    );

    isNewForm && categoryExists && setMutation('update');
    !isNewForm && setMutation('update');
    isNewForm && !categoryExists && setMutation('create');
  };

  const getOptionsList = () => {
    return (
      allCatData?.categoryProduct.results?.map((item) => ({
        id: item.id,
        label: `${
          item.parent?.title ? `${item.parent?.title} /` : ""
        } ${item.title}`,
        metaTitle: item.metaTitle,
        ...item,
      })) || []
    );
  };
  return (
    <Formik
      initialValues={{
        id: categoryData?.id || null,
        title: categoryData ? categoryData.title : "",
        metaTitle: categoryData ? categoryData.metaTitle : "",
        displayOrder: categoryData ? categoryData.displayOrder : "",
        parentId:
          categoryData && categoryData.parent
            ? {
                id: categoryData.parent.id,
                label: categoryData.parent.metaTitle,
              }
            : null,
        isDeleted: categoryData ? !categoryData.isDeleted : false,
        imagepath:
          categoryData && categoryData.image.length
            ? categoryData.image[0].path
            : "",
        isMenuItem: categoryData ? categoryData.isMenuItem : false,
        isNodeItem: categoryData ? categoryData.isNodeItem : false,
        categoryProductAttribute: getCategoryNodeForm(categoryData),
      }}
      validate={(values) => {
        const errors = {};
        if (values.isNodeItem) {
          if (
            !values.categoryProductAttribute ||
            !values.categoryProductAttribute.length
          ) {
            errors.categoryProductAttribute =
              "At least one node field is required";
          }
        }
        return errors;
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formikProps) => (
        <Form>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Grid item xs={12}>
              <Autocomplete
                id="tags-standard"
                name="title"
                freeSolo
                value={formikProps.values.title || null}
                options={getOptionsList()}
                onChange={(event, newValue) => {
                  if (newValue) {
                    formikProps.setValues({
                      id: newValue?.id,
                      title: newValue?.title,
                      metaTitle: newValue?.metaTitle,
                      displayOrder: newValue?.displayOrder,
                      parentId: newValue?.parent?.id
                        ? {
                            id: newValue?.parent?.id,
                            label: newValue?.parent?.metaTitle,
                          }
                        : null,
                      isDeleted: !newValue?.isDeleted,
                      imagepath: newValue?.image[0]?.path,
                      isMenuItem: newValue?.isMenuItem,
                      isNodeItem: newValue?.isNodeItem,
                      categoryProductAttribute: getCategoryNodeForm(newValue),
                    });
                  }
                }}
                onInputChange={(event, newValue) => {
                  handleInputChange(newValue);
                  if (newValue) {
                    formikProps.setFieldValue("title", newValue);
                  }
                  if ((event && event?.type === "reset") || newValue === "") {
                    setAllCatSearchValues(""); // Clear search when input is cleared
                  }
                  if (event && event.type === "change") {
                    setAllCatSearchValues(newValue);
                  }
                }}
                loading={allCatLoading}
                loadingText="Loading..."
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category Name"
                    helperText={
                      formikProps.touched.title ? formikProps.errors.title : ""
                    }
                    error={
                      formikProps.touched.title &&
                      Boolean(formikProps.errors.title)
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {[
                  { field: "metaTitle", label: "Title" },
                  { field: "displayOrder", label: "Order", type: "number" },
                ].map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Field
                      name={item.field}
                      as={TextField}
                      sx={{ label: { padding: "2px" } }}
                      type={item.type ? item.type : "text"}
                      label={
                        item.label.charAt(0).toUpperCase() + item.label.slice(1)
                      }
                      fullWidth
                      error={
                        formikProps.touched[item.field] &&
                        !!formikProps.errors[item.field]
                      }
                      helperText={
                        formikProps.touched[item.field] &&
                        formikProps.errors[item.field]
                      }
                    />
                  </Grid>
                ))}
                {/* Image path section to be added in the future */}
                <Grid item xs={12} mt={1}>
                  <FileUploader
                    isMultiple={false}
                    preLoadedFile={
                      !!formikProps.values.imagepath
                        ? [formikProps.values.imagepath]
                        : null
                    }
                    fieldName={"Upload Category Image"}
                    signedUrlCallback={(newUrl, index) => {
                      const updatedImagePathArray = [
                        ...formikProps.values.imagepath,
                      ];
                      if (newUrl) {
                        // Update the URL at the given index
                        updatedImagePathArray[index] = newUrl;
                      } else {
                        // Delete the URL at the given index
                        updatedImagePathArray.splice(index, 1);
                      }
                      formikProps.setFieldValue("imagepath", newUrl);
                    }}
                    isFieldValid={
                      formikProps?.touched?.imagepath &&
                      formikProps?.errors?.imagepath
                    }
                  />
                  {formikProps?.touched?.imagepath &&
                    formikProps?.errors?.imagepath && (
                      <Typography fontSize={12} color="error">
                        {formikProps?.errors?.imagepath}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                  <CategoryDropDown
                    categoryList={
                      categoryList && categoryList.length
                        ? categoryData
                          ? categoryList.filter(
                              (item) => item.id !== categoryData.id
                            )
                          : categoryList
                        : []
                    }
                    loading={loading}
                    name="parentId"
                    setSearchValues={setSearchValues}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Field
                    as={TextField}
                    name="isDeleted"
                    value="Is it active?"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formikProps.values.isDeleted}
                              onChange={(e) =>
                                formikProps.setFieldValue(
                                  "isDeleted",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          labelPlacement="start"
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Field
                    as={TextField}
                    name="isMenuItem"
                    value="Is it Menu Item?"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formikProps.values.isMenuItem}
                              onChange={(e) =>
                                formikProps.setFieldValue(
                                  "isMenuItem",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          labelPlacement="start"
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Field
                    as={TextField}
                    name="isNodeItem"
                    value="Is It Node Item?"
                    fullWidth
                    disabled={!formikProps.values?.parentId}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formikProps.values.isNodeItem}
                              onChange={(e) =>
                                formikProps.setFieldValue(
                                  "isNodeItem",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          labelPlacement="start"
                        />
                      ),
                    }}
                  />
                </Grid>
                {formikProps.values.isNodeItem && (
                  <>
                    <FieldArray name="categoryProductAttribute">
                      {({ push, remove, form }) => (
                        <>
                          {Object.keys(
                            formikProps.values?.categoryProductAttribute
                          ).map((index) => {
                            const key =
                              formikProps.values?.categoryProductAttribute[
                                index
                              ].ginesysAttribute;
                            return (
                              !formikProps.values?.categoryProductAttribute[
                                index
                              ].isDeleted && (
                                <>
                                  <Grid container key={index} spacing={2} m={0}>
                                    <Grid item xs={1.5}>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          borderColor: "#344767",
                                          color: "#344767",
                                          margin: "0px",
                                          minWidth: "100px",
                                        }}
                                      >
                                        {key}
                                      </Button>
                                    </Grid>

                                    <Grid item xs={3}>
                                      <FormControl fullWidth>
                                        <Autocomplete
                                          size="small"
                                          options={
                                            attributeListData
                                              ? attributeListData.masterAttributes.results
                                                  ?.filter(
                                                    (item) =>
                                                      !item.isDeleted &&
                                                      item.ginesysAttribute ===
                                                        key
                                                  )
                                                  .map((attr) => attr.name)
                                              : ""
                                          }
                                          value={
                                            formikProps.values
                                              ?.categoryProductAttribute[index]
                                              ?.name || ""
                                          }
                                          name={`categoryProductAttribute[${index}].name`}
                                          onChange={(event, newValue) => {
                                            formikProps.setFieldValue(
                                              `categoryProductAttribute[${index}].values`,
                                              []
                                            );
                                            formikProps.setFieldValue(
                                              `categoryProductAttribute[${index}].name`,
                                              newValue
                                            );
                                            formikProps.setFieldTouched(
                                              `categoryProductAttribute[${index}].name`,
                                              true,
                                              false
                                            );
                                            handleNameChange(
                                              index,
                                              newValue,
                                              formikProps
                                            );
                                          }}
                                          disablePortal={true}
                                          IconComponent={ArrowDropDownIcon}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              label="Name"
                                              placeholder="Add name"
                                              // name="name"
                                              InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                  ...params.InputProps.style,
                                                  padding: "0",
                                                  height: "40px",
                                                },
                                                input: {
                                                  padding: "10px 14px",
                                                },
                                              }}
                                              error={
                                                formikProps.touched
                                                  .categoryProductAttribute &&
                                                formikProps.touched
                                                  .categoryProductAttribute[
                                                  index
                                                ] &&
                                                formikProps.touched
                                                  .categoryProductAttribute[
                                                  index
                                                ].name &&
                                                formikProps.errors
                                                  .categoryProductAttribute &&
                                                formikProps.errors
                                                  .categoryProductAttribute[
                                                  index
                                                ] &&
                                                formikProps.errors
                                                  .categoryProductAttribute[
                                                  index
                                                ].name
                                              }
                                              helperText={
                                                formikProps.touched
                                                  .categoryProductAttribute &&
                                                formikProps.touched
                                                  .categoryProductAttribute[
                                                  index
                                                ] &&
                                                formikProps.touched
                                                  .categoryProductAttribute[
                                                  index
                                                ].name &&
                                                formikProps.errors
                                                  .categoryProductAttribute &&
                                                formikProps.errors
                                                  .categoryProductAttribute[
                                                  index
                                                ] &&
                                                formikProps.errors
                                                  .categoryProductAttribute[
                                                  index
                                                ].name
                                              }
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <FormControl fullWidth>
                                        <InputLabel>Type</InputLabel>
                                        <Field
                                          as={Select}
                                          disabled
                                          name={`categoryProductAttribute[${index}].type`}
                                          label="Type"
                                          error={
                                            formikProps?.touched
                                              ?.categoryProductAttribute?.[
                                              index
                                            ]?.type &&
                                            Boolean(
                                              formikProps?.errors
                                                ?.categoryProductAttribute?.[
                                                index
                                              ]?.type
                                            )
                                          }
                                          helperText={
                                            formikProps?.touched
                                              ?.categoryProductAttribute?.[
                                              index
                                            ]?.type &&
                                            formikProps?.errors
                                              ?.categoryProductAttribute?.[
                                              index
                                            ]?.type
                                          }
                                          style={{ padding: "10px" }}
                                          onChange={(e) => {
                                            formikProps.setFieldValue(
                                              `categoryProductAttribute[${index}].type`,
                                              e.target.value
                                            );
                                            formikProps.setFieldTouched(
                                              `categoryProductAttribute[${index}].type`,
                                              e.target.value
                                            );
                                            formikProps.validateField(
                                              `categoryProductAttribute[${index}].type`,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <MenuItem value="Open">Open</MenuItem>
                                          <MenuItem value="Closed">
                                            Closed
                                          </MenuItem>
                                        </Field>
                                        {formikProps.touched
                                          .categoryProductAttribute &&
                                          formikProps.touched
                                            .categoryProductAttribute[index] &&
                                          formikProps.touched
                                            .categoryProductAttribute[index]
                                            .type &&
                                          formikProps.errors
                                            .categoryProductAttribute &&
                                          formikProps.errors
                                            .categoryProductAttribute[index] &&
                                          formikProps.errors
                                            .categoryProductAttribute[index]
                                            .type && (
                                            <Typography
                                              fontSize={12}
                                              color="error"
                                            >
                                              {
                                                formikProps.errors
                                                  .categoryProductAttribute[
                                                  index
                                                ].type
                                              }
                                            </Typography>
                                          )}
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Autocomplete
                                        size="small"
                                        multiple
                                        disablePortal={true}
                                        id={`values-autocomplete-${index}`}
                                        name={`categoryProductAttribute[${index}].values`}
                                        disabled={
                                          formikProps.values
                                            .categoryProductAttribute[index]
                                            ?.type === "Open"
                                        }
                                        options={getValueOptionsForAttribute(
                                          formikProps.values
                                            .categoryProductAttribute?.[index]
                                            ?.name
                                        )}
                                        value={
                                          formikProps.values
                                            .categoryProductAttribute[index]
                                            ?.values || []
                                        }
                                        onChange={(event, newValue) => {
                                          formikProps.setFieldValue(
                                            `categoryProductAttribute[${index}].values`,
                                            newValue
                                          );
                                        }}
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Values"
                                            name="values"
                                            placeholder="Add values"
                                            InputProps={{
                                              ...params.InputProps,
                                              style: {
                                                ...params.InputProps.style,
                                                overflow: "auto",
                                                maxHeight: "40px",
                                              },
                                            }}
                                            error={
                                              formikProps.touched
                                                .categoryProductAttribute &&
                                              formikProps.touched
                                                .categoryProductAttribute[
                                                index
                                              ] &&
                                              formikProps.touched
                                                .categoryProductAttribute[index]
                                                .values &&
                                              Boolean(
                                                formikProps.errors
                                                  .categoryProductAttribute &&
                                                  formikProps.errors
                                                    .categoryProductAttribute[
                                                    index
                                                  ] &&
                                                  formikProps.errors
                                                    .categoryProductAttribute[
                                                    index
                                                  ].values
                                              )
                                            }
                                            helperText={
                                              formikProps.touched
                                                .categoryProductAttribute &&
                                              formikProps.touched
                                                .categoryProductAttribute[
                                                index
                                              ] &&
                                              formikProps.touched
                                                .categoryProductAttribute[index]
                                                .values &&
                                              formikProps.errors
                                                .categoryProductAttribute &&
                                              formikProps.errors
                                                .categoryProductAttribute[
                                                index
                                              ] &&
                                              formikProps.errors
                                                .categoryProductAttribute[index]
                                                .values
                                            }
                                            style={{ maxHeight: "40px" }}
                                            sx={{
                                              "& .MuiInput-underline:after": {
                                                borderBottom: "none",
                                              },
                                              "& .MuiInput-underline:before": {
                                                borderBottom: "none", // normal state
                                              },
                                              "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                                                {
                                                  borderBottom: "none", // hover state
                                                },
                                              "& .MuiInput-underline.Mui-focused:after":
                                                {
                                                  borderBottom: "none", // focused state
                                                },
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Field
                                        as={TextField}
                                        name={`categoryProductAttribute[${index}].isVariant`}
                                        value=" "
                                        label="Variant"
                                        fullWidth
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    formikProps.values
                                                      .categoryProductAttribute[
                                                      index
                                                    ]?.isVariant
                                                  }
                                                  onChange={(e) =>
                                                    formikProps.setFieldValue(
                                                      `categoryProductAttribute[${index}].isVariant`,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                              }
                                              // labelPlacement="start"
                                            />
                                          ),
                                        }}
                                      />
                                      {formikProps.errors
                                        .categoryProductAttribute &&
                                        formikProps.errors
                                          .categoryProductAttribute[index] &&
                                        formikProps.errors
                                          .categoryProductAttribute[index]
                                          .isVariant && (
                                          <div>
                                            {
                                              formikProps.errors
                                                .categoryProductAttribute[index]
                                                .isVariant
                                            }
                                          </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Field
                                        as={TextField}
                                        name={`categoryProductAttribute[${index}].isMandatory`}
                                        value=" "
                                        label="Manadatory"
                                        fullWidth
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    formikProps.values
                                                      .categoryProductAttribute[
                                                      index
                                                    ]?.isMandatory
                                                  }
                                                  onChange={(e) =>
                                                    formikProps.setFieldValue(
                                                      `categoryProductAttribute[${index}].isMandatory`,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                              }
                                              // labelPlacement="start"
                                            />
                                          ),
                                        }}
                                      />
                                      {formikProps.errors
                                        .categoryProductAttribute &&
                                        formikProps.errors
                                          .categoryProductAttribute[index] &&
                                        formikProps.errors
                                          .categoryProductAttribute[index]
                                          .isMandatory && (
                                          <div>
                                            {
                                              formikProps.errors
                                                .categoryProductAttribute[index]
                                                .isMandatory
                                            }
                                          </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          borderColor: "#344767",
                                          color: "#344767",
                                          borderRadius: "20px",
                                          margin: "0px",
                                        }}
                                        onClick={() => {
                                          formikProps.setFieldValue(
                                            `categoryProductAttribute[${index}].isDeleted`,
                                            true
                                          );
                                        }}
                                        // onClick={handleDeleteEvent}
                                        disabled={
                                          formikProps.values
                                            ?.categoryProductAttribute[index]
                                            .isDeleted ||
                                          Object.keys(ginesysAttribute).length >
                                            +index
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </>
                              )
                            );
                          })}
                          <Grid item xs={12}>
                            <MDButton
                              variant="gradient"
                              color="secondary"
                              fullWidth
                              onClick={() => {
                                push({
                                  categoryProductId: categoryData
                                    ? categoryData.id
                                    : null,
                                  ginesysAttribute:
                                    formikProps.values
                                      ?.categoryProductAttribute[
                                      formikProps.values
                                        ?.categoryProductAttribute.length - 1
                                    ].ginesysAttribute,
                                  name: "",
                                  type: "",
                                  isVariant: false,
                                  isMandatory: false,
                                });
                              }}
                            >
                              + Add Another Node
                            </MDButton>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid
                container
                xs={12}
                sx={{ mt: 4, ml: 2, pb: 2 }}
                flexDirection={"row-reverse"}
                gap={3}
              >
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <MDButton
                  color="dark"
                  onClick={handleClose}
                  variant="outlined"
                  circular={true}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProductCategoryFormComponent;

