import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
} from "react";

import { Grid, Box, Autocomplete, TextField } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import ReportTabs from "./reportTab";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import { GET_STORES_LIST_NEW } from "services/store-service";
import { BRANDS_LIST } from "services/brand-service";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import { GET_GMV_REPORT } from "services/report-service";
import { GET_SALES_REPORT } from "services/report-service";
import { GET_RETURN_AND_REFUND_REPORT } from "services/report-service";
import { GET_STOCK_REPORT } from "services/report-service";
import { GET_EVENT_REPORT } from "services/report-service";
import { SearchContext } from "context";

const user =
  localStorage.getItem("userDetails") &&
  localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {};

export const DropDownSelectionContext = createContext();

function Reports() {
  const [tabValue, setTabValue] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandOptions, setBrandOptions] = useState([]);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST_NEW, {
    client: dashboardServiceClient,
    variables: { input: { take: 100, skip: 0 } },
  });

  const { data: brandCategoryStoreList, refetch: brandCategoryStoreRefetch } =
    useQuery(GET_BRAND_CATEGORY_STORE, {
      client: dashboardServiceClient,
      variables: {
        take: 1000000,
        skip: 0,
        filter: { store: { id: selectedStore?.id }, isDeleted: false },
      },
    });

  const { loading, data, error, refetch } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      filter: {},
    },
  });
  const Brand = data?.brandlist?.results;

  // Start Report API

  const {
    loading: gmvloading,
    data: gmvData,
    error: gmvError,
    refetch: gmvRefetch,
  } = useQuery(GET_GMV_REPORT, {
    client: dashboardServiceClient,
    variables: {
      filter: {},
    },
  });

  const {
    loading: salesloading,
    data: salesData,
    error: salesError,
    refetch: salesRefetch,
  } = useQuery(GET_SALES_REPORT, {
    client: dashboardServiceClient,
    variables: {
      filter: {},
    },
  });

  const {
    loading: returnloading,
    data: returnData,
    error: returnError,
    refetch: returnRefetch,
  } = useQuery(GET_RETURN_AND_REFUND_REPORT, {
    client: dashboardServiceClient,
    variables: {
      filter: {},
    },
  });

  const {
    loading: stockloading,
    data: stockData,
    error: stockError,
    refetch: stockRefetch,
  } = useQuery(GET_STOCK_REPORT, {
    client: dashboardServiceClient,
    variables: {
      filter: {},
    },
  });

  const {
    loading: eventloading,
    data: eventData,
    error: eventError,
    refetch: eventRefetch,
  } = useQuery(GET_EVENT_REPORT, {
    client: dashboardServiceClient,
    variables: {
      filter: {},
    },
  });

  const gmvDataRows = gmvData?.gmvReports ?? [];
  const salesDataRows = salesData?.salesReports ?? [];
  const returnRefundDataRows = returnData?.returnAndRefundReports ?? [];
  const stockDataRows = stockData?.stockReports ?? [];
  const eventDataRows = eventData?.eventReports ?? [];

  const refetchFilters = () => {
    const newFilter = {
      date: selectedDate,
      brand: selectedBrand,
      store: selectedStore,
    };
    gmvRefetch({
      filter: newFilter,
    });
    salesRefetch({
      filter: newFilter,
    });
    returnRefetch({
      filter: newFilter,
    });
    stockRefetch({
      filter: newFilter,
    });
    eventRefetch({
      filter: newFilter,
    });
  };

  useEffect(() => {
    refetchFilters();
  }, [selectedBrand, selectedStore, selectedDate]);

  // End Report API

  const commonSelections = useMemo(
    () => ({
      selectedStoreId: selectedStore?.id,
      selectedBrandId: selectedBrand?.id,
      user,
      tabValue,
      Brand: data?.brandlist?.results,
    }),
    [selectedStore, selectedBrand, user, tabValue, data]
  );

  const AdsDropDown = ({
    labelId,
    label,
    id,
    name,
    iconComponent,
    onChange,
    menuItems = [],
    value,
    disabled,
  }) => {
    return (
      <Box mr={1} width={200}>
        <Autocomplete
          onChange={onChange}
          disablePortal
          disabled={disabled}
          id={id}
          options={menuItems}
          value={value}
          sx={{
            "& .MuiInputBase-root": {
              height: 40,
              borderRadius: "20px",
              px: 2,
              backgroundColor: "white.main",
            },
          }}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </Box>
    );
  };
  useEffect(() => {
    if (user && user.role !== "admin") {
      setSelectedBrand(Brand?.find((brand) => brand.id == user?.userId));
    } else if (user && user.role === "admin") {
      setSelectedBrand(selectedBrand);
    }
  }, [user]);

  useEffect(() => {
    if (selectedStore) {
      brandCategoryStoreRefetch({
        variables: {
          take: 1000000,
          skip: 0,
          filter: { store: { id: selectedStore?.id }, isDeleted: false },
        },
      }).then(({ data }) => {
        const filteredBrands = {};
        data?.brandCategoryStores?.results.forEach((bcs) => {
          if (filteredBrands[bcs?.brand?.id] == null) {
            filteredBrands[bcs?.brand?.id] = bcs?.brand;
          }
        });
        setBrandOptions(Object.values(filteredBrands));
        setSelectedBrand(null);
        // setSelectedBrand(Object.values(filteredBrands)?.[0])
      });
    } else {
      setBrandOptions(Brand);
    }
  }, [selectedStore, Brand]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error?.message}</p>;
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <Grid container mt={2}>
        <Grid item xs={12} display="flex">
          <AdsDropDown
            labelId="demo-simple-select-label"
            label="Select Store"
            id="demo-simple-select"
            name="store"
            iconComponent={() => (
              <ArrowDropDownIcon style={{ marginRight: "18px" }} />
            )}
            menuItems={storeList?.stores?.results
              ?.filter((item) => item.status === "Active")
              .map((item) => ({ label: item.name, value: item }))}
            value={selectedStore?.name || ""}
            onChange={(e, selectedOption) => {
              setSelectedStore(selectedOption?.value);
            }}
          />

          {
            <AdsDropDown
              labelId="demo-simple-select-label"
              label="Select Brand"
              id="demo-simple-select"
              name="brand"
              iconComponent={() => (
                <ArrowDropDownIcon style={{ marginRight: "18px" }} />
              )}
              menuItems={
                brandOptions?.map((item) => {
                  // const brandProfile = brand.brandMetaData?.find(
                  //   (meta) => meta.key === "brandCompanyProfile"
                  // )?.value;
                  return { label: item.name, value: item.id };
                }) || []
              }
              value={selectedBrand?.name}
              onChange={(e, selectedOption) => {
                setSelectedBrand(selectedOption?.value);
              }}
              disabled={user && user.role === "admin" ? false : true}
            />
          }

          <AdsDropDown
            labelId="demo-simple-select-label"
            label="Select Date"
            id="demo-simple-select"
            name="date"
          />
        </Grid>
        <Box
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <DropDownSelectionContext.Provider value={commonSelections}>
            <ReportTabs
              key={selectedBrand?.id || "_noBrand"}
              tabValue={tabValue}
              setTabValue={setTabValue}
              user={user}
              // selectedBrandId={selectedBrandId}
              // Brand={Brand}
              gmvRows={gmvDataRows}
              salesRows={salesDataRows}
              returnRefundRows={returnRefundDataRows}
              stockRows={stockDataRows}
              eventRows={eventDataRows}
            />
          </DropDownSelectionContext.Provider>
        </Box>
      </Grid>
    </DashboardLayout>
  );
}

export default Reports;
