import React from "react";

// MUI components
import {
    Grid,
    TextField,
    IconButton,
    Typography,
    Box,
} from "@mui/material";
//Yup And Formik Components
import { Field, FieldArray, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
// Icons And Images
import { DeleteOutline as DeleteIcon, AddOutlined as AddIcon } from "@mui/icons-material";
// Apollo Clent Imports 
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_STORE_METADATA, UPDATE_STORE_METADATA, GET_STORES } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";

// Custom Components 
import MDButton from "components/MDButton";
import { useNotification } from "context";
import Loader from "components/MDLoader";

// Validation schema for the form using Yup
const validationSchema = Yup.object().shape({
    entrances: Yup.array()
      .of(
        Yup.object().shape({
            id: Yup.mixed().nullable(), 
          name: Yup.string()
            .required("Entrance name is required")
            .max(100, "Maximum 100 Characters allowed")
            .test(
                'unique-name',
                'Entrance name must be unique',
                function (value) {
                    // this function is to check the duplicate entry on entrance name .
                  const entrances = this?.options?.context?.entrances;
                  if (!entrances) return true;               
                  const namesArray = entrances?.map((entrance) => entrance?.name);
                  return namesArray?.indexOf(value) === namesArray?.lastIndexOf(value);
                }
              ),              
          details: Yup.string().max(100,"Maximum 100 Characters allowed"),
        })
      )
      .min(1, "At least one entrance is required"),
  });
  

// Custom hook for managing store metadata mutation
const useStoreMetadataMutation = (
    storeData,
    storeMetadata,
    setNotification,
    handleTabChange,
    setStoreData
) => {
    const mutationOptions = {
        client: dashboardServiceClient,
        refetchQueries: [{ query: GET_STORES, variables: { listStoreFilter: { take: 100, skip: 0, search: "" } } }],
    };

    const [createStoreMutation] = useMutation(CREATE_STORE_METADATA, mutationOptions);
    const [updateStoreMutation] = useMutation(UPDATE_STORE_METADATA, mutationOptions);

    const handleSubmit = (values) => {
        const mutation = storeMetadata && storeMetadata.id ? updateStoreMutation : createStoreMutation;
        const mutationInput = storeMetadata && storeMetadata.id
            ? {
                updateStoreMetadataInput: {
                    id: storeMetadata.id,
                    key: "entrances",
                    value: values, // The data object for entrances
                },
            }
            : {
                createStoreMetaDataInput: {
                    storeId: storeData.id,
                    key: "entrances",
                    value: values,
                },
            };

        mutation({ variables: mutationInput })
            .then(() => {
                setStoreData(storeData);
                handleTabChange(null, 3);
                setNotification({
                    color: "success",
                    isVisible: true,
                    message: storeData && storeData.id
                        ? "Store entrance data updated successfully"
                        : "Store entrance data created successfully",
                });
            })
            .catch((error) => {
                console.error("Error:", error?.message);
                setNotification({
                    color: "error",
                    isVisible: true,
                    message: error?.message || "Something went wrong",
                });
            });
    };

    return handleSubmit;
};

const EntranceForm = ({ storeData, handleClose, handleTabChange, setStoreData }) => {
    const { setNotification } = useNotification();

    // Fetching existing entrance metadata if available
    const { data, loading, error } = useQuery(GET_STORES, {
        client: dashboardServiceClient,
        variables: { listStoreFilter: { take: 10, skip: 0, search: "", filter:{id : storeData?.id} } },
    });

    const storeData1 = data?.stores?.results?.find((store) => store?.id === storeData?.id);
    const isEntrancesPresent =
        (storeData1 &&
            storeData1?.storeMetaData &&
            storeData1?.storeMetaData.length &&
            [...storeData1?.storeMetaData]
                .reverse()
                .find((item) => item.key === "entrances")) ||
        null;

    // Define initial values based on fetched metadata
    const initialValues = isEntrancesPresent ? isEntrancesPresent.value : { entrances: [{ id: null, name: "", details: "" }] };

    const handleSubmit = useStoreMetadataMutation(
        storeData,
        isEntrancesPresent,
        setNotification,
        handleTabChange,
        setStoreData
    );

    if (loading) return <Loader />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Formik
            initialValues={initialValues} 
            validationSchema={validationSchema} 
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values); 
                setSubmitting(false); 
            }}
        >
            {({errors,touched, values, isSubmitting, setFieldValue }) => (
                <Form>
                    <FieldArray name="entrances">
                        {({ remove, push }) => (
                            <>
                                {values.entrances.map((entrance, index) => (
                                    <Box key={index} mb={4}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" >
                                                <Grid item xs={6} >
                                                    <Typography variant="h6" gutterBottom>
                                                        Entrance {index + 1} {/* Display the entrance number */}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} display="contents" >
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => remove(index)}
                                                        color="error"
                                                        disabled={values.entrances.length === 1} // Disable delete if only one entrance remains
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} >
                                                {/* Field for Entrance Name */}
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    name={`entrances.${index}.name`}
                                                    label="Entrance Name"
                                                    variant="outlined"
                                                    error={
                                                        touched.entrances?.[index]?.name &&
                                                        !!errors.entrances?.[index]?.name
                                                      }
                                                    helperText={<ErrorMessage name={`entrances.${index}.name`} />} 
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    name={`entrances.${index}.details`}
                                                    label="Entrance Details"
                                                    variant="outlined"
                                                    error={
                                                        touched.entrances?.[index]?.details &&
                                                        !!errors.entrances?.[index]?.details
                                                      }
                                                    helperText={<ErrorMessage name={`entrances.${index}.details`} />} 
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                                <Box mb={2} display="flex" justifyContent="end">
                                    <MDButton
                                        variant="outlined"
                                        color="dark"
                                        onClick={() => push({ id: null, name: "", details: "" })} 
                                        startIcon={<AddIcon />}
                                        circular={true}
                                    >
                                        Add Another Entrance
                                    </MDButton>
                                </Box>
                            </>
                        )}
                    </FieldArray>
                    <Grid container spacing={2} mt={4} justifyContent="flex-end">
                        <Grid item>
                            {/* Cancel button */}
                            <MDButton variant="outlined" circular={true} color="dark" onClick={handleClose}>
                                Cancel
                            </MDButton>
                        </Grid>
                        <Grid item>
                            {/* Save button */}
                            <MDButton
                                type="submit"
                                variant="contained"
                                circular={true}
                                color="dark"
                            >
                                Save
                            </MDButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default EntranceForm;
