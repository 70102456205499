import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Autocomplete, Box, Chip, FormHelperText } from "@mui/material";
import {
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { vmServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import { useNotification } from "context";
import "react-quill/dist/quill.snow.css"; // import styles
import MDButton from "components/MDButton";
import Loader from "components/MDLoader";
import { UPDATE_FIXTURE } from "services/advertisment-service";
import { LIST_ALL_DEVICE } from "services/advertisment-service";
import { GET_STORES_LIST_NEW } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import { getFlatIdMap } from "layouts/device-Master/utils";
import { useGridContext } from ".";


const validationSchema = Yup.object({

    fixtureType: Yup.string().required("Fixture Type is required"),
    fixtureName: Yup.string().required("Fixture Name is required"),
    opsCode: Yup.string().required("ops code is required"),
    businessCode: Yup.string().required("business code is required"),
    storeId: Yup.string().required("Store is required"),
    // section: Yup.string().required("Section is required"),
    // subSection: Yup.string().required("Sub-Section is required"),
    locationId: Yup.string().required("Location is required"),
    subLocationId: Yup.string().required("Sub Location is required"),
    subLocationTypeId: Yup.string().required("Sub Location Type is required"),
    assetTypeCode: Yup.string().required("Asset type code is required"),
    // locationId: Yup.string().required("Location ID is required"),
    devices: Yup.array()

});

const FixtureType = {
    WallScreen: "Wall Screen",
    StaticVisual: "Static Visual",
    FrontCounter: "Front Counter",
    BackCounter: "Back Counter",
    Cabinet: "Cabinet",
    WallScreenBoxes: "Wall Screen Boxes",
    Browser: "Browser",
    WallFixture: "Wall Fixture",
    TableDisplay: "Table Display",
    WallDisplay: "Wall Display",
    CashRegister: "Cash Register",
    CircularSection: "Circular Section",
    PerforatedDisplay: "Perforated Display",
    SSMetalTable: "SS Metal Table",
    Seating: "Seating",
    Column: "Column",
    Wall: "Wall",
    InfinityWall: "Infinity Wall",
    FootwearTable: "Footwear Table",
    FootwearSeating: "Footwear Seating",
    DisplayTable: "Display Table",
    DisplayTower: "Display Tower",
    Cart: "Cart",
    TrialRoomDoorFrame: "Trial room Door Frame",
    NextToFashionTrialRoom: "Next to fashion Trial room",
  }
  Object.freeze(FixtureType);

const useMasterFixtureMutation = (fixtureData, handleClose, setNotification, callback = null) => {
    const {externalGridRef} = useGridContext()
    const mutationOptions = {
        client: vmServiceClient,
        // refetchQueries: [{
        //     query: LIST_ALL_DEVICE, variables: {
        //         "page": 1,
        //         "limit": 20,
        //         "sortBy": "updatedAt",
        //         "order": "DESC",
        //     },
        //     fetchPolicy: "network-only",
        // }],
    }

    const [updateFixtureMutation] = useMutation(
        UPDATE_FIXTURE,
        mutationOptions
    )

    const handleSubmit = (values) => {

        const matchingFixture = Object.entries(FixtureType).find(([typeKey, typeValue]) => values.fixtureType === typeValue);
        const fixtureTypeKey = matchingFixture ? matchingFixture[0] : null;
        const payload = {
            fixtureType: fixtureTypeKey || values.fixtureType,
            fixtureName: values.fixtureName,
            opsCode: values.opsCode,
            businessCode: values.businessCode,
            storeId: values.storeId,
            locationId: values.locationId,
            subLocationId: values.subLocationId,
            subLocationTypeId: values.subLocationTypeId,
            assetTypeCode: values.assetTypeCode,
            isShared: values.isShared,
            deviceIds: values?.devices?.map(device => device?.uuid),
        }

        const mutation = updateFixtureMutation;
        const mutationInput =
            fixtureData && fixtureData.uuid
                ? { input: { uuid: fixtureData.uuid, ...payload } }
                : { input: payload };

        mutation({ variables: mutationInput })
            .then((res) => {
                handleClose();
                setNotification({
                    color: "success",
                    isVisible: true,
                    message: "Fixture  updated successfully"
                });
                if (callback) callback();
                if (externalGridRef) {
                    externalGridRef.current?.refreshServerSide({purge:true});
                }
            })
            .catch((error) => {
                console.error("Error:", error?.message);
                setNotification({
                    color: "error",
                    isVisible: true,
                    message: error?.message || "Something went wrong",
                });
            });
    };

    return handleSubmit;
}

const FixtureForm = ({
    fixtureData,
    user,
    handleClose,
    callback
}) => {
    const { setNotification } = useNotification();
    const { loading: deviceLoading, error: deviceError, data: deviceData } = useQuery(LIST_ALL_DEVICE, {
        client: vmServiceClient,
        variables: {
            page: 1,
            limit: 9999,
            sortBy: "code",
            order: "ASC",
            relations: ["fixture"],
            "filter": {
                "isDeleted": false,
            }
        },
    });
    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
    } = useQuery(GET_STORES_LIST_NEW, {
        client: dashboardServiceClient,
        variables: { input: { take: 100, skip: 0 } },
    });
    const storeIdMap = React.useMemo(
    () =>
        getFlatIdMap(
        storeList?.stores?.results?.filter((store) => !store.isDeleted) || []
        ),
    [storeList]
    );
    const handleSubmit = useMasterFixtureMutation(fixtureData, handleClose, setNotification, callback);
    return (
        <Formik
            initialValues={{
                fixtureType: fixtureData?.fixtureType || "",
                fixtureName: fixtureData?.fixtureName || "",
                opsCode: fixtureData?.opsCode || "",
                businessCode: fixtureData?.businessCode || "",
                storeId: fixtureData?.storeId || "",
                locationId: fixtureData?.locationId || "",
                subLocationId: fixtureData?.subLocationId || "",
                subLocationTypeId: fixtureData?.subLocationTypeId || "",
                assetTypeCode: fixtureData?.assetTypeCode || "",
                code: fixtureData?.code || "",
                isShared: fixtureData?.isShared || false,
                devices: fixtureData?.devices || []
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
            }}

        >
            {({
                values,
                setFieldValue,
                setFieldTouched,
                errors,
                touched,
                validateField,
                isSubmitting,
                handleChange,
                handleBlur
            }) => {
                return (
                    <Form>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                <Field
                                as={TextField}
                                name="fixtureType"
                                label="Fixture Type"
                                value={fixtureData?.fixtureType}
                                disabled
                                sx={{ backgroundColor: "#F0F2F5" }}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                as={TextField}
                                name="store"
                                label="Store"
                                value={storeIdMap[fixtureData?.storeId]?.name}
                                disabled
                                sx={{ backgroundColor: "#F0F2F5" }}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                as={TextField}
                                name="location"
                                label="Location"
                                value={storeIdMap[fixtureData?.locationId]?.division?.title}
                                disabled
                                sx={{ backgroundColor: "#F0F2F5" }}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                as={TextField}
                                name="subLocation"
                                label="Sub Location"
                                value={storeIdMap[fixtureData?.subLocationId]?.name}
                                disabled
                                sx={{ backgroundColor: "#F0F2F5" }}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                as={TextField}
                                name="subLocationType"
                                label="Batch Code"
                                // value={storeIdMap[fixtureData?.subLocationTypeId]?.batchCode}
                                value={fixtureData?.subLocationTypeId}
                                disabled
                                sx={{ backgroundColor: "#F0F2F5" }}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                as={TextField}
                                name="assetTypeCode"
                                label="Fixture Unit"
                                // value={storeIdMap[fixtureData?.assetTypeCode]?.code}
                                value={fixtureData?.assetTypeCode}
                                disabled
                                sx={{ backgroundColor: "#F0F2F5" }}
                                fullWidth
                                />
                            </Grid>

                            {fixtureData && <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="code"
                                    label="Fixture Code"
                                    fullWidth
                                    disabled
                                    sx={{ backgroundColor: "#F0F2F5" }}
                                    error={touched.code && !!errors.code}
                                    helperText={touched.code && errors.code}
                                />
                            </Grid>}
                            <Grid item xs={12}><hr/><hr/></Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="fixtureName"
                                    label="Fixture Name"
                                    fullWidth
                                    error={touched.fixtureName && !!errors.fixtureName}
                                    helperText={touched.fixtureName && errors.fixtureName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="opsCode"
                                    label="Ops Code"
                                    fullWidth
                                    error={touched.opsCode && !!errors.opsCode}
                                    helperText={touched.opsCode && errors.opsCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="businessCode"
                                    label="Business Code"
                                    fullWidth
                                    error={touched.businessCode && !!errors.businessCode}
                                    helperText={touched.businessCode && errors.businessCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    renderInput={(params) => <TextField multiline {...params} label={'Map Devices'} />}
                                    options={deviceData?.findAllDevices?.results?.filter(device => !device.fixture) || []}
                                    onChange={(_, devices) => {
                                        setFieldValue("devices", devices);
                                    }}
                                    value={values?.devices}
                                    getOptionLabel={(option) => option.code || option?.deviceName}
                                    renderOption={(props, option) => {
                                        if (values?.devices.map(device => device.uuid).includes(option.uuid))
                                            return <Box sx={{ background: '#F0F2F5' }} {...props} value={option}>{option.code || option?.deviceName}</Box>
                                        // if (option.value == value)
                                        //     return <Box sx={{background:'#F0F2F5'}} {...props} value={option}>{option.label}</Box>

                                        return <Box {...props} value={option}>{option.code || option?.deviceName}</Box>
                                    }}
                                    renderTags={(tagValue, getTagProps) => {
                                        return tagValue.map((option, index) => (
                                            <Chip {...getTagProps({ index })} label={option?.code || option?.deviceName} />
                                        ));
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                spacing={2}
                                alignItems="center"
                                xs={12}
                            >
                                <Grid item>
                                    <Typography
                                        variant="h7"
                                        fontWeight="bold"
                                        fontSize="13px"
                                    >
                                        Shared
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        checked={values.isShared}
                                        onChange={(event) =>
                                            setFieldValue(
                                                "isShared",
                                                event.target.checked
                                            )
                                        }
                                        name="isShared"
                                        color="primary"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                                    <MDButton
                                        type="submit"
                                        variant="contained"
                                        circular={true}
                                        color="dark"
                                    >
                                        Save
                                    </MDButton>
                                    <MDButton onClick={handleClose} circular={true} variant="outlined" color="dark">
                                        Cancel
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FixtureForm;
