import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Autocomplete,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form, Field, FieldArray } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Yup from "yup";
import { dashboardServiceClient } from "graphql/client";
import {
  CREATE_STORE_LOCATION,
  UPDATE_STORE_LOCATION,
  DELETE_STORE_LOCATION,
} from "services/store-service";
import { useNotification } from "context";
import MDButton from "components/MDButton";
import Loader from "components/MDLoader";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Add, BorderColor, ContentCopy, DeleteOutlineOutlined } from "@mui/icons-material";
import { GET_CATEGORIES } from "services/product-category-service";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const defaultSection = [
  {
    __typename: "StoreLocation",
    id: "95692eb1-0cc9-47a2-9f10-0784ce1c66c8",
    code: "",
    locationName: "",
    division: "",
    subLocations: [
      {
        __typename: "SubLocation",
        id: "",
        name: "",
        assetTypes: [
          {
            __typename: "AssetType",
            id: "",
            subLocationId: "",
            name: "",
            size: "",
            quantity: "0",
          },
        ],
      },
    ],
  },
];

const mainLocationCreate = {
  __typename: "StoreLocation",
  id: "",
  code: "",
  locationName: "",
  division: "",
  subLocations: [
    {
      __typename: "SubLocation",
      id: "",
      name: "",
      assetTypes: [
        {
          __typename: "AssetType",
          id: "",
          subLocationId: "",
          name: "",
          size: "",
          quantity: "0",
        },
      ],
    },
  ],
};

const assetsTypeCreate = {
  __typename: "AssetType",
  id: "",
  subLocationId: "",
  name: "",
  size: "",
  quantity: "0",
};

const subLocationsCreate = {
  __typename: "SubLocation",
  id: "",
  name: "",
  assetTypes: [
    {
      __typename: "AssetType",
      id: "",
      subLocationId: "",
      name: "",
      size: "",
      quantity: "0",
    },
  ],
};

const DialogBox = ({ title, description, handleFunction, status, setStatus, funcParams }) => {

  const handleOpen = () => {
    setStatus(true);
  };

  const handleClose = () => {
    setStatus(false);
  };
  return (
    <Dialog
      open={status}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <Grid sx={{ paddingX: "25px" }}>
        <DialogTitle
          style={{
            color: "black",
            fontFamily: "Montserrat",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "black", fontFamily: "Montserrat" }}
          >
            {description}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <MDButton
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "black",
              fontFamily: "Montserrat",
              borderRadius: "50px",
              border: "1px solid black",
              width: "50%",
            }}
            onClick={handleClose}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            style={{
              backgroundColor: "black",
              color: "white",
              fontFamily: "Montserrat",
              borderRadius: "50px",
              width: "50%",
            }}
            onClick={()=>{handleFunction(funcParams); handleClose();}}
          >
            Yes
          </MDButton>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
export const assetTypeOptions = [
  { label: "Back Counter", value: "Back Counter" },
  { label: "Browser", value: "Browser" },
  { label: "Cabinet", value: "Cabinet" },
  { label: "Cart", value: "Cart" },
  { label: "Cash Register", value: "Cash Register" },
  { label: "Circular Section", value: "Circular Section" },
  { label: "Column", value: "Column" },
  { label: "Display Table", value: "Display Table" },
  { label: "Display Tower", value: "Display Tower" },
  { label: "Footwear Seating", value: "Footwear Seating" },
  { label: "Footwear Table", value: "Footwear Table" },
  { label: "Front Counter", value: "Front Counter" },
  { label: "Infinity Wall", value: "Infinity Wall" },
  { label: "Next To Fashion Trial Room", value: "Next to fashion Trial room" },
  { label: "Perforated Display", value: "Perforated Display" },
  { label: "Seating", value: "Seating" },
  { label: "SS Metal Table", value: "SS Metal Table" },
  { label: "Static Visual", value: "Static Visual" },
  { label: "Table Display", value: "Table Display" },
  { label: "Trial Room Door Frame", value: "Trial room Door Frame" },
  { label: "Wall", value: "Wall" },
  { label: "Wall Display", value: "Wall Display" },
  { label: "Wall Fixture", value: "Wall Fixture" },
  { label: "Wall Screen", value: "Wall Screen" },
  { label: "Wall Screen Boxes", value: "Wall Screen Boxes" }
];

const StoreOffer = ({
  divisionData,
  handleClose,
  storeData,
  storeLocation,
  storeLocationRefetch,
}) => {
  let filterlocations = [];
  let isCreate = true;
  const [showDialog, setShowDialog] = useState(false);
  const [deleteFunction, setDeleteFunction] = useState(() => () => {});
  const [deleteParams, setDeleteParams] = useState(null);
  const [key,setKey]=useState(Date.now());
  const handleOpenDialog = (deleteFn, params) => {
    setDeleteFunction(() => deleteFn);
    setDeleteParams(params);
    setShowDialog(true);
  };

  const handleDelete = () => {
    deleteFunction(deleteParams);
    setShowDialog(false);
  };


  if (storeData) {
    const filterlocationsGet = storeLocation?.filter(
      (item) => item.store.id === storeData.id
    );
    if (filterlocationsGet?.length <= 0) {
      isCreate = true;
      filterlocations = defaultSection;
    } else {
      isCreate = false;
      const updatedStoreOffer = filterlocationsGet?.map((location) => {
        const updatedSubLocations = location.subLocations.map((subLocation) => {
          const assetTypeCounts = new Map();
          const assetSizeCounts = new Map();
          const assetTypesDeleteIds = new Map();
          const assetTypesbatchCode = new Map();

          subLocation.assetTypes.forEach((assetType) => {
            const { name, size, id, batchCode } = assetType;
            const key = `${name}-${size}`;
            assetTypeCounts.set(key, (assetTypeCounts.get(key) || 0) + 1);
            assetSizeCounts.set(key, (assetSizeCounts.get(key) || 0) + 1);
            assetTypesDeleteIds.set(
              key,
              (assetTypesDeleteIds.get(key) || "") + id + ","
            );
            assetTypesbatchCode.set(
              key,
              (assetTypesbatchCode.get(key) || "") + batchCode + ","
            );
          });

          const updatedAssetTypes1 = subLocation.assetTypes.filter(
            (assetType) => {
              const { name, size } = assetType;
              const key = `${name}-${size}`;
              if (assetTypeCounts.get(key) > 1) {
                assetTypeCounts.set(key, assetTypeCounts.get(key) - 1);
                return false;
              }
              return true;
            }
          );

          const updatedAssetTypes = updatedAssetTypes1.map((assetType) => {
            const { name, size } = assetType;
            const key = `${name}-${size}`;
            return {
              ...assetType,
              quantity: assetSizeCounts.get(key),
              batchCodes: assetTypesbatchCode.get(key),
              ids: assetTypesDeleteIds.get(key),
            };
          });

          return {
            ...subLocation,
            assetTypes: updatedAssetTypes,
          };
        });
        return {
          ...location,
          subLocations: updatedSubLocations,
        };
      });
      filterlocations = updatedStoreOffer;
    }
  }

  const [storeOffer, setStoreOffer] = useState(
    storeData ? filterlocations : defaultSection
  );

  const [createStoreLocation] = useMutation(CREATE_STORE_LOCATION, {
    client: dashboardServiceClient,
  });

  const [updateStoreLocation] = useMutation(UPDATE_STORE_LOCATION, {
    client: dashboardServiceClient,
  });

  const [deleteStoreLocation] = useMutation(DELETE_STORE_LOCATION, {
    client: dashboardServiceClient,
  });
  const { loading: catLoading, error:catError, data:catData, refetch:catRefetch } = useQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 100000,
        skip: 0,
        search: "",
        orderby: { isDeleted: "ASC", updatedAt: "DESC" },
        parentIsNull:true,
        filter:{isDeleted:false}
      },
      fetchPolicy: "network-only",
    },
  });
  const options = catData?.categoryProduct?.results || [];

  const { setNotification } = useNotification();

  const deleteCall = (id) => {
    deleteStoreLocation({
      variables: { id: id },
      onCompleted: () => {
        storeLocationRefetch({
          variables: {
            take: 10,
            skip: 0,
            filter: {
              isDeleted: false,
            },
          },
        });
      },
      onError: () => {},
    });
  };

  const createCall = (values) => {
    const payload1 = [];
    values.storeOffer.forEach((section) => {
      const sublocationsData = [];
      section.subLocations.forEach((sublocation) => {
        const assetTypesData = [];
        sublocation.assetTypes.forEach((assettype) => {
          assetTypesData.push({
            name: assettype.name,
            size: assettype.size,
            quantity: parseFloat(assettype.quantity),
          });
        });
        sublocationsData.push({
          sublocationName: sublocation.name,
          assetTypes: assetTypesData,
        });
      });
      const locationData = {
        locationName: section.locationName,
        divisionId: section.division?.id,
        sublocations: sublocationsData,
        storeId: storeData.id,
      };
      payload1.push(locationData);
    });

    const payload = payload1;

    createStoreLocation({
      variables: { input: payload },
      onCompleted: () => {
        storeLocationRefetch({
          variables: {
            take: 10,
            skip: 0,
            filter: {
              isDeleted: false,
            },
          },
        });
        handleClose()
        setKey(Date.now());
        setNotification({
          color: "success",
          isVisible: true,
          message:
            storeData && storeData.id
              ? "Store section data updated successfully"
              : "",
        });
      },
      onError: (err) => {
        setNotification({
          color: "error",
          isVisible: true,
          message:err?.message ? err.message : "Something went wrong"
        });
      },    });
  };

  const updateCall = (values) => {
    const payload1 = [];
    values.storeOffer.forEach((section) => {
      const sublocationsData = [];
      section.subLocations.forEach((sublocation) => {
        const assetTypesData = [];
        sublocation.assetTypes.forEach((assettype) => {
          assetTypesData.push({
            name: assettype.name,
            size: assettype.size,
            quantity: parseFloat(assettype.quantity),
          });
        });
        sublocationsData.push({
          id: sublocation?.id,
          sublocationName: sublocation.name,
          assetTypes: assetTypesData,
        });
      });
      const locationData = {
        locationName: section.locationName,
        divisionId: section.division?.id,
        sublocations: sublocationsData,
        id: section.id,
        storeId: storeData.id,
      };
      payload1.push(locationData);
    });

    const payload = payload1;

    updateStoreLocation({
      variables: { input: payload },
      onCompleted: () => {
        storeLocationRefetch({
          variables: {
            take: 10,
            skip: 0,
            filter: {
              isDeleted: false,
            },
          },
        });
        handleClose()
        setKey(Date.now());
        setNotification({
          color: "success",
          isVisible: true,
          message:
            storeData && storeData.id
              ? "Store section data updated successfully"
              : "",
        });
      },
      onError: (err) => {
        setNotification({
          color: "error",
          isVisible: true,
          message:err?.message ? err.message : "Something went wrong"
        });
      },
    });
  };

  const deleteAssetsType = (ids) => {
    const idds = ids.slice(0, -1).split(",");
    idds.forEach((deleteId, index) => {
      deleteCall(deleteId);
    });
  };

  const handleSubmit = (values) => {
    if (!isCreate) {
      updateCall(values);
    } else {
      createCall(values);
    }
  };

  const validationSchema = (data) =>
    Yup.object({
      storeOffer: Yup.array()
        .of(
          Yup.object({
            locationName: Yup.string()
              .required("Location Name is required")
              .max(30, "Maximum 30 Character is Allowed"),
              division: Yup.object({
                id: Yup.string().required("Division is required"),
              }).required("Division is required"),
            subLocations: Yup.array()
              .of(
                Yup.object({
                  name: Yup.string().required("Location Name is required"),
                  assetTypes: Yup.array()
                    .of(
                      Yup.object({
                        name: Yup.string().required(
                          "Location Name is required"
                        ),
                        quantity: Yup.number()
                          .required("Quantity is required")
                          .positive("A number must be positive.")
                          .integer("Quantity must be an integer")
                          .typeError("Quantity must be a number"),
                        size: Yup.string().required("Size is required"),
                      })
                    )
                    .required("At least one section is required"),
                })
              )
              .required("At least one section is required"),
          })
        )
        .required("At least one section is required"),
    });

  return (
    <Formik
      initialValues={{
        storeOffer: storeOffer,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (storeData) {
          handleSubmit(values);
        } 
      }}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <>
          {/* {isSubmitting && <Loader />} */}
          <Form key={key}>
            <FieldArray name="storeOffer" >
              {({ push, remove }) => (
                <>
                  <Grid container spacing={2}>
                    {showDialog && <DialogBox title={"Delete"} description={"Are you sure you want to delete Location?"} 
                      handleFunction={handleDelete}
                      funcParams={setDeleteParams}
                      status={showDialog}
                      setStatus={setShowDialog}
                    />}
                    {Array.isArray(values?.storeOffer) &&
                      values?.storeOffer?.map((section, index) => (
                        <Grid item xs={12} key={index}>
                          <Accordion defaultExpanded={index === 0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>{`Location-${section?.division?.title}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    options={options}
                                    getOptionLabel={(option) => option.title || ""}
                                    value={
                                      options.find((option) => option.id === values.storeOffer[index].division?.id || "") ||
                                      null
                                    }
                                    onChange={(event, newValue) => {
                                      setFieldValue(`storeOffer[${index}].division`, newValue || "");
                                    }}
                                    loading={catLoading}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Dvision"
                                        variant="outlined"
                                        fullWidth
                                        error={touched.storeOffer?.[index]?.division  && Boolean(errors.storeOffer?.[index]?.division)}
                                        helperText={touched.storeOffer?.[index]?.division  && Boolean(errors.storeOffer?.[index]?.division)}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    as={TextField}
                                    name={`storeOffer[${index}].locationName`}
                                    label="Location Description"
                                    fullWidth
                                    error={
                                      touched.storeOffer?.[index]
                                        ?.locationName &&
                                      !!errors.storeOffer?.[index]?.locationName
                                    }
                                    helperText={
                                      touched.storeOffer?.[index]
                                        ?.locationName &&
                                      errors.storeOffer?.[index]?.locationName
                                    }
                                  />
                                </Grid>
                                {/* Child-Accordion Start Here */}
                                <FieldArray
                                  name={`storeOffer[${index}].subLocations`}
                                >
                                  {({
                                    push: pushSubLocation,
                                    remove: removeSubLocation,
                                  }) => (
                                    <Grid ml={2}>
                                      {Array.isArray(
                                        values?.storeOffer[index]?.subLocations
                                      ) &&
                                        values?.storeOffer[
                                          index
                                        ]?.subLocations?.map(
                                          (sublocation, index1) => (
                                            <Grid
                                              item
                                              xs={12}
                                              key={index1}
                                              mt={2}
                                            >
                                              <Accordion
                                                defaultExpanded={index1 === 0}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <ExpandMoreIcon />
                                                  }
                                                >
                                                  <Typography>{`Sub-Location - ${sublocation.name}`}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                      <Field
                                                        as={TextField}
                                                        name={`storeOffer[${index}].subLocations[${index1}].name`}
                                                        label="Section-Location Name"
                                                        fullWidth
                                                        error={
                                                          touched.storeOffer?.[
                                                            index
                                                          ]?.subLocations?.[
                                                            index1
                                                          ]?.name &&
                                                          !!errors.storeOffer?.[
                                                            index
                                                          ]?.subLocations?.[
                                                            index1
                                                          ]?.name
                                                        }
                                                        helperText={
                                                          touched.storeOffer?.[
                                                            index
                                                          ]?.subLocations?.[
                                                            index1
                                                          ]?.name &&
                                                          errors.storeOffer?.[
                                                            index
                                                          ]?.subLocations?.[
                                                            index1
                                                          ]?.name
                                                        }
                                                      />
                                                    </Grid>
                                                    {/* Sub-Child-Accordion Start Here */}
                                                    <FieldArray
                                                      name={`storeOffer[${index}].subLocations[${index1}].assetTypes`}
                                                    >
                                                      {({
                                                        push: pushAssetType,
                                                        remove: removeAssetType,
                                                      }) => (
                                                        <Grid ml={2}>
                                                          {Array.isArray(
                                                            values?.storeOffer[
                                                              index
                                                            ]?.subLocations[
                                                              index1
                                                            ]?.assetTypes
                                                          ) &&
                                                            values?.storeOffer[
                                                              index
                                                            ]?.subLocations[
                                                              index1
                                                            ]?.assetTypes?.map(
                                                              (
                                                                section2,
                                                                index2
                                                              ) => (
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  key={index2}
                                                                  mt={2}
                                                                >
                                                                  <Accordion
                                                                    defaultExpanded={
                                                                      index2 ===
                                                                      0
                                                                    }
                                                                    sx={{
                                                                      background:
                                                                        "#fafafa",
                                                                    }}
                                                                  >
                                                                    <AccordionSummary
                                                                      expandIcon={
                                                                        <ExpandMoreIcon />
                                                                      }
                                                                    >
                                                                      <Typography>{`Type - ${section2.name}`}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                      <Grid
                                                                        container
                                                                        spacing={
                                                                          2
                                                                        }
                                                                      >
                                                                        <Grid
                                                                          item
                                                                          xs={
                                                                            12
                                                                          }
                                                                        >
                                                                          <FormControl
                                                                            fullWidth
                                                                          >
                                                                            <InputLabel
                                                                              id={`status-label-${index}`}
                                                                            >
                                                                              Type
                                                                            </InputLabel>
                                                                            <Select
                                                                              labelId={`status-label-${index}`}
                                                                              label="Type"
                                                                              name={`storeOffer[${index}].subLocations[${index1}].assetTypes[${index2}].name`}
                                                                              disabled={
                                                                                values
                                                                                  .storeOffer[
                                                                                  index
                                                                                ]
                                                                                  .subLocations[
                                                                                  index1
                                                                                ]
                                                                                  .assetTypes[
                                                                                  index2
                                                                                ]
                                                                                  .batchCode !=
                                                                                null
                                                                              }
                                                                              value={
                                                                                values
                                                                                  .storeOffer[
                                                                                  index
                                                                                ]
                                                                                  .subLocations[
                                                                                  index1
                                                                                ]
                                                                                  .assetTypes[
                                                                                  index2
                                                                                ]
                                                                                  .name ||
                                                                                ""
                                                                              } // Ensures binding to the current form state
                                                                              IconComponent={() => (
                                                                                <GridExpandMoreIcon fontSize="medium" />
                                                                              )}
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                setFieldValue(
                                                                                  `storeOffer[${index}].subLocations[${index1}].assetTypes[${index2}].name`,
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                );
                                                                              }}
                                                                              error={Boolean(
                                                                                touched
                                                                                  .storeOffer?.[
                                                                                  index
                                                                                ]
                                                                                  ?.subLocations?.[
                                                                                  index1
                                                                                ]
                                                                                  ?.assetTypes?.[
                                                                                  index2
                                                                                ]
                                                                                  ?.name &&
                                                                                  !!errors
                                                                                    .storeOffer?.[
                                                                                    index
                                                                                  ]
                                                                                    ?.subLocations?.[
                                                                                    index1
                                                                                  ]
                                                                                    ?.assetTypes?.[
                                                                                    index2
                                                                                  ]
                                                                                    ?.name
                                                                              )}
                                                                              displayEmpty // Shows placeholder even when value is ''
                                                                              sx={{
                                                                                height: 40,
                                                                                width:
                                                                                  "100%",
                                                                              }}
                                                                            >
                                                                              {assetTypeOptions.map((option) => (
                                                                                <MenuItem key={option.value} value={option.value}>
                                                                                  {option.label}
                                                                                </MenuItem>
                                                                              ))}
                                                                            </Select>
                                                                            {touched
                                                                              .storeOffer?.[
                                                                              index
                                                                            ]
                                                                              ?.subLocations?.[
                                                                              index1
                                                                            ]
                                                                              ?.assetTypes?.[
                                                                              index2
                                                                            ]
                                                                              ?.name &&
                                                                              errors
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.name && (
                                                                                <FormHelperText>
                                                                                  {
                                                                                    errors
                                                                                      .storeOffer?.[
                                                                                      index
                                                                                    ]
                                                                                      ?.subLocations?.[
                                                                                      index1
                                                                                    ]
                                                                                      ?.assetTypes?.[
                                                                                      index2
                                                                                    ]
                                                                                      ?.name
                                                                                  }
                                                                                </FormHelperText>
                                                                              )}
                                                                          </FormControl>
                                                                        </Grid>
                                                                        <Grid
                                                                          item
                                                                          xs={6}
                                                                        >
                                                                          <Field
                                                                            as={
                                                                              TextField
                                                                            }
                                                                            name={`storeOffer[${index}].subLocations[${index1}].assetTypes[${index2}].size`}
                                                                            label="Size"
                                                                            disabled={
                                                                              values
                                                                                .storeOffer[
                                                                                index
                                                                              ]
                                                                                .subLocations[
                                                                                index1
                                                                              ]
                                                                                .assetTypes[
                                                                                index2
                                                                              ]
                                                                                .batchCode !=
                                                                              null
                                                                            }
                                                                            fullWidth
                                                                            error={
                                                                              touched
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.size &&
                                                                              !!errors
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.size
                                                                            }
                                                                            helperText={
                                                                              touched
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.size &&
                                                                              errors
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.size
                                                                            }
                                                                          />
                                                                        </Grid>
                                                                        <Grid
                                                                          item
                                                                          xs={6}
                                                                        >
                                                                          <Field
                                                                            as={
                                                                              TextField
                                                                            }
                                                                            type="number"
                                                                            name={`storeOffer[${index}].subLocations[${index1}].assetTypes[${index2}].quantity`}
                                                                            label="Quantity"
                                                                            fullWidth
                                                                            error={
                                                                              touched
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.quantity &&
                                                                              !!errors
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.quantity
                                                                            }
                                                                            helperText={
                                                                              touched
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.quantity &&
                                                                              errors
                                                                                .storeOffer?.[
                                                                                index
                                                                              ]
                                                                                ?.subLocations?.[
                                                                                index1
                                                                              ]
                                                                                ?.assetTypes?.[
                                                                                index2
                                                                              ]
                                                                                ?.quantity
                                                                            }
                                                                          />
                                                                        </Grid>
                                                                        <Grid
                                                                          item
                                                                          xs={
                                                                            12
                                                                          }
                                                                          display="flex"
                                                                          gap={2}
                                                                        >
                                                                          <Grid
                                                                            item
                                                                            xs={values?.storeOffer?.[index]?.subLocations?.[index1]?.assetTypes.length > 1 ? 4 : 6}
                                                                          >
                                                                            <MDButton
                                                                              type="button"
                                                                              variant="outlined"
                                                                              // className="addBtnStyle"
                                                                              fullWidth
                                                                              color="dark"
                                                                              circular={true}
                                                                              startIcon={<Add />}
                                                                              onClick={() => {
                                                                                const newAssetType =
                                                                                  assetsTypeCreate;
                                                                                pushAssetType(
                                                                                  newAssetType
                                                                                );
                                                                              }}
                                                                            >
                                                                              Add
                                                                              Another
                                                                            </MDButton>
                                                                          </Grid>
                                                                          <Grid
                                                                            item
                                                                            xs={values?.storeOffer?.[index]?.subLocations?.[index1]?.assetTypes.length > 1 ? 4 : 6}
                                                                          >
                                                                            <MDButton
                                                                              variant="outlined"
                                                                              color="dark"
                                                                              fullWidth
                                                                              startIcon={<ContentCopy />}
                                                                              circular={true}
                                                                              onClick={() => {
                                                                                const newAssetType =
                                                                                  {
                                                                                    ...values
                                                                                      .storeOffer[
                                                                                      index
                                                                                    ]
                                                                                      .subLocations[
                                                                                      index1
                                                                                    ]
                                                                                      .assetTypes[
                                                                                      index2
                                                                                    ],
                                                                                  };
                                                                                pushAssetType(
                                                                                  newAssetType
                                                                                );
                                                                              }}
                                                                              // className="duplicateBtnStyle"
                                                                            >
                                                                              Duplicate
                                                                            </MDButton>
                                                                          </Grid>
                                                                          {values
                                                                            ?.storeOffer?.[
                                                                            index
                                                                          ]
                                                                            ?.subLocations?.[
                                                                            index1
                                                                          ]
                                                                            ?.assetTypes
                                                                            .length >
                                                                            1 && (
                                                                            <Grid
                                                                              item
                                                                              xs={
                                                                                4
                                                                              }
                                                                            >
                                                                              <MDButton
                                                                                variant="outlined"
                                                                                circular={true}
                                                                                startIcon={<DeleteOutlineOutlined />}
                                                                                fullWidth
                                                                                color={"error"}
                                                                                onClick={() => {handleOpenDialog(removeAssetType, index2)}}
                                                                                // className="duplicateBtnStyle"
                                                                              >
                                                                                Delete
                                                                              </MDButton>
                                                                            </Grid>
                                                                          )}
                                                                        </Grid>
                                                                      </Grid>
                                                                    </AccordionDetails>
                                                                  </Accordion>
                                                                </Grid>
                                                              )
                                                            )}
                                                        </Grid>
                                                      )}
                                                    </FieldArray>
                                                    {/* Sub-Child-Accordion End Here */}
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      display="flex"
                                                      gap={2}
                                                    >
                                                      <Grid item xs={values?.storeOffer?.[
                                                        index
                                                      ]?.subLocations.length >
                                                        1 ? 4 : 6}>
                                                        <MDButton
                                                          type="button"
                                                          variant="outlined"
                                                          circular={true}
                                                          // className="addBtnStyle"
                                                          color="dark"
                                                          fullWidth
                                                          startIcon={<Add />}
                                                          onClick={() => {
                                                            const newSubloacations =
                                                              subLocationsCreate;
                                                            pushSubLocation(
                                                              newSubloacations
                                                            );
                                                          }}
                                                        >
                                                          Add Another
                                                        </MDButton>
                                                      </Grid>
                                                      <Grid item xs={values?.storeOffer?.[
                                                        index
                                                      ]?.subLocations.length >
                                                        1 ? 4 : 6}>
                                                        <MDButton
                                                          variant="outlined"
                                                          color="dark"
                                                          circular={true}
                                                          fullWidth
                                                          startIcon={<ContentCopy />}
                                                          onClick={() => {
                                                            const newSubloacations =
                                                              {
                                                                ...values
                                                                  .storeOffer[
                                                                  index
                                                                ].subLocations[
                                                                  index1
                                                                ],
                                                                id: "",
                                                              };
                                                            pushSubLocation(
                                                              newSubloacations
                                                            );
                                                          }}
                                                          // className="duplicateBtnStyle"
                                                        >
                                                          Duplicate
                                                        </MDButton>
                                                      </Grid>
                                                      {values?.storeOffer?.[
                                                        index
                                                      ]?.subLocations.length >
                                                        1 && (
                                                        <Grid item xs={4}>
                                                          <MDButton
                                                            variant="outlined"
                                                            fullWidth
                                                            circular={true}
                                                            color={"black"}
                                                            style={{borderColor:"red", color:"red"}}
                                                            onClick={() => {handleOpenDialog(removeSubLocation, index1)}}
                                                            // className="duplicateBtnStyle"
                                                            startIcon={<DeleteOutlineOutlined />}
                                                          >
                                                            Delete
                                                          </MDButton>
                                                        </Grid>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </AccordionDetails>
                                              </Accordion>
                                            </Grid>
                                          )
                                        )}
                                    </Grid>
                                  )}
                                </FieldArray>
                                {/* Child-Accordion End Here */}
                                <Grid item xs={12} display="flex" gap={2}>
                                  <Grid item xs={values?.storeOffer.length > 1 ? 4 : 6}>
                                    <MDButton
                                      type="button"
                                      variant="outlined"
                                      color="dark"
                                      circular={true}
                                      fullWidth
                                      startIcon={<Add />}
                                      // className="addBtnStyle"
                                      onClick={() => {
                                        const newlocation = mainLocationCreate;
                                        push(newlocation);
                                      }}
                                    > 
                                      Add Another
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={values?.storeOffer.length > 1 ? 4 : 6}>
                                    <MDButton
                                      variant="outlined"
                                      onClick={() => {
                                        const newlocation = {
                                          ...values.storeOffer[index],
                                          id: "",
                                          subLocations:values.storeOffer[index].subLocations.map((subLocation) => ({
                                            ...subLocation,
                                            id: "",
                                          }))
                                        };
                                        push(newlocation);
                                      }}
                                      color="dark"
                                      fullWidth
                                      circular={true}
                                      startIcon={<ContentCopy />}
                                      // className="duplicateBtnStyle"
                                    >
                                      Duplicate
                                    </MDButton>
                                  </Grid>
                                  {values?.storeOffer.length > 1 && (
                                    <Grid item xs={4}>
                                      <MDButton
                                        variant="outlined"
                                        color="dark"
                                        fullWidth
                                        circular={true}
                                        style={{color:"red", borderColor:"red"}}
                                        startIcon={<DeleteOutlineOutlined />}
                                        onClick={() => {
                                          handleOpenDialog((arr)=>{remove(arr[0]), deleteCall(arr[1]), setNotification({
                                            color: "success",
                                            isVisible: true,
                                            message: "Deleted successfully",
                                          })}, [index, values?.storeOffer?.[index].id])}}
                                        // className="duplicateBtnStyle"
                                      >
                                        Delete
                                      </MDButton>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      ))}
                  </Grid>
                </>
              )}
            </FieldArray>
            <Grid container spacing={1}>
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <MDButton color="dark" onClick={handleClose} variant="outlined" circular={true}>
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default StoreOffer;
  