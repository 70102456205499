import React from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

// Function to format date using dayjs
const formatDate = (dateString) => {
  return dayjs(dateString).format("D  MMM  YYYY");
};

// Custom styled Tooltip component with white background
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    fontSize: "14px",
  },
});

// Custom cell renderer for inward details
const InwardDetailRenderer = (params) => {
  const inwardDetails = params.data.inwardDetail || [];
  const firstDetail = inwardDetails[0]; // Show the first item by default

  // Check if more details exist
  const hasMore = inwardDetails.length > 1;

  return (
    <div>
      <span>
        {firstDetail
          ? `${formatDate(firstDetail.date)} - ${firstDetail.totalQuantity}`
          : "-"}
      </span>
      {hasMore && (
        <CustomTooltip
          title={
            <div>
              {inwardDetails.map((detail, index) => (
                <div key={index} style={{ marginBottom: "5px" }}>
                  {formatDate(detail.date)}: {detail.totalQuantity}
                </div>
              ))}
            </div>
          }
          arrow
        >
          <a href="#" style={{ marginLeft: "5px", color: "#1976d2" }}>
            View more
          </a>
        </CustomTooltip>
      )}
    </div>
  );
};

export const adminHeaderSalesReport = [
  {
    headerName: "Store",
    field: "storeName",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "Bill Date",
    field: "billDate",
    filter: "agMultiColumnFilter", // Multi-column filter
    valueGetter: (params) => {
      // Check if params.data exists and has a billDate
      if (!params.data || !params.data.billDate) {
        return null; // Handle missing data
      }

      const date = new Date(params.data.billDate);
      return isNaN(date.getTime()) ? null : date.toISOString().split("T")[0]; // Return date in yyyy-mm-dd
    },
    valueFormatter: (params) => {
      // Check if params.value exists before formatting
      if (!params.value) {
        return ""; // Return empty string if no value
      }

      const date = new Date(params.value);
      if (isNaN(date.getTime())) {
        return ""; // Handle invalid date
      }

      const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const year = date.getFullYear();

      return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
    },
    filterParams: {
      filters: [
        {
          filter: "agDateColumnFilter", // Date filter
          filterParams: {
            comparator: (filterDate, cellValue) => {
              // Check if cellValue is valid before comparing
              if (!cellValue) {
                return -1; // Fail gracefully
              }

              const cellDate = new Date(cellValue);
              let filterDateObject;

              // If filterDate is a string, parse it
              if (typeof filterDate === "string") {
                const [day, month, year] = filterDate.split("/");
                filterDateObject = new Date(`${year}-${month}-${day}`);
              } else if (filterDate instanceof Date) {
                filterDateObject = filterDate;
              } else {
                console.error("Unexpected filterDate format", filterDate);
                return 0; // Fail gracefully
              }

              // Set time to midnight for both dates
              cellDate.setHours(0, 0, 0, 0);
              filterDateObject.setHours(0, 0, 0, 0);

              // Compare the two dates
              if (cellDate < filterDateObject) return -1;
              if (cellDate > filterDateObject) return 1;
              return 0; // They are equal
            },
          },
        },
        {
          filter: "agSetColumnFilter", // Set filter
          filterParams: {
            valueFormatter: (params) => {
              if (!params.value) {
                return ""; // Handle missing or invalid data
              }

              const date = new Date(params.value);
              if (isNaN(date.getTime())) {
                return "";
              }

              const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
              const year = date.getFullYear();

              return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
            },
            keyCreator: (params) => {
              const date = new Date(params.value);
              return isNaN(date.getTime()) ? "" : date.toISOString(); // Standardized key
            },
          },
        },
      ],
    },
  },
  {
    headerName: "Bill NO",
    field: "billNo",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Bill GUID",
    field: "billGuid",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "id",
    headerName: "Customer ID",
    valueGetter: (params) =>
      params?.data?.customerId?.split("-").reverse()[0].toUpperCase(),
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Division",
    field: "division",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Section",
    field: "section",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Department",
    field: "department",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Node",
    field: "node",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Brand",
    field: "brand",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Vendor",
    field: "vendor",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Barcode",
    field: "barcode",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Icode",
    field: "icode",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Vendor Article Number",
    field: "vendorArticleNumber",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Item Name",
    field: "itemName",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Size",
    field: "size",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "MRP",
    field: "mrp",
    valueGetter: (params) => +params?.data?.mrp || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "RSP",
    field: "rsp",
    valueGetter: (params) => +params?.data?.rsp || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Qty",
    field: "qty",
    valueGetter: (params) => +params?.data?.qty || 0,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Basic Amt",
    field: "basicAmt",
    valueGetter: (params) => +params?.data?.basicAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Promo Amt",
    field: "promoAmt",
    valueGetter: (params) => +params?.data?.promoAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Coupon Amt",
    field: "couponAmt",
    valueGetter: (params) => +params?.data?.couponAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Net Sale Amt",
    field: "netAmt",
    valueGetter: (params) => +params?.data?.netAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Payment Mode",
    field: "MOPName",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
];

export const headerSalesReport = [
  {
    headerName: "Store",
    field: "storeName",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "Bill Date",
    field: "billDate",
    filter: "agMultiColumnFilter", // Multi-column filter
    valueGetter: (params) => {
      // Check if params.data exists and has a billDate
      if (!params.data || !params.data.billDate) {
        return null; // Handle missing data
      }

      const date = new Date(params.data.billDate);
      return isNaN(date.getTime()) ? null : date.toISOString().split("T")[0]; // Return date in yyyy-mm-dd
    },
    valueFormatter: (params) => {
      // Check if params.value exists before formatting
      if (!params.value) {
        return ""; // Return empty string if no value
      }

      const date = new Date(params.value);
      if (isNaN(date.getTime())) {
        return ""; // Handle invalid date
      }

      const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const year = date.getFullYear();

      return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
    },
    filterParams: {
      filters: [
        {
          filter: "agDateColumnFilter", // Date filter
          filterParams: {
            comparator: (filterDate, cellValue) => {
              // Check if cellValue is valid before comparing
              if (!cellValue) {
                return -1; // Fail gracefully
              }

              const cellDate = new Date(cellValue);
              let filterDateObject;

              // If filterDate is a string, parse it
              if (typeof filterDate === "string") {
                const [day, month, year] = filterDate.split("/");
                filterDateObject = new Date(`${year}-${month}-${day}`);
              } else if (filterDate instanceof Date) {
                filterDateObject = filterDate;
              } else {
                console.error("Unexpected filterDate format", filterDate);
                return 0; // Fail gracefully
              }

              // Set time to midnight for both dates
              cellDate.setHours(0, 0, 0, 0);
              filterDateObject.setHours(0, 0, 0, 0);

              // Compare the two dates
              if (cellDate < filterDateObject) return -1;
              if (cellDate > filterDateObject) return 1;
              return 0; // They are equal
            },
          },
        },
        {
          filter: "agSetColumnFilter", // Set filter
          filterParams: {
            valueFormatter: (params) => {
              if (!params.value) {
                return ""; // Handle missing or invalid data
              }

              const date = new Date(params.value);
              if (isNaN(date.getTime())) {
                return "";
              }

              const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
              const year = date.getFullYear();

              return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
            },
            keyCreator: (params) => {
              const date = new Date(params.value);
              return isNaN(date.getTime()) ? "" : date.toISOString(); // Standardized key
            },
          },
        },
      ],
    },
  },
  {
    field: "id",
    headerName: "Customer ID",
    valueGetter: (params) =>
      params?.data?.customerId?.split("-").reverse()[0].toUpperCase() || "",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  // {
  //   headerName: "Bill NO",
  //   field: "billNo",
  //   filter: "agMultiColumnFilter",
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter",
  //       },
  //       {
  //         filter: "agSetColumnFilter",
  //       },
  //     ],
  //   },
  // },
  // {
  //   headerName: "Bill GUID",
  //   field: "billGuid",
  //   filter: "agMultiColumnFilter",
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter",
  //       },
  //       {
  //         filter: "agSetColumnFilter",
  //       },
  //     ],
  //   },
  // },
  {
    headerName: "Division",
    field: "division",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Section",
    field: "section",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Department",
    field: "department",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Node",
    field: "node",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  // {
  //   headerName: "Brand",
  //   field: "brand",
  //   filter: "agMultiColumnFilter",
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter",
  //       },
  //       {
  //         filter: "agSetColumnFilter",
  //       },
  //     ],
  //   },
  // },
  // {
  //   headerName: "Vendor",
  //   field: "vendor",
  //   filter: "agMultiColumnFilter",
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter",
  //       },
  //       {
  //         filter: "agSetColumnFilter",
  //       },
  //     ],
  //   },
  // },
  {
    headerName: "Barcode",
    field: "barcode",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Icode",
    field: "icode",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Vendor Article Number",
    field: "vendorArticleNumber",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Item Name",
    field: "itemName",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Size",
    field: "size",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "MRP",
    field: "mrp",
    valueGetter: (params) => +params?.data?.mrp || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "RSP",
    field: "rsp",
    valueGetter: (params) => +params?.data?.rsp || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Qty",
    field: "qty",
    valueGetter: (params) => +params?.data?.qty || 0,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Basic Amt",
    field: "basicAmt",
    valueGetter: (params) => +params?.data?.basicAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Promo Amt",
    field: "promoAmt",
    valueGetter: (params) => +params?.data?.promoAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Coupon Amt",
    field: "couponAmt",
    valueGetter: (params) => +params?.data?.couponAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Net Sale Amt",
    field: "netAmt",
    valueGetter: (params) => +params?.data?.netAmt || 0,
    valueFormatter: (params) => {
      // Format the displayed value to 2 decimal places
      return parseFloat(params.value).toFixed(2);
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Payment Mode",
    field: "MOPName",
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
];

export const headerStockReport = [
  // {
  //   headerName: "Store Name",
  //   field: "storeName",
  //   filter: "agMultiColumnFilter", // Use multi-column filter
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter", // Text filter for search functionality
  //       },
  //       {
  //         filter: "agSetColumnFilter", // Set filter for dropdown selection
  //       },
  //     ],
  //   },
  // },
  // {
  //   headerName: "Store Code",
  //   field: "storeCode",
  //   filter: "agMultiColumnFilter", // Use multi-column filter
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter", // Text filter for search functionality
  //       },
  //       {
  //         filter: "agSetColumnFilter", // Set filter for dropdown selection
  //       },
  //     ],
  //   },
  // },
  {
    headerName: "Sync Timestamp",
    field: "lastModifiedOn",
    filter: "agMultiColumnFilter", // Multi-column filter
    valueGetter: (params) => {
      // Check if params.data exists and has a lastModifiedOn
      if (!params.data || !params.data.lastModifiedOn) {
        return null; // Handle missing data
      }

      const date = new Date(params.data.lastModifiedOn);
      return isNaN(date.getTime()) ? null : date.toISOString().split("T")[0]; // Return date in yyyy-mm-dd
    },
    valueFormatter: (params) => {
      // Check if params.value exists before formatting
      if (!params.value) {
        return ""; // Return empty string if no value
      }

      const date = new Date(params.value);
      if (isNaN(date.getTime())) {
        return ""; // Handle invalid date
      }

      const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const year = date.getFullYear();

      return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
    },
    filterParams: {
      filters: [
        {
          filter: "agDateColumnFilter", // Date filter
          filterParams: {
            comparator: (filterDate, cellValue) => {
              // Check if cellValue is valid before comparing
              if (!cellValue) {
                return -1; // Fail gracefully
              }

              const cellDate = new Date(cellValue);
              let filterDateObject;

              // If filterDate is a string, parse it
              if (typeof filterDate === "string") {
                const [day, month, year] = filterDate.split("/");
                filterDateObject = new Date(`${year}-${month}-${day}`);
              } else if (filterDate instanceof Date) {
                filterDateObject = filterDate;
              } else {
                console.error("Unexpected filterDate format", filterDate);
                return 0; // Fail gracefully
              }

              // Set time to midnight for both dates
              cellDate.setHours(0, 0, 0, 0);
              filterDateObject.setHours(0, 0, 0, 0);

              // Compare the two dates
              if (cellDate < filterDateObject) return -1;
              if (cellDate > filterDateObject) return 1;
              return 0; // They are equal
            },
          },
        },
        {
          filter: "agSetColumnFilter", // Set filter
          filterParams: {
            valueFormatter: (params) => {
              if (!params.value) {
                return ""; // Handle missing or invalid data
              }

              const date = new Date(params.value);
              if (isNaN(date.getTime())) {
                return "";
              }

              const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
              const year = date.getFullYear();

              return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
            },
            keyCreator: (params) => {
              const date = new Date(params.value);
              return isNaN(date.getTime()) ? "" : date.toISOString(); // Standardized key
            },
          },
        },
      ],
    },
  },
  {
    headerName: "Item Code",
    field: "itemCode",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "Closing Stock Qty",
    field: "closingStockQty",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "Item Name",
    field: "itemName",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "Vendor Name",
    field: "vendorName",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "Vendor Code",
    field: "vendorCode",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
];

export const headerGMVREPORT = [
  { headerName: "Date", field: "date" },
  { headerName: "Brand", field: "brandName", enableValue: true },
  { headerName: "Sales By MRP", field: "salesByMrp", enableValue: true },
  { headerName: "Selling Price", field: "sellingPrice" },
  { headerName: "Promotion Discount(%)", field: "promotionDiscount" },
  { headerName: "Coupon Discount", field: "couponDiscount" },
  { headerName: "Reward-Burn", field: "rewardsBurn" },
  { headerName: "Amount Collected", field: "amountCollected" },
];

export const headerEventReport = [
  { headerName: "Event Name", field: "eventName" },
  { headerName: "Event Date", field: "eventDate" },
  { headerName: "Enent Time", field: "eventTime" },
  { headerName: "Ticket Type", field: "ticketType" },
  { headerName: "Category", field: "category" },
  { headerName: "Ticket Sold", field: "ticketsSold" },
  { headerName: "Ticket Amount", field: "ticketAmount" },
  { headerName: "Rewards Burn", field: "rewardsBurn" },
  { headerName: "No. of Participant", field: "numberOfParticipants" },
  {
    headerName: "Samples Distributed (if applicable)",
    field: "samplesDistributed",
  },
];

export const headerReturnAndRefund = [
  { headerName: "Product ID", field: "productId" },
  { headerName: "Sales Order ID", field: "salesOrderID" },
  { headerName: "Product Name", field: "productName" },
  { headerName: "Amount", field: "amount" },
  { headerName: "Refund Date", field: "refundDate" },
  { headerName: "Refund Status", field: "refundStatus" },
  { headerName: "Returned Reason", field: "returnReason" },
];

export const headerFootfallReport = [
  {
    headerName: "Store Name",
    field: "store.name",
    rowGroup:true,
    valueGetter: (params) => params?.data?.store?.name || "",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
    flex: 1,
    minWidth: 100,
  },
  {
    headerName: "Date",
    field: "date",
    flex: 1,
    minWidth: 100,
    rowGroup: "true",
    hide: false,
    filter: "agMultiColumnFilter", // Multi-column filter
    valueGetter: (params) => {
      // Convert to dd/mm/yyyy format for display
      const date = new Date(params?.data?.date);
      if (isNaN(date.getTime())) {
        return "";
      }

      const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const year = date.getFullYear();

      return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
    },
    filterParams: {
      filters: [
        {
          filter: "agDateColumnFilter", // Date filter
          filterParams: {
            comparator: (filterDate, cellValue) => {
              // Parse the cell value (expected to be in ISO or other date formats)
              const cellDate = new Date(cellValue);

              let filterDateObject;

              // If filterDate is a Date object, use it directly; otherwise parse it
              if (typeof filterDate === "string") {
                // Convert filterDate from dd/mm/yyyy to yyyy-mm-dd for comparison
                const [day, month, year] = filterDate.split("/");
                filterDateObject = new Date(`${year}-${month}-${day}`);
              } else if (filterDate instanceof Date) {
                filterDateObject = filterDate;
              } else {
                console.error("Unexpected filterDate format", filterDate);
                return 0; // Fail gracefully
              }

              // Set time to midnight for both dates
              cellDate.setHours(0, 0, 0, 0);
              filterDateObject.setHours(0, 0, 0, 0);

              // Compare the two dates
              if (cellDate < filterDateObject) return -1;
              if (cellDate > filterDateObject) return 1;
              return 0; // They are equal
            },
          },
        },
        {
          filter: "agSetColumnFilter", // Set filter
          filterParams: {
            // Ensure the valueFormatter is consistent with the display format
            valueFormatter: (params) => {
              // Convert to dd/mm/yyyy format for display
              const date = new Date(params.value);
              if (isNaN(date.getTime())) {
                return "";
              }

              const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
              const year = date.getFullYear();

              return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
            },
            keyCreator: (params) => {
              const date = new Date(params.value);
              return isNaN(date.getTime()) ? "" : date.toISOString(); // Standardized key
            },
          },
        },
      ],
    },
  },
  {
    headerName: "From Time",
    field: "fromTime",
    flex: 1,
    minWidth: 100,
    valueGetter: (params) =>
      params?.data?.fromTime
        ? dayjs(params?.data?.fromTime)
            .utc()
            .tz("Asia/Kolkata")
            .format("hh:mm A")
        : "N/A",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "To Time",
    field: "tillTime",
    flex: 1,
    minWidth: 100,
    valueGetter: (params) =>
      params?.data?.tillTime
        ? dayjs(params?.data?.tillTime)
            .utc()
            .tz("Asia/Kolkata")
            .format("hh:mm A")
        : "",
    filter: "agMultiColumnFilter", // Use multi-column filter
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter", // Text filter for search functionality
        },
        {
          filter: "agSetColumnFilter", // Set filter for dropdown selection
        },
      ],
    },
  },
  {
    headerName: "Total Visitors",
    field: "count",
    flex: 1,
    minWidth: 100,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
];
