import * as Yup from "yup";
export const generateYupSchema = (jsonSchema) => {

    const parseSchema = (schema, fieldName) => {
      if(schema == null) return
      switch (schema.type) {
        case 'object':
          const shape = {};
          let objectSchema = Yup.object(shape);
          if (schema.required) {
            objectSchema = objectSchema.required(`${fieldName} is required`);
          }
          return objectSchema;
  
        case 'array':
          // let arraySchema =schema.items ? Yup.array().of(parseSchema(schema.items, fieldName)):Yup.array();
          let arraySchema = Yup.array();
          if (schema.min != null) {
            arraySchema = arraySchema.min(schema.min, `${fieldName} must have at least ${schema.min} item(s)`);
          }
          if (schema.max != null) {
            arraySchema = arraySchema.max(schema.max, `${fieldName} cannot have more than ${schema.max} item(s)`);
          }
          if (schema.required) {
            arraySchema = arraySchema.required(`${fieldName} is required`);
          }
          return arraySchema;
  
        case 'string':
          let stringSchema = Yup.string();
          if (schema.min != null) {
            stringSchema = stringSchema.min(schema.min, `${fieldName} must be at least ${schema.min} characters`);
          }
          if (schema.max != null) {
            stringSchema = stringSchema.max(schema.max, `${fieldName} must be no more than ${schema.max} characters`);
          }
          if (schema.url) {
            stringSchema = stringSchema.url(`${fieldName} must be a valid URL`);
          }
          if (schema.required) {
            stringSchema = stringSchema.required(`${fieldName} is required`);
          }
          return stringSchema;
  
        case 'number':
          let numberSchema = Yup.number();
          if (schema.min != null) {
            numberSchema = numberSchema.min(schema.min, `${fieldName} must be at least ${schema.min}`);
          }
          if (schema.max != null) {
            numberSchema = numberSchema.max(schema.max, `${fieldName} must be no more than ${schema.max}`);
          }
          if (schema.required) {
            numberSchema = numberSchema.required(`${fieldName} is required`);
          }
          return numberSchema;
  
        case 'boolean':
          let booleanSchema = Yup.boolean();
          if (schema.required) {
            booleanSchema = booleanSchema.required(`${fieldName} is required`);
          }
          return booleanSchema;
  
        case 'date':
          let dateSchema = Yup.date();
          if (schema.min != null) {
            dateSchema =
              schema.min == "today"
                ? dateSchema.min(
                    new Date(),
                    `${fieldName} must be on or after ${schema.min}`
                  )
                : dateSchema.min(
                    new Date(schema.min),
                    `${fieldName} must be on or after ${schema.min}`
                  );
          }
          if (schema.max != null) {
            dateSchema = dateSchema.max(new Date(schema.max), `${fieldName} must be on or before ${schema.max}`);
          }
          if (schema.required) {
            dateSchema = dateSchema.required(`${fieldName} is required`);
          }
          return dateSchema;
  
        default:
          throw new Error(`Unsupported schema type: ${schema.type}`);
      }
    };
  
    const yupSchema = {};
    for (const [key, value] of Object.entries(jsonSchema)) {
      yupSchema[key] = parseSchema(value, key);
    }
  
    return Yup.object(yupSchema);
};

export const  generateInitialValues = (jsonSchema, formData={}) => {
  const initialValues = {};
  for (const key in jsonSchema) {
    const field = jsonSchema[key];
    switch (field.type) {
      case 'string':
        initialValues[key] = formData[key] || ''; // Initialize string fields as empty strings
        break;
      case 'object':
        initialValues[key] = formData[key] || null; // Initialize object fields as empty objects
        break;
      // Add more cases for other types if needed
      default:
        initialValues[key] = formData[key] || null; // Fallback initial value
    }
  }
  // * NOTE: update intial value with form data value if necessary
  return initialValues;
}

export const getExtraFields = (jsonSchema)=>{
  const defaultFields = ['category','brand','event','product','news','media','ctaText','ctaLink','startDate','startTime','endDate','endTime','status']
  const extraFields = Object.keys(jsonSchema).filter(field=>!defaultFields.includes(field))
  return extraFields;
}