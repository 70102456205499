import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import {
  Grid,
  Pagination as MuiPagination,
  Box,
  TablePagination,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_ATTRIBUTE_LIST } from "services/master-attribute-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from "react";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
};

const columns = [
  {
    field: "name",
    headerName: "Attribute Name",
    width: 250,
  },
  { field: "fieldType", headerName: "Attribute Type", width: 200 },
  { field: "ginesysAttribute", headerName: "Ginesys Key Name", width: 200 },
  {
    field: "values",
    headerName: "Values",
    width: 180,
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => (
      <Chip
        color={params?.value === "live" ? "success" : params?.value === "inactive" ? "error" : "warning"}
        label={params?.value === "live" ? "Active" : params?.value === "inactive" ? "Inactive" : "Pending Approval"}
        variant="outlined"
      />
    ),
  },
  {
    field: "isFilter",
    headerName: "Is Filter",
    width: 230,
    renderCell: (params) => (
      <Chip
      color={params?.value === true ? "success" : "error"}
      label={params?.value === true ? "True" : "False"}
      variant="outlined"
    />
    ),
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    valueGetter: (params) => formatDate(params?.value),
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 200,
    valueGetter: (params) => formatDate(params?.value),
  },
];

function MasterAttributeLandingPage({ onRowClick }) {
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_ATTRIBUTE_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      search: searchKeyword,
    },
    fetchPolicy: "network-only", // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.masterAttributes?.total);
    },
  });
  const [rowCount, setRowCount] = React.useState(
    data?.masterAttributes?.total || 0
  );

  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.masterAttributes?.total !== undefined
        ? data?.masterAttributes?.total
        : prevRowCount
    );
  }, [data?.masterAttributes?.total, setRowCount]);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;

  const attributes = data?.masterAttributes?.results;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <DataGrid
      autoHeight
      rows={attributes}
      columns={columns}
      rowCount={rowCount}
      pageSizeOptions={[10, 20, 50, 100]}
      getRowId={(row) => row.id}
      onRowClick={onRowClick}
      loading={loading}
      onPageChange={handleChangePage}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
    />
  );
}

export default MasterAttributeLandingPage;
