import { useQuery } from "@apollo/client";
import {
  Badge,
  Box,
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import Loader from "components/MDLoader";
import { dashboardServiceClient } from "graphql/client";
import React from "react";
import { LIST_INFORMATION_GUIDES } from "services/brand-service";

import InformationGuideCard from "./informationGuideCard";
import { isVisible } from "@testing-library/user-event/dist/utils";

const InformationGuideListings = ({ onItemClick, isAdmin }) => {
  const { loading, error, data, refetch } = useQuery(LIST_INFORMATION_GUIDES, {
    client: dashboardServiceClient,
    variables: {
      listInformationGuideFilter: {
        take: 9999,
        skip: 0,
        search: "",
        filter: {
          isVisibleToBrands: !isAdmin ? true : null,
          status: !isAdmin ? "Published" : null,
          isDeleted: false,
        },
      },
    },
    fetchPolicy: "network-only", // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      //   setRowCount(data?.masterActivity?.total);
    },
  });
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;
  return (
    <Grid container>
      {data?.informationGuides?.results?.map((informationGuide) => (
        <InformationGuideCard
          isAdmin={isAdmin}
          onEditClick={onItemClick}
          informationGuide={informationGuide}
        />
      ))}
    </Grid>
  );
};

export default InformationGuideListings;
