import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";

// @mui material components
import {
  Button,
  Grid,
  TextField,
  Typography,
  Rating,
  Switch,
  IconButton,
  Icon,
  Box,
} from "@mui/material";
import ColorPicker from "components/MDColorPicker";
import { Delete as DeleteIcon, UploadSharp } from "@mui/icons-material";

// Material Dashboard 2 React components
import FileUploader from "components/MDFileUpload";
import TagsInput from "components/TagsInput/TagsInput";
import Loader from "components/MDLoader";
import MDButton from "components/MDButton";

// Formik & Yup React Form Validation
import { Formik, Form, Field, FieldArray } from "formik";
import * as yup from "yup";

// Apollo Client 
import { useLazyQuery, useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";

// Graphql query & mutation
import { 
  UPDATE_BRAND,
  GET_COMPANY_DETAILS_BY_USERNAME 
} from "services/brand-service";

// Custom Context
import { useNotification, AuthContext } from "context";

// Yup Form Validation Schema
const validationSchema = yup.object().shape({
  companyName: yup.string()
    .required("Company Name is required")
    .max(50, "Maximum 50 characters allowed"),
  brandTitle: yup.string()
  .required("Brand Title is required")
  .max(100, "Maximum 100 characters allowed"),
  brandDescription: yup.string()
  .required("Brand Description is required")
  .max(2000, "Maximum 2000 characters allowed"),
  brandTags: yup.array(),
  // brandLogoPath: yup.string().required('Brand Logo is required'),
  // brandLogoUrl: yup.string().required('Brand Logo is required'),
  // brandCoverPath: yup.string().required('Brand Cover is required'),
  // brandCoverUrl: yup.string().required('Brand Cover is required'),
  // brandThumbnailPath: yup.string().required('Brand Thumbnail is required'),
  // brandThumbnailUrl: yup.string().required('Brand Thumbnail is required'),
  // brandColour: yup.string().required('Brand Color is required'),
  // brandRating: yup.number().required('Brand Rating is required'),
  // brandVisibile: yup.boolean().required('Brand Visibile is required')
});

/**
 * 
 * @param {*} vendorDetail desc: payload details
 * @param {*} setNotification desc: user feedback notification
 * @param {*} handleTabChange desc: callback function to chnage tab value to next
 * @param {*} refetchQuery desc: query to refetch after successful mutation
 * @returns handleSubmit callback function (values) => { implementation of submit function }
 */
const useMetadataMutation = (
  vendorDetail,
  setNotification,
  handleTabChange,
  refetchQuery,
  setFormTabValue,
  tabValueFlag
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQuery
  };

  const [updateBrandMutation] = useMutation(
    UPDATE_BRAND,
    mutationOptions
  )

  const handleSubmit = (values) => {

      const updateBrandInput = {
        input: {
          id: vendorDetail?.id || vendorDetail?.userId,
          name: values.companyName,
          usp: values.brandTitle,
          description: values.brandDescription,
          brandTags: values.brandTags,
          hexColour: values.brandColour,
          brandRating: values.brandRating,
          isBrandVisible: values.brandVisibile
        }
      }

    if(values.brandLogos.filter(logo => logo.path).length) {
      updateBrandInput.input.logos = values.brandLogos.filter(logo => logo.path)
    }
    if(values.brandThumbnailPath) {
      updateBrandInput.input.thumbnailpath = values.brandThumbnailPath;
    }
    if(values.brandCoverPath) {
      updateBrandInput.input.coverPicPath = values.brandCoverPath;
    }

    updateBrandMutation({ variables: updateBrandInput })
    .then(() => {
      // handleTabChange(null, 1);
      tabValueFlag && setFormTabValue(1);
      setNotification({
        color: "success",
        isVisible: true,
        message:"Brand Details updated successfully",
      });
    }).catch((error) => {
      console.error(" Error:", error?.message);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Something went wrong",
      });
    });
  };

  return handleSubmit;
};


const CompanyForm = forwardRef((props, ref) => {
  const { handleTabChange, brandData, setDirtyData, setShowDialog, setFormTabValue} = props;
  const { setNotification } = useNotification();
  const { user } = useContext(AuthContext);
  const [ tabValueFlag, setTabValueFlag ] = useState(false)

  useEffect(()=>{
    setShowDialog(false)
    setTabValueFlag(false)
  },[])

  const [comapanyDetail, setCompanyDetail] = useState(null);

  const [getCompanyDetails, 
    {
      loading: vendorDetailLoading, 
      error: vendorDetailError,
      refetch: vendorDetailRefetch
    }
    ] = useLazyQuery(
      GET_COMPANY_DETAILS_BY_USERNAME,
      {
        client: dashboardServiceClient,
        onCompleted: (response) => {
          // * as we are requesting for brand by id and take only 1
          const comapnyDetail = response.brand;
          setCompanyDetail(comapnyDetail)
        },
        onError: (error) => {
          console.error(error);
          setNotification({
            color: "error",
            isVisible: true,
            message:"Error: fetching brand details",
          });
        }
      },
    );

  useEffect(() => {
    if (user || brandData) {
      getCompanyDetails({
        variables: {
          brandByUsernameDto: {
            username: brandData ? brandData?.username : user.username,
          }
        },
      });
    }
  }, [user, brandData]);

  const handleSubmit = useMetadataMutation(
    brandData || user,
    setNotification,
    handleTabChange,
    vendorDetailRefetch,
    setFormTabValue,
    tabValueFlag
  );

  if(vendorDetailLoading) return <Loader />
  if(vendorDetailError) return <>Error: {vendorDetailError.message}</>

  // const handleRemove = (metadata, index, remove) => {
  //   remove(index);
  //   if (metadata.id) {
  //     removeComponentMetaData({
  //       variables: {
  //         id: metadata.id,
  //       },
  //     });
  //   }
  // };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        companyName: comapanyDetail?.name || "",
        brandTitle: comapanyDetail?.usp || "",
        brandDescription: comapanyDetail?.description || "",
        brandTags: comapanyDetail?.brandTags || [],
        brandLogoPath: comapanyDetail?.logo?.path || "",
        brandLogoUrl: comapanyDetail?.logo?.publicurl || "",
        brandLogos: comapanyDetail?.logos?.length
          ? comapanyDetail.logos
          : comapanyDetail?.logo?.path
            ? [comapanyDetail.logo]
            : [{path:"", publicurl:""}],
        brandCoverPath: comapanyDetail?.coverPic?.[0]?.path || "",
        brandCoverUrl: comapanyDetail?.coverPic?.[0]?.publicurl || "",
        brandThumbnailPath: comapanyDetail?.thumbnail?.path || "",
        brandThumbnailUrl: comapanyDetail?.thumbnail?.publicurl || "",
        brandColour: comapanyDetail?.hexColour || "#000000",
        brandRating: comapanyDetail?.brandRating || 0,
        brandVisibile: comapanyDetail?.isBrandVisible || false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(formik) => {
          useEffect(() => {
          // return () => {
            // * will check if unsave data then show warning data loss
            // TODO: implement confirmation block to save or discard data
            // if (formik.dirty) {
              //   setNotification({
                //     color: "warning",
                //     isVisible: true,
                //     message: "Warning: Unsaved data was lost",
                //   });
                // }
                // };
            if (formik.dirty) {
              setDirtyData(true)
            }
            else {
              setDirtyData(false)
            }
          }, [formik.dirty]);
          useImperativeHandle(ref, () => ({
            handleSubmitCompanyDetail: formik.handleSubmit,
            validateFormCompanyDetail: () => {
              formik.setTouched(
                Object.keys(formik.values).reduce(
                  (acc, field) => ({
                    ...acc,
                    [field]: true,
                  }),
                  { companyName: true } 
                )
              );
              return formik.errors
            },
          }));
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: "#000000", fontFamily: "Montserrat" }}
                  >
                    Company Information
                  </Typography>
                </Grid>
                <hr color="#EAEAEA" />
                <Grid item xs={12} mt={2}>
                  <Field
                    as={TextField}
                    name="companyName"
                    label="Company Name As Per GST"
                    fullWidth
                    inputProps={{
                      style: { fontFamily: "Montserrat" },
                    }}
                    InputLabelProps={{
                      style: { fontFamily: "Montserrat" },
                    }}
                    error={
                      formik.touched.companyName && !!formik.errors.companyName
                    }
                    helperText={
                      formik.touched.companyName
                        ? formik.errors.companyName
                        : ""
                    }
                  />
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Brand Details
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="brandTitle"
                      label="Brand Name"
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={
                        formik.touched.brandTitle && !!formik.errors.brandTitle
                      }
                      helperText={
                        formik.touched.brandTitle
                          ? formik.errors.brandTitle
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="brandDescription"
                      label="Brand Description"
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={
                        formik.touched.brandDescription &&
                        !!formik.errors.brandDescription
                      }
                      helperText={
                        formik.touched.brandDescription
                          ? formik.errors.brandDescription
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TagsInput
                      label="Brand Tags"
                      inputValue={formik.values.brandTags}
                      setInputValue={(value) =>
                        formik.setFieldValue("brandTags", value)
                      }
                      onChange={(value) =>
                        formik.setFieldValue("brandTags", value)
                      }
                    />
                    {formik.touched?.brandTags && formik.errors?.brandTags && (
                      <Typography fontSize={12} color="error">
                        {formik.errors?.brandTags}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container px={2}>
                <Grid item xs={12}>
                  <FieldArray name="brandLogos">
                    {({ push, remove }) => (
                      <div>
                        {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6>Metadata:</h6>
                        <IconButton
                          size="small"
                          type="button"
                          sx={{
                            px: 2,
                            backgroundColor: "#cccccc",
                            borderRadius: 5,
                          }}
                          aria-label="add"
                          onClick={() => push({ key: "", value: "" })}
                        >
                          <Icon component={AddIcon} fontSize="small" />
                        </IconButton>
                      </Box> */}
                        {formik.values.brandLogos?.map((logo, index) => (
                          <div key={"logo"+index+logo?.path}>
                            <Typography>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontSize: "15px",
                                }}
                              >
                                Brand Logo
                              </span>
                              <span
                                style={{
                                  color: "#9E9E9E",
                                  fontSize: "12px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                {" "}
                                Please upload PSD,CDR,PNG or Jpeg files only | Size: 1mb
                                | Dimensions: 171*174
                              </span>
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box sx={{flex:1}}>
                                  <FileUploader
                                    keyPrefix={`logo_${index}_${logo?.path}`}
                                    hasDisplayName
                                    isMultiple={false}
                                    isImage={true}
                                    fieldName={"Upload Brand Logo"}
                                    preLoadedFile={
                                      !!formik.values?.brandLogos?.length
                                        ? [
                                            {
                                              fileName:
                                                formik?.values?.brandLogos?.[index]
                                                  ?.publicurl,
                                              displayName:
                                                formik?.values?.brandLogos?.[index].path,
                                            },
                                          ]
                                        : null
                                    }
                                    signedUrlCallback={(newUrl, idx, publicUrl) => {
                                      formik.setFieldValue(
                                        `brandLogos.${index}.path`,
                                        newUrl
                                      );
                                      formik.setFieldValue(
                                        `brandLogos.${index}.publicurl`,
                                        publicUrl
                                      );
                                    }}
                                    isFieldValid={
                                      formik?.touched?.brandLogos?.[index]?.publicurl &&
                                      formik?.errors?.brandLogos?.[index]?.publicurl
                                    }
                                  />
                                  {formik?.touched?.brandLogos?.[index]?.publicurl &&
                                    formik?.errors?.brandLogos?.[index]?.publicurl && (
                                      <Typography fontSize={12} color="error">
                                        {formik?.errors?.brandLogos?.[index]?.publicurl}
                                      </Typography>
                                    )}
                                </Box>
                                <IconButton
                                  size="small"
                                  type="button"
                                  sx={{ px: 2, ml:2, borderRadius: 5 }}
                                  aria-label="remove"
                                  onClick={() =>
                                    // handleRemove(logo, index, remove)
                                    remove(index)
                                  }
                                >
                                  <Icon
                                    component={DeleteIcon}
                                    fontSize="small"
                                  />
                                </IconButton>
                            </Box>
                          </div>
                        ))}
                        <Box sx={{display:'flex', alignItems:'center'}}>
                          <MDButton type="button"
                            variant="contained"
                            color="dark"
                            circular={true}
                            sx={{ borderRadius: "100px", mr: 4, width: "max-content" }}
                            onClick={() => push({ key: `logo_${formik.values.brandLogos?.length + 1}`, path: "", publicurl: "" })}
                          >
                            <UploadSharp /> Upload Another Logo
                          </MDButton>
                          <Typography sx={{fontFamily:"Montserrat", fontSize:12, ml:2}}>
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "#f00",
                                // fontFamily: "Montserrat",
                              }}
                            >
                              Note: {" "}
                            </span>
                            <span>
                              If there are multiple brands please upload another logo 
                            </span>
                          </Typography>
                        </Box>
                      </div>
                    )}
                  </FieldArray>

                  {/* <Typography>
                    <span style={{ fontWeight: 'bold', color: "#000", fontFamily: "Montserrat", fontSize: "15px" }}>Brand Logo</span>
                    <span style={{ color: '#9E9E9E', fontSize: '12px', fontFamily: "Montserrat" }}> Please upload JPEG or PNG files only | Size: 1mb | Dimensions: 171*174</span>
                  </Typography>
                    <FileUploader
                      hasDisplayName
                      isMultiple={false}
                      isImage={true}
                      fieldName={"Upload Brand Logo"}
                      preLoadedFile={
                        !!formik.values.brandLogoUrl
                          ? [{fileName:formik.values.brandLogoUrl, displayName:formik.values.brandLogoPath}]
                          : null
                      }
                      signedUrlCallback={(newUrl, index, publicUrl) => {
                        formik.setFieldValue("brandLogoPath", newUrl);
                        formik.setFieldValue("brandLogoUrl", publicUrl);
                      }}
                      isFieldValid={formik.touched?.brandLogoUrl && formik.errors?.brandLogoUrl}
                    />
                    {formik.touched.brandLogoUrl && formik.errors.brandLogoUrl && (
                      <Typography fontSize={12} color="error">
                        {formik.errors.brandLogoUrl}
                      </Typography>
                    )} */}
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                      }}
                    >
                      Brand Cover Image
                    </span>
                    <span
                      style={{
                        color: "#9E9E9E",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {" "}
                      Please upload JPEG or PNG files only | Size: 1mb |
                      Dimensions: 390*570
                    </span>
                  </Typography>
                  <FileUploader
                    fieldName={"Upload Brand Cover Image"}
                    isMultiple={false}
                    isImage={true}
                    hasDisplayName
                    preLoadedFile={
                      !!formik.values.brandCoverUrl
                        ? [
                            {
                              fileName: formik.values.brandCoverUrl,
                              displayName: formik.values.brandCoverPath,
                            },
                          ]
                        : null
                    }
                    signedUrlCallback={(newUrl, index, publicUrl) => {
                      formik.setFieldValue("brandCoverPath", newUrl);
                      formik.setFieldValue("brandCoverUrl", publicUrl);
                    }}
                    isFieldValid={
                      formik.touched?.brandCoverUrl &&
                      formik.errors?.brandCoverUrl
                    }
                  />
                  {formik.touched?.brandCoverUrl &&
                    formik.errors?.brandCoverUrl && (
                      <Typography fontSize={12} color="error">
                        {formik.errors?.brandCoverUrl}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                      }}
                    >
                      Brand Thumbnail
                    </span>
                    <span
                      style={{
                        color: "#9E9E9E",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {" "}
                      Please upload JPEG and PNG files only | Size : 1mb
                    </span>
                  </Typography>
                  <FileUploader
                    isMultiple={false}
                    isImage={true}
                    fieldName={"Upload Brand Thumbnail"}
                    preLoadedFile={
                      !!formik.values.brandThumbnailUrl
                        ? [
                            {
                              fileName: formik.values.brandThumbnailUrl,
                              displayName: formik.values.brandThumbnailPath,
                            },
                          ]
                        : null
                    }
                    hasDisplayName
                    signedUrlCallback={(newUrl, index, publicUrl) => {
                      formik.setFieldValue("brandThumbnailPath", newUrl);
                      formik.setFieldValue("brandThumbnailUrl", publicUrl);
                    }}
                    isFieldValid={
                      formik.touched?.brandThumbnailUrl &&
                      formik.errors?.brandThumbnailUrl
                    }
                  />
                  {formik.touched?.brandThumbnailUrl &&
                    formik.errors?.brandThumbnailUrl && (
                      <Typography fontSize={12} color="error">
                        {formik.errors?.brandThumbnailUrl}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                      }}
                    >
                      Brand Color
                    </span>
                  </Typography>
                  <ColorPicker
                    style={{ height: 52 }}
                    color={formik.values.brandColour}
                    setColor={(setColor) => {
                      formik.setFieldValue("brandColour", setColor);
                    }}
                  />
                </Grid>
              </Grid>
              {user && user.role === "admin" && (
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          fontFamily: "Montserrat",
                          fontSize: "15px",
                          mr: 1,
                        }}
                        component="legend"
                      >
                        Brand Ratings
                      </Typography>
                      <Rating
                        name="brand-rating"
                        value={formik.values.brandRating}
                        onChange={(event, newValue) => {
                          formik.setFieldValue("brandRating", newValue);
                        }}
                      />
                    </Grid>
                    {formik.touched?.brandRating && formik.errors?.brandRating && (
                      <Typography fontSize={12} color="error">
                        {formik.errors?.brandRating}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          fontFamily: "Montserrat",
                          fontSize: "15px",
                          mr: 1,
                        }}
                        component="legend"
                      >
                        Brand Visible
                      </Typography>
                      <Switch
                        checked={formik.values.brandVisibile}
                        onChange={(event) =>
                          formik.setFieldValue(
                            "brandVisibile",
                            event.target.checked
                          )
                        }
                        name="brandVisibile"
                        color="primary"
                      />
                    </Grid>
                    {formik.touched?.brandVisibile &&
                      formik.errors?.brandVisibile && (
                        <Typography fontSize={12} color="error">
                          {formik.errors?.brandVisibile}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                sx={{ mt: 3 }}
                justifyContent={"flex-end"}
                gap={3}
              >
                <Button
                  sx={{
                    color: "#000000",
                    borderRadius: "50px",
                    borderColor: "#000000",
                    fontFamily: "Montserrat",
                  }}
                  variant="outlined"
                  onClick={() => {
                    formik.resetForm({
                      values: {
                        companyName: comapanyDetail?.name || "",
                        brandTitle: comapanyDetail?.usp || "",
                        brandDescription: comapanyDetail?.description || "",
                        brandTags: comapanyDetail?.brandTags || [],
                        brandLogoPath: comapanyDetail?.logo?.path || "",
                        brandLogoUrl: comapanyDetail?.logo?.publicurl || "",
                        brandCoverPath:
                          comapanyDetail?.coverPic?.[0]?.path || "",
                        brandCoverUrl:
                          comapanyDetail?.coverPic?.[0]?.publicurl || "",
                        brandThumbnailPath:
                          comapanyDetail?.thumbnail?.path || "",
                        brandThumbnailUrl:
                          comapanyDetail?.thumbnail?.publicurl || "",
                        brandColour: comapanyDetail?.hexColour || "#000000",
                        brandRating: comapanyDetail?.brandRating || 0,
                        brandVisibile: comapanyDetail?.isBrandVisible || false,
                      },
                    });
                  }}
                >
                  Reset
                </Button>
                <Button
                  // type="submit"
                  onClick={()=>{
                    setTabValueFlag(true)
                    formik.handleSubmit();
                  }}
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#000000",
                    color: "#fff",
                    borderRadius: "50px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});

export default CompanyForm;
