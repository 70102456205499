import React from 'react';
import PropTypes from "prop-types";
import MDBox from 'components/MDBox';

function MDTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <MDBox sx={{ py: 1 }}>
            {children}
            </MDBox>
        )}
        </div>
    );
}

MDTabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default MDTabPanel;