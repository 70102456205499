import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "./DataTable";
import {
  AvailableTabs,
  getBulkPriceUpdateTableColumnDefs,
  BulkProductTableHeaderData,
  VariantTableColumnDefs,
  VariantTableHeaderData,
  getBulkProductTableColumnDefs,
  getSuperAdminBulkProductTableColumnDefs,
  getBulkPriceUpdateAuditTableColumnDefs,
} from "./tableData";
import TableTabs from "./TableTabs";
import useTableData from "../hooks/useTableData";
import LoadingDataPlaceHolder from "./dataStatePlaceholder/LoadingDataPlaceholder";
import ErrorDataPlaceHolder from "./dataStatePlaceholder/ErrorDataPlaceholder";
import NoDataPlaceHolder from "./dataStatePlaceholder/NoDataPlaceholder";
import { productServiceClient } from "graphql/client";
import { GET_BULK_PRODUCT_UPLOAD } from "services/product-service";
import ChangeStatusModalForm from "../forms/ChangeStatusModalForm";
import { GET_PRODUCT_VARIANT_LIST } from "services/product-service";
import useProductContext from "../context/useProductContext";
import ProductFilter from "./Filters/ProductFilter";
import AgGrid from "components/MDGrid";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createOrderByFromOrderModel } from "layouts/cutsomer/utils";
import { GET_SUPER_ADMIN_BULK_PRODUCT } from "services/product-service";
import { GET_BULK_PRICE_UPDATES } from "services/product-service";
import { DELETE_BULK_PRICE_UPDATE } from "services/product-service";
import select from "assets/theme/components/form/select";
import { CHANGE_STATUS_BULK_PRICE_UPDATE } from "services/product-service";
import { GET_BULK_PRICE_UPDATE_AUDIT } from "services/product-service";
import useStoreSelection from "layouts/device-Master/storeSelectionHook";
const defaultPageSize = 1;

let abortController = new AbortController();
let signal = abortController.signal;

const getProductData = async (tab, limit, skip, filters = {}) => {
  abortController.abort("stalled data fetch");

  abortController = new AbortController();
  signal = abortController.signal;

  Object.keys(filters).forEach((key) => {
    if (filters[key] === "") {
      delete filters[key];
    }
  });

  if (tab === AvailableTabs.singleProduct) {
    const data = await productServiceClient.query({
      query: GET_PRODUCT_VARIANT_LIST,
      variables: { filter: { limit, skip, ...filters } },
      fetchPolicy: "network-only",
      context: { fetchOptions: { signal } },
    });

    const { data: variantList } = data;

    const { getVariants } = variantList || {
      getVariants: { variants: [], total: 0 },
    };

    return {
      ...getVariants,
      variants: getVariants.variants,
    };
  } else if (tab === AvailableTabs.nodeWiseBulkProduct) {
    const data = await productServiceClient.query({
      query: GET_BULK_PRODUCT_UPLOAD,
      variables: { filter: { limit, skip, ...filters } },
      fetchPolicy: "network-only",
      context: { fetchOptions: { signal } },
    });

    const { data: bulkProductData } = data;

    const { getBulkProductUpload } = bulkProductData;

    return {
      ...getBulkProductUpload,
      bulkProduct: getBulkProductUpload.bulkProducts,
    };
  }
  // else if (tab === AvailableTabs.bulkPriceUpload) {
  // const data = await productServiceClient.query({
  //   query: GET_BULK_PRICE_UPDATES,
  //   variables: { GetBulkPriceUpdateInput: { limit, skip, ...filters } },
  //   fetchPolicy: "network-only",
  //   context: { fetchOptions: { signal } },
  // });

  // const { data: bulkPriceUpdatesData } = data;

  // const { getbulkPriceUpdates } = bulkPriceUpdatesData;

  // // return {
  // //   ...getbulkPriceUpdates,
  // //   // bulkProduct: getBulkProductUpload.bulkProducts,
  // // };
  // return getbulkPriceUpdates;
  // }
  else if (tab === AvailableTabs.bulkProduct) {
    const data = await productServiceClient.query({
      query: GET_SUPER_ADMIN_BULK_PRODUCT,
      variables: { filter: { limit, skip, ...filters } },
      fetchPolicy: "network-only",
      context: { fetchOptions: { signal } },
    });

    const { data: superAdminBulkProductData } = data;

    const { getSuperAdminBulkProduct } = superAdminBulkProductData;

    return {
      ...getSuperAdminBulkProduct,
      superAdminBulkProduct: getSuperAdminBulkProduct.data,
    };
  }
};

export default function ProductData({ gridRef, selectedTab, setSelectedTab }) {
  let { loading, error, data, reFetchData } = useTableData(getProductData);
  const [queryData, setQueryData] = useState(null);
  const [readMethod, setReadMethod] = useState(null);
  const [colDef, setColDef] = useState([]);
  const [key, setKey] = useState(AvailableTabs.singleProduct);
  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  const [limit, setLimit] = useState(defaultPageSize);
  const [skip, setSkip] = useState(0);

  const [changeStatusRow, setChangeStatusRow] = useState(null);

  const { productContext, setProductContext } = useProductContext();

  const [singleProductFilters, setSingleProductFilters] = useState({
    status: "",
    brandId: "",
    categoryId: "",
  });

  const [bulkProductFilters, setBulkProductFilters] = useState({
    status: "",
    brand: "",
    filename: "",
  });

  const filteredBulkProductFilters = { ...bulkProductFilters };

  Object.keys(filteredBulkProductFilters).forEach((key) => {
    if (filteredBulkProductFilters[key] === "") {
      delete filteredBulkProductFilters[key];
    }
  });

  const filteredSingleProductFilters = {
    ...singleProductFilters,
  };

  Object.keys(filteredSingleProductFilters).forEach((key) => {
    if (filteredSingleProductFilters[key] === "") {
      delete filteredSingleProductFilters[key];
    }
  });

  useEffect(() => {
    fetchQueryData();
    getReadMethod();
  }, [selectedTab]);

  useEffect(() => {
    getColDef();
  }, [queryData, readMethod]);

  const { storeIdMap } = useStoreSelection();
  const [storeMap, setStoreMap] = useState({});

  useEffect(() => {
    if (storeIdMap) {
      const map = Object.entries(storeIdMap).reduce((acc, [key, value]) => {
        if (value.__typename === "Store") {
          acc[value.code] = value.name;
        }
        return acc;
      }, {});
      setStoreMap(map);
    }
  }, [storeIdMap]);

  const fetchQueryData = async () => {
    let data;
    switch (selectedTab) {
      case AvailableTabs.bulkPriceUpload:
        setQueryData([]);
        // return getBulkPriceUpdateTableColumnDefs({handleDeleteClick: handleBulkPriceUpdateDelete});
        data = await productServiceClient.query({
          query: GET_BULK_PRICE_UPDATES,
          variables: { filter: { limit: 1000, skip: 0 } },
          fetchPolicy: "network-only",
          context: { fetchOptions: { signal } },
        });

        const { data: bulkPriceUpdatesData } = data;

        const { getbulkPriceUpdates } = bulkPriceUpdatesData;

        // return {
        //   ...getbulkPriceUpdates,
        //   // bulkProduct: getBulkProductUpload.bulkProducts,
        // };
        setQueryData(getbulkPriceUpdates.bulkPriceUpdates);
        setKey(selectedTab);
        break;
      case AvailableTabs.productsPriceList:
        setQueryData([]);
        // return getBulkPriceUpdateTableColumnDefs({handleDeleteClick: handleBulkPriceUpdateDelete});
        data = await productServiceClient.query({
          query: GET_BULK_PRICE_UPDATE_AUDIT,
          variables: { filter: { limit: 1000, skip: 0 } },
          fetchPolicy: "network-only",
          context: { fetchOptions: { signal } },
        });

        const { data: bulkPriceAuditData } = data;

        const { getbulkPriceAudits } = bulkPriceAuditData;

        // return {
        //   ...getbulkPriceAudits,
        //   // bulkProduct: getBulkProductUpload.bulkProducts,
        // };
        const queryData = getbulkPriceAudits.bulkPriceAudits.map((bpa) => {
          const bpaData = {
            ...bpa,
            ...bpa.priceUpdates,
          };
          delete bpaData?.priceUpdates;
          return bpaData;
        });
        setQueryData(queryData);
        setKey(selectedTab);
        break;
      default:
        setQueryData(null);
        setKey(selectedTab);
    }
  };

  useEffect(() => {
    // the if statments are written twice, to avoid unnecessary api call when the user is on different tab
    if (
      productContext.addedBulkProduct &&
      selectedTab === AvailableTabs.nodeWiseBulkProduct
    ) {
      reFetchData(selectedTab, limit, 0, bulkProductFilters);
      setSkip(0);
    }

    if (
      productContext.addedSuperAdminBulkProduct &&
      selectedTab === AvailableTabs.bulkProduct
    ) {
      reFetchData(selectedTab, limit, 0);
      setSkip(0);
    }

    if (
      productContext.addedSingleProduct &&
      selectedTab === AvailableTabs.singleProduct
    ) {
      reFetchData(selectedTab, limit, 0, singleProductFilters);
      setSkip(0);
    }

    if (productContext.addedBulkProduct || productContext.addedSingleProduct) {
      setProductContext({ addedBulkProduct: false, addedSingleProduct: false });
    }

    if (
      productContext.updatedBulkPriceUpdate &&
      selectedTab === AvailableTabs.bulkPriceUpload
    ) {
      fetchQueryData();
    }
  }, [productContext]);

  useEffect(() => {
    reFetchData(
      selectedTab,
      limit,
      skip,
      selectedTab === AvailableTabs.nodeWiseBulkProduct
        ? bulkProductFilters
        : singleProductFilters
    );
  }, [limit, skip, singleProductFilters, bulkProductFilters]);

  const [fetchVariants] = useLazyQuery(GET_PRODUCT_VARIANT_LIST, {
    client: productServiceClient,
    fetchPolicy: "network-only",
  });

  const [fetchSuperAdminBulkProduct] = useLazyQuery(
    GET_SUPER_ADMIN_BULK_PRODUCT,
    {
      client: productServiceClient,
      fetchPolicy: "network-only",
    }
  );

  const [fetchBulk] = useLazyQuery(GET_BULK_PRODUCT_UPLOAD, {
    client: productServiceClient,
    fetchPolicy: "network-only",
  });

  const [fetchBulkPriceUpdate] = useLazyQuery(GET_BULK_PRICE_UPDATES, {
    client: productServiceClient,
    fetchPolicy: "network-only",
  });

  const [deleteBulkPriceUpdateMutation] = useMutation(
    DELETE_BULK_PRICE_UPDATE,
    {
      client: productServiceClient,
    }
  );

  const [changeStatusBulkPriceUpdateMutation] = useMutation(
    CHANGE_STATUS_BULK_PRICE_UPDATE,
    { client: productServiceClient }
  );

  const handleBulkPriceUpdateDelete = (id) => {
    deleteBulkPriceUpdateMutation({
      variables: {
        id,
      },
      onCompleted: (data) => {
        fetchQueryData();
      },
    });
  };

  const handleBulkPriceUpdateStatusChange = (
    id,
    status = undefined,
    gSyncStatus = undefined
  ) => {
    changeStatusBulkPriceUpdateMutation({
      variables: {
        updatedData: {
          id,
          status,
          gSyncStatus,
        },
      },
      onCompleted: () => {
        fetchQueryData();
      },
    });
  };

  const getColDef = () => {
    switch (selectedTab) {
      case AvailableTabs.nodeWiseBulkProduct:
        setColDef(getBulkProductTableColumnDefs(setChangeStatusRow));
        break;
      case AvailableTabs.bulkProduct:
        setColDef(getSuperAdminBulkProductTableColumnDefs(setChangeStatusRow));
        break;
      case AvailableTabs.bulkPriceUpload:
        setColDef(
          getBulkPriceUpdateTableColumnDefs({
            user,
            handleDeleteClick: handleBulkPriceUpdateDelete,
            handleStatusChange: handleBulkPriceUpdateStatusChange,
          })
        );
        break;
      case AvailableTabs.productsPriceList:
        setColDef(getBulkPriceUpdateAuditTableColumnDefs(queryData, storeMap));
        break;
      default:
        setColDef(VariantTableColumnDefs);
    }
  };

  // const getQueryData = async () => {
  //   switch (selectedTab) {
  //     case AvailableTabs.bulkPriceUpload:
  //       const data = await productServiceClient.query({
  //         query: GET_BULK_PRICE_UPDATES,
  //         variables: { GetBulkPriceUpdateInput: { limit, skip, ...filters } },
  //         fetchPolicy: "network-only",
  //         context: { fetchOptions: { signal } },
  //       });

  //       const { data: bulkPriceUpdatesData } = data;

  //       const { getbulkPriceUpdates } = bulkPriceUpdatesData;
  //       return getbulkPriceUpdates;
  //     default: return null;
  //   }
  // }

  const getReadMethod = () => {
    switch (selectedTab) {
      case AvailableTabs.nodeWiseBulkProduct:
        setReadMethod({
          fetchFn: fetchBulk,
          inputParser: ({ startRow, endRow, filter, sortModel }) => ({
            filter: {
              skip: startRow,
              limit: endRow - startRow,
              ...filteredBulkProductFilters,
            },
          }),
          resultsParser: (data) => ({
            results: data?.data?.getBulkProductUpload?.bulkProducts,
            total: data?.data?.getBulkProductUpload?.total,
          }),
        });
        break;
      case AvailableTabs.bulkProduct:
        setReadMethod({
          fetchFn: fetchSuperAdminBulkProduct,
          inputParser: ({ startRow, endRow }) => ({
            filter: {
              skip: startRow,
              limit: endRow - startRow,
            },
          }),
          resultsParser: (data) => ({
            results: data?.data?.getSuperAdminBulkProduct?.data,
            total: data?.data?.getSuperAdminBulkProduct?.total,
          }),
        });
        break;
      case AvailableTabs.singleProduct:
        setReadMethod({
          fetchFn: fetchVariants,
          inputParser: ({ startRow, endRow, filter, sortModel }) => {
            console.log(filter, "filter");

            const agGridFilter = {
              articleName: filter?.articleName,
              skuId: filter?.skuId,
              barCode: filter?.barCode,
              icode: filter.ginesys_response?.code,
              itemName: filter?.itemName,
              bulkProductUploadFileName: filter.bulkProductUpload?.filename,
              // mrp: filter.productPrice?.mrp,
              // msp: filter.productPrice?.msp,
              // listingPrice: filter.productPrice?.listingPrice,
              brandName: filter.brandName,
              imageUrl: filter.imageUrl,
              availableQty: filter.availableQty,
              // status: filter.status,
              // normal_status: filter.status_1,
              isSample: filter.isSample,
              message: filter.message,
              broadwayCreatedDate: new Date(
                filter.broadwayCreatedDate
              ).getTime(),
              ERPCreatedDate: new Date(filter.ERPCreatedDate).getTime(),
            };

            return {
              filter: {
                skip: startRow,
                limit: endRow - startRow,
                ...filteredSingleProductFilters,
                filter,
                agGridFilter,
              },
            };
          },
          resultsParser: (data) => ({
            results: data?.data?.getVariants?.variants,
            total: data?.data?.getVariants?.total,
          }),
        });
    }
  };

  return (
    <Grid container mt={5} borderRadius={10}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#fafafa",
          borderRadius: "10px 10px 0px 0px",
          padding: "0.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableTabs
          selectedTab={selectedTab}
          onChange={(e, newTab) => {
            setSkip(0);
            setSelectedTab(newTab);
            reFetchData(newTab, limit, 0);
          }}
        />

        {/* {[AvailableTabs.singleProduct,AvailableTabs.nodeWiseBulkProduct].includes(selectedTab) && (
          <ProductFilter
            setSingleProductFilters={setSingleProductFilters}
            singleProductFilters={singleProductFilters}
            setBulkProductFilters={setBulkProductFilters}
            bulkProductFilters={bulkProductFilters}
            selectedTab={selectedTab}
          />
        )} */}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ backgroundColor: "#ffffff", borderRadius: "0px 0px 10px 10px" }}
      >
        {loading ? (
          <LoadingDataPlaceHolder />
        ) : error ? (
          <ErrorDataPlaceHolder error={error} />
        ) : (
          <AgGrid
            key={key}
            gridProps={{
              rowHeight: 60,
              pivotMode: false,
              sideBar: {
                toolPanels: ["columns", "filters"],
              },
              enableRangeSelection: true,
            }}
            queryData={queryData}
            readMethod={readMethod}
            columns={colDef}
            defaultColDefProp={{
              floatingFilter: true,
              enableRowGroup: true,
              enablePivot: true,
              sortable: true,
              editable: true,
              filter: true,
              flex: 1,
              minWidth: 200,
            }}
            customGridRef={gridRef}
          />
        )}
      </Grid>

      <ChangeStatusModalForm
        initialValues={{ status: changeStatusRow?.status, message: "" }}
        open={changeStatusRow}
        onClose={() => setChangeStatusRow(null)}
        onSubmit={(status, err) => {
          if (status) {
            reFetchData(selectedTab, limit, skip);
          }
        }}
        selectedTab={selectedTab}
      />
    </Grid>
  );
}
