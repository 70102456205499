

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";

const { transparent,secondary } = colors;

const textField = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: secondary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: secondary.main,
        },
      },
    },
    
  },
};

export default textField;
