import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNotification } from "context";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// ModuleRegistry.registerModules([ClientSideRowModelModule]);

const AgGrid = ({
  columns = [],
  readMethod = {},
  createMethod = {},
  updateMethod = {},
  deleteMethod = {},
  isGraphql = true,
  queryLoading = true,
  queryError = null,
  queryData = null,
  // page = "",
  pageSize = 20,
  gridProps = {},
  defaultColDefProp = {},
  externalGridRef = null,
  allowSearch = false,
  customGridRef = null,
  paginationPageSizeSelector = [10, 20, 50, 100],
}) => {
  const defaultGridRef = useRef();

  const gridRef = customGridRef || defaultGridRef;

  const { setNotification } = useNotification();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "75vh", width: "100%" }), []);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [isPivotMode, setIsPivotMode] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const onPivotModeChanged = () => {
    setIsPivotMode(gridRef.current.api.isPivotMode());
  };

  useEffect(() => {
    setColumnDefs(parseColumnDefs(columns));
  }, [columns]);

  const parseColumnDefs = (columns) => {
    return columns.map((column) => ({
      headerTooltip: column.headerName,
      ...column,
    }));
  };

  useEffect(() => {
    if (isPivotMode) {
      gridRef.current.api.createPivotChart({
        chartType: "bar",
        chartContainer: document.querySelector("#chartContainer"),
      });
    }
  }, [isPivotMode]);

  useEffect(() => {
    if (queryData && JSON.stringify(queryData) !== JSON.stringify(rowData)) {
      // Update the rowData state based on the fetched data
      setRowData(queryData); // Adjust this based on your data structure
    }
  }, [queryData]);

  const defaultColDef = {
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };

  const getServerSideDatasource = (server) => {
    return {
      getRows: async (params) => {
        var response = await server.getData(params.request);
        // adding delay to simulate real server call
        if (response.success) {
          // call the success callback
          params.success({
            rowData: response.rows,
            rowCount: response.lastRow,
          });
        } else {
          // inform the grid request failed
          params.fail();
        }
      },
    };
  };

  const dataServer = {
    getData: async (request) => {
      const { startRow, endRow, filterModel, sortModel } = request;
      // const pageSize = endRow - startRow;
      // const skip = startRow;
      let filter = {};
      const searchCols = [];
      const searchBy = [];
      for (let key in filterModel) {
        const column = columnDefs.find((colDef) => colDef.field == key);
        if (
          !allowSearch ||
          column.floatingFilterComponent ||
          column.filter == "agSetColumnFilter" ||
          column.filter == "agDateColumnFilter" ||
          column.filter == "agNumberColumnFilter"
        ) {
          filter = {
            ...filter,
            ...(column?.getFilterObj?.(filterModel[key]) || {
              [key]: filterModel[key].filter,
            }),
          };
        } else {
          searchCols.push(key);
          searchBy.push(filterModel[key].filter);
        }
      }
      const queryVariables = readMethod?.inputParser({
        startRow,
        endRow,
        filter,
        sortModel,
        searchCols,
        searchBy,
      }) || {}
      const data = await readMethod?.fetchFn({
        variables: queryVariables,
      });
      const parsedData = readMethod?.resultsParser?.(data);
      return {
        success: true,
        rows: parsedData?.results || [],
        lastRow: parsedData?.total || 0,
      };
    },
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    if (externalGridRef) externalGridRef.current = params.api;
    var datasource = getServerSideDatasource(dataServer);
    if (!queryData)
      params?.api?.setGridOption("serverSideDatasource", datasource);
    // setGridColumnApi(params.columnApi);
  };

  const onRowDragEnd = useCallback(
    (event) => {
      updateMethod?.mutation({
        variables: {
          input: {
            id: event.node.data.id,
            displayOrder: +(+event.node.rowIndex + 1),
          },
        },
        // onCompleted: () => updateMethod?.refetch({ variables: { input: { page, limit: 10, skip: 0 } } }),
      });
    },
    [rowData]
  );

  return (
    <>
      <div style={containerStyle}>
        <div id="grid-wrapper" style={{ width: "100%", height: "100%" }}>
          <div style={gridStyle} className={"ag-theme-alpine"}>
            <AgGridReact
              // TODO: implement pagination logic based on the queryData length and page number
              ref={gridRef}
              rowData={queryData ? rowData : undefined}
              rowModelType={queryData ? "clientSide" : "serverSide"}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              defaultColDef={{ ...defaultColDef, ...defaultColDefProp }}
              // onGridSizeChanged={onGridSizeChanged}
              // onFirstDataRendered={onFirstDataRendered}
              rowDragMultiRow={true}
              rowDragManaged={true}
              rowSelection={"multiple"}
              animateRows={true}
              onRowDragEnd={onRowDragEnd}
              alwaysShowHorizontalScroll={true}
              suppressHorizontalScroll={false}
              suppressAutoSize={true} // Globally disable auto-sizing
              pivotMode={false}
              onColumnPivotModeChanged={onPivotModeChanged}
              rowGroupPanelShow="onlyWhenGrouping"
              pivotPanelShow="onlyWhenPivoting"
              pagination={true}
              paginationPageSizeSelector={paginationPageSizeSelector}
              paginationPageSize={rowsPerPage}
              cacheBlockSize={rowsPerPage}
              onPaginationChanged={(params) => {
                if (params.newPageSize)
                  setRowsPerPage(gridApi?.paginationGetPageSize());
              }}
              {...gridProps}
            />
          </div>
          <div
            id="chartContainer"
            style={{
              height: 400,
              width: "100%",
              display: isPivotMode ? "block" : "none",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default AgGrid;
