import { gql } from "@apollo/client";

// DEVICES START************************************************************************************
export const LIST_ALL_DEVICE = gql`
query findAllDevices(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "updatedAt",
  $order: String! = "DESC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter: JSONObject={}
) {
  findAllDevices(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter: $filter
  ) {
    results {
      uuid
      code
      isActive
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      deviceName
      deviceType
      brandName
      screenSize
      orientation
      screenResolution
      purchaseDate
      acquireDate
      warrentyStartDate
      warrentyEndDate
      serialNumber
      manufacturer
      status
      isShared
      orientationDirection
      softwareFirmwareVersion
      configurationDetails
      assetTagBarcode
      supportContact
      storeId
      locationId
      subLocationId
      subLocationTypeId
      assetTypeCode
      fixture {
        uuid
        code
      }
    }
    total
  }
}  
`
/**
 * default values
 * {
    "page": 1,
    "limit": 10,
    "sortBy": "uuid",
    "order": "DESC"
}
 */

export const CREATE_DEVICE = gql`
mutation createDevice($input: CreateDeviceInput!) {
  createDevice(input: $input) {
      uuid
      code
      isActive
      isDeleted
      createdAt
      updatedAt
      createdBy
      updatedBy
      deviceName
      deviceType
      brandName
      screenSize
      orientation
      screenResolution
      purchaseDate
      acquireDate
      warrentyStartDate
      warrentyEndDate
      serialNumber
      manufacturer
      status
      isShared
      orientationDirection
      softwareFirmwareVersion
      configurationDetails
      assetTagBarcode
      supportContact
      storeId
      locationId
      subLocationId
      subLocationTypeId
  }
}
`
/**
 * {
    "input": {
        "deviceName": "Bravia",
        "deviceType": "LED_TV",
        "brandName": "Sony",
        "screenSize": "FOURTYTHREE",
        "orientation": "VERTICAL",
        "screenResolution": "1080X720",
        "purchaseDate": "2023-09-12",
        "acquireDate": "2023-10-12",
        "warrentyStartDate": "2023-10-12",
        "warrentyEndDate": "2025-09-12",
        "serialNumber": "123456789",
        "manufacturer": "OEM",
        "status": "ACTIVE",
        "isShared": true,
        "orientationDirection": "CLOCKWISE",
        "softwareFirmwareVersion": "10.1.1",
        "configurationDetails": "configurationDetails Test1",
        "assetTagBarcode": "1234562345",
        "supportContact": "9326598839",
        "storeId": "c2c2e9db-e4f5-426c-b772-54f68e8ac0d2",
        "locationId": "93ca51cc-292c-47ac-9172-de0dec22ada9",
        "subLocationId": "a1890d4d-b519-45ed-81b3-2d9bea229855",
        "subLocationTypeId": "de67df65-c4ff-439c-a25a-69e732aaf785",
        "isActive": true
    }
}
 */


export const UPDATE_DEVICE = gql`
mutation updateDevice($input: UpdateDeviceInput!) {
  updateDevice(input: $input) {
      uuid
      code
      isActive
      isDeleted
      createdAt
      updatedAt
      createdBy
      updatedBy
      deviceName
      deviceType
      brandName
      screenSize
      orientation
      screenResolution
      purchaseDate
      acquireDate
      warrentyStartDate
      warrentyEndDate
      serialNumber
      manufacturer
      status
      isShared
      orientationDirection
      softwareFirmwareVersion
      configurationDetails
      assetTagBarcode
      supportContact
      storeId
      locationId
      subLocationId
      subLocationTypeId
  }
}
`
/**
 * {
    "input": {
        "uuid": "67eaf8b1-326c-462c-84cd-2c110a96c2df",
        "deviceName": "QLED Update",
        "deviceType": "LED_TV",
        "brandName": "Sony",
        "screenSize": "FOURTYTHREE",
        "orientation": "VERTICAL",
        "screenResolution": "1080X720",
        "purchaseDate": "2023-09-12",
        "acquireDate": "2023-10-12",
        "warrentyStartDate": "2023-10-12",
        "warrentyEndDate": "2025-09-12",
        "serialNumber": "123456789",
        "manufacturer": "OEM",
        "status": "ACTIVE",
        "isShared": true,
        "orientationDirection": "CLOCKWISE",
        "softwareFirmwareVersion": "10.1.1",
        "configurationDetails": "configurationDetails Test1",
        "assetTagBarcode": "1234562345",
        "supportContact": "9326598839",
        "storeId": "c2c2e9db-e4f5-426c-b772-54f68e8ac0d2",
        "locationId": "93ca51cc-292c-47ac-9172-de0dec22ada9",
        "subLocationId": "a1890d4d-b519-45ed-81b3-2d9bea229855",
        "subLocationTypeId": "de67df65-c4ff-439c-a25a-69e732aaf785",
        "isActive": true
    }
}
 */

export const REMOVE_DEVICE = gql`
mutation removeDevice($id: String!) {
  removeDevice(id: $id)
}
`
/* {
  "id": "67eaf8b1-326c-462c-84cd-2c110a96c2df"
} */

// DEVICES END **************************************************************************************

//DEVICES BULK UPLOAD START
export const LIST_ALL_DEVICE_BULK_UPLOAD = gql`
query findAllDeviceBulkUplods(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "updatedAt",
  $order: String! = "DESC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter: JSONObject={}
) {
  findAllDeviceBulkUplods(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter: $filter
  ) {
    results {
      uuid
      code
      isActive
      isDeleted
      createdAt
      updatedAt
      createdBy
      updatedBy
      filename
      path
      publicUrl
      createDate
      updateDate
      status
    }
    total
  }
}  
`

export const CREATE_DEVICE_BULK_UPLOAD = gql`
mutation createDeviceBulkUplod($input: BulkUploadInput!) {
  createDeviceBulkUplod(input: $input) {
    uuid
    code
    isActive
    isDeleted
    createdAt
    updatedAt
    createdBy
    updatedBy
    filename
    path
    publicUrl
    createDate
    updateDate
    status
  }
}
`
/**
 * {
    "input": {
        "deviceName": "Bravia",
        "deviceType": "LED_TV",
        "brandName": "Sony",
        "screenSize": "FOURTYTHREE",
        "orientation": "VERTICAL",
        "screenResolution": "1080X720",
        "purchaseDate": "2023-09-12",
        "acquireDate": "2023-10-12",
        "warrentyStartDate": "2023-10-12",
        "warrentyEndDate": "2025-09-12",
        "serialNumber": "123456789",
        "manufacturer": "OEM",
        "status": "ACTIVE",
        "isShared": true,
        "orientationDirection": "CLOCKWISE",
        "softwareFirmwareVersion": "10.1.1",
        "configurationDetails": "configurationDetails Test1",
        "assetTagBarcode": "1234562345",
        "supportContact": "9326598839",
        "storeId": "c2c2e9db-e4f5-426c-b772-54f68e8ac0d2",
        "locationId": "93ca51cc-292c-47ac-9172-de0dec22ada9",
        "subLocationId": "a1890d4d-b519-45ed-81b3-2d9bea229855",
        "subLocationTypeId": "de67df65-c4ff-439c-a25a-69e732aaf785",
        "isActive": true
    }
}
 */


export const UPDATE_DEVICE_BULK_UPLOAD = gql`
mutation changeStatusBulkDeviceUpload ($input:UpdateDeviceBulkUploadInput!) {
  changeStatusBulkDeviceUpload(updatedData:$input) {
    filename
    uuid
    status
  }
}

`


export const REMOVE_DEVICE_BULK_UPLOAD = gql`
mutation removeDeviceBulkUplod($id: String!) {
  removeDeviceBulkUplod(id: $id)
}
`
/* {
  "id": "67eaf8b1-326c-462c-84cd-2c110a96c2df"
} */

// DEVICES BULK UPLOAD END **************************************************************************************



// FIXTURES START ***********************************************************************************
export const LIST_ALL_FIXTURES = gql`
query findAllFixtures(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "id",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter: JSONObject={}
) {
  findAllFixtures(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter: $filter
  ) {
    results {
      uuid
      code
      isActive
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      fixtureType
      fixtureSize
      fixtureName
      opsCode
      businessCode
      storeId
      locationId
      subLocationId
      subLocationTypeId
      assetTypeCode
      isShared
      devices {
        uuid
        deviceName
        code
      }
    }
    total
  }
}  
`
/* {
  "page": 1,
  "limit": 10,
  "sortBy": "uuid",
  "order": "DESC",
  "relations": ["images"]
} */

export const CREATE_FIXTURE = gql`
mutation createFixture($input: CreateFixtureInput!) {
  createFixture(input: $input) {
      uuid
      code
      createdAt
      updatedAt
      createdBy
      updatedBy
      isActive
      isDeleted
      fixtureType
      fixtureSize
      storeId
      locationId
      subLocationId
      subLocationTypeId
  }
}
`
/* {
  "input": {
      "fixtureType": "BROWSER",
      "fixtureSize": "4ft",
      "storeId": "c2c2e9db-e4f5-426c-b772-54f68e8ac0d2",
      "locationId": "93ca51cc-292c-47ac-9172-de0dec22ada9",
      "subLocationId": "a1890d4d-b519-45ed-81b3-2d9bea229855",
      "subLocationTypeId": "de67df65-c4ff-439c-a25a-69e732aaf785"
  }
} */

export const UPDATE_FIXTURE = gql`
mutation updaetFixture($input: UpdateFixtureInput!) {
  updateFixture(input: $input) {
      uuid
      code
      createdAt
      updatedAt
      createdBy
      updatedBy
      isActive
      isDeleted
      fixtureType
      fixtureSize
      storeId
      locationId
      subLocationId
      subLocationTypeId
  }
}
`
/* {
  "input": {
      "uuid": "3460da1c-047a-4bbd-8a0e-74f72f07e394",
      "fixtureType": "BROWSER",
      "fixtureSize": "5ft",
      "storeId": "c2c2e9db-e4f5-426c-b772-54f68e8ac0d2",
      "locationId": "93ca51cc-292c-47ac-9172-de0dec22ada9",
      "subLocationId": "a1890d4d-b519-45ed-81b3-2d9bea229855",
      "subLocationTypeId": "de67df65-c4ff-439c-a25a-69e732aaf785",
      "imageIds": [
          "07349af4-9547-42d8-82c9-e7ca7343d466"
      ]
  }
} */
export const REMOVE_FIXTURE = gql`
mutation removeFixture($id: String!) {
  removeFixture(id: $id)
}
`
/* {
  "id": "3460da1c-047a-4bbd-8a0e-74f72f07e394"
} */

// FIXTURES END **************************************************************************************
// FIXTUREMEDIA START **************************************************************************************
export const LIST_ALL_FIXTURE_MEDIA = gql`
query findAllFixtureMedias(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "id",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = []
) {
  findAllFixtureMedias(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
  ) {
    results {
      uuid
      code
      isActive
      isDeleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      brandId
      fixture {
        uuid
        code
        fixtureType
        fixtureSize
        isShared
        storeId
        locationId
        subLocationId
        subLocationTypeId
      }
      images {
        uuid
        code
        name
        size
        isActive
        format
        dimension
        status
      }
    }
    total
  }
}
`
/* {
  "page": 1,
  "limit": 10,
  "sortBy": "uuid",
  "order": "DESC",
  "relations": ["fixture", "images"]
} */

export const CREATE_FIXTURE_MEDIA = gql`
mutation createFixtureMedia($input: CreateFixtureMediaInput!) {
  createFixtureMedia(input: $input) {
    uuid
    code
    isActive
    isDeleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    brandId
    fixture {
      uuid
      code
      fixtureType
      fixtureSize
      isShared
      storeId
      locationId
      subLocationId
      subLocationTypeId
    }
  }
}
`
/* {
  "input": {
    "brandId": "2dcef135-1da8-42d9-9d4b-ae90ebc21f6e",
    "fixtureId": "1099d5ee-baa1-41e1-988a-146a83cd79e3",
    "startDateTime": "2023-09-12T01:05:55.970Z",
    "endDateTime": "2024-09-12T01:05:55.970Z",
    "status": "PENDING",
    "imageIds": ["980df619-b6cc-4c47-933e-44213ffbde54"]
  }
} */

export const UPDATE_FIXTURE_MEDIA = gql`
mutation updateFixtureMedia($input: UpdateFixtureMediaInput!) {
  updateFixtureMedia(input: $input) {
    uuid
    code
    isActive
    isDeleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    brandId
    fixture {
      uuid
      code
      fixtureType
      fixtureSize
      isShared
      storeId
      locationId
      subLocationId
      subLocationTypeId
    }
    images {
      uuid
      code
      name
      size
      isActive
      format
      dimension
      status
    }
  }
}
`
/* {
  "input": {
    "uuid": "ebd9427d-335f-4c24-8933-eb9c6ae49cee",
    "brandId": "2dcef135-1da8-42d9-9d4b-ae90ebc21f6e",
    "fixtureId": "1099d5ee-baa1-41e1-988a-146a83cd79e3",
    "startDateTime": "2024-06-12T01:05:55.970Z",
    "endDateTime": "2024-07-12T01:05:55.970Z",
    "status": "PENDING",
    "imageIds": ["980df619-b6cc-4c47-933e-44213ffbde54", "b587f14b-e21f-4298-932b-5b4ba3bb6af9"]
  }
} */

export const REMOVE_FIXTURE_MEDIA = gql`
mutation removeFixtureMedia($id: String!) {
  removeFixtureMedia(id: $id)
}
`
/* {
  "id": "ebd9427d-335f-4c24-8933-eb9c6ae49cee"
} */

// FIXTUREMEDIA END **********************************************************************************

// VIDEO START **************************************************************************************
export const LIST_ALL_VIDEOS = gql`
query findAllVideo(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "id",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter: JSONObject = {}
) {
  findAllVideos(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter: $filter
  ) {
    results {
      uuid
      code
      isActive
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      brandId
      name
      path
      publicUrl
      duration
      aspectRatio
      orientation
      size
      status
    }
    total
  }
}
`
/* {
  "page": 1,
  "limit": 10,
  "sortBy": "uuid",
  "order": "DESC"
} */

export const CREATE_VIDEO = gql`
mutation createVideo($input: CreateVideoInput!) {
  createVideo(input: $input) {
    uuid
    code
    isActive
    isActive
    createdAt
    updatedAt
    createdBy
    updatedBy
    brandId
    name
    path
    publicUrl
    duration
    aspectRatio
    orientation
    size
    status
  }
}
`
/* {
  "input": {
   "brandId": "e386a082-1d13-4d46-8172-4aa50a28fa74",
   "name": "video test 1",
   "path": "test/path",
   "publicUrl": "test/publicurl.com",
   "duration": "20",
   "aspectRatio": "9:16",
   "orientation": "LANDSCAPE",
   "size": "1024",
   "status": "PENDING"
 }
} */

export const UPDATE_VIDEO = gql`
mutation updateVideo($input: UpdateVideoInput!) {
  updateVideo(input: $input) {
    uuid
    code
    isActive
    isActive
    createdAt
    updatedAt
    createdBy
    updatedBy
    brandId
    name
    path
    publicUrl
    duration
    aspectRatio
    orientation
    size
    status
  }
}
`
/* {
  "input": {
   "uuid": "fdb202af-2f5d-46a4-a4c0-b4388e54a362",
   "brandId": "e386a082-1d13-4d46-8172-4aa50a28fa74",
   "name": "video test update",
   "path": "test/path",
   "publicUrl": "test/publicurl.com",
   "duration": "20",
   "aspectRatio": "9:16",
   "orientation": "LANDSCAPE",
   "size": "1024",
   "status": "PENDING"
 }
} */

export const REMOVE_VIDEO = gql`
mutation removeVideo($id: String!) {
  removeVideo(id: $id)
}
`
/* {
  "id": "fdb202af-2f5d-46a4-a4c0-b4388e54a362"
} */
// VIDEO END **************************************************************************************

// VIDEO SCHEDULE START **************************************************************************************
export const LIST_VIDEO_SCHEDULES = gql`
query FindAllVideoSchedules(
  $page: Int!,
  $limit: Int!,
  $sortBy: String!,
  $order: String!,
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = []
) {
    findAllVideoSchedules(
      page: $page,
      limit: $limit,
      sortBy: $sortBy,
      order: $order,
      searchCols: $searchCols, 
      searchBy: $searchBy
      relations: $relations
    ) {
        total
        results {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fromTime
          toTime
          orderedVideos {
            order
            video {
              uuid
              code
              isActive
              isDeleted
              createdAt
              updatedAt
              createdBy
              updatedBy
              brandId
              name
              path
              publicUrl
              duration
              aspectRatio
              orientation
              size
              status
            }
          }
        }
      }
  }
`
export const CREATE_VIDEO_SCHEDULE = gql`
  mutation createVideoSchedule($input: CreateVideoScheduleInput!) {
    createVideoSchedule(input: $input) {
      uuid
    }
  }
`;

export const UPDATE_VIDEO_SCHEDULE = gql`
  mutation updateVideoSchedule($input: UpdateVideoScheduleInput!) {
    updateVideoSchedule(input: $input) {
      uuid
    }
  }
`;

export const REMOVE_VIDEO_SCHEDULE = gql`
  mutation RemoveVideoSchedule($id: String!) {
    removeVideoSchedule(id: $id)
  }
`;
// VIDEO SCHEDULE END **************************************************************************************




// PLAYLIST START **************************************************************************************
export const LIST_ALL_PLAYLISTS = gql`
query findAllPlaylist(
  $page: Int!,
  $limit: Int!,
  $sortBy: String!,
  $order: String!,
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter: JSONObject = {}
) {
  findAllPlaylists(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter: $filter
  ) {
    results {
      uuid
      code
      isActive
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      name
      brandId
      status
      orderedVideos{
        order
        video {
          uuid
          code
          duration
          name
          path
          publicUrl
          brandId
          isActive
          isDeleted
          aspectRatio
          size
          status
          orientation
        }
      }
    }
    total
  }
}
`
/* {
  "page": 1,
  "limit": 10,
  "sortBy": "uuid",
  "order": "DESC"
} */

export const CREATE_PLAYLIST = gql`
mutation createPlaylist($input: CreatePlaylistInput!) {
  createPlaylist(input: $input) {
    uuid
  }
}
`
/* {
  "input": {
    "name": "playlist test 1",
    "status": "PENDING"
  }
} */

export const UPDATE_PLAYLIST = gql`
mutation updatePlaylist($input: UpdatePlaylistInput!) {
  updatePlaylist(input: $input) {
    uuid
  }
}
`
/* {
  "input": {
    "uuid": "d94d5841-db37-4b41-8b3d-11968e00a4b8",
    "name": "playlist test update",
    "status": "PENDING"
  }
} */

export const REMOVE_PLAYLIST = gql`
mutation removePlaylist($id: String!) {
  removePlaylist(id: $id)
}
`
/* {
  "id": "d94d5841-db37-4b41-8b3d-11968e00a4b8"
} */
// PLAYLIST END **************************************************************************************

// MAP DEVICE VIDEO SCHEDULE START **************************************************************************************
export const LIST_MAP_DEVICE_VIDEO_SCHEDULES = gql`
query FindAllMapDeviceVideoSchedules(
  $page: Int!,
  $limit: Int!,
  $sortBy: String!,
  $order: String!,
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = []
) {
    findAllMapDeviceVideoSchedules(
      page: $page,
      limit: $limit,
      sortBy: $sortBy,
      order: $order,
      searchCols: $searchCols, 
      searchBy: $searchBy
      relations: $relations
    ) {
      total
      results {
        uuid
        code
        isActive
        isDeleted
        createdAt
        updatedAt
        createdBy
        updatedBy
        device {
          uuid
          code
          isActive
          isActive
          createdAt
          updatedAt
          createdBy
          updatedBy
          deviceName
          deviceType
          brandName
          screenSize
          orientation
          screenResolution
          purchaseDate
          acquireDate
          warrentyStartDate
          warrentyEndDate
          serialNumber
          manufacturer
          status
          isShared
          orientationDirection
          softwareFirmwareVersion
          configurationDetails
          assetTagBarcode
          supportContact
          storeId
          locationId
          subLocationId
          subLocationTypeId
          assetTypeCode
        }
        videoSchedules {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fromTime
          toTime
          orderedVideos {
            order
            video {
              uuid
              code
              isActive
              isDeleted
              createdAt
              updatedAt
              createdBy
              updatedBy
              brandId
              name
              path
              publicUrl
              duration
              aspectRatio
              orientation
              size
              status
            }
          }
        }
      }
    }
  }
`
export const CREATE_MAP_DEVICE_VIDEO_SCHEDULE = gql`
  mutation createMapDeviceVideoSchedule($input: CreateMapDeviceVideoScheduleInput!) {
    createMapDeviceVideoSchedule(input: $input) {
      uuid
    }
  }
`;

export const UPDATE_MAP_DEVICE_VIDEO_SCHEDULE = gql`
  mutation updateMapDeviceVideoSchedule($input: UpdateMapDeviceVideoScheduleInput!) {
    updateMapDeviceVideoSchedule(input: $input) {
      uuid
    }
  }
`;

export const REMOVE_MAP_DEVICE_VIDEO_SCHEDULE = gql`
  mutation RemoveMapDeviceVideoSchedule($id: String!) {
    removeMapDeviceVideoSchedule(id: $id)
  }
`;
// MAP DEVICE VIDEO SCHEDULE END **************************************************************************************




// MAP DEVICE PLAYLIST START **************************************************************************************
export const LIST_MAP_DEVICE_PLAYLISTS = gql`
query findAllMapDevicePlaylists(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "id",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter: JSONObject! = {}
) {
  findAllMapDevicePlaylists(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter: $filter
  ) {
    results {
      uuid
      code
      isActive
      isDeleted
      createdAt
      updatedAt
      createdBy
      updatedBy
      brandId
      startDateTime
      endDateTime
      status
      device {
        uuid
        code
        isActive
        isActive
        createdAt
        updatedAt
        createdBy
        updatedBy
        deviceName
        deviceType
        brandName
        screenSize
        orientation
        screenResolution
        purchaseDate
        acquireDate
        warrentyStartDate
        warrentyEndDate
        serialNumber
        manufacturer
        status
        isShared
        orientationDirection
        softwareFirmwareVersion
        configurationDetails
        assetTagBarcode
        supportContact
        storeId
        locationId
        subLocationId
        subLocationTypeId
      }
      playlists {
        uuid
        code
        isActive
        isActive
        createdAt
        updatedAt
        createdBy
        updatedBy
        name
        status
        videos {
          uuid
          code
          duration
          name
          path
          publicUrl
          brandId
          isActive
          isDeleted
          aspectRatio
          size
          status
          orientation
        }
      }
    }
    total
  }
}
`
/* {
    "page": 1,
    "limit": 10,
    "sortBy": "uuid",
    "order": "DESC",
    "relations": ["device", "playlists",  "playlists.videos"]
} */

export const CREATE_MAP_DEVICE_PLAYLIST = gql`
mutation createMapDevicePlaylist($input: CreateMapDevicePlaylistInput!) {
  createMapDevicePlaylist(input: $input) {
    uuid
    code
    isActive
    isDeleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    brandId
    device {
      uuid
      code
      brandName
      deviceName
      deviceType
      status
    }
    playlists {
      uuid
      code
      name
      status
    }
    startDateTime
    endDateTime
    status
  }
}
`
/* {
  "input": {
    "brandId": "2dcef135-1da8-42d9-9d4b-ae90ebc21f6e",
    "deviceId": "35d0743b-2095-40bb-8385-35abb7b90060",
    "startDateTime": "2024-06-12T01:05:55.970Z",
    "endDateTime": "2024-07-12T01:05:55.970Z",
    "status": "PENDING",
    "playlistId": ["f186daac-7278-4bbf-a975-f1d6b50994ae"]
  }
} */

export const UPDATE_MAP_DEVICE_PLAYLIST = gql`
mutation updateMapDevicePlaylist($input: UpdateMapDevicePlaylistInput!) {
  updateMapDevicePlaylist(input: $input) {
    uuid
    code
    isActive
    isDeleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    brandId
    device {
      uuid
      code
      brandName
      deviceName
      deviceType
      status
    }
    playlists {
      uuid
      code
      name
      status
    }
    startDateTime
    endDateTime
    status
  }
}
`
/* {
  "input": {
    "uuid": "5c6831db-e0f0-47bd-a4f5-6df45372b07e",
    "brandId": "2dcef135-1da8-42d9-9d4b-ae90ebc21f6e",
    "deviceId": "35d0743b-2095-40bb-8385-35abb7b90060",
    "startDateTime": "2024-06-12T01:05:55.970Z",
    "endDateTime": "2024-07-12T01:05:55.970Z",
    "status": "APPROVED",
    "playlistId": ["f186daac-7278-4bbf-a975-f1d6b50994ae"]
  }
} */

export const REMOVE_MAP_DEVICE_PLAYLIST = gql`
mutation removeMapDevicePlaylist($id: String!) {
  removeMapDevicePlaylist(id: $id)
}
`
/* {
  "id": "5c6831db-e0f0-47bd-a4f5-6df45372b07e"
} */
// MAP DEVICE PLAYLIST END **************************************************************************************
// IMAGE MASTER START **************************************************************************************
export const LIST_ALL_IMAGE_MASTERS = gql`
query FindAllImageMasters(
  $page: Int! = 1,
  $limit: Int! = 10,
  $sortBy: String! = "id",
  $order: String! = "ASC",
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = [],
  $filter: JSONObject = {}
) {
  findAllImageMasters(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
    filter: $filter
  ) {
    total
        results {
            uuid
            code
            isActive
            isDeleted
            createdAt
            updatedAt
            createdBy
            updatedBy
            status
            size
            name
            dimension
            format
            path
            publicUrl
            brandId
        }
  }
}
`;

export const CREATE_IMAGE_MASTER = gql`
  mutation createImageMaster($input: CreateImageMasterInput!) {
    createImageMaster(input: $input) {
      uuid
    }
  }
`;

export const UPDATE_IMAGE_MASTER = gql`
  mutation updateImageMaster($input: UpdateImageMasterInput!) {
    updateImageMaster(input: $input) {
      uuid
      name
    }
  }
`;

export const REMOVE_IMAGE_MASTER = gql`
  mutation RemoveImageMaster($id: String!) {
    removeImageMaster(id: $id)
  }
`;
// IMAGE MASTER END **************************************************************************************
// IMAGE SCHEDULE START **************************************************************************************
export const LIST_IMAGE_SCHEDULES = gql`
query FindAllImageSchedules(
  $page: Int!,
  $limit: Int!,
  $sortBy: String!,
  $order: String!,
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = []
) {
    findAllImageSchedules(
      page: $page,
      limit: $limit,
      sortBy: $sortBy,
      order: $order,
      searchCols: $searchCols, 
      searchBy: $searchBy
      relations: $relations
    ) {
        total
        results {
            uuid
            code
            isActive
            isDeleted
            createdAt
            updatedAt
            createdBy
            updatedBy
            fromTime
            toTime
            imageMaster {
                uuid
                code
                isActive
                isDeleted
                createdAt
                updatedAt
                createdBy
                updatedBy
                brandId
                status
                size
                name
                dimension
                format
                path
                publicUrl
            }
            mapFixtureImageSchedule {
                uuid
                code
                isActive
                isDeleted
                createdAt
                updatedAt
                createdBy
                updatedBy
            }
        }
    }
}
`
export const CREATE_IMAGE_SCHEDULE = gql`
  mutation createImageSchedule($input: CreateImageScheduleInput!) {
    createImageSchedule(input: $input) {
      uuid
    }
  }
`;

export const UPDATE_IMAGE_SCHEDULE = gql`
  mutation updateImageSchedule($input: UpdateImageScheduleInput!) {
    updateImageSchedule(input: $input) {
      uuid
    }
  }
`;

export const REMOVE_IMAGE_SCHEDULE = gql`
  mutation RemoveImageSchedule($id: String!) {
    removeImageSchedule(id: $id)
  }
`;
// IMAGE SCHEDULE END **************************************************************************************
// MAP FIXTURE IMAGE SCHEDULE START **************************************************************************************
export const LIST_MAP_FIXTURE_IMAGE_SCHEDULES = gql`
query FindAllMapFixtureImageSchedules(
  $page: Int!,
  $limit: Int!,
  $sortBy: String!,
  $order: String!,
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = []
) {
    findAllMapFixtureImageSchedules(
      page: $page,
      limit: $limit,
      sortBy: $sortBy,
      order: $order,
      searchCols: $searchCols, 
      searchBy: $searchBy
      relations: $relations
    ) {
      total
      results {
        uuid
        code
        isActive
        isDeleted
        createdAt
        updatedAt
        createdBy
        updatedBy
        fixture {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fixtureType
          fixtureSize
          isShared
          storeId
          locationId
          subLocationId
          subLocationTypeId
          assetTypeCode
          store
        }
        imageSchedules {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fromTime
          toTime
          imageMaster {
            uuid
            code
            isActive
            isDeleted
            createdAt
            updatedAt
            createdBy
            updatedBy
            brandId
            status
            size
            name
            dimension
            format
            path
            publicUrl
          }
          mapFixtureImageSchedule {
            uuid
          }
        }
      }
    }
  }
`
export const CREATE_MAP_FIXTURE_IMAGE_SCHEDULE = gql`
  mutation createMapFixtureImageSchedule($input: CreateMapFixtureImageScheduleInput!) {
    createMapFixtureImageSchedule(input: $input) {
      uuid
    }
  }
`;

export const UPDATE_MAP_FIXTURE_IMAGE_SCHEDULE = gql`
  mutation updateMapFixtureImageSchedule($input: UpdateMapFixtureImageScheduleInput!) {
    updateMapFixtureImageSchedule(input: $input) {
      uuid
    }
  }
`;

export const REMOVE_MAP_FIXTURE_IMAGE_SCHEDULE = gql`
  mutation RemoveMapFixtureImageSchedule($id: String!) {
    removeMapFixtureImageSchedule(id: $id)
  }
`;
// MAP FIXTURE IMAGE SCHEDULE END **************************************************************************************







// MAP PLAYLIST VIDEO START **************************************************************************************
export const LIST_ALL_MAP_PLAYLIST_VIDEOS = gql`
query FindAllMapPlaylistVideos(
  $page: Int!,
  $limit: Int!,
  $sortBy: String!,
  $order: String!,
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = []
  $filter: JSONObject={}

) {
    findAllMapPlaylistVideos(
        page: $page,
        limit: $limit,
        sortBy: $sortBy,
        order: $order,
        searchCols: $searchCols, 
        searchBy: $searchBy
        relations: $relations
        filter: $filter
    ) {
        total
        results {
            uuid
            playlist {
                uuid
                code
                isActive
                isDeleted
                createdAt
                updatedAt
                createdBy
                updatedBy
                brandId
                name
                status
            }
            videos {
                uuid
                code
                isActive
                isDeleted
                createdAt
                updatedAt
                createdBy
                updatedBy
                brandId
                name
                path
                publicUrl
                duration
                aspectRatio
                orientation
                size
                status
            }
            code
            isActive
            isDeleted
        }
    }
}`
export const CREATE_MAP_PLAYLIST_VIDEO=gql`
mutation CreateMapPlaylistVideo($input: CreateMapPlaylistVideoInput!) {
    createMapPlaylistVideo(input: $input) {
        uuid
        code
        isActive
        isDeleted
        createdAt
        updatedAt
        createdBy
        updatedBy
    }
}`

export const UPDATE_MAP_PLAYLIST_VIDEO=gql`
mutation UpdateMapPlaylistVideo($input: UpdateMapPlaylistVideoInput!) {
    updateMapPlaylistVideo(input: $input) {
        uuid
        code
        isActive
        isDeleted
        createdAt
        updatedAt
        createdBy
        updatedBy
    }
}`
export const REMOVE_MAP_PLAYLIST_VIDEO = gql`
mutation RemoveMapPlaylistVideo($id: String!) {
  removeMapPlaylistVideo(id: $id)
}
`
// MAP PLAYLIST VIDEO END **************************************************************************************

// MAP DEVICE PLAYLIST VIDEO START **************************************************************************************
export const LIST_ALL_MAP_DEVICE_PLAYLIST_VIDEOS = gql`
query FindAllMapDevicePlayListVideos(
  $page: Int!,
  $limit: Int!,
  $sortBy: String!,
  $order: String!,
  $searchCols: [String!] = [],
  $searchBy: [String!] = [],
  $relations: [String!] = []
) {
  findAllMapDevicePlayListVideos(
    page: $page,
    limit: $limit,
    sortBy: $sortBy,
    order: $order,
    searchCols: $searchCols, 
    searchBy: $searchBy
    relations: $relations
  ) {
    total
    results {
      uuid
      playlist {
          uuid
      }
      videos {
          uuid
      }
      code
      isActive
      isDeleted
    }
  }
}`

export const CREATE_MAP_DEVICE_PLAYLIST_VIDEO = gql`
mutation CreateMapDevicePlayListVideo($input: CreateMapDevicePlayListVideoInput!) {
  createMapDevicePlayListVideo(input: $input) {
      uuid
      code
      isActive
      isDeleted
      createdAt
      updatedAt
      createdBy
      updatedBy
      name
      fromTime
      toTime
  }
}
`

export const UPDATE_MAP_DEVICE_PLAYLIST_VIDEO = gql`
mutation UpdateMapDevicePlayListVideo($input: UpdateMapDevicePlayListVideoInput!) {
  updateMapDevicePlayListVideo(input: $input) {
      uuid
      code
      isActive
      isDeleted
      createdAt
      updatedAt
      createdBy
      updatedBy
      name
      fromTime
      toTime
  }
}`

export const REMOVE_MAP_DEVICE_PLAYLIST_VIDEO = gql`
mutation removeMapDevicePlayListVideo($id: String!) {
  removeMapDevicePlayListVideo(id: $id)
}
`
// MAP DEVICE PLAYLIST VIDEO END **************************************************************************************

export const MAP_DEVICE_STORE_LOCATION = gql`
mutation createDeviceLocation($input: CreateDeviceLocationInput!) {
    createDeviceLocation(input: $input) {
        locationId
        locationCode
        uuid
        code
    }
}
`
/**
 * {
    "page": 1,
    "limit": 10,
    "sortBy": "uuid",
    "order": "DESC",
    "relations": ["devices"]
}
 */
export const LIST_DEVICE_STORE_LOCATION = gql`
query findAllDevicesLocations(
    $page: Int! = 1,
    $limit: Int! = 10,
    $sortBy: String! = "id",
    $order: String! = "ASC",
    $searchCols: [String!] = [],
    $searchBy: [String!] = []
    $relations: [String!] = []
  ) {
    findAllDeviceLocations(
      page: $page,
      limit: $limit,
      sortBy: $sortBy,
      order: $order,
      searchCols: $searchCols, 
      searchBy: $searchBy,
      relations: $relations
    ) {
      results {
        uuid
        code
        locationCode
        locationId
        devices {
          uuid
          deviceType
          manufacturer
        }
      }
      total
    }
  }  
`;


/**NEW */
export const MAP_DEVICE_BRAND_PLAYLIST = gql`
  query FindAllMapDeviceBrandPlaylists(
    $page: Int!,
    $limit: Int!,
    $sortBy: String,
    $order: String,
    $searchCols: [String!] = [],
    $searchBy: [String!] = [],
    $relations: [String!] = [],
    $filter: JSONObject={}
  ) {
      findAllMapDeviceBrandPlaylists(
        page: $page,
        limit: $limit,
        sortBy: $sortBy,
        order: $order,
        searchCols: $searchCols, 
        searchBy: $searchBy,
        relations: $relations,
        filter: $filter
      ) {
          total
          results {
              uuid
              code
              isActive
              isDeleted
              createdAt
              updatedAt
              createdBy
              updatedBy
              fromTime
              toTime
              playlists {
                  uuid
                  code
                  isActive
                  isDeleted
                  createdAt
                  updatedAt
                  createdBy
                  updatedBy
                  brandId
                  name
                  status
              }
              mapDeviceBrandPlaylistVideos {
                  uuid
                  sortOrder
                  video {
                      uuid
                      code
                      isActive
                      isDeleted
                      createdAt
                      updatedAt
                      createdBy
                      updatedBy
                      brandId
                      name
                      path
                      publicUrl
                      duration
                      aspectRatio
                      orientation
                      size
                      status
                  }
              }
              mapDeviceFixtureBrands {
                uuid
              }
          }
      }
  }

`
export const CREATE_MAP_DEVICE_BRAND_PLAYLIST = gql`
  mutation CreateMapDeviceBrandPlaylist($input: CreateMapDeviceBrandPlaylistInput!) {
      createMapDeviceBrandPlaylist(input: $input) {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fromTime
          toTime
      }
  }
`
export const UPDATE_MAP_DEVICE_BRAND_PLAYLIST = gql`
  mutation UpdateMapDeviceBrandPlaylist($input: UpdateMapDeviceBrandPlaylistInput!) {
      updateMapDeviceBrandPlaylist(input: $input) {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fromTime
          toTime
      }
  }
`
export const REMOVE_MAP_DEVICE_BRAND_PLAYLIST = gql`
  mutation RemoveMapDeviceBrandPlaylist($id: String!) {
    removeMapDeviceBrandPlaylist(id: $id)
  }
`

/***MAP FIXTURE BRAND IMAGE */
export const MAP_FIXTURE_BRAND_IMAGE = gql`
  query FindAllMapFixtureBrandImages(
    $page: Int!,
    $limit: Int!,
    $sortBy: String,
    $order: String,
    $searchCols: [String!] = [],
    $searchBy: [String!] = [],
    $relations: [String!] = [],
    $filter: JSONObject={}
  ) {
      findAllMapFixtureBrandImages(
        page: $page,
        limit: $limit,
        sortBy: $sortBy,
        order: $order,
        searchCols: $searchCols, 
        searchBy: $searchBy,
        relations: $relations,
        filter: $filter
      ) {
          total
          results {
              uuid
              code
              isActive
              isDeleted
              createdAt
              updatedAt
              createdBy
              updatedBy
              fromTime
              toTime
              imageMaster {
                uuid
                code
                isActive
                isDeleted
                createdAt
                updatedAt
                createdBy
                updatedBy
                brandId
                status
                size
                name
                dimension
                format
                path
                publicUrl
            }
            mapDeviceFixtureBrand {
              uuid
            }
          }
      }
  }

`

export const CREATE_MAP_FIXTURE_BRAND_IMAGE = gql`
  mutation CreateMapFixtureBrandImage($input: CreateMapFixtureBrandImageInput!) {
      createMapFixtureBrandImage(input: $input) {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fromTime
          toTime
          
      }
  }
`
export const UPDATE_MAP_FIXTURE_BRAND_IMAGE = gql`
  mutation UpdateMapFixtureBrandImage($input: UpdateMapFixtureBrandImageInput!) {
      updateMapFixtureBrandImage(input: $input) {
          uuid
          code
          isActive
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          fromTime
          toTime
      }
  }
`
export const REMOVE_MAP_FIXTURE_BRAND_IMAGE = gql`
  mutation RemoveMapFixtureBrandImage($id: String!) {
    removeMapFixtureBrandImage(id: $id)
  }
`