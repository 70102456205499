import { Autocomplete, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReviewTable from './reviewTable'
import { BRANDS_LIST } from 'services/brand-service';
import { dashboardServiceClient } from 'graphql/client';
import { useQuery } from '@apollo/client';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import useDebounce from '../common/debounce';

function Review() {
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    const { loading, data, error, refetch } = useQuery(BRANDS_LIST, {
        client: dashboardServiceClient,
        variables: {
            take: 30,
            skip: 0,
            search:debouncedSearchTerm,
            filter: { isDeleted: false }
        },
    });
    useEffect(() => {
        if (debouncedSearchTerm !== null) {
            refetch();
        }
    }, [debouncedSearchTerm, refetch]);
    return (
        <Grid display="contents" container spacing={2}>
            <Grid item xs={3}>
                <Autocomplete
                    labelId="demo-simple-select-label"
                    label="Select Brand"
                    id="demo-simple-select"
                    name="brand"
                    loading={loading}
                    iconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    options={data?.brandlist?.results
                        ?.map((item) => (
                            { label: item?.name, value: item?.id }
                        )) || []}
                    onChange={(e, newValue) => {
                        setSelectedBrand(newValue?.value || null)
                    }}
                    onInputChange={(event, newValue) => {
                        if ((event && event?.type === "reset") || newValue === "") {
                          setSearchTerm(""); // Clear search when input is cleared
                        } else if (event && event.type === "change") {
                          setSearchTerm(newValue);
                        }
                      }}
                    sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main" } }}
                    renderInput={(params) => <TextField {...params} label="Select Brand" />}
                />
            </Grid>
            <ReviewTable selectedBrandId={selectedBrand} reviewType="Product" />
            <ReviewTable selectedBrandId={selectedBrand} reviewType="Brand" />
            <ReviewTable selectedBrandId={selectedBrand} reviewType="Event" />
        </Grid>
    )
}

export default Review
