import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Tabs, Tab, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import StoreInfoComponent from "./StoreInfo";
import AddressFormComponent from "./AddressForm";
import StoreOffer from "./StoreOffer";
import StoreDetailsCalender from "./StoreDetailsCalender";
import EntranceForm from "./storeEntrance";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const PinkButton = styled(Button)({
  width: "100%",
  backgroundColor: "#f2aec5",
  color: "#344767",
  marginTop: "5%",
  "&:hover": {
    backgroundColor: "#344767",
    color: "white",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  handleClose,
  storeData,
  setStoreData,
  storeLocation,
  storeLocationRefetch,
  refetch,
  searchKeyword,
  itemsPerPage,
  skip,
  value,
  setValue,
}) {

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Store Info" {...a11yProps(0)} />
          <Tab label="Address" {...a11yProps(1)} />
          <Tab label="Entrances" {...a11yProps(2)} />
          <Tab label="Calendar" {...a11yProps(3)} />
          <Tab label="Location" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
          <StoreInfoComponent
            key="storeInfoKey"
            handleClose={handleClose}
            storeData={storeData}
            handleTabChange={handleChange}
            setStoreData={setStoreData}
            refetch={refetch}
            searchKeyword={searchKeyword}
            itemsPerPage={itemsPerPage}
            skip={skip}
            value={value}
            setValue={setValue}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AddressFormComponent
          key="storeAddress"
          handleClose={handleClose}
          storeData={storeData}
          handleTabChange={handleChange}
          setStoreData={setStoreData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <EntranceForm
          key="storeEntrance"
          handleClose={handleClose}
          storeData={storeData}
          handleTabChange={handleChange}
          setStoreData={setStoreData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <StoreDetailsCalender
          key="storeCalender"
          handleClose={handleClose}
          storeData={storeData}
          handleTabChange={handleChange}
          setStoreData={setStoreData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <StoreOffer
          key="storeOffer"
          handleClose={handleClose}
          storeData={storeData}
          storeLocation={storeLocation}
          storeLocationRefetch={storeLocationRefetch}
          setStoreData={setStoreData}
        />
      </CustomTabPanel>
    </Box>
  );
}
