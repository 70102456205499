import { gql } from "@apollo/client";

export const GET_ACTIVITY_LIST = gql`
  query brandSpaceReadiness($listBrandSpaceReadinessFilter: ListBrandSpaceReadinessFilter!) {
    brandSpaceReadiness(
        listBrandSpaceReadinessFilter: $listBrandSpaceReadinessFilter
    )  {
        total
        results {
          id
          images
          status
          isDeleted
          createdAt
          updatedAt
          createdBy
          updatedBy
          store {
            name
            id
          }
          brand {
            name
            id
          }
          activity {
            name
            id
            isDeleted
          }
        }
  }
}
`;

export const CREATE_ACTIVITY = gql`
  mutation createBrandSpaceReadiness(
    $createBrandSpaceReadinessInput: CreateBrandSpaceReadinessInput!
  ) {
    createBrandSpaceReadiness(
      createBrandSpaceReadinessInput: $createBrandSpaceReadinessInput
    )  {
        id
        images
        status
        isDeleted
      }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateBrandSpaceReadiness(
    $updateBrandSpaceReadinessInput: UpdateBrandSpaceReadinessInput!
  ) {
    updateBrandSpaceReadiness(
      updateBrandSpaceReadinessInput: $updateBrandSpaceReadinessInput
    ) {
        id
        images
        status
        status
        isDeleted
      }
  }
`;
