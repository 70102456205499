import { AddIcCallOutlined } from "@mui/icons-material";
import { Grid, IconButton, Typography, Icon, Box } from "@mui/material";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import UploadIcon from "@mui/icons-material/Upload";
import React, { useState, useRef, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDButton from "components/MDButton";
import { useNotification } from "context";
import { useQuery, useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { GET_BULK_REVIWS } from "services/ecommerce-media-service";
import Loader from "components/MDLoader";
import { UPDATE_BULK_REVIWS } from "services/ecommerce-media-service";
import { REMOVE_REVIEW } from "services/ecommerce-media-service";

const ReviewTable = ({ selectedBrandId, reviewType }) => {
  const { setNotification } = useNotification();
  const [isLoading, setIsLoading] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_BULK_REVIWS, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      brandId: reviewType === "Event" ? "" : selectedBrandId,
      filter: { reviewType: reviewType },
    },
    onCompleted: (data) => {
      setRowCount(data?.bulkReviews?.total);
    },
    onError: (error) => {
      // Handle error
      console.error(`Error fetching ${reviewType} reviews:`, error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || `Failed to fetch ${reviewType} reviews`,
      });
    },
  });
  const [rowCount, setRowCount] = React.useState(data?.bulkReviews?.total || 0);
  useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.bulkReviews?.total !== undefined
        ? data?.bulkReviews?.total
        : prevRowCount
    );
  }, [data?.bulkReviews?.total, setRowCount]);
  const columns = [
    {
      field: "filename",
      headerName: "File Name",
      width: 200,
    },
    {
      field: "uploadedOn",
      headerName: "Updated On",
      width: 200,
    },
    {
      field: "uploadedBy",
      headerName: "Updated By",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 260,
      renderCell: (params) => {
        return (
          <select
            onChange={(e) => {
              e.stopPropagation(); //
              handleStatusChange(params.row.id, e.target.value);
            }}
            defaultValue={params.value.toUpperCase()}
            style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
          >
            <option value="DRAFT">DRAFT</option>
            <option value="PENDING">PENDING</option>
            <option value="APPROVED">APPROVED</option>
            <option value="REJECTED">REJECTED</option>
          </select>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton aria-label="download">
            <Icon
              component={downloadIcon}
              onClick={() => {
                handleDownloadClick(params.row.url, params.row.fileName);
              }}
              fontSize="small"
            />
          </IconButton>
          <IconButton aria-label="delete">
            <Icon
              component={deleteIcon}
              onClick={(e) => handleDeleteClick(e, params.row.id)}
              fontSize="small"
            />
          </IconButton>
        </Box>
      ),
    },
  ];
  const handleDownloadClick = (excelUrl, fileName) => {
    // This creates a new anchor element and triggers a download
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = fileName; // You can set the default file name for the download here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [updateReviews] = useMutation(UPDATE_BULK_REVIWS, {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BULK_REVIWS,
        variables: {
          take: 20,
          skip: 0,
          filter: { reviewType: reviewType },
        },
        fetchPolicy: "network-only",
      },
    ],
  });
  const [deleteReviews] = useMutation(REMOVE_REVIEW, {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BULK_REVIWS,
        variables: {
          take: 20,
          skip: 0,
        },
        fetchPolicy: "network-only",
      },
    ],
  });
  const handleStatusChange = async (id, statusValue) => {
    setIsLoading(true);
    try {
      await updateReviews({
        variables: { id: id, status: statusValue },
      });
      await refetch();
    } catch (error) {
      console.error("Failed to update device:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteClick = async (e, id) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await deleteReviews({
        variables: { id: id },
      });
      await refetch();
    } catch (error) {
      console.error("Failed to remove Review:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fileInputRef = useRef(null);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return setNotification({
        color: "error",
        isVisible: true,
        message: "Please Select File",
      });
    }

    const formData = new FormData();

    formData.append("templateType", reviewType);
    if (reviewType !== "Event") {
      formData.append("brandId", selectedBrandId);
    }
    formData.append("file", file);

    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/dashboard-service/bulk-review`
        : `/dashboard-service/bulk-review`;

    try {
      const response = await fetch(`${url}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setNotification({
          color: "success",
          isVisible: true,
          message: "File Uploaded Successfully",
        });
        refetch({
          take: 20,
          skip: 0,
          filter: { reviewType: reviewType },
        });
      } else {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error:", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Something went wrong",
      });
    } finally {
      event.target.value = ""; // Resetting the input value to allow re-upload of the same file
    }
  };

  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem("token");
    let url;

    if (reviewType === "Event") {
      url =
        process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/feed-service/section/review-template-excel?type=${reviewType}`
          : `/feed-service/section/review-template-excel?type=${reviewType}`;
    } else {
      url = process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/feed-service/section/review-template-excel?brandId=${selectedBrandId}&type=${reviewType}`
          : `/feed-service/section/review-template-excel?brandId=${selectedBrandId}&type=${reviewType}`;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `template_${reviewType}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to download template");
      }
    } catch (error) {
      console.error("Error:", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Something went wrong",
      });
    }
  };

  if (loading || isLoading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const allReviews = data && data?.bulkReviews?.results;
  return (
    <Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        p={1}
        mb={1}
        sx={{
          backgroundColor: "#EFF4F9",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        }}
      >
        <Grid container display="flex">
          <Grid item xs={6}>
            <Typography
              variant="h6"
              mt={1}
              sx={{ fontFamily: "Montserrat", color: "#25252E" }}
            >
              {reviewType} Reviews
            </Typography>
          </Grid>
          {(selectedBrandId || reviewType === "Event") && (
            <Grid item xs={6} display="flex" justifyContent="end">
              <IconButton
                aria-label="download"
                onClick={handleDownloadTemplate}
              >
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "Montserrat", color: "#25252E" }}
                >
                  <Icon
                    component={downloadIcon}
                    sx={{ marginRight: "10px", fontSize: "12px" }}
                  />
                  Download Template
                </Typography>
              </IconButton>
            </Grid>
          )}
          <Grid></Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ backgroundColor: "white" }}>
          <DataGrid
            autoHeight
            rows={allReviews || []}
            columns={columns}
            rowCount={rowCount}
            pageSizeOptions={[5, 10, 20]}
            getRowId={(row) => row.id}
            loading={loading}
            onPageChange={handleChangePage}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
          />
        </Grid>
      </Grid>
      {selectedBrandId || reviewType === "Event" ? (
        <>
          <input
            type="file"
            onChange={handleUpload}
            accept=".xlsx, .xlx, .csv"
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <MDButton
            onClick={() => fileInputRef.current.click()}
            type="button"
            variant="outlined"
            color="dark"
            circular={true}
            startIcon={<UploadIcon />}
            sx={{ marginTop: "10px", marginBottom: "20px" }}
          >
            Upload {reviewType} Review Excel
          </MDButton>
          <></>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ReviewTable;
