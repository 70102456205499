import React, { useState } from "react";

// @mui material  components
import { Grid, Chip, Typography } from "@mui/material";

// Apollo Client
import { useQuery } from "@apollo/client";
import { orderServiceClient } from "graphql/client";

// Graphql Query & Mutations
import { GET_ORDER_BY_ID } from "services/order-service";

// Material Dashboard 2 React Components
import Loader from "components/MDLoader";
import { AgGridReact } from "ag-grid-react";

const columnDefs = [
  // {
  //   field: "storeName",
  //   headerName: "Store Name",
  //   filter: "agMultiColumnFilter",
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter",
  //       },
  //       {
  //         filter: "agSetColumnFilter",
  //       },
  //     ],
  //   },
  // },
  // {
  //   field: "skuId",
  //   headerName: "SKU Id",
  //   filter: "agMultiColumnFilter",
  //   filterParams: {
  //     filters: [
  //       {
  //         filter: "agTextColumnFilter",
  //       },
  //       {
  //         filter: "agSetColumnFilter",
  //       },
  //     ],
  //   },
  // },
  // {
  //   field: "image",
  //   headerName: "Image",
  //   cellRenderer: (params) => {
  //     const value = params.value;
  //     return (
  //       <img
  //         src={value}
  //         alt="Image"
  //         style={{
  //           width: "50px",
  //           height: "50px",
  //           objectFit: "contain",
  //           border: "1px solid #ddd",
  //           borderRadius: "5px",
  //           boxShadow: "0px 0px 5px 0px #ddd",
  //         }}
  //         onError={(e) => {
  //           e.target.onerror = null;
  //           e.target.src = "https://via.placeholder.com/50";
  //         }}
  //       />
  //     );
  //   },
  // },
  {
    field: "posBillItemId",
    headerName: "Pos Bill Item Id",
    valueGetter: (params) => params?.data?.POSBillItemId,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "icode",
    headerName: "Item Code",
    valueGetter: (params) => params?.data?.ItemId,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "barcode",
    headerName: "Barcode",
    valueGetter: (params) => params?.data?.BarCode,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "itemName",
    headerName: "Item Name",
    valueGetter: (params) => params?.data?.ItemName,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Division",
    field: "division",
    valueGetter: (params) => params?.data?.Division,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Section",
    field: "section",
    valueGetter: (params) => params?.data?.Section,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Department",
    field: "department",
    valueGetter: (params) => params?.data?.Department,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Node",
    field: "categoryNode",
    valueGetter: (params) => params?.data?.Cat2,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Brand",
    field: "brand",
    valueGetter: (params) => params?.data?.Cat1,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Size",
    field: "size",
    valueGetter: (params) => params?.data?.Cat6,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "MRP",
    field: "mrp",
    valueGetter: (params) => params?.data?.MRP,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "RSP",
    field: "rsp",
    valueGetter: (params) => params?.data?.RSP,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Qty",
    field: "netQty",
    valueGetter: (params) => params?.data?.Qty,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Basic Amt",
    field: "basicAmt",
    valueGetter: (params) => params?.data?.BasicAmt,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Promo Amt",
    field: "promoAmt",
    valueGetter: (params) => params?.data?.PromoAmt,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Coupon Amt",
    field: "couponAmt",
    valueGetter: (params) => params?.data?.MCouponAmount,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    headerName: "Net Sale Amt",
    field: "netSalesAmt",
    valueGetter: (params) => params?.data?.NetAmt,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
];

function OrderDetailLandingPage({ selectedOrder }) {
  const [defaultColDef] = useState({
    sortable: true,
    filter: "agSetColumnFilter",
    resizable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    floatingFilter: true,
  });

  const { loading, error, data, refetch } = useQuery(GET_ORDER_BY_ID, {
    client: orderServiceClient,
    variables: { id: selectedOrder },
    fetchPolicy: "network-only", // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <Loader />;
  if (error) return <p>Error: {error?.message}</p>;

  const ordersDetailsData = data?.PaginatedOrderDetails?.items;

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "85vh", width: "100%", marginTop: "20px" }}
    >
      <AgGridReact
        rowData={data?.order?.posBill?.billingDetails?.POSBillItems || []}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        groupDisplayType={"groupRows"}
        rowGroupPanelShow={"always"} // Show row group panel
        enableCharts={true} // Enable chart visualizations
        enableRangeSelection={true} // Enable range selection for charts
        animateRows={true} // Smooth transitions
        sideBar={{ toolPanels: ["columns", "filters"] }}
      />
    </div>
  );
}

export default OrderDetailLandingPage;
