import { gql } from "@apollo/client";

export const GET_PROMOTION_LIST = gql`
query GetPromotionList($take: Int, $skip: Int, $search: String,$filter:JSONObject){
    promotions(listPromotionFilter: {take: $take, skip: $skip, search: $search, filter: $filter}) {
        total
        results {
          id
          name
          badge
          isDeleted
          description
          tnc
          channelType
          start
          status
          end
          createdAt
          updatedAt
          promotionBrand{
            id
            isDeleted
            brand{
              id
              username
              name
              brandMetaData {
                id
                key
                value
                isDeleted
              }
            }
          }
          promotionChannel{
            id
            isDeleted
            channel{
              id
              name
              channelType
              channelStore{
                store{
                  name
                  primaryText
                  secondaryText
                }
              }
            }
          }
          promotionCategory{
            id
            isDeleted
            category{
              id
              title
              isNodeItem
            }
          }
          promotionProduct{
            id
            isDeleted
            isExcluded
            product{
              id
              title
              metaTitle
            }
          }
        }
    }
  }
`
export const GET_LIST_OF_CATEGORIES = gql`
  query categoryProduct($listCategoryProductFilter: ListCategoryProductFilter!) {
    categoryProduct(listCategoryProductFilter: $listCategoryProductFilter) {
      total
      results {
        id
        title
        metaTitle
        isDeleted
        isMenuItem
        isNodeItem
      }
    }
  }
`;
export const GET_LIST_OF_CHANNEL = gql`
query GetChannelList($take: Int, $skip: Int, $search: String, $filter:JSONObject) {
    channels(listChannelFilter: {take: $take, skip: $skip, search: $search,,filter: $filter}) {
      total
      results {
        id
        name
        channelType
        displayOrder
        isDeleted
        createdAt
        updatedAt
        channelStore {
          store {
            id
            name
            code
          }
        }
      }
    }
  }
`;

export const GET_LIST_OF_BRAND_PRODUCT = gql`
query listProduct($listProductFilter: ListProductFilter!) {
  products(listProductFilter: $listProductFilter) {
    total
    results {
      id
      title
    }
  }
}
`;
export const GET_LIST_OF_BRAND_EXCLUDED_PRODUCT = gql`
query listProduct($listProductFilter: ListProductFilter!) {
  products(listProductFilter: $listProductFilter) {
    total
    results {
      id
      title
    }
  }
}
`;
export const CREATE_PROMOTION_MUTATION =gql`
mutation createPromotion( $createPromotionInput : CreatePromotionInput!)  {
    createPromotion (createPromotionInput :$createPromotionInput) {
        id
       name
       badge
       description
       tnc
       channelType
       start
       end
       isDeleted
       promotionBrand {
         id
         isDeleted
         brand {
           id
           username
           name
         }
       }
       promotionChannel {
         id
         isDeleted
         channel {
           id
           name
           channelType
           channelStore {
             store {
               name
               primaryText
               secondaryText
             }
           }
         }
       }
       promotionCategory {
         id
         isDeleted
         category {
           id
           title
           isNodeItem
         }
       }
       promotionProduct {
         id
         isDeleted
         isExcluded
         product {
           id
           title
           metaTitle
         }
       }
     }
   }
   
`
export const UPDATE_PROMOTION_MUTATION =gql`
mutation updatePromotion( $updatePromotionInput : UpdatePromotionInput!)  {
    updatePromotion (updatePromotionInput :$updatePromotionInput) {
       id
       name
       badge
       description
       tnc
       channelType
       start
       end
       isDeleted
       promotionBrand {
         id
         isDeleted
         brand {
           id
           username
           name
         }
       }
       promotionChannel {
         id
         isDeleted
         channel {
           id
           name
           channelType
           channelStore {
             store {
               name
               primaryText
               secondaryText
             }
           }
         }
       }
       promotionCategory {
         id
         isDeleted
         category {
           id
           title
           isNodeItem
         }
       }
       promotionProduct {
         id
         isDeleted
         isExcluded
         product {
           id
           title
           metaTitle
         }
       }
     }
   }
   
`