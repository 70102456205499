import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon,
  Box,
  IconButton,
  Switch,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import galleryIcon from "../../../../assets/images/gallery-icon.svg";
import addIcon from "../../../../assets/images/add-icon.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import downloadIcon from "../../../../assets/images/downloadIcon.svg";
import { useLazyQuery, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { AvailableForms } from "../../productTable/tableData";
import axios from "axios";
import { useNotification } from "context";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import useProductContext from "../../context/useProductContext";
import { productServiceClient } from "graphql/client";
import { ALL_PRODUCT_VARIANT_LIST } from "services/product-service";
import useDebounce from "layouts/product-category/useDebounce";
import MDButton from "components/MDButton";

const validationSchema = Yup.object().shape({
  fileUpload: Yup.mixed()
    .required("File is required")
    .test("fileFormat", "Only Excel and CSV files are allowed", (data) => {
      const value = data.file;
      return (
        value &&
        (value.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          value.type === "application/vnd.ms-excel" ||
          value.type === "text/csv")
      );
    }),
  brandOption: Yup.object().required("brand is required"),
});

const initialValues = {
  fileUpload: null,
  brandOption: null
};

function CustomErrorTextField({ children, sx, ...props }) {
  return (
    <Typography
      sx={{
        color: "#F44335",
        marginTop: "3px",
        marginRight: "14px",
        marginBottom: "0",
        marginLeft: "14px",
        fontSize: "0.75rem",
        fontWeight: "300",
        lineHeight: "1.25",
        letterSpacing: "0.03333em",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default function AddBulkPriceUpdate({ setForm, onPreview }) {
  const formRef = useRef(null);

  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  const isAdmin = user?.role === "admin";

  const { setNotification } = useNotification();
  const { productContext } = useProductContext();

  // const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const validBrands = {}
  productContext?.brandStores?.forEach(brandStore => {
    if(!validBrands.hasOwnProperty(brandStore?.brand?.id && brandStore?.brand?.brandStatus==="Live")){
      validBrands[brandStore.brand.id] = brandStore.brand
    }
  })
  const brandOptions = Object.values(validBrands);

  const handleProductPriceDump = async () => {
    if (!selectedBrand) {
      return setNotification({
        color: "error",
        isVisible: true,
        message: "Please select a Brand",
      });
    }
    try {
      const exportUrl =
        process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/product-service`
          : `/product-service`;
      const response = await axios({
        url: `${exportUrl}/variants/export-prices`,
        method: "post",
        // url: "http://localhost:3002/master-attribute/export",
        responseType: "blob",
        data: {
          brand: selectedBrand,
          brandStores: productContext.brandStores
            .filter((brandStore) => brandStore.brand.id == selectedBrand.id)
            .filter(
              (brandStore, index, self) =>
                index ===
                self.findIndex((bs) => bs.store.id === brandStore.store.id)
            ),
          filter: { limit: 1000, skip: 0 },
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-customer": user,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const now = new Date();
      link.setAttribute(
        "download",
        `${
          selectedBrand.name
        }-prices-${now.getDate()}-${now.getMonth()+1}-${now.getFullYear()}-.xlsx`
      ); // 设置下载文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch(error){
      console.error("error:", error);
    }
  };
  const formSubmitHandler = async (values, { setSubmitting }) => {
    onPreview(values);
  };

  return (
    <>
      <Grid
        container
        sx={{
          pb: 2,
          pt: 2,
          pl: "10px",
          pr: "10px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={formSubmitHandler}
            innerRef={formRef}
          >
            {({
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
              handleSubmit,
            }) => {
              useEffect(() => {
                if (isAdmin) return;

                // if (brandLoading || brandError || !brandData) return;

                //auto select brand field if the user role is brand_admin
                // const userBrand = brandData?.brandlist?.results?.[0];
                const userBrand = Object.values(validBrands)?.[0];
                setSelectedBrand(userBrand);
                setFieldValue("brandOption", userBrand);
              }, []);

              return (
                <Form
                  id={AvailableForms.nodeWiseBulkProduct}
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="tags-standard"
                        name="brand"
                        disabled={!isAdmin}
                        value={selectedBrand?.name || null}
                        options={(brandOptions || []).map((brand) => {
                          return {
                            //   id: option.id,
                            label: brand.name,
                            value: brand,
                          };
                        })}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value
                        }
                        onChange={(event, newValue) => {
                          setFieldValue("brandOption", newValue?.value);
                          setSelectedBrand(newValue?.value);
                        }}
                        // onInputChange={(event, newValue) => {
                        //   if (
                        //     (event && event?.type === "reset") ||
                        //     newValue === ""
                        //   ) {
                        //     setSearchBrandValues(""); // Clear search when input is cleared
                        //   } else if (event && event.type === "change") {
                        //     setSearchBrandValues(newValue);
                        //   }
                        // }}
                        // onBlur={() => {
                        //   setSearchBrandValues("");
                        // }}
                        loading={productContext}
                        loadingText="Loading..."
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Brand"
                            helperText={touched.brandOption ? errors.brandOption : ""}
                            error={touched.brandOption && Boolean(errors.brandOption)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          startIcon={
                            <img src={downloadIcon} alignItems={"center"} />
                          }
                          component={"label"}
                          disabled={isSubmitting}
                          onClick={handleProductPriceDump}
                        >
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#000000",
                            }}
                          >
                            Product Price Dump
                          </Typography>
                        </Button>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Button
                          startIcon={
                            <img src={downloadIcon} alignItems={"center"} />
                          }
                          component={"label"}
                          disabled={isSubmitting}
                          onClick={handleTemplteDownload}
                        >
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#000000",
                            }}
                          >
                            Product Price Template
                          </Typography>
                        </Button>
                      </Grid> */}
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="fileUpload">
                        {({ field, form }) => (
                          <Grid
                            container
                            justifyContent={"space-between"}
                            padding={"10px"}
                            sx={{
                              backgroundColor: "#F0F2F5",
                              borderRadius: "10px",
                            }}
                          >
                            <Grid item display={"flex"} alignItems={"center"}>
                              <Icon
                                component={() => <img src={galleryIcon} />}
                                variant="img"
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  marginLeft: "10px",
                                }}
                              >
                                Upload Excel file
                                <Typography
                                  display={"inline"}
                                  variant="h6"
                                  sx={{
                                    fontWeight: "500",
                                    opacity: "0.5",
                                  }}
                                >
                                  (Maximum 1 excel can be uploaded)
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item display={"flex"}>
                              <Button
                                startIcon={
                                  <img src={addIcon} alignItems={"center"} />
                                }
                                component={"label"}
                                htmlFor={"product-excel"}
                                disabled={isSubmitting}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "#E93165",
                                  }}
                                >
                                  CHOOSE FILE
                                </Typography>
                              </Button>
                            </Grid>

                            <input
                              type="file"
                              hidden
                              style={{
                                display: "none",
                              }}
                              accept=".xlsx, .xlx, .csv"
                              multiple
                              id="product-excel"
                              onChange={(event) => {
                                const allowedFileExt = ["xlsx", "xlx", "csv"];
                                const newFiles = Array.from(event.target.files)
                                  .map((file) => {
                                    const ext = file.name.split(".").pop();
                                    if (!allowedFileExt.includes(ext)) {
                                      setNotification({
                                        color: "error",
                                        isVisible: true,
                                        message:
                                          "Invalid File Format. Please use .xlsx or .csv files only.",
                                      });
                                      //   setFieldError(
                                      //     field.name,
                                      //     "Only CSV or Excel files are allowed"
                                      //   );
                                      return null;
                                    }

                                    const mbToBytes = 1024 * 1024;

                                    if (file.size > 5 * mbToBytes) {
                                      setNotification({
                                        color: "error",
                                        isVisible: true,
                                        message:
                                          "File size should not exceed 5MB",
                                      });
                                      return null;
                                    }

                                    return {
                                      file,
                                      filename: file.name,
                                    };
                                  })
                                  .filter((file) => file !== null);

                                setFieldValue(field.name, newFiles[0]);

                                event.target.value = "";
                              }}
                            />
                            <Box
                              sx={{
                                mt: 2,
                                display: "flex",
                                gap: 2,
                                width: "100%",
                              }}
                            >
                              {values.fileUpload && (
                                <Box sx={{display:'flex', alignItems:'center'}}>
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      
                                    }}
                                  >
                                    {values.fileUpload.filename}
                                  </Typography>
                                    <IconButton
                                      sx={{
                                        // position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                      }}
                                      onClick={() => {
                                        setFieldValue(field.name, null);
                                      }}
                                    >
                                      <img src={deleteIcon} />
                                    </IconButton>
                                </Box>
                              )}
                            </Box>
                            {errors.fileUpload && (
                              <CustomErrorTextField
                                color="error"
                                sx={{ mt: 1 }}
                              >
                                {errors.fileUpload}
                              </CustomErrorTextField>
                            )}
                          </Grid>
                          
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  {/* <p>{JSON.stringify(errors)}</p> */}
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        gap={2}
        sx={{ pr: 2, pb: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <MDButton
          variant="outlined"
          color={"black"}
          circular={true}
          onClick={() => {
            setForm(false);
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="contained"
          // style={{
          //   background: "#E93165",
          //   borderRadius: "24px",
          //   color: "#fff",
          // }}
          color={"black"}
          circular={true}
          onClick={() => {
            formRef.current.submitForm();
          }}
          // disabled={!selectedBrand || !formRef?.current?.values?.fileUpload}
        >
          Preview
        </MDButton>
      </Grid>
    </>
  );
}
