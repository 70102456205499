import { useState } from "react";

export default function useTableData(fetchFunc) {
  const [data, setData] = useState({ total: 0, bulkProduct: [], variants: [] });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const reFetchData = async (...args) => {
    setLoading(true);
    setError(false);

    try {
      const data = await fetchFunc(...args);

      setData(data);
      setError(false);
    } catch (error) {
      if (
        error.name === "ApolloError" &&
        error.networkError === "stalled data fetch"
      ) {
        return;
      }
      setError(error?.message);
    }

    setLoading(false);
  };

  return { loading, data, error, reFetchData };
}
