import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
// import Downshift from "downshift";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Autocomplete } from "@mui/material";
import input from "assets/theme/components/form/input";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

export default function TagsInput({
  inputValue,
  setInputValue,
  ...props
}) {
  const classes = useStyles();
  const { placeholder, tags, label, ...other } = props;
  const [value, setValue] = useState(inputValue);
  const [autoInputValue, setAutoInputValue] = useState('');
  const categories = [
    "Fashion/Child Top Wear/T-shirt",
    "Fashion/Women Top Wear/Top",
    "Fashion/Men Top Wear/T-shirt",
  ];

  useEffect(()=>{
    setValue(inputValue)
  },[inputValue])

  return (
    <React.Fragment>
    <div>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={value}
                onChange={(event, newValue) => {
                  setInputValue(newValue);
                  setValue(newValue);
                }}
                inputValue={autoInputValue}
                onInputChange={(event, newInputValue) => {
                  setAutoInputValue(newInputValue);
                }}
                renderTags={(val, getTagProps) =>
                  val.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      className={classes.chip}
                      deleteIcon={<RemoveCircleOutlineIcon />}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={label||"Tags"}
                    placeholder={placeholder || 'Enter tags, press enter to add tag'}
                  />
                )}
              />
            </div>
    </React.Fragment>
  );
}
TagsInput.defaultProps = {
  tags: [],
};
TagsInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};
