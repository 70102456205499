import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_NEWS_MUTATION } from "services/news-service";
import { GET_NEWS } from "services/news-service";
import { dashboardServiceClient } from "graphql/client";
import { UPDATE_NEWS_MUTATION } from "services/news-service";
import FileUploader from "components/MDFileUpload";
import { useNotification } from "context";
import MDButton from "components/MDButton";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("News Name is required")
    .max(50, "Max length for News Name is 50"),
  title: Yup.string()
    .required("Title is required")
    .max(100, "Max length for Title is 50"),
  displayOrder: Yup.number()
    .required("Order is required")
    .positive("A number must be positive.")
    .integer("Order must be an integer")
    .typeError("Order must be a number"),
  isDeleted: Yup.bool(),
  description: Yup.string()
    .required("Description is required")
    .max(500, "Max length for Description is 500"),
    coverpicpath: Yup.array()
    .required("At least one image is required")
    .min(1, "At least one image is required"),
    thumbnailpath: Yup.string()
    .required("Thumbnail is required"),
});

const useNewsMutations = (newsData, handleClose,setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_NEWS, variables: { listNewsFilter:{ take: 20, skip: 0, search: "" }, } }],
  };

  const [createNewsMutation] = useMutation(
    CREATE_NEWS_MUTATION,
    mutationOptions
  );
  const [updateNewsMutation] = useMutation(
    UPDATE_NEWS_MUTATION,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const payload = {
      name: values?.name,
      title: values?.title,
      displayOrder: +values?.displayOrder,
      isDeleted: !values?.isDeleted,
      description: values?.description,
      thumbnailpath: values?.thumbnailpath,
      coverpicpath: values?.coverpicpath,
    };

    const mutation =
      newsData && newsData.id ? updateNewsMutation : createNewsMutation;
    const mutationInput =
      newsData && newsData.id
        ? { updateNewsInput: { id: newsData.id, ...payload } }
        : { createNewsInput: payload };

    mutation({ variables: mutationInput })
      .then(() => {
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
          newsData && newsData?.id 
              ? "News  updated successfully"
              : "News  created successfully",
        });
      })
      .catch((error) => {
        console.error("Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const NewsFormComponent = ({ handleClose, newsData }) => {
  const { setNotification } = useNotification();

  const handleSubmit = useNewsMutations(newsData, handleClose,setNotification);

  const coverPicImages =
    (newsData &&
      newsData.coverpic &&
      newsData.coverpic.length &&
      newsData.coverpic.map((item) => item.path)) ||
    [];

  return (
    <Formik
      initialValues={{
        name: newsData ? newsData.name : "",
        title: newsData ? newsData.title : "",
        displayOrder: newsData ? newsData.displayOrder : "",
        isDeleted: newsData ? !newsData.isDeleted : true,
        description: newsData ? newsData.description : "",
        thumbnailpath:
          newsData && newsData.thumbnail ? newsData.thumbnail.path : "",
        coverpicpath: newsData && newsData.coverpic ? coverPicImages : "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="name"
                    as={TextField}
                    label="News Name"
                    fullWidth
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="title"
                    as={TextField}
                    label="Title"
                    fullWidth
                    error={touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                <Grid item xs={15}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Thumbnail
                  </Typography>
                  <FileUploader
                    fieldName={"Thumbnail"}
                    isMultiple={false}
                    preLoadedFile={
                      !!values.thumbnailpath ? [values.thumbnailpath] : null
                    }
                    signedUrlCallback={(newUrl, index) => {
                      const updatedImagePathArray = [...values.thumbnailpath];
                      if (newUrl) {
                        // Update the URL at the given index
                        updatedImagePathArray[index] = newUrl;
                      } else {
                        // Delete the URL at the given index
                        updatedImagePathArray.splice(index, 1);
                      }
                      setFieldValue("thumbnailpath", newUrl);
                    }}
                    isFieldValid={touched?.thumbnailpath && errors?.thumbnailpath}
                  />
                  {touched?.thumbnailpath && errors?.thumbnailpath && (
                        <Typography fontSize={12} color="error">
                          {errors.thumbnailpath}
                        </Typography>
                      )}
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Coverpic
                  </Typography>
                  <FileUploader
                    fieldName={"Cover Pic"}
                    isMultiple={true}
                    preLoadedFile={
                      !!values.coverpicpath ? values.coverpicpath : null
                    }
                    signedUrlCallback={(newUrl, index) => {
                      const updatedImagePathArray = [...values.coverpicpath];
                      if (newUrl) {
                        // Update the URL at the given index
                        updatedImagePathArray[index] = newUrl;
                      } else {
                        // Delete the URL at the given index
                        updatedImagePathArray.splice(index, 1);
                      }
                      setFieldValue("coverpicpath", updatedImagePathArray);
                    }}
                    isFieldValid={touched?.coverpicpath && errors?.coverpicpath }
                  />
                   {touched?.coverpicpath && errors?.coverpicpath && (
                        <Typography fontSize={12} color="error">
                          {errors.coverpicpath}
                        </Typography>
                      )}
              </Grid>
                <Grid item xs={12} mb={2}>
                  <Field
                    name="displayOrder"
                    as={TextField}
                    label="Order"
                    type="number"
                    fullWidth
                    error={touched.displayOrder && !!errors.displayOrder}
                    helperText={touched.displayOrder && errors.displayOrder}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Field
                  as={TextField}
                  value="Is it active?"
                  name="isActive"
                  fullWidth
                  // disabled={true}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isDeleted}
                            onChange={(e) =>
                              setFieldValue("isDeleted", e.target.checked)
                            }
                          />
                        }
                        labelPlacement="start"
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  id="feedback"
                  label="Description"
                  name="description"
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  error={touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <MDButton color="dark" onClick={handleClose} variant="outlined" circular={true}>
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default NewsFormComponent;
