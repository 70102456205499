import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import "react-quill/dist/quill.snow.css"; // import styles
import MDButton from "components/MDButton";
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { CREATE_SECTION } from "services/ecommerce-section-service";
import { feedServiceClient } from "graphql/client";
import { CREATE_SECTION_METADATA } from "services/ecommerce-section-service";
import { useNotification } from "context";
import { GET_SECTION_LISTING } from "services/ecommerce-section-service";
import { REMOVE_SECTION_METADATA } from "services/ecommerce-section-service";
import { UPDATE_SECTION } from "services/ecommerce-section-service";
import { UPDATE_SECTION_METADATA } from "services/ecommerce-section-service";

const metadataSchema = Yup.object().shape({
  key: Yup.string().required("Metadata key is required"),
  value: Yup.lazy((value) => {
    try {
      JSON.parse(value);
      return Yup.string().required(
        "Metadata value must be a valid JSON string"
      );
    } catch (error) {
      return Yup.string().test("is-json", "Invalid JSON format", () => false);
    }
  }),
});
export const validationSchema = Yup.object({
  name: Yup.string().required("Section name is required"),
  internalName: Yup.string().required("Section internal name is required"),
  displayOrder: Yup.number().required("Display Order is required"),
  metadata: Yup.array().of(metadataSchema),
});

const user =
  localStorage.getItem("userDetails") &&
  localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {};

const SectionForm = ({
  handleClose,
  callback,
  brandId = undefined,
  page,
  selectedSection,
}) => {
  const { setNotification } = useNotification();
  const [createSection] = useMutation(CREATE_SECTION, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_LISTING,
        variables: {
          input: { page },
        },
      },
    ],
  });
  const [updateSection] = useMutation(UPDATE_SECTION, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_LISTING,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });
  const [createSectionMetadata] = useMutation(CREATE_SECTION_METADATA, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_LISTING,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });
  const [updateSectionMetadata] = useMutation(UPDATE_SECTION_METADATA, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_LISTING,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });
  const [removeSectionMetadata] = useMutation(REMOVE_SECTION_METADATA, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_LISTING,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });

  const handleSubmit = (values) => {
    if (selectedSection) {
      updateSection({
        variables: {
          input: {
            id: selectedSection.id,
            name: values.name,
            internalName: values.internalName,
            displayOrder: values.displayOrder,
            brandId,
            page,
          },
        },
        onCompleted: (section) => {
          values.metadata.forEach((item) => {
            const payload = {
              id: item?.id,
              sectionId: section.updateSection.id,
              key: item.key,
              value: {
                ...JSON.parse(item.value),
              },
            };
            payload.id
              ? updateSectionMetadata({
                  variables: {
                    input: payload,
                  },
                })
              : createSectionMetadata({
                  variables: {
                    input: { ...payload },
                  },
                });
          });
          setNotification({
            color: "success",
            isVisible: true,
            message: "Updated section successfully",
          });
          if (callback) callback();
        },
      });
    } else {
      createSection({
        variables: {
          createSectionInput: {
            name: values.name,
            internalName: values.internalName,
            displayOrder: values.displayOrder,
            brandId,
            page,
            status: true,
          },
        },
        onCompleted: (section) => {
          values.metadata.forEach((item) => {
            const payload = {
              sectionId: section.createSection.id,
              key: item.key,
              value: {
                ...JSON.parse(item.value),
              },
            };
            createSectionMetadata({
              variables: {
                input: payload,
              },
            });
          });
          setNotification({
            color: "success",
            isVisible: true,
            message: "Created section successfully",
          });
          if (callback) callback();
        },
      });
    }
  };

  const handleRemove = (metadata, index, remove) => {
    remove(index);
    if (metadata.id) {
      removeSectionMetadata({
        variables: {
          id: metadata.id,
        },
      });
    }
  };
  return (
    <Formik
      initialValues={{
        name: selectedSection?.name || "",
        internalName: selectedSection?.internalName || "",
        displayOrder: selectedSection?.displayOrder || 1,
        metadata: selectedSection?.sectionMetaData?.filter((meta) => !meta.isDeleted).map(meta => ({...meta, value: JSON.stringify(meta.value)})) || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        validateField,
        isSubmitting,
        handleChange,
        handleBlur,
      }) => {
        return (
          <Form>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="name"
                  label="Section Name"
                  fullWidth
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="internalName"
                  label="Section Internal Name"
                  fullWidth
                  error={touched.internalName && !!errors.internalName}
                  helperText={touched.internalName && errors.internalName}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  type={"number"}
                  name="displayOrder"
                  label="Display Order"
                  fullWidth
                  error={touched.displayOrder && !!errors.displayOrder}
                  helperText={touched.displayOrder && errors.displayOrder}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray name="metadata">
                  {({ push, remove }) => (
                    <div>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6>Metadata:</h6>
                        <IconButton
                          size="small"
                          type="button"
                          sx={{
                            px: 2,
                            backgroundColor: "#cccccc",
                            borderRadius: 5,
                          }}
                          aria-label="add"
                          onClick={() => push({ key: "", value: "" })}
                        >
                          <Icon component={AddIcon} fontSize="small" />
                        </IconButton>
                      </Box>
                      {values.metadata.map((meta, index) => (
                        <>
                          <Grid
                            key={index}
                            container
                            xs={12}
                            spacing={2}
                            py={1}
                            px={2}
                          >
                            <Grid item xs={6}>
                              <Field
                                as={TextField}
                                name={`metadata.${index}.key`}
                                label="Key"
                                fullWidth
                                error={
                                  touched.displayOrder && !!errors.displayOrder
                                }
                                helperText={
                                  touched.displayOrder && errors.displayOrder
                                }
                              />
                              {errors?.metadata?.[index] &&
                                errors?.metadata?.[index]?.key && (
                                  <Typography fontSize={12} color="error">
                                    {errors?.metadata?.[index]?.key}
                                  </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Field
                                  as={TextField}
                                  name={`metadata.${index}.value`}
                                  value={meta.value}
                                  label="Value"
                                  fullWidth
                                  error={
                                    touched.displayOrder &&
                                    !!errors.displayOrder
                                  }
                                  helperText={
                                    touched.displayOrder && errors.displayOrder
                                  }
                                  multiline
                                  rows={3}
                                />
                                <IconButton
                                  size="small"
                                  type="button"
                                  sx={{ px: 2, borderRadius: 5 }}
                                  aria-label="remove"
                                  onClick={() =>
                                    handleRemove(meta, index, remove)
                                  }
                                >
                                  <Icon
                                    component={DeleteIcon}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Box>
                              {errors?.metadata?.[index] &&
                                errors?.metadata?.[index]?.value && (
                                  <Typography fontSize={12} color="error">
                                    {errors?.metadata?.[index]?.value}
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>
                        </>
                        // <div key={index}>
                        //     <label htmlFor={`metadata.${index}.key`}>Key:</label>
                        //     <Field id={`metadata.${index}.key`} name={`metadata.${index}.key`} />

                        //     <label htmlFor={`metadata.${index}.value`}>Value:</label>
                        //     <Field as="textarea" id={`metadata.${index}.value`} name={`metadata.${index}.value`} />

                        //     <button type="button" onClick={() => remove(index)}>
                        //     Remove
                        //     </button>
                        // </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <Button
                  color="dark"
                  style={{ backgroundColor: "#FFFFFF" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SectionForm;
