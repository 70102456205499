import { createContext } from "react";

export const ProductContext = createContext({
  productContext: {
    addedSingleProduct: false,
    addedBulkProduct: false,
    addedSuperAdminBulkProduct: false,
    updatedBulkPriceUpdate: false,
    brandStores:[],
  },
  setProductContext: () => {},
});

export const ProductContextProvider = ProductContext.Provider;
