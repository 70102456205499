// refreshToken.js
async function getNewAccessToken(refreshToken) {
    const url = '/sso-service/auth/refresh';
    const body = {
        refreshToken: refreshToken
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error(`Refresh token request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data; // Adjust based on your actual server response
}

export default getNewAccessToken;