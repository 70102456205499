import { useQuery } from '@apollo/client';
import { UploadSharp } from '@mui/icons-material';
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, styled } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import MDButton from 'components/MDButton';
import { vmServiceClient } from 'graphql/client';
import React, { useContext, useState } from 'react'
import { LIST_ALL_IMAGE_MASTERS } from 'services/advertisment-service';
import { LIST_VIDEO_PLAYLIST } from 'services/advertisment-service';
import { DropDownSelectionContext } from '..';

export default function ImageSelect({ onSubmit, images=[], brandId=null }) {
  const [componentKey, setComponentKey] = useState(Date.now());
  let { selectedBrandId, user, Brand} = useContext(DropDownSelectionContext);
  if(brandId != null){
    selectedBrandId = brandId;
  }
    const { loading, error, data:imageListData, refetch } = useQuery(LIST_ALL_IMAGE_MASTERS, {
        client: vmServiceClient,
        variables: {
          "page": 1,
          "limit": 100,
          "sortBy": "updatedAt",
          "order": "DESC",
          "relations": [],
          "searchCols": ["brandId"],
          "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId || '_noBrand'] : [selectedBrandId || '_noBrand'],
          "filter": {status: 'approved'}
        //   "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId] : []
        },
      });

    const handleImageChange = (event) => {
      // setSelectedVideo(event.target.value);
      onSubmit(event.target.value);
      event.stopPropagation();
      setComponentKey(Date.now());
    };
  
    return (
      <Box mr={1} width={250} key={componentKey}>
        <FormControl fullWidth >
          <InputLabel sx={{ pb: 1, color: "black.main",  top:-2}}>Select Uploaded Image</InputLabel>
          <Select
            as={Select}
            labelId="demo-simple-select-label"
            label="Select Uploaded Image"
            IconComponent={() => (
              <GridExpandMoreIcon fontSize="medium" />
            )}
            
            sx={{ height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main", border:1, borderColor: "black.main" }}
            onChange={handleImageChange}
          >
            {imageListData?.findAllImageMasters?.results.filter(image=>!images.includes(image.uuid)).map((image) => (
              <MenuItem key={image.uuid} value={image}>
                {image.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
}
