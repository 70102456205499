
// Usage:
import { gql } from "@apollo/client";


export const GET_NEWS = gql`
    query listNews($listNewsFilter: ListNewsFilter!){
        news(listNewsFilter:$listNewsFilter){
          total
          results{
            id
            name
            title
            thumbnail
            coverpic
            description
            displayOrder
            isDeleted
            createdAt
            updatedAt
          }
        }
    }
`;


export const CREATE_NEWS_MUTATION = gql`
  mutation createNews($createNewsInput: CreateNewsInput!) {
    createNews(createNewsInput: $createNewsInput) {
      id
      name
      title
      thumbnail
      coverpic
      description
      displayOrder
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_NEWS_MUTATION = gql`
  mutation updateNews($updateNewsInput: UpdateNewsInput!) {
    updateNews(updateNewsInput: $updateNewsInput) {
      id
      name
      title
      thumbnail
      coverpic
      description
      displayOrder
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
