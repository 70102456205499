import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import { useMutation } from "@apollo/client";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { useQuery } from "@apollo/client";
import { GET_STORES_LIST } from "services/store-service";
import { CREATE_CHANNEL, UPDATE_CHANNEL,GET_CHANNELS } from 'services/channel-service';
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { useNotification } from "context";


import * as Yup from 'yup';
import MDButton from 'components/MDButton';

// Yup validation schema
const validationSchema = Yup.object({
    channel: Yup.string().required('Channel is required'),
    channelType: Yup.string().when('channel', (channel, schema) => {
      return channel ? schema.required('Channel Type is required') : schema;
    }),
  });
  
  const useChannelMutation =(channelData,handleClose,setNotification,storeList)=>{
    const mutationOptions ={
      client: dashboardServiceClient,
      refetchQueries: [{ query: GET_CHANNELS, variables: { take: 20, skip: 0, search: "" },  }],
    }

    const [createChannelMutation] = useMutation(
      CREATE_CHANNEL,
      mutationOptions
    )
    const [updateChannelMutation] = useMutation(
      UPDATE_CHANNEL,
      mutationOptions
    )

    const handleSubmit = (values) => {
     
    let payload;
    if (values.channel === 'online') {
      payload = {
        // id: channelData?.id || null,
        name: values.channelType,
        channelType: 'online',
        isDeleted:false
      };
    } else { // offline
      const storeId = storeList?.stores?.results?.find(store => store?.name === values?.channelType)?.id;
      payload = {
        // id: channelData?.id || null ,
        storeId: storeId,
        channelType: 'offline',
        isDeleted:false
      };
    }
  
      const mutation =
        channelData && channelData.id ? updateChannelMutation : createChannelMutation;
      const mutationInput =
        channelData && channelData.id
          ? { updateChannelInput: { id: channelData.id, ...payload } }
          : { createChannelInput: payload };
  
      mutation({ variables: mutationInput })
        .then((res) => {
          if(res?.data){
            handleClose();
            setNotification({
              color: "success",
              isVisible: true,
              message:
              channelData && channelData?.id 
              ? "Channel  updated successfully"
              : "Channel  created successfully",
            });
          }
          else{
            setNotification({
              color: "error",
              isVisible: true,
              message: res?.errors?.[0]?.message || "Something went wrong",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error?.message);
          setNotification({
            color: "error",
            isVisible: true,
            message: error?.message || "Something went wrong",
          });
        });
    };
  
    return handleSubmit;
  }

const ChannelForm = ({channelData,handleClose}) => {
  const { setNotification } = useNotification();

  const {
    loading,
    error,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });
  const handleSubmit = useChannelMutation(channelData, handleClose,setNotification,storeList);

  if (loading) return <><Loader /></>;
  if (error) return <p>Error: {error?.message}</p>;
  const initialChannelName = channelData?.channelType === "offline"
    ?channelData?.channelStore[0]?.store?.name
    : channelData?.name
    return (
      <Formik
        initialValues={{
          channel:
            channelData?.channelType === "offline"
              ? "offline"
              : "online" || "",
          channelType:initialChannelName || ""
            
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
        handleSubmit(values);
      }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          validateField,
          errors,
          touched,
          isValid,
          isSubmitting,
          handleChange,
        }) => (
          <Form>
            <Grid container spacing={3} alignItems="center" p={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Channel</InputLabel>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="channel"
                    label="Channel"
                    sx={{ height: 40 }}
                    value={values.channel}
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    onChange={(e) => {
                      setFieldValue("channel", e.target.value);
                      setFieldValue("channelType",null);
                      setFieldTouched(`channel`, true, false);
                      validateField(`channel`, e.target.value);
                    }}
                    error={touched.channel && Boolean(errors.channel)}
                    helperText={touched.channel && errors.channel}
                  >
                    <MenuItem value="online">Online</MenuItem>
                    <MenuItem value="offline">Offline</MenuItem>
                  </Field>
                  {touched.channel && errors.channel && (
                    <Typography fontSize={12} color="error">
                      {errors.channel}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth disabled={!values.channel}>
                  <InputLabel>Channel Type</InputLabel>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="channelType"
                    label="Channel Type"
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    sx={{ height: 40 }}
                    value={values.channelType}
                    onChange={(e) => {
                      setFieldValue("channelType", e.target.value);
                      setFieldTouched(`channelType`, true, false);
                      validateField(`channelType`, e.target.value);
                    }}
                    helperText={touched.channelType && errors.channelType}
                    error={touched.channelType && Boolean(errors.channelType)}
                  >
                    {values.channel === "online" && [
                      <MenuItem key="Android App" value="Android App">
                        Android App
                      </MenuItem>,
                      <MenuItem key="iOS App" value="iOS App">
                        iOS App
                      </MenuItem>,
                      <MenuItem key="Web" value="Web">
                        Web
                      </MenuItem>,
                    ]}
                    {values.channel === "offline" &&
                      storeList?.stores?.results
                        ?.filter((item) => item.status === "Active")
                        .map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                  </Field>
                  {touched.channelType && errors.channelType && (
                    <Typography fontSize={12} color="error">
                      {errors.channelType}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid container xs={12} sx={{ mt: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                    type="submit"
                    variant="contained"
                    circular={true}
                    color="dark"
                    // disabled={isSubmitting}
                  >
                    Save
                  </MDButton>
                  <MDButton
                    color='dark'
                    variant="outlined"
                    circular={true}
                    style={{ backgroundColor: "#FFFFFF" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
};

export default ChannelForm;
