import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';

const AdsTableWithDraggable = ({ id, columns, dragCallback=null, footer=null, rows,parent, showHeader = true }) => {
  const [tableRows, setTableRows] = useState(rows);

  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setTableRows((prev) => {
      const temp = [...prev];
      const [movedRow] = temp.splice(result.source.index, 1);
      temp.splice(result.destination.index, 0, movedRow);
      if(dragCallback) dragCallback(temp);
      return temp;
    });
  };

  return (
    <Table>
      {/* {showHeader && <TableHead sx={{ display: 'contents' }}>
        
      </TableHead>} */}
      
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={"droppable-"+id}>
          {(droppableProvided) => (
            <TableBody
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {showHeader &&<TableRow sx={{ fontWeight:600 }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align={column.align || 'left'}
                    sx={{ width: column.width, }}
                  >
                    {column.headerName}
                  </TableCell>))}
              </TableRow>}
              {tableRows?.map((item, index) => (
                <Draggable
                  key={'draggable-'+index}
                  draggableId={'draggable-'+index}
                  index={index}
                >
                  {(
                    draggableProvided,
                    snapshot
                  ) => {
                    return (
                      <TableRow
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={{
                          ...draggableProvided.draggableProps.style,
                          background: snapshot.isDragging
                            ? "rgba(245,245,245, 0.75)"
                            : "none",
                            display:'flex'
                        }}
                      >
                        {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                        {columns.map((column) => {
                          if (column.field == 'dragHandle') {
                            return <TableCell align={column.align || 'left'} sx={{ width: column.width, maxWidth:column.width, overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis' }}>
                              <div {...draggableProvided.dragHandleProps}>
                                <DragIndicator />
                              </div>
                            </TableCell>
                          }
                          else if(column.renderCell!=null){
                            return <TableCell align={column.align || 'left'} sx={{width: column.width, maxWidth:column.width,  overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{column.renderCell({value:item[column.field],index,row:item, parent})}</TableCell>
                          }
                          return <TableCell align={column.align || 'left'} sx={{ width: column.width, maxWidth:column.width,  overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis' }}>{item[column.field]}</TableCell>
                        })}
                      </TableRow>
                    );
                  }}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
      {footer!=null && footer}
    </Table>
  );
};

export default AdsTableWithDraggable;
