import React, { useState } from "react";

// @mui material  components
import { Grid, IconButton } from "@mui/material";

// Images & Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Material Dashboard 2 React Components
import MDTypography from "components/MDTypography";

// Custom Components
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import OrderLandingPage from "./orderLandingPage";
import OrderDetailLandingPage from "./orderDetailsListing";

// Custom Context
import { AuthContext } from "context";

function Order() {
  const [isOrderDetail, setIsOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  let clickTimeout = null; // A reference for the timeout
  const DOUBLE_CLICK_THRESHOLD = 500; // Time in milliseconds (500ms = 0.5 seconds)

  // Function to handle the click
  const handleRowClick = (rowNode) => {
    // If the row was clicked twice quickly (double-click)
    if (clickTimeout) {
      clearTimeout(clickTimeout); // Clear the previous timeout

      // Trigger double-click behavior
      setSelectedOrder(rowNode.data?.id);
      setIsOrderDetail(true);
      clickTimeout = null; // Reset the timeout
    } else {
      // Single click behavior (set timeout for next click)
      // Delay the action and wait for the next click
      clickTimeout = setTimeout(() => {
        // Trigger single-click behavior (e.g., open the form)
        setSelectedOrder(rowNode.data?.id);
        clickTimeout = null; // Reset the timeout
      }, DOUBLE_CLICK_THRESHOLD);
    }
  };

  return (
    <DashboardLayout>
      <Grid item xs={12} sm={6} md={3}>
        {!isOrderDetail ? (
          <>
            <OrderLandingPage onRowClick={handleRowClick} />
          </>
        ) : (
          <div>
            <Grid mt={2}>
              <IconButton
                aria-label="Go Back"
                onClick={() => {
                  setIsOrderDetail(false);
                }}
              >
                <ArrowBackIcon />
                <MDTypography
                  sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
                >
                  Back
                </MDTypography>
              </IconButton>
            </Grid>
            <OrderDetailLandingPage selectedOrder={selectedOrder} />
          </div>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default Order;
