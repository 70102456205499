export const getFilteredLocationOptions = (locations, externalGridRef) => {
  //   const storeList =
  //     externalGridRef.current?.getColumnFilterModel("storeId")?.values ||
  //     externalGridRef.current?.getColumn("storeId")?.colDef?.filterParams
  //       ?.values || [];
  const selectedStore =
    externalGridRef.current?.getColumnFilterModel("storeId")?.filter || null;
  return selectedStore
    ? locations
        .filter((location) => {
          return (
            location.division &&
            !location.isDeleted &&
            selectedStore == location.parentId
          );
        })
        .map((location) => ({
          label: location.division.title,
          value: location.id,
        }))
    : [];
};

export const getFilteredSubLocationOptions = (
  subLocations,
  externalGridRef
) => {
  const selectedLocation =
    externalGridRef.current?.getColumnFilterModel("location")?.filter || null;
  return selectedLocation
    ? subLocations
        .filter((subLocation) => {
          return (
            !subLocation.isDeleted && selectedLocation == subLocation.parentId
          );
        })
        .map((subLocation) => ({
          label: subLocation.name,
          value: subLocation.id,
        }))
    : [];
};
