import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import { Card, TextField, Typography } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";


// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import AuthService from "services/auth-service";
import '../style.css';


const initialValues = {
    password: "",
    password_confirmation: "",
};

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password does not match")
        .required("Confirm Password is required"),
});

const PasswordReset = () => {
    const navigate = useNavigate();

    const [token, setToken] = useState(null);
    const [notification, setNotification] = useState(false);

    const [errors, setErrors] = useState({
        passwordError: false,
        confirmationError: false,
        error: false,
        textError: "",
    });

    useEffect(() => {
        // get the token and email sent in the url
        const queryParams = new URLSearchParams(window.location.search);
        setToken(queryParams.get("token"));
    }, []);

    return (
        <CoverLayout image={bgImage}>
            <Card sx={{ borderRadius: "50px", width: "500px" }}>
                <MDBox
                    borderRadius="lg"
                    mx={2}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDBox mt={10} pb={1}>
                        <img src={"/images/broadwayLogo.png"} />
                    </MDBox>
                    <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} sx={{fontFamily:"Montserrat"}}>
                        New Password
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={async (values, { setSubmitting }) => {
                        const data = {
                            resetPasswordToken: token,
                            newpassword: values.password
                        }

                        try {
                            await AuthService.resetPassword(data);
                            navigate('/auth/login')

                            if (errors.passwordError === false && errors.confirmationError === false) {
                                setNotification(true);
                                setTimeout(() => { setNotification(false) }, 3000)
                            }
                        } catch (err) {
                            if (err.hasOwnProperty("errors")) {
                                setErrors({ ...errors, error: true, textError: err.errors.password[0] });
                            }
                            return null;
                        }
                    }}>
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={10} mx="auto">
                                        <Field
                                            as={TextField}
                                            name="password"
                                            type="password"
                                            label="Password"
                                            error={touched.password && !!errors.password}
                                            helperText={touched.password ? errors.password : ""}
                                            fullWidth
                                            inputProps={{style: {fontFamily: 'Montserrat'}}}
                                            InputLabelProps={{style: {fontFamily: 'Montserrat'}}}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={10} mx="auto">
                                        <Field
                                            as={TextField}
                                            type="password"
                                            name="password_confirmation"
                                            label="Password Confirmation"
                                            error={touched.password_confirmation && !!errors.password_confirmation}
                                            helperText={touched.password_confirmation ? errors.password_confirmation : ""}
                                            fullWidth
                                            inputProps={{style: {fontFamily: 'Montserrat'}}}
                                            InputLabelProps={{style: {fontFamily: 'Montserrat'}}}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={10} mx="auto">
                                        <MDButton variant="gradient" color="dark" fullWidth type="submit" sx={{ borderRadius: "50px",fontFamily:"Montserrat" }}>
                                            Change Password
                                        </MDButton>
                                    </Grid>
                                </Grid>
                                <MDBox mt={3} mb={5} textAlign="center">
                                    <MDTypography variant="button" color="dark"  sx={{fontFamily:"Montserrat"}}>
                                        Remember Password?{" "}
                                        <Typography
                                            component={Link}
                                            to="/auth/login"
                                            variant="button"
                                            color="dark"
                                            fontWeight="medium"
                                            sx={{fontFamily:"Montserrat"}}
                                        >
                                            <Typography sx={{ textDecoration: 'underline', display: "inline", color: "#000", textTransform: "capitalize", fontFamily: "Montserrat", fontWeight: 600, fontSize: "15px" }}>Sign In</Typography>
                                            
                                        </Typography>
                                    </MDTypography>
                                </MDBox>
                            </Form>
                        )}
                    </Formik>
                </MDBox>
            </Card>
            {notification && (
                <MDAlert color="info" mt="20px" dismissible>
                    <MDTypography variant="body2" color="white"  sx={{fontFamily:"Montserrat"}}>
                        Your password change was successful. Go back to
                        <MDTypography
                            component={Link}
                            to="/auth/login"
                            variant="body2"
                            fontWeight="medium"
                            color="white"
                            sx={{fontFamily:"Montserrat"}}
                        >
                            &nbsp;login&nbsp;
                        </MDTypography>
                        to authenticate.
                    </MDTypography>
                </MDAlert>
            )}
        </CoverLayout>
    );
};

export default PasswordReset;
