import React, { useState } from "react";
import { Formik, Form, Field, useField } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Autocomplete,
} from "@mui/material";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { dashboardServiceClient } from "graphql/client";
import { useMutation } from "@apollo/client";
import {
  CREATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  GET_ATTRIBUTE_LIST,
} from "services/master-attribute-service";
import { useNotification } from "context";
import MDButton from "components/MDButton";

export const ginesysAttribute = {
  CNAME3: "cName3",
  CNAME4: "cName4",
  CNAME5: "cName5",
  CNAME6: "cName6",
  DESC1: "desc1",
  DESC2: "desc2",
  DESC4: "desc4",
  DESC6: "desc6",
  UDF1: "udfString01",
  UDF2: "udfString02",
  UDF3: "udfString03",
  ONLINE: "online"
};
Object.freeze(ginesysAttribute);

const validationSchema = Yup.object({
  id: Yup.string(),
  attributeName: Yup.string()
    .required("Attribute Name is required")
    .max(50, "Maximum 50 characters allowed"),
    description: Yup.string()
    .required("Description is required")
    .max(250, "Maximum 250 characters allowed"),
  type: Yup.string().required("Type is required"),
  isDeleted: Yup.boolean().required("This Field is required"),
  isFilter: Yup.boolean().required("isFilter is required"),
  values: Yup.array().when("type", (type, yup) =>
    type === "Open"
      ? Yup.array().of(Yup.string()).min(1, "This Field is Required")
      : Yup.array().of(Yup.string())
  ),
  ginesysAttribute: Yup.string().required("Ginesys Attribute is required"),
});

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const [field, , helpers] = useField(props);
  const { setValue } = helpers;
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const valueToAdd = inputValue.trim();
      if (valueToAdd !== "") {
        setValue([...field.value, valueToAdd]);
        setInputValue(""); // Clear the input value
      }
    }
  };

  const handleBlur = () => {
    const valueToAdd = inputValue.trim();
    if (valueToAdd !== "") {
      setValue([...field.value, valueToAdd]);
      setInputValue(""); // Clear the input value
    }
  };

  return (
    <Autocomplete
      {...props}
      multiple
      freeSolo
      options={[]}
      open={false}
      value={field.value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      )}
    />
  );
};

const useAttributeMutation = (attributeData, handleClose, setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_ATTRIBUTE_LIST,
        variables: { take: 20, skip: 0, search: "" },
      },
    ],
  };

  const [createAttributeMutation] = useMutation(
    CREATE_ATTRIBUTE,
    mutationOptions
  );
  const [updateAttributeMutation] = useMutation(
    UPDATE_ATTRIBUTE,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      name: values?.attributeName || "",
      description:values?.description || "",
      fieldType: values?.type || "",
      isFilter: values?.isFilter,
      values: values?.values || [],
      isDeleted: !values?.isDeleted,
      ginesysAttribute: values?.ginesysAttribute || "",
    };
    const mutation =
      attributeData && attributeData.id
        ? updateAttributeMutation
        : createAttributeMutation;
    const mutationInput =
      attributeData && attributeData.id
        ? { updateMasterAttributeInput: { id: attributeData.id, ...payload } }
        : { createMasterAttributeInput: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
            attributeData && attributeData?.id
              ? "Attribute  updated successfully"
              : "Attribute  created successfully",
        });
      })
      .catch((error) => {
        console.error("Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const AttributesForm = ({ attributeData, user, handleClose }) => {
  const { setNotification } = useNotification();

  const handleSubmit = useAttributeMutation(
    attributeData,
    handleClose,
    setNotification
  );
  return (
    <Formik
      initialValues={{
        id: attributeData?.id || "",
        attributeName: attributeData?.name || "",
        description: attributeData?.description || "",
        type: attributeData?.fieldType || "",
        isFilter: attributeData?.isFilter || false,
        values: attributeData?.values || [],
        isDeleted: !attributeData?.isDeleted || false,
        ginesysAttribute: attributeData?.ginesysAttribute || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateField,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Field as={TextField} name="id" label="ID" fullWidth disabled />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="attributeName"
                label="Attribute Name"
                fullWidth
                error={touched.attributeName && !!errors.attributeName}
                helperText={touched.attributeName && errors.attributeName}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="description"
                label="Enter Description"
                fullWidth
                error={touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  label="Type"
                  sx={{ height: 40 }}
                  value={values.type}
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  onChange={(e) => {
                    setFieldValue("type", e.target.value);
                    setFieldTouched(`type`, true, false);
                    validateField(`type`, e.target.value);
                    setFieldValue("isFilter", false);
                  }}
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Closed">Closed</MenuItem>
                </Select>
                {touched.type && errors.type && (
                  <Typography fontSize={12} color="error">
                    {errors.type}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="ginesys-attribute-label">
                  Ginesys Key Name
                </InputLabel>
                <Select
                  as={Select}
                  labelId="ginesys-attribute-label"
                  id="ginesys-attribute"
                  name="ginesysAttribute"
                  label="Ginesys Key Name"
                  sx={{ height: 40 }}
                  value={values.ginesysAttribute}
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  onChange={(e) => {
                    setFieldValue("ginesysAttribute", e.target.value);
                    setFieldTouched(`ginesysAttribute`, true, false);
                    validateField(`ginesysAttribute`, e.target.value);
                  }}
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                >
                  {Object.keys(ginesysAttribute).map((key) => (
                    <MenuItem value={key} key={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
                {touched.ginesysAttribute && errors.ginesysAttribute && (
                  <Typography fontSize={12} color="error">
                    {errors.ginesysAttribute}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormikAutocomplete
                name="values"
                disabled={values.type === "Open"}
                textFieldProps={{
                  label: "Values",
                  helperText: touched.values && errors.values,
                  error: touched.values && Boolean(errors.values),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="isFilter"
                disabled={values.type === "Open"}
                value="Is Filter ?"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!(user && user.role === "admin")}
                          checked={values.isFilter}
                          onChange={(e) =>
                            setFieldValue("isFilter", e.target.checked)
                          }
                        />
                      }
                      labelPlacement="start"
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="isDeleted"
                value="Active"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!(user && user.role === "admin")}
                          checked={values.isDeleted}
                          onChange={(e) =>
                            setFieldValue("isDeleted", e.target.checked)
                          }
                        />
                      }
                      labelPlacement="start"
                    />
                  ),
                }}
              />
            </Grid>
            <Grid container spacing={1}>
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
              <MDButton
                type="submit"
                variant="contained"
                circular={true}
                color="dark"
                disabled={isSubmitting}
              >
                Save
              </MDButton>
              <MDButton onClick={handleClose} circular={true} variant="outlined" color="dark">
                Cancel
              </MDButton>
            </Grid>
          </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AttributesForm;
