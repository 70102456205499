import React from "react";
import pdfIcon from "../../assets/images/pdf-icon.svg";
import xlsIcon from "../../assets/images/xls-icon.svg";
import docIcon from "../../assets/images/doc-icon.svg";
import pptIcon from "../../assets/images/ppt-icon.svg";
import videoIcon from "../../assets/images/video-doc-icon.svg";
import {
  Badge,
  Box,
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as editIcon } from "assets/images/editIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import dayjs from "dayjs";
import { REMOVE_INFORMATION_GUIDE } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import { LIST_INFORMATION_GUIDES } from "services/brand-service";
import { useMutation } from "@apollo/client";

const InformationGuideCard = ({ informationGuide, onEditClick, isAdmin }) => {
  const [removeInformationGuideMutation] = useMutation(
    REMOVE_INFORMATION_GUIDE,
    {
      client: dashboardServiceClient,
      refetchQueries: [
        {
          query: LIST_INFORMATION_GUIDES,
          variables: {
            listInformationGuideFilter: {
              take: 9999,
              skip: 0,
              search: "",
              filter: {
                isVisibleToBrands: !isAdmin ? true : null,
                status: !isAdmin ? "Published" : null,
                isDeleted: false,
              },
            },
          },
        },
      ],
    }
  );

  const getIconFromFile = (filePath) => {
    const ext = filePath.split(".").pop().toLowerCase();
    switch (ext) {
      case "pdf":
        return pdfIcon;
      case "xls":
      case "xlsx":
        return xlsIcon;
      case "doc":
      case "docx":
        return docIcon;
      case "ppt":
        return pptIcon;
      case "mp4":
        return videoIcon;
      default:
        return "";
    }
  };

  const handleDownload = (infoGuide) => {
    // This creates a new anchor element and triggers a download
    const link = document.createElement("a");
    link.href = infoGuide?.contentFile?.publicurl;
    link.download = infoGuide?.contentFile?.path?.split("/").reverse()[0]; // You can set the default file name for the download here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Grid item xs={4} sx={{ padding: 2, minHeight: 300 }}>
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          boxShadow: 3,
          background: "white",
          height: "100%",
        }}
      >
        {/* Header Section */}
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: 16 }} fontWeight="bold">
            {informationGuide.name}
          </Typography>
          <img
            src={getIconFromFile(informationGuide.contentFile?.path)}
            alignItems={"center"}
          />
        </Grid>

        {/* Module Section */}
        <Typography color="primary" sx={{ mb: 1, fontSize: 12 }}>
          Module:{" "}
          <span style={{ color: "#026BE7" }}>{informationGuide.module}</span>
        </Typography>

        {/* Description Section */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ mb: 2, whiteSpace: "break-spaces", overflow:'hidden', height: 70, fontSize:14 }}
        >
          {informationGuide.description}
        </Typography>

        {/* Last Updated Section */}
        <Typography variant="caption" color="textSecondary" sx={{ mb: 2 }}>
          Last Updated on: {dayjs(informationGuide.updatedAt).format('D MMM, YYYY')}
        </Typography>
        <hr color="#EAEAEA" />

        {/* Action Buttons Section */}
        <Grid
          sx={{ my: 2 }}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          {isAdmin && (
            <Box sx={{ display: "flex" }}>
              {informationGuide.status == "Published" ? (
                <Chip
                  label="Published"
                  sx={{
                    color: "#2BA464",
                    backgroundColor: "rgba(43, 164, 100, 0.1)",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <Chip
                  label={informationGuide.status}
                  sx={{
                    color: "#EC9884",
                    backgroundColor: "rgba(236, 152, 132, 0.1)",
                    borderRadius: "5px",
                  }}
                />
              )}

              {informationGuide.isVisibleToBrands && isAdmin && (
                <Chip
                  label="For Brands"
                  sx={{
                    color: "#026BE7",
                    backgroundColor: "rgba(2, 107, 231, 0.1)",
                    borderRadius: "5px",
                    mx: 1,
                  }}
                />
              )}
            </Box>
          )}

          <Grid item sx={{ width: "fit-content", ml: "auto" }}>
            {isAdmin && (
              <IconButton
                onClick={() => onEditClick(informationGuide)}
                sx={{ fontSize: 16, cursor: "pointer" }}
              >
                <Icon component={editIcon} />
              </IconButton>
            )}
            <IconButton sx={{ fontSize: 16 }}>
              <Icon
                onClick={() => handleDownload(informationGuide)}
                component={downloadIcon}
              />
            </IconButton>
            {isAdmin && (
              <IconButton
                sx={{ fontSize: 16 }}
                aria-label="delete"
                onClick={() => {
                  removeInformationGuideMutation({
                    variables: { id: informationGuide.id },
                  });
                }}
              >
                <Icon component={deleteIcon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default InformationGuideCard;
