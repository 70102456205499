import { gql } from "@apollo/client";

export const GET_SECTION_AND_COMPONENTS = gql `
  query getTransformedSection($filter: GetSectionsInput!) {
    getTransformedSection(filter: $filter) {
      name
      id
      metaData {
        key
        value
      }
      displayOrder
      components {
        name
        id
        isDeleted
        displayOrder
        metaData {
          key
          value
          id
        }
      }
    }
  }
`;

export const CREATE_NEW_MEDIA = gql `
  mutation createEcommerceMedia(
    $createEcommerceMedia: CreateEcommerceMediaDto!
  ) {
    createEcommerceMedia(createEcommerceMedia: $createEcommerceMedia) {
      id
      fileName
      status
    }
  }
`;

export const IMAGE_MASTER_LISTING = gql `
  query FindAllEcommerceMedia($filter: GetEcommerceMediaDto!) {
    findAllEcommerceMedia(filter: $filter) {
      id
      fileName
      applicationPage
      pageSection
      brandId
      title
      description
      size
      status
      duration
      path
      publicUrl
      createdAt
      updatedAt
      pageSection
      productName
      startDate
      endDate
      startTime
      endTime
      ctaName
      link
    }
  }
`;

export const GET_ECOMMERCE_MEDIA = gql `
  query FindAllEcommerceMedia {
    findAllEcommerceMedia(filter: { limit: 100, skip: 0 }) {
      id
      fileName
      applicationPage
      pageSection
      brandId
      title
      description
      size
      status
      duration
      path
      publicUrl
      createdAt
      updatedAt
      pageSection
      productName
      startDate
      endDate
      startTime
      endTime
      ctaName
      link
    }
  }
`;

export const GET_ECOMMERCE_MEDIA_FILTER = gql `
  query FindAllEcommerceMediaWithFilter(
    $skip: Float! = 0
    $limit: Float! = 100
    $brandId: String!
    $applicationPage: String!
    $pageSection: String!
    $status: EcommerceMediaStatus!
  ) {
    findAllEcommerceMediaWithFilter(
      filter: {
        limit: $limit
        skip: $skip
        brandId: $brandId
        applicationPage: $applicationPage
        pageSection: $pageSection
        status: $status
      }
    ) {
      id
      fileName
      applicationPage
      pageSection
      brandId
      size
      status
      duration
      publicUrl
      coverPath
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ECOMMERCE_MEDIA = gql `
  mutation UpdateEcommerceMedia($input: UpdateEcommerceMediaDto!) {
    updateEcommerceMedia(updateEcommerceMedia: $input) {
      id
      fileName
      applicationPage
      pageSection
      brandId
      size
      status
      duration
      publicUrl
      coverPath
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_ECOMMERCE_MEDIA = gql `
  mutation CreateEcommerceMedia($input: CreateEcommerceMediaDto!) {
    createEcommerceMedia(createEcommerceMedia: $input) {
      id
    }
  }
`;

export const DELETE_IMAGE = gql `
  mutation DeleteEcommerceMedia($id: String!) {
    deleteEcommerceMedia(deleteEcommerceMedia: { id: $id }) {
      fileName
      path
      publicUrl
      size
      duration
      applicationPage
      pageSection
      brandId
      productName
      title
      description
      startDate
      endDate
      startTime
      endTime
      ctaName
      link
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_IMAGE_STATUS = gql `
  mutation updateEcommerceMedia(
    $updateEcommerceMedia: UpdateEcommerceMediaDto!
  ) {
    updateEcommerceMedia(updateEcommerceMedia: $updateEcommerceMedia) {
      status
    }
  }
`;

export const UPDATE_STATUS = gql `
  mutation updateComponentMetaData($input: UpdateComponentMetadatumInput!) {
    updateComponentMetadatum(updateComponentMetadatumInput: $input) {
      id
      value
      key
    }
  }
`;

export const DELETE_COMPONENT = gql `
  mutation removeComponent($id: String!) {
    removeComponent(id: $id) {
      id
      name
      displayOrder
    }
  }
`;

export const CREATE_COMPONENT_MUTATIONS = gql `
  mutation createComponentWithMetaData(
    $input: CreateComponentWithMetaDataInput!
  ) {
    createComponentWithMetaData(data: $input) {
      id
      name
      displayOrder
      product
      isDeleted
      metaData {
        key
        value
      }
    }
  }
`;

export const GET_CATEGORY_COMPONENT = gql `
  query getAllCategoryProduct(
    $listCategoryProductFilter: ListCategoryProductFilter!
  ) {
    categoryProduct(listCategoryProductFilter: $listCategoryProductFilter) {
      total
      results {
        id
        title
        metaTitle
        image
        displayOrder
        isDeleted
        isMenuItem
        isNodeItem
        createdAt
        updatedAt
        parent {
          isDeleted
          id
          title
          metaTitle
          parent {
            id
            title
            isDeleted
            parent {
              id
              title
              isDeleted
              parent {
                id
                title
                isDeleted
                parent {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_COMPONENT_METADATA = gql `
  mutation updateComponentWithMetaData(
    $updateComponentMetadatumInput: UpdateComponentMetadatumInput!
  ) {
    updateComponentMetadatum(
      updateComponentMetadatumInput: $updateComponentMetadatumInput
    ) {
      id
    }
  }
`;

export const GET_PRODUCT_COMPONENT = gql `
  query getProducts($limit: Float! = 10, $skip: Float! = 0) {
    getProducts(filter: { limit: $limit, skip: $skip }) {
      total
      products {
        id
        parentArticle
        brand {
          name
          id
        }
        imageUrl
        bulkProduct
      }
    }
  }
`;

export const UPDATE_DISPLAY_ORDER_COMPONENT = gql `
  mutation updateComponent($id: String!, $displayOrder: Int!) {
    updateComponent(
      updateComponentInput: { id: $id, displayOrder: $displayOrder }
    ) {
      id
      name
      displayOrder
      product
    }
  }
`;

export const GET_PRODUCT_VARIENTS_COMPONENT = gql `
  query getVariants($filter: GetVariantsInput!) {
    getVariants(filter: $filter) {
      total
      variants {
        id
        articleName
        itemName
        shortName
        product {
          id
          parentArticle
          brand {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_LIST = gql `
  query getCategoryProduct {
    categoryProduct(
      listCategoryProductFilter: {
        take: 100
        skip: 0
        filter: { isNodeItem: false, isDeleted: false }
      }
    ) {
      total
      results {
        id
        title
        metaTitle
        image
        displayOrder
        isDeleted
        isMenuItem
        isNodeItem
        createdAt
        updatedAt
        parent {
          isDeleted
          id
          title
          metaTitle
          parent {
            id
          }
        }
      }
    }
  }
`;

export const GET_MEDIA_LIST_COMPONENT = gql `
  query FindAllEcommerceMedia($filter: GetEcommerceMediaDto!) {
    findAllEcommerceMedia(filter: $filter) {
      id
      fileName
      applicationPage
      pageSection
      brandId
      size
      status
      duration
    }
  }
`;

export const GET_BULK_REVIWS = gql `
  query BulkReviews(
    $take: Int!
    $skip: Int!
    $brandId: String!
    $filter: JSONObject
  ) {
    bulkReviews(
      listBulkReviewFilter: {
        take: $take
        skip: $skip
        brandId: $brandId
        filter: $filter
      }
    ) {
      total
      results {
        id
        filename
        uploadedOn
        uploadedBy
        status
        filePath
        url
        reviewType
        isDeleted
        __typename
      }
    }
  }
`;
export const UPDATE_BULK_REVIWS = gql `
  mutation UpdateStatusBulkReview($id: String!, $status: bulkReviewStatus!) {
    updateStatusBulkReview(id: $id, status: $status) {
      id
      filename
      uploadedOn
      uploadedBy
      status
      reviewType
      isDeleted
    }
  }
`;

export const GET_EVENT_CATEGORY_LIST = gql `
  query getEventCategoris($limit: Float! = 10, $skip: Float! = 0) {
    getEventCategories(filter: { limit: $limit, skip: $skip }) {
      total
      results {
        title
        id
        image {
          publicurl
        }
      }
    }
  }
`;

export const GET_EVENTS_LIST = gql `
  query events {
    events(eventListFilter: { search: "" }) {
      results {
        id
        name
      }
    }
  }
`;

export const GET_EVENT_BRAND_LIST = gql `
  query getEventBrands($limit: Float! = 10, $skip: Float! = 0) {
    getUniqueEventBrandSponsors(filter: { limit: $limit, skip: $skip }) {
      results {
        name
        id
      }
      total
    }
  }
`;
export const REMOVE_REVIEW = gql `
  mutation removeBulkReview($id: String!) {
    removeBulkReview(id: $id)
  }
`;

export const GET_SECTIONS_BY_PAGE = gql `
  query getSectionByPageName($input: GetSectionsInput!) {
    sections(input: $input) {
      results {
        id
        brandId
        displayOrder
        internalName
        name
        page
        storeId
        sectionMetaData {
          id
          key
          value
          type
          isDeleted
        }
        component {
          id
          displayOrder
          isDeleted
          name
          product
          componentMetaData {
            id
            isDeleted
            key
            value
            isDeleted
          }
        }
      }
      total
    }
  }
`;