import HttpService from "./htttp.service";
import * as axios from "axios";

class QRCodeService {

  getQrCodeListing = async () => {
    const qrlisting = "/vm-service/qr";
    return await HttpService.get(qrlisting);
  };
  getQrCodeUpdate = async (id,payload) => {
    const qrlisting = "/vm-service/qr/"+id;
    return await HttpService.patch(qrlisting,payload);
  };
  getQrCodeCreate = async (payload) => {
    const qrlisting = "/vm-service/qr";
    return await HttpService.post(qrlisting,payload);
  };
  getQrCodeDelete = async (id) => {
    const qrlisting = "/vm-service/qr/"+id;
    return await HttpService.delete(qrlisting);
  };

}

export default new QRCodeService();
