import React, { useEffect, useState } from 'react'

// Custom Context
import { useNotification } from 'context'

// MUI Components
import { Grid, Typography,Autocomplete,TextField } from '@mui/material'
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//images and icons
import { Add as AddIcon } from "@mui/icons-material";


// Apollo Clients Query's
import { useLazyQuery } from "@apollo/client";
import { SAMPLE_FEEDBACK_TOTAL } from "services/sample-feedback";
import { productServiceClient,dashboardServiceClient } from "graphql/client";
import { BRANDS_LIST } from "services/brand-service";


// Custom Components
import DashboardLayout from 'ui/LayoutContainers/DashboardLayout'
import FeedbackListing from './feedbackListing';
import useDebounce from "layouts/ecommerce-media/common/debounce";
import MDButton from '../../components/MDButton';


function SampleFeedback() {
    const { setNotification } = useNotification();
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'day').startOf("day"));
    const [endDate, setEndDate] = useState(dayjs().endOf("day"));

    const [searachBrandValues, setSearchBrandValues] = useState("");
    const debouncedBrandSearchTerm = useDebounce(searachBrandValues, 300);

    const [startDateError, setStartDateError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);
    const [key, setKey] = useState(new Date()) // This is the key which we are using to re-render our ag grid when date chanhes in filter

    const [fetchFeedbackTotal, { data }] = useLazyQuery(SAMPLE_FEEDBACK_TOTAL, {
        client: productServiceClient,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    const [
        getBrandList,
        { loading: brandListLoading, data: brandListData, error: brandError },
      ] = useLazyQuery(BRANDS_LIST, {
        client: dashboardServiceClient,
        variables: {
          take: 10,
          skip: 0,
          search: debouncedBrandSearchTerm,
          filter: { isDeleted: false }
        },
      });

      useEffect(() => {
        getBrandList();
      }, [debouncedBrandSearchTerm]);

    useEffect(() => {
        fetchFeedbackTotal({ variables: { listReviewFilter: { fromDate: startDate, tillDate: endDate, brandIds:selectedBrand, take: 0, skip: 0 } } });
        setKey(new Date()); // This will trigger a re-render of the ag grid with new data
    }, [startDate, endDate,selectedBrand])

    const handleFeedbackExport = async (_event) => {
        const url = process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/product-service/feedback/export?fromDate=${startDate}&tillDate=${endDate}&brandId=${selectedBrand ? selectedBrand : ""}`
        : `/product-service/feedback/export?fromDate=${startDate}&tillDate=${endDate}&brandId=${selectedBrand ? selectedBrand : ""}`;      
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const blob = await response.blob();
    
            const downloadUrl = window.URL.createObjectURL(blob);
    
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `feedback-export_${Math.floor(Date.now() / 1000)}.xlsx`; // Use epoch timestamp for filename
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
          } else {
            throw new Error("Failed to download export");
          }
        } catch (error) {
          console.error("Error while exporting brands", error);
          setNotification({
            color: "error",
            isVisible: true,
            message: error?.message || "Error while exporting feedback",
          })
        }
      }

    const handleStartDateChange = (newValue) => {
        if (newValue === null || newValue?.isAfter(dayjs())) {
            setStartDateError("Please Enter Valid Start date.");
            setStartDate(null);
        } else {
            setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
            setStartDateError(null);
            // Automatically adjust the end date to be within one month of the start date
            const newEndDate = dayjs(newValue).add(6, "month").isAfter(dayjs())
                ? dayjs() // If one month from startDate is in the future, set endDate to today
                : dayjs(newValue).add(6, "month");
            setEndDate(newEndDate?.format("YYYY-MM-DD"));
            setEndDateError(null);
        }
    };

    const handleEndDateChange = (newValue) => {
        if (newValue === null) {
            setEndDate(null); // Set to null if the date is cleared
            setEndDateError(null);
        }
        const maxAllowedEndDate = dayjs(startDate, "YYYY-MM-DD").add(
            6,
            "month"
        ); // Maximum allowed end date is 6 month from start date
        if (
            dayjs(newValue).isAfter(maxAllowedEndDate) ||
            dayjs(newValue).isAfter(dayjs()) ||
            dayjs(newValue).isBefore(startDate)
        ) {
            setEndDateError(
                "End date must be within one month of the start date and cannot be a future date."
            );
        } else {
            setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
            setEndDateError(null);
        }
    };
    return (
        <DashboardLayout>
            <Grid container gap={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5}>
                            <Grid item xs={6} mb={2}>
                                <Autocomplete
                                    label="Select Brand"
                                    loading={brandListLoading}
                                    iconComponent={() => (
                                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                                    )}
                                    options={
                                        brandListData?.brandlist?.results?.length
                                            ? brandListData?.brandlist?.results.map((item) => ({
                                                label: item?.name,
                                                value: item?.id,
                                            }))
                                            : []
                                    }
                                    onChange={(e, newValue) => {
                                        setSelectedBrand(newValue?.value || null);
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40,
                                            px: 2,
                                            backgroundColor: "white.main",
                                        },
                                    }}
                                    onInputChange={(event, newValue) => {
                                        if (
                                            (event && event?.type === "reset") ||
                                            newValue === ""
                                        ) {
                                            setSearchBrandValues(""); // Clear search when input is cleared
                                        } else if (event && event.type === "change") {
                                            setSearchBrandValues(newValue);
                                        }
                                    }}
                                    onBlur={() => {
                                        setSearchBrandValues("");
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Select Brand" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='typography-header'> Sample Feedback </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} display="flex">
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="From Date"
                                            value={dayjs(startDate, "YYYY-MM-DDT")}
                                            onChange={handleStartDateChange}
                                            maxDate={dayjs()}
                                            className='date-filter-style'
                                            disableFuture
                                            renderInput={(params) => (
                                                <Field
                                                    as={TextField}
                                                    {...params}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {startDateError && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        sx={{ mt: 1, fontSize: "10px" }}
                                    >
                                        {startDateError}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="To Date"
                                            value={dayjs(endDate, "YYYY-MM-DD")}
                                            minDate={dayjs(startDate, "YYYY-MM-DD")}
                                            maxDate={
                                                dayjs(startDate, "YYYY-MM-DD")
                                                    .add(6, "month")
                                                    .isAfter(dayjs())
                                                    ? dayjs()
                                                    : dayjs(startDate, "YYYY-MM-DD").add(
                                                        6,
                                                        "month"
                                                    )
                                            }
                                            className='date-filter-style'
                                            disabled={!startDate}
                                            onChange={handleEndDateChange}
                                            disableFuture
                                            renderInput={(params) => (
                                                <Field
                                                    as={TextField}
                                                    {...params}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {endDateError && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        sx={{ mt: 1, fontSize: "10px" }}
                                    >
                                        {endDateError}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <MDButton
                                type="button"
                                variant="outlined"
                                color="dark"
                                backgroundColor="white !important"
                                className="typography-header"
                                circular={true}
                                startIcon={<AddIcon />}
                                onClick={handleFeedbackExport}
                                style={{margin:"10px", backgroundColor: "white"}}
                            >
                                Export
                            </MDButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={4} sx={{backgroundColor:"#FFFFFF" , padding:"10px",maxWidth:"320px !important"}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography className='typography-title'>
                                    Total Feedbacks
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        className='typography-subtitle'
                                    >
                                       {` (From: ${dayjs(startDate).format('DD/MM/YYYY')} - To: ${dayjs(endDate).format('DD/MM/YYYY')})`}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='typography-title' sx={{color:"#9349FB !important"}}>
                                {data?.getReviewListApp?.total}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <FeedbackListing startDate={startDate} endDate={endDate} key={key} selectedBrand={selectedBrand} />
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default SampleFeedback
