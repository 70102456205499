

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
    overflowX: "hidden",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  // "::-webkit-scrollbar": {
  //   width: "8px",
  // },

  // /* Track */
  // "::-webkit-scrollbar-track": {
  //   background: "#fff",
  // },

  // /* Handle */
  // "::-webkit-scrollbar-thumb": {
  //   background: "#344767",
  // },

  // /* Handle on hover */
  // "::-webkit-scrollbar-thumb:hover": {
  //   background: "#bc1e4b",
  // },

  ".css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
    padding: "12px !important"
  },
  ".css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select > input": {
    padding: "12px !important"
  },
};

export default globals;
