import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import "react-quill/dist/quill.snow.css"; // import styles
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_COMPONENT } from "services/ecommerce-component-service";
import { feedServiceClient } from "graphql/client";
import { CREATE_COMPONENT_METADATA } from "services/ecommerce-component-service";
import { useNotification } from "context";
import { GET_SECTION_COMPONENT } from "services/ecommerce-component-service";
import { REMOVE_COMPONENT_METADATA } from "services/ecommerce-component-service";
import { UPDATE_COMPONENT } from "services/ecommerce-component-service";
import { UPDATE_COMPONENT_METADATA } from "services/ecommerce-component-service";
import { GET_SECTIONS_BY_PAGE } from "services/ecommerce-media-service";

const metadataSchema = Yup.object().shape({
  key: Yup.string().required("Metadata key is required"),
  value: Yup.lazy((value) => {
    try {
      JSON.parse(value);
      return Yup.string().required(
        "Metadata value must be a valid JSON string"
      );
    } catch (error) {
      return Yup.string().test("is-json", "Invalid JSON format", () => false);
    }
  }),
});
export const validationSchema = Yup.object({
  name: Yup.string().required("Component name is required"),
  section: Yup.object().required("Section is required"),
  displayOrder: Yup.number().required("Display Order is required"),
  metadata: Yup.array().of(metadataSchema),
});

const user =
  localStorage.getItem("userDetails") &&
  localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {};

const SectionForm = ({
  handleClose,
  callback,
  brandId = undefined,
  page,
  selectedSection,
}) => {
  const { setNotification } = useNotification();

  const [createComponent] = useMutation(CREATE_COMPONENT, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_COMPONENT,
        variables: {
          input: { page },
        },
      },
    ],
  });
  const [updateComponent] = useMutation(UPDATE_COMPONENT, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_COMPONENT,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });
  const [createComponentMetadata] = useMutation(CREATE_COMPONENT_METADATA, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_COMPONENT,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });
  const [updateComponentMetadata] = useMutation(UPDATE_COMPONENT_METADATA, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_COMPONENT,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });
  const [removeComponentMetaData] = useMutation(REMOVE_COMPONENT_METADATA, {
    client: feedServiceClient,
    refetchQueries: [
      {
        query: GET_SECTION_COMPONENT,
        variables: {
          input: { page, limit: 100, skip: 0 },
        },
      },
    ],
  });
  const {
    loading: sectionListLoading,
    error: sectionListError,
    data: sectionListData,
    refetch: sectionListRefetch,
  } = useQuery(GET_SECTIONS_BY_PAGE, {
    client: feedServiceClient,
    variables: { input: { page, limit: 100, skip: 0 } },
  });

  // TODO: need to handle bulk metadata create and refatch
  const handleSubmit = (values) => {
    if (selectedSection) {
      updateComponent({
        variables: {
          input: {
            id: selectedSection.id,
            name: values.name,
            displayOrder: values.displayOrder,
            sectionId: values.section.value,
          },
        },
        onCompleted: (section) => {
          values.metadata.forEach((item) => {
            const payload = {
              id: item?.id,
              componentId: section.updateComponent.id,
              key: item.key,
              value: {
                ...JSON.parse(item.value),
              },
            };
            payload.id
              ? updateComponentMetadata({
                  variables: {
                    input: payload,
                  },
                })
              : createComponentMetadata({
                  variables: {
                    input: { ...payload },
                  },
                });
          });
          setNotification({
            color: "success",
            isVisible: true,
            message: "Updated component successfully",
          });
          if (callback) callback();
        },
      });
    } else {
      createComponent({
        variables: {
          input: {
            name: values.name,
            internalName: values.internalName,
            displayOrder: values.displayOrder,
            sectionId: values.section.value,
            status: true,
          },
        },
        onCompleted: (component) => {
          values.metadata.forEach((item) => {
            const payload = {
              componentId: component.createComponent.id,
              key: item.key,
              value: {
                ...JSON.parse(item.value),
              },
            };
            createComponentMetadata({
              variables: {
                input: payload,
              },
            });
          });
          setNotification({
            color: "success",
            isVisible: true,
            message: "Created component successfully",
          });
          if (callback) callback();
        },
      });
    }
  };

  const handleRemove = (metadata, index, remove) => {
    remove(index);
    if (metadata.id) {
      removeComponentMetaData({
        variables: {
          id: metadata.id,
        },
      });
    }
  };
  return (
    <Formik
      initialValues={{
        name: selectedSection?.name || "",
        section: selectedSection?.sectionId
          ? {
              value: selectedSection?.sectionId,
              label: selectedSection?.sectionName,
            }
          : {} || "",
        displayOrder: selectedSection?.displayOrder || 1,
        metadata: selectedSection?.componentMetaData?.filter((meta) => !meta.isDeleted).map(meta => ({...meta, value: JSON.stringify(meta.value)})) || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        validateField,
        isSubmitting,
        handleChange,
        handleBlur,
      }) => {
        return (
          <Form>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="name"
                  label="Component Name"
                  fullWidth
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="section"
                  name="section"
                  multiple={false}
                  value={values.section}
                  options={
                    sectionListData?.sections.results.map((section) => ({
                      value: section.id,
                      label: section.name,
                    })) || []
                  }
                  getOptionLabel={(option) => {
                    return option?.label || option?.name || "Unknown";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option?.value === value?.value;
                  }}
                  loading={sectionListLoading}
                  loadingText="Loading..."
                  onChange={(event, newValue) => {
                    setFieldValue("section", newValue);
                    sectionListRefetch();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Section"
                      error={touched.section && !!errors.section}
                      helperText={touched.section && errors.section}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  type={"number"}
                  name="displayOrder"
                  label="Display Order"
                  fullWidth
                  error={touched.displayOrder && !!errors.displayOrder}
                  helperText={touched.displayOrder && errors.displayOrder}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray name="metadata">
                  {({ push, remove }) => (
                    <div>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6>Metadata:</h6>
                        <IconButton
                          size="small"
                          type="button"
                          sx={{
                            px: 2,
                            backgroundColor: "#cccccc",
                            borderRadius: 5,
                          }}
                          aria-label="add"
                          onClick={() => push({ key: "", value: "" })}
                        >
                          <Icon component={AddIcon} fontSize="small" />
                        </IconButton>
                      </Box>
                      {values.metadata.map((meta, index) => (
                        <>
                          <Grid
                            key={index}
                            container
                            xs={12}
                            spacing={2}
                            py={1}
                            px={2}
                          >
                            <Grid item xs={6}>
                              <Field
                                as={TextField}
                                name={`metadata.${index}.key`}
                                label="Key"
                                fullWidth
                                error={
                                  touched.displayOrder && !!errors.displayOrder
                                }
                                helperText={
                                  touched.displayOrder && errors.displayOrder
                                }
                              />
                              {errors?.metadata?.[index] &&
                                errors?.metadata?.[index]?.key && (
                                  <Typography fontSize={12} color="error">
                                    {errors?.metadata?.[index]?.key}
                                  </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Field
                                  as={TextField}
                                  name={`metadata.${index}.value`}
                                  value={meta.value}
                                  label="Value"
                                  fullWidth
                                  error={
                                    touched.displayOrder &&
                                    !!errors.displayOrder
                                  }
                                  helperText={
                                    touched.displayOrder && errors.displayOrder
                                  }
                                  multiline
                                  rows={3}
                                />
                                <IconButton
                                  size="small"
                                  type="button"
                                  sx={{ px: 2, borderRadius: 5 }}
                                  aria-label="remove"
                                  onClick={() =>
                                    handleRemove(meta, index, remove)
                                  }
                                >
                                  <Icon
                                    component={DeleteIcon}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Box>
                              {errors?.metadata?.[index] &&
                                errors?.metadata?.[index]?.value && (
                                  <Typography fontSize={12} color="error">
                                    {errors?.metadata?.[index]?.value}
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>
                        </>
                        // <div key={index}>
                        //     <label htmlFor={`metadata.${index}.key`}>Key:</label>
                        //     <Field id={`metadata.${index}.key`} name={`metadata.${index}.key`} />

                        //     <label htmlFor={`metadata.${index}.value`}>Value:</label>
                        //     <Field as="textarea" id={`metadata.${index}.value`} name={`metadata.${index}.value`} />

                        //     <button type="button" onClick={() => remove(index)}>
                        //     Remove
                        //     </button>
                        // </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <Button
                  color="dark"
                  style={{ backgroundColor: "#FFFFFF" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SectionForm;
