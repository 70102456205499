import React, { forwardRef, useEffect, useState } from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 React components
import MDTabPanel from "components/MDTabPanel";
import MDBox from "components/MDBox";

// Custom Brand Components
import BrandContactDetailsFormComponent from "./ContactDetailsForm";
import CompanyForm from "./companyForm";
import BankDetailsForm from "./BankDetailsForm";
import AssetMapping from "./Asset Mapping/assetMapping";
import CompanyVerificationForm from "./companyVerificationForm";
import StoreAgreement from "./Store Agreement";
import NodeAgreement from "./Node Agreement/nodeAgreement";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BrandDetailsTab = forwardRef(
  (
    {
      brandData,
      setShowDialog,
      setSkipDirtyValidation,
      formTabValue,
      setFormTabValue,
      handleTabChange,
      setTempTabValue,
      setDirtyData,
    },
    ref
  ) => {
    const handleChange = (_event, newValue) => {
      setTempTabValue(newValue);
      handleTabChange(_event, newValue);
    };

    return (
      <MDBox sx={{}}>
        <MDBox sx={{}}>
          <Tabs
            value={formTabValue}
            onChange={handleChange}
            aria-label="ant example"
          >
            <Tab
              sx={{ fontSize: "13px" }}
              label="Company Details"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ fontSize: "13px" }}
              label="Company Verification"
              {...a11yProps(1)}
            />
            <Tab
              sx={{ fontSize: "13px" }}
              label="Contact Details"
              {...a11yProps(2)}
            />
            <Tab
              sx={{ fontSize: "13px" }}
              label="Bank Details"
              {...a11yProps(3)}
            />
            <Tab
              sx={{ fontSize: "13px" }}
              label="Store Agreement"
              {...a11yProps(4)}
            />
            {/* <Tab sx={{fontSize:"13px"}} label="Node Assigning" {...a11yProps(4)} /> */}
            <Tab
              sx={{ fontSize: "13px" }}
              label="Asset Mapping"
              {...a11yProps(5)}
            />
          </Tabs>
        </MDBox>
        <MDTabPanel value={formTabValue} index={0}>
          <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
            <CompanyForm
              setDirtyData={setDirtyData}
              setFormTabValue={setFormTabValue}
              setShowDialog={setShowDialog}
              setSkipDirtyValidation={setSkipDirtyValidation}
              handleTabChange={handleChange}
              brandData={brandData}
              ref={ref}
            />
          </MDBox>
        </MDTabPanel>
        <MDTabPanel value={formTabValue} index={1}>
          <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
            <CompanyVerificationForm
              setDirtyData={setDirtyData}
              setShowDialog={setShowDialog}
              setFormTabValue={setFormTabValue}
              handleTabChange={handleChange}
              brandData={brandData}
              ref={ref}
            />
          </MDBox>
        </MDTabPanel>
        <MDTabPanel value={formTabValue} index={2}>
          <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
            <BrandContactDetailsFormComponent
              setDirtyData={setDirtyData}
              setShowDialog={setShowDialog}
              setFormTabValue={setFormTabValue}
              handleTabChange={handleChange}
              brandData={brandData}
              ref={ref}
            />
          </MDBox>
        </MDTabPanel>
        <MDTabPanel value={formTabValue} index={3}>
          <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
            <BankDetailsForm
              setDirtyData={setDirtyData}
              setShowDialog={setShowDialog}
              setFormTabValue={setFormTabValue}
              handleTabChange={handleChange}
              brandData={brandData}
              ref={ref}
            />
          </MDBox>
        </MDTabPanel>
        <MDTabPanel value={formTabValue} index={4}>
          <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
            <StoreAgreement
              handleTabChange={handleChange}
              brandData={brandData}
            />
          </MDBox>
        </MDTabPanel>
        {/* <MDTabPanel value={formTabValue} index={5}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <NodeAgreement
            handleTabChange={handleChange}
            brandData={brandData}
          />
        </MDBox>
      </MDTabPanel> */}
        <MDTabPanel value={formTabValue} index={5}>
          <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
            <AssetMapping
              handleTabChange={handleChange}
              brandData={brandData}
            />
          </MDBox>
        </MDTabPanel>
      </MDBox>
    );
  }
);

export default BrandDetailsTab;
