import * as React from "react";

// Apollo Clients Query's
import { useLazyQuery } from "@apollo/client";
import { SAMPLE_FEEDBACK_LIST } from "services/sample-feedback";
import { productServiceClient } from "graphql/client";

// MUI Components
import dayjs from "dayjs";

// Custom Components
import AgGrid from "components/MDGrid";



const columns = [
  {
    field: "reviews.brandName",
    headerName: "Brand Name",
    width: 300,
    valueGetter: (params) => params?.data?.brandName ? params?.data?.brandName : params?.data?.brand?.name  || "",
    // filter: false, 
  },
  {
    field: "reviews.barCode",
    headerName: "Barcode",
    width: 300,
    valueGetter: (params) => params?.data?.variant?.barcode ? params?.data?.variant?.barcode  : params?.data?.barcode  || "",
  },

  {
    field: "variant.shortName",
    headerName: "Product Name",
    width: 300,
  },
  {
    field: "reviews.createdAt",
    headerName: "Date and Time",
    width: 300,
    filter: "agDateColumnFilter", 
    getFilterObj: (value) => ({ 'reviews.createdAt' : value?.dateFrom }), 
    valueGetter: (params) =>
      params?.data?.createdAt
        ? dayjs(params?.data?.createdAt).format("DD-MM-YYYY")
        : "N/A", // Display formatted date or N/A
  },  
  {
    field: "feedback.customerName",
    headerName: "Customer Name",
    width: 300,
    valueGetter: (params) => params?.data?.customerName || "",
  },
  {
    field: "feedback.customerMobile",
    headerName: "Mobile",
    width: 300,
    valueGetter: (params) => params?.data?.customerMobile || "",
  },
  {
    field: "reviews.rating",
    headerName: "Ratings",
    width: 300,
    valueGetter: (params) => params?.data?.rating || "",
  },
  {
    field: "reviews.feedbackDesc",
    headerName: "Feedback",
    width: 300,
    valueGetter: (params) => params?.data?.feedbackDesc || "",
  },
  {
    field: "feedback.remark", // no search from BE
    headerName: "Remarks",
    width: 300,
    valueGetter: (params) => params?.data?.remark || "",
    filter: false, 
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 300,
    valueGetter: (params) => params?.data?.createdBy?.name || params?.data?.createdBy?.username,
    filter: false, 
  },
];
const getLastKey = (field) => {
    const keys = field.split('.');
    return keys[keys.length - 1]; // This function is returning the single key as the BE expects for orderBy
  };
function FeedbackListing({startDate, endDate,key,selectedBrand}) {

  const [fetchFeedbackList] = useLazyQuery(SAMPLE_FEEDBACK_LIST, {
    client: productServiceClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return (
    <AgGrid
    key={key}
    columns={columns}
    readMethod={{
      fetchFn: fetchFeedbackList,
        inputParser: ({ startRow, endRow, filter, sortModel }) => {
            return {
                listReviewFilter: {
                    take: endRow - startRow,
                    skip: startRow,
                    brandIds:selectedBrand,
                    fromDate: startDate,
                    tillDate: endDate,
                    searchBy: Object.values(filter) || [],
                    searchCols: Object.keys(filter) || [],
                    orderBy: sortModel.reduce((acc, { colId, sort }) => {
                        acc[getLastKey(colId)] = sort.toUpperCase();
                        return acc;
                    }, {}), 
                }
            }
        },
      resultsParser: ({ data }) => {
        return ({
          total: data?.getReviewListApp?.total || 0,
          results: data?.getReviewListApp?.results || [],
        })
      },
    }}
    gridProps={{
      rowHeight:60,
      defaultColDef:{
        sortable: true,
        editable: false,
        flex: 1,
        minWidth: 200,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        multiSort: true,
      },
      pivotMode:false,
      enableRangeSelection:true,
    }}  
  />
  );
}

export default FeedbackListing;
