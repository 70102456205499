import React, { useContext, useState, useEffect, createContext } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import DashboardLayout from 'ui/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'ui/Navbars/DashboardNavbar';
import MDButton from 'components/MDButton';
import { Add, Clear, FileDownloadOutlined } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as XLSX from "xlsx";
import LoyaltyTabs from './loyaltyTabs';
import CustomerLoyalityDetails from './customerLoyalityDetails';

export const DateFiltersContext = createContext()
function Loyalty() {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));    
    const [startDateError, setStartDateError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);
    const [activityData, setActivityData] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [customersDetailsData, setcustomersDetailsData] = useState([])
    const [isDetailPage, setIsDetailPage] = useState(false)
    const [loyalityCustomer, setLoyalityCustomer] = useState(null)
    const [tabValue, setTabValue] = useState(0);

    const handleStartDateChange = (newValue) => {
        if (newValue.isAfter(dayjs())) {
            setStartDateError("Start date cannot be a future date.");
        } else {
            setStartDate(dayjs(newValue).format('YYYY-MM-DD'));
            setStartDateError(null);
            
            // Automatically adjust the end date to be within one month of the start date
            const newEndDate = dayjs(newValue).add(1, 'month').isAfter(dayjs())
                ? dayjs()  // If one month from startDate is in the future, set endDate to today
                : dayjs(newValue).add(1, 'month');
            setEndDate(newEndDate.format('YYYY-MM-DD'));
            setEndDateError(null);
        }
    };
    
    const handleEndDateChange = (newValue) => {
        const maxAllowedEndDate = dayjs(startDate, 'YYYY-MM-DD').add(1, 'month'); // Maximum allowed end date is 1 month from start date
        if (dayjs(newValue).isAfter(maxAllowedEndDate) || dayjs(newValue).isAfter(dayjs()) || dayjs(newValue).isBefore(startDate)) {
            setEndDateError("End date must be within one month of the start date and cannot be a future date.");
        } else {
            setEndDate(dayjs(newValue).format('YYYY-MM-DD'));
            setEndDateError(null);
        }
    };    

    const exportToExcel = () => {
        let dataToExport;
        let headers;
    
        if (isDetailPage) {
            // Detail page logic (customer details data)
            dataToExport = customersDetailsData.map(item => ({
                accountId: item.accountId,
                activityType: item.activityType,
                totalRewardsEarned: item.totalRewardsEarned,
                totalRewardsBurned: item.totalRewardsBurned,
                totalBalanceRewards: item.totalBalanceRewards,
                transactionId: item.transactionId,
                orderDate: item.orderDate,
                rewardsExpiryDate: item.rewardsExpiryDate,
                orderTotal: item.orderTotal,
                orderId: item.orderId,
                rewardCreditDate: item.rewardCreditDate,
            }));
            headers = [
                ["Account ID", "Activity Type", "Total Rewards Earned", "Total Rewards Burned", "Total Balance Rewards", "Transaction ID", "Order Date", "Rewards Expiry Date", "Order Total", "Order ID", "Reward Credit Date"],
            ];
        } else  if (tabValue === 0) {
            // Tab 1 logic (activity data)
            dataToExport = activityData.map(item => ({
                activityType: item.activityType,
                totalActivities: item.totalActivites,
                earnedRewardsTotalCustomers: item.rewardsEarned.totalCustomers,
                earnedRewardsTotalPoints: item.rewardsEarned.totalRewardPoints,
                burnedRewardsTotalCustomers: item.rewardsBurned.totalCustomers,
                burnedRewardsTotalPoints: item.rewardsBurned.totalRewardPoints,
            }));
            headers = [
                ["Activity Type", "Total Activities", "Earned Rewards", "", "Burned Rewards", ""],
                ["", "", "Total Customers", "Total Points", "Total Customers", "Total Points"],
            ];
        } else if (tabValue === 1) {
            // Tab 2 logic (customer data)
            dataToExport = customerData.map(item => ({
                name: item.name,
                mobile: item.mobile,
                totalActiveRewards: item.totalActiveRewards,
                totalRewardsBurned: item.totalRewardsBurned,
                totalRewardsEarned: item.totalRewardsEarned,
                customerStatus: item.status,
            }));
            headers = [
                ["Name", "Mobile", "Total Active Rewards", "Total Rewards Burned", "Total Rewards Earned", "Customer Status"],
            ];
        }
    
        const ws_data = [...headers, ...dataToExport?.map(item => Object?.values(item))];
    
        const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
    
        const defaultWidth = 20;
        worksheet['!cols'] = Array(ws_data[0].length).fill({ wch: defaultWidth });
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Loyalty Data");
    
        XLSX.writeFile(workbook, `loyalty_data_tab_${tabValue + 1}.xlsx`);
    };
    
    const ControlSection = ({ user }) => (
        user && user?.role === "admin" ? (<>
            <Grid item xs={12} sm={6} md={6} />

           { !isDetailPage && (
           <>
           <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        label="From Date *"
                        value={dayjs(startDate, 'YYYY-MM-DD')}
                        onChange={handleStartDateChange}
                        maxDate={dayjs()}  
                        disableFuture
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                error={!!startDateError}
                                helperText={startDateError}
                            />
                        )}
                    />
                    {startDateError && (
                        <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: "10px" }}>
                            {startDateError}
                        </Typography>
                    )}
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        label="To Date *"
                        value={dayjs(endDate, 'YYYY-MM-DD')}
                        onChange={handleEndDateChange}
                        minDate={dayjs(startDate, 'YYYY-MM-DD')} 
                        maxDate={dayjs(startDate, 'YYYY-MM-DD').add(1, 'month').isAfter(dayjs()) 
                            ? dayjs() 
                            : dayjs(startDate, 'YYYY-MM-DD').add(1, 'month')}  
                        disableFuture
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                error={!!endDateError}
                                helperText={endDateError}
                            />
                        )}
                    />
                    {endDateError && (
                        <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: "10px" }}>
                            {endDateError}
                        </Typography>
                    )}
                </LocalizationProvider>
            </Grid>
            </>)}
            <MDButton
                type="button"
                variant="outlined"
                color='dark'
                circular={true}
                onClick={exportToExcel}
                startIcon={<FileDownloadOutlined />}
            >
                Export
            </MDButton>
        </>) : <></>

    );

    const user = JSON.parse(localStorage.getItem("userDetails") || "{}");

    return (
        <DashboardLayout>
            {/* <DashboardNavbar /> */}
            <DateFiltersContext.Provider value={{ startDate, endDate, setActivityData, setCustomerData, setcustomersDetailsData, setIsDetailPage, setLoyalityCustomer }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Grid display={"flex"} justifyContent={"space-between"} mb={3}>
                        <Typography sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }} ml={1}>Loyalty</Typography>
                        <ControlSection user={user} />
                    </Grid>
                    {!isDetailPage ?
                        <LoyaltyTabs setTabValue={setTabValue} tabValue={tabValue} /> :
                        <div>
                            <Grid mt={2}>
                                <IconButton
                                    aria-label="Go Back"
                                    onClick={() => { setIsDetailPage(false); }}
                                >
                                    <ArrowBackIcon />
                                    <Typography
                                        sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
                                    >
                                        {`${loyalityCustomer?.name} Loyalty Details`}
                                    </Typography>
                                </IconButton>
                            </Grid>
                            <CustomerLoyalityDetails loyalityCustomer={loyalityCustomer} />
                        </div>}
                </Grid>
            </DateFiltersContext.Provider>

        </DashboardLayout>
    );
}

export default Loyalty;
