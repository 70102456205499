import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState } from 'react'

export default function MDConfirm({children, handleAccept, title, description}) {
    const [open, setOpen] = useState(false);

    const showDialog = () => {
      setOpen(true);
    };
  
    const hideDialog = () => {
      setOpen(false);
    };
  
    const confirmRequest = () => {
      handleAccept();
      hideDialog();
    };
  
    return (
      <>
        {children(showDialog)}
        {open && (
          <Dialog
            open={open}
            onClose={hideDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" sx={{color:'error.main'}}>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={confirmRequest} sx={{color:'success.main'}}>
                Yes
              </Button>
              <Button onClick={hideDialog} sx={{color:'secondary.main'}}>
                No
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  
}
