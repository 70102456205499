import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Icon,
  IconButton,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const hsnCode = ["HSN 1", "HSN 2", "HSN 3"];

const uom = ["UOM 1", "UOM 2", "UOM 3"];

function CustomErrorTextField({ children, sx, ...props }) {
  return (
    <Typography
      sx={{
        color: "#F44335",
        marginTop: "3px",
        marginRight: "14px",
        marginBottom: "0",
        marginLeft: "14px",
        fontSize: "0.75rem",
        fontWeight: "300",
        lineHeight: "1.25",
        letterSpacing: "0.03333em",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

function CustomToggleField({ field, props, form }) {
  return (
    <FormControlLabel
      control={
        <Switch
          {...field}
          {...props}
          checked={field.value}
          onChange={(event) =>
            form.setFieldValue(field.name, event.target.checked)
          }
          sx={{
            "& .Mui-checked .MuiSwitch-thumb": {
              backgroundColor: "#E93165",
              borderColor: "#E93165",
            },
            "& .MuiSwitch-thumb": {
              backgroundColor: "#fde9f0",
              borderColor: "#fde9f0",
            },
            "& .Mui-checked+.MuiSwitch-track": {
              backgroundColor: "#fde9f0 !important",
              borderColor: "#fde9f0",
            },
          }}
        />
      }
    />
  );
}

export default function AddSingleProductVariantDetails() {
  const validationSchema = Yup.object().shape({
    variantName: Yup.string().required("Variant Name is required"),
    variantCode: Yup.string().required("Variant Code is required"),
    hsnCode: Yup.string().required("HSN Code is required"),
    variantDescription: Yup.string().required(
      "Variant Description Name is required"
    ),
    uom: Yup.string().required("UOM description is required"),
    mrp: Yup.string().required("MRP is required"),
    rsp: Yup.string().required("RSP is required"),
    cp: Yup.string().required("CP is required"),
    gst: Yup.string().required("GST is required"),
    units: Yup.string().required("Units is required"),
    vendorCode: Yup.string().required("Vendor Code is required"),
    sample: Yup.string().required("Sample is required"),
    packType: Yup.string().required("Pack Type master is required"),
  });

  const initialValues = {
    variantName: "",
    variantCode: "",
    hsnCode: "",
    variantDescription: "",
    uom: "",
    mrp: "",
    rsp: "",
    cp: "",
    gst: "",
    units: "",
    vendorCode: "",
    sample: "",
    packType: "",
  };

  const [formData, setFormData] = useState({});

  return (
    <Grid>
      <Formik validationSchema={validationSchema} initialValues={initialValues}>
        {({
          touched,
          errors,
          values,
          setFieldValue,
          setFieldError,
          handleSubmit,
        }) => {
          useEffect(() => {
            setFormData(values);
          }, [values]);

          return (
            <Form onSubmit={handleSubmit} id="add-product-details">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="variantName"
                    as={TextField}
                    label="Variant Name"
                    fullWidth
                    error={touched.variantName && Boolean(errors.variantName)}
                    helperText={touched.variantName && errors.variantName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="variantCode"
                    as={TextField}
                    label="Variant Code"
                    fullWidth
                    type="number"
                    error={touched.variantCode && Boolean(errors.variantCode)}
                    helperText={touched.variantCode && errors.variantCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>HSN Code</InputLabel>
                    <Field
                      as={Select}
                      name="hsnCode"
                      label="HSN Code"
                      fullWidth
                      type="number"
                      onChange={(event) => {
                        setFieldValue("hsnCode", event.target.value);
                        setFieldValue("companyName", "");
                      }}
                      sx={{
                        height: "40px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7b809a !important",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                      )}
                    >
                      {hsnCode.map((brand) => (
                        <MenuItem key={hsnCode} value={hsnCode}>
                          {hsnCode}
                        </MenuItem>
                      ))}
                    </Field>

                    {touched.hsnCode && errors.hsnCode && (
                      <CustomErrorTextField color="error">
                        {errors.hsnCode}
                      </CustomErrorTextField>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="variantDescription"
                    as={TextField}
                    label="Variant Description"
                    fullWidth
                    multiline
                    rows={4}
                    error={
                      touched.variantDescription &&
                      Boolean(errors.variantDescription)
                    }
                    helperText={
                      touched.variantDescription && errors.variantDescription
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>UOM</InputLabel>
                    <Field
                      as={Select}
                      name="uom"
                      label="UOM"
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("uom", event.target.value);
                        setFieldValue("companyName", "");
                      }}
                      sx={{
                        height: "40px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7b809a !important",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                      )}
                    >
                      {uom.map((uom) => (
                        <MenuItem key={uom} value={uom}>
                          {uom}
                        </MenuItem>
                      ))}
                    </Field>

                    {touched.uom && errors.uom && (
                      <CustomErrorTextField color="error">
                        {errors.uom}
                      </CustomErrorTextField>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="mrp"
                    as={TextField}
                    label="MRP"
                    fullWidth
                    type="number"
                    error={touched.mrp && Boolean(errors.mrp)}
                    helperText={touched.mrp && errors.mrp}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="rsp"
                    as={TextField}
                    label="RSP"
                    fullWidth
                    error={touched.rsp && Boolean(errors.rsp)}
                    helperText={touched.rsp && errors.rsp}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="cp"
                    as={TextField}
                    label="CP"
                    fullWidth
                    error={touched.cp && Boolean(errors.cp)}
                    helperText={touched.cp && errors.cp}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="gst"
                    as={TextField}
                    label="GST"
                    fullWidth
                    type="number"
                    error={touched.gst && Boolean(errors.gst)}
                    helperText={touched.gst && errors.gst}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="units"
                    as={TextField}
                    label="Units"
                    fullWidth
                    type="number"
                    error={touched.units && Boolean(errors.units)}
                    helperText={touched.units && errors.units}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="vendorCode"
                    as={TextField}
                    label="Vendor Code"
                    fullWidth
                    type="number"
                    error={touched.vendorCode && Boolean(errors.vendorCode)}
                    helperText={touched.vendorCode && errors.vendorCode}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Typography variant="h6" mr={"10px"}>
                    Sample
                  </Typography>
                  <Field
                    name="sample"
                    component={CustomToggleField}
                    fullWidth
                  />
                  {touched.sample && errors.sample && (
                    <FormHelperText error>{errors.sample}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="packType"
                    as={TextField}
                    label="Pack Type"
                    fullWidth
                    error={touched.packType && Boolean(errors.packType)}
                    helperText={touched.packType && errors.packType}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Grid
        item
        xs={12}
        sx={{ pr: 2, pb: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <Button
          variant="outlined"
          style={{
            backgroundColor: "#FFFFFF",
            marginRight: "10px",
            border: "1px solid #E93165",
            borderRadius: "24px",
            color: "#E93165",
          }}
          onClick={() => {
            setForm(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            background: "#E93165",
            borderRadius: "24px",
            color: "#fff",
          }}
          onClick={() => {
            formRef.current.submitForm();
          }}
          disabled={isSubmittingForm}
        >
          {isSubmittingForm ? "Loading..." : "Save"}
        </Button>
      </Grid>
    </Grid>
  );
}
