import React, { useContext } from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Custom Components
import ReportTables from "./reportTables";
import ExcelToAgGrid from "./visualisation";
import {
  headerEventReport,
  headerGMVREPORT,
  headerReturnAndRefund,
  headerStockReport,
  headerSalesReport,
  headerFootfallReport,
  adminHeaderSalesReport,
} from "./header";

// Material Dashboard 2 React Components
import MDTabPanel from "components/MDTabPanel";
import { AuthContext } from "context";
import CycleCountTable from "./CycleCountTable";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportTabs({
  tabValue,
  setTabValue,
  gmvDataRows,
  stockDataRows,
  eventDataRows,
  returnRefundDataRows,
  salesDataRows,
  footfallDataRows,
  loading,
  startDate,
  endDate,
}) {
  const handleChange = (_event, newValue) => {
    console.log("newValue", newValue);
    setTabValue(newValue);
  };
  const { user } = useContext(AuthContext);

  return (
    <>
      <Tabs
        sx={{ px: 2 }}
        value={tabValue}
        onChange={handleChange}
        aria-label="ant example"
      >
        <Tab
          sx={{ fontSize: "13px" }}
          label="Sales Reports"
          {...a11yProps(0)}
          value={0}
        />
        <Tab
          sx={{ fontSize: "13px" }}
          label="Return and Refund Status"
          {...a11yProps(1)}
          value={1}
        />
        {user && user.role !== "brand_admin" && <Tab
          sx={{ fontSize: "13px" }}
          label="Cycle Count"
          {...a11yProps(6)}
          value={6}
        />}
        {user && user.role !== "brand_admin" && (
          <Tab
            sx={{ fontSize: "13px" }}
            label="Footfall"
            {...a11yProps(2)}
            value={2}
          />
        )}
        {user && user.role !== "brand_admin" && (
          <Tab
            sx={{ fontSize: "13px" }}
            label="Stock Report"
            {...a11yProps(3)}
            value={3}
          />
        )}
      </Tabs>
      <MDTabPanel value={tabValue} index={0}>
        <ReportTables
          loading={loading}
          rowData={salesDataRows}
          columnDefs={
            user && user.role !== "brand_admin"
              ? adminHeaderSalesReport
              : headerSalesReport
          }
        />
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={1}>
        <ReportTables
          loading={loading}
          rowData={returnRefundDataRows}
          columnDefs={
            user && user.role !== "brand_admin"
              ? adminHeaderSalesReport
              : headerSalesReport
          }
        />
      </MDTabPanel>
      {user && user.role !== "brand_admin" && <MDTabPanel value={tabValue} index={6}>
        <CycleCountTable
          tabValue={tabValue}
          startDate={startDate}
          endDate={endDate}
        />
      </MDTabPanel>}
      {user && user.role !== "brand_admin" && (
        <MDTabPanel value={tabValue} index={2}>
          <ReportTables
            rowData={footfallDataRows}
            columnDefs={headerFootfallReport}
          />
        </MDTabPanel>
      )}
      {user && user.role !== "brand_admin" && (
        <MDTabPanel value={tabValue} index={3}>
          <ReportTables
            rowData={stockDataRows}
            columnDefs={headerStockReport}
          />
        </MDTabPanel>
      )}
      {/* <MDTabPanel value={tabValue} index={5}>
        <ReportTables rowData={eventDataRows} columnDefs={headerEventReport} />
      </MDTabPanel> */}
      {/* <MDTabPanel value={tabValue} index={6}>
        <ReportTables rowData={gmvDataRows} columnDefs={headerGMVREPORT} />
      </MDTabPanel> */}
    </>
  );
}
