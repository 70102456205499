import { Box, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful';
import { position } from 'stylis';

export default function ColorPicker({color, setColor, label="Color Picker", style}) {
  const [open, setOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (!event.target.closest('.color-picker')) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const body = document.body;
    if (open) {
      body.addEventListener('click', handleClickOutside);
    } else {
      body.removeEventListener('click', handleClickOutside);
    }

    return () => {
      body.removeEventListener('click', handleClickOutside); // Cleanup on unmount
    };
  }, [open]);

  const handleChangeColor = (color) => {
    setColor(color);
    // setOpen(false);
  }
  return (
  <Box sx={{position:'relative', width:'100%', ...style}} className="color-picker">
    <TextField sx={{ height:'100%', ".MuiInputBase-root":{height:'100%'}}} fullWidth value={color} label={label} onChange={(e)=>setColor(e.target.value)}
        onClick={()=>setOpen(true)}
        InputProps={{
            endAdornment: (
              <InputAdornment sx={{height:'100%', width:'5%'}} position="end">
                <Box sx={{ height:'100%', my:'auto', width:'100%',borderRadius:'20%', backgroundColor:color, border:1, borderColor:'light.main'}} />
              </InputAdornment>
            ),
          }}
        />
    {open && <HexColorPicker style={{position:'absolute', width: '100%', zIndex:1}} color={color} onChange={handleChangeColor} />}
  </Box>
  )
}
