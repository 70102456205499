import React, { useContext, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { GET_SALES_SUMMARY } from "services/report-service";
import { reportServiceClient } from "graphql/client";
import { ReportsFilter } from ".";
import { useLazyQuery } from "@apollo/client";

// Custome Libraries
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Setting up default timezone for dayjs operations
dayjs.extend(utc);
dayjs.extend(timezone);

const getColor = (label) => {
  switch (label) {
    case "Total No of Orders":
      return "#9c27b0";
    case "Total Quantity Sold":
      return "#EA5F3D";
    case "Total Quantity Returned":
      return "#EA5F3D";
    case "Total Visitors":
      return "#9349FB"
    case "Total Sales":
      return "#D99541";
    case "Total Return Value":
      return "#D99541";
    case "Average Order Value":
      return "#EBCA43";
    case "Basket Size":
      return "#4A7BC1";
    case "Credit Note Sale":
      return "#8DD9BB";
    case "Total Unique Customer":
      return "#8BC256";
    default:
      return "#000";
  }
};

// Stateless component for rendering individual statistic card
const StatCard = ({ label, value, fromTime, toTime, tabValue }) => {
  const color = getColor(label); // Get color dynamically based on label

  return (
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <Box
        sx={{
          // p: 2,
          textAlign: "center",
          backgroundColor: "#f9f9f9",
          // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
          height: "60px",
          width: tabValue === 0 || tabValue === 1 ? "140px" : "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: "10px",
        }}
      >
        {tabValue !== 3 ? (
          <>
            <Typography
              fontSize="12px"
              fontFamily="Montserrat"
              fontWeight="400"
            >
              {label}
              {fromTime ? "(From: " + fromTime + " - To: " + toTime + ")" : ""}
            </Typography>
            <Typography
              fontSize="12px"
              fontFamily="Montserrat"
              sx={{ color: color, fontWeight: 600 }}
            >
              {value}
            </Typography>{" "}
          </>
        ) : (
          <>
            <Typography fontSize="12px" fontWeight={"700"} fontFamily="Montserrat" textAlign={"left"} ml={2}>
              {label}
              <span style={{ fontWeight: 400 }}>{fromTime
                ? "(From: " + fromTime + " - To: " + toTime + ")"
                : ""}{" "}<br /></span>
              
              <span style={{ color: color, fontWeight: 600 }}>{value}</span>
            </Typography>
            {/* <Typography fontSize="12px" fontFamily="Montserrat" sx={{ color: color, fontWeight: 600 }}>
                    {value}
                </Typography> */}
          </>
        )}
      </Box>
    </Grid>
  );
};

// Main component to render all statistic cards
const StatsCards = ({ displayData }) => {
  const {
    getStockReport,
    tabValue,
    startDate,
    endDate,
    selectedBrand,
    selectedStore,
  } = useContext(ReportsFilter);

  const [getSalesQuantity, { loading: salesLoading, data: salesData }] =
    useLazyQuery(GET_SALES_SUMMARY, {
      client: reportServiceClient,
    });

  useEffect(() => {
    if (tabValue === 0 || tabValue === 1) {
      getSalesQuantity({
        variables: {
          startDate: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss"),
          orderType:
            tabValue === 0 ? "PURCHASE" : tabValue === 1 ? "RETURN" : "",
          filter: {
            storeId: selectedStore,
            brandId: selectedBrand,
          },
        },
      });
    }
  }, [
    tabValue,
    startDate,
    endDate,
    selectedBrand,
    selectedStore,
    getSalesQuantity,
  ]);
  const statsData = salesData?.getSalesSummary
    ? [
        {
          label: "Total No of Orders",
          value: salesData?.getSalesSummary.totalOrders || 0,
        },
        {
          label:
            tabValue === 0 ? "Total Quantity Sold" : "Total Quantity Returned",
          value: salesData?.getSalesSummary.totalQtySold || 0,
        },
        {
          label: tabValue === 0 ? "Total Sales" : "Total Return Value",
          value: salesData?.getSalesSummary.totalNetSalesValue || 0,
        },
        ...(tabValue === 0
          ? [
              {
                label: "Average Order Value",
                value: parseFloat(salesData?.getSalesSummary.totalNetSalesValue / salesData?.getSalesSummary.totalOrders).toFixed(2) || salesData?.getSalesSummary.avgOrderValue || 0,
              },
              {
                label: "Total Unique Customer",
                value: salesData?.getSalesSummary.uniqueCustomerCount || 0,
              },
              {
                label: "Basket Size",
                value: salesData?.getSalesSummary.basketSize || 0,
              },
              // {
              //   label: "Footfall",
              //   value: salesData?.getSalesSummary.footfall || 0,
              // },
              {
                label: "Credit Note Sale",
                value:
                  salesData?.getSalesSummary?.creditNoteSale?.find(
                    (item) => item?.moptype === "CNR"
                  )?.totalmopvalue || 0,
              },
            ]
          : []),
      ]
    : [
        // If no data yet, use default/fallback values
        { label: "Total No of Orders", value: 0 },
        { label: "Total Quantity Sold", value: 0 },
        { label: "Total Sales", value: 0 },
        { label: "Average Order Value", value: 0 },
        { label: "Total Unique Customer", value: 0 },
        { label: "Basket Size", value: 0 },
        { label: "Credit Note Sale", value: 0 },
      ];
  return (
    <>
      {tabValue === 0 || tabValue === 1
        ? statsData.map(({ label, value }, index) => (
            <StatCard
              key={index}
              label={label}
              value={value}
              tabValue={tabValue}
            />
          ))
        : displayData.map(({ label, value, fromTime, toTime }, index) => (
            <StatCard
              key={index}
              label={label}
              value={value}
              tabValue={tabValue}
              fromTime={fromTime}
              toTime={toTime}
            />
          ))}
    </>
  );
};
export default StatsCards;
