import { gql } from "@apollo/client";

export const GET_SECTION_LISTING = gql `
query getSection($input: GetSectionsInput!) {
  sections(input: $input) {
    total
    results {
      id
      internalName
      name
      displayOrder
      isDeleted
      storeId
      createdAt
      updatedAt
      sectionMetaData {
        id
        key
        type
        value
        isDeleted
        createdAt
        updatedAt
      }
      component {
        id
        name
        displayOrder
        product
        componentMetaData {
          id
          key
          value
          isDeleted
          createdAt
          updatedAt
        }
        isDeleted
        createdAt
        updatedAt
      }
      brandId
      page
      status
      updatedByUser {
        id
        name
        username
      }
    }
  }
}
`;

export const CREATE_SECTION = gql `
  mutation createSection($createSectionInput: CreateSectionInput!) {
    createSection(createSectionInput: $createSectionInput) {
      id
      internalName
      name
      displayOrder
      isDeleted
      page
      updatedAt
      createdAt
      updatedByUser {
        id
        username
        name
      }
    }
  }
`;

export const DELETE_SECTION = gql `
  mutation deleteSection($id: String!) {
    removeSection(id: $id) {
      id
    }
  }
`;

export const UPDATE_SECTION = gql `
  mutation updateSection($input: UpdateSectionInput!) {
    updateSection(updateSectionInput: $input) {
      id
    }
  }
`;

export const UPDATE_SECTION_METADATA = gql `
  mutation updateSectionMetaData($input: UpdateSectionsMetadatumInput!) {
    updateSectionsMetadatum(updateSectionsMetadatumInput: $input) {
      id
    }
  }
`;

export const CREATE_SECTION_METADATA = gql `
  mutation createSectionMetaData($input: CreateSectionsMetadatumInput!) {
    createSectionsMetadatum(createSectionsMetadatumInput: $input) {
      id
    }
  }
`;

export const REMOVE_SECTION_METADATA = gql `
  mutation removeSectionMetaData($id: String!) {
    removeSectionsMetadatum(id: $id) {
      id
    }
  }
`;