import React, { useContext, useEffect, useRef, useState } from "react";

// Custom Context
import { AuthContext, useNotification } from "context";

// Custom Components
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "../../components/MDButton";

// @mui material components
import {
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Divider,
  Button,
} from "@mui/material";

// Images & Icon
import { Add as AddIcon, Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Custom/Additional Components
import BrandListingPage from "./BrandListingPage";
import BrandDetailsTab from "./form";
import BrandForm from "./newCompanyForm";
import "./style.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogBox = ({
  title,
  description,
  handleSave,
  status,
  setStatus,
  handleDontSave,
  dirtyData,
  showDialog,
}) => {
  const handleOpen = () => {
    setStatus(true);
  };

  const handleClose = () => {
    setStatus(false);
  };
  return (
    <Dialog
      open={status}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <Grid sx={{ paddingX: "25px" }}>
        <DialogTitle
          style={{
            color: "black",
            fontFamily: "Montserrat",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000000",
          }}
        >
          <Close />
        </IconButton>
        <Divider />
        <DialogContent style={{ maxWidth: "350px" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              color: "black",
              fontFamily: "Montserrat",
              textAlign: "center",
            }}
          >
            {description}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <MDButton
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "black",
              fontFamily: "Montserrat",
              borderRadius: "50px",
              border: "1px solid black",
              width: "50%",
            }}
            onClick={handleDontSave}
          >
            Don't Save
          </MDButton>
          <MDButton
            variant="contained"
            style={{
              backgroundColor: "black",
              color: "white",
              fontFamily: "Montserrat",
              borderRadius: "50px",
              width: "50%",
            }}
            onClick={() => {
              handleSave();
              handleClose();
            }}
          >
            Save
          </MDButton>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

function Brands() {
  const { user } = useContext(AuthContext);
  const { setNotification } = useNotification();
  const [showBrandsDetails, setShowBrandsDetails] = useState(false);
  const [openNewCompanyForm, setOpenNewCompanyForm] = useState(false);
  const [brandData, setBrandData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [formTabValue, setFormTabValue] = useState(0);
  const [skipDirtyValidation, setSkipDirtyValidation] = useState(false);
  const [tempTabValue, setTempTabValue] = useState(0);
  const [dirtyData, setDirtyData] = useState(false);

  const companyFormSubmitRef = useRef();

  const handleTabChange = (_event, newValue) => {
    setShowDialog(false);
    if (skipDirtyValidation) {
      setShowDialog(false);
      setFormTabValue(newValue);
    } else if (dirtyData) {
      setShowDialog(true);
    } else {
      setFormTabValue(newValue);
    }
    setSkipDirtyValidation(false);
  };
  const handleDontSave = () => {
    setShowDialog(false);
    setFormTabValue(tempTabValue);
    setDirtyData(false);
  };

  const handleSave = () => {
    switch (formTabValue) {
      case 0:
        if (
          !Object.keys(companyFormSubmitRef.current.validateFormCompanyDetail())
            .length
        ) {
          companyFormSubmitRef.current.handleSubmitCompanyDetail();
          setDirtyData(false);
          setShowDialog(false);
          setFormTabValue(tempTabValue);
        } else {
          console.log("Form not valid");
        }
        break;
      case 1:
        if (
          !Object.keys(
            companyFormSubmitRef.current.validateFormCompanyVerification()
          ).length
        ) {
          companyFormSubmitRef.current.handleSubmitCompanyVerification();
          setDirtyData(false);
          setShowDialog(false);
          setFormTabValue(tempTabValue);
        } else {
          console.log("Form not valid");
        }
        break;
      case 2:
        if (
          !Object.keys(companyFormSubmitRef.current.validateFormContactDetail())
            .length
        ) {
          companyFormSubmitRef.current.handleSubmitContactDetail();
          setDirtyData(false);
          setShowDialog(false);
          setFormTabValue(tempTabValue);
        } else {
          console.log("Form not valid");
        }
        break;
      case 3:
        if (
          !Object.keys(companyFormSubmitRef.current.validateFormBankDetail())
            .length
        ) {
          companyFormSubmitRef.current.handleSubmitBankDetail();
          setDirtyData(false);
          setShowDialog(false);
          setFormTabValue(tempTabValue);
        } else {
          console.log("Form not valid");
        }
        break;
      // default:
      // setDirtyData(false)
      // setShowDialog(false)
      // setFormTabValue(tempTabValue)
      // break;
    }
    // setDirtyData(false)
    // setShowDialog(false)
    // setFormTabValue(tempTabValue)
  };

  const handleBrandExport = async (_event) => {
    // Brand Export Logic
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/dashboard-service/brands/export`
        : `/dashboard-service/brands/export`;
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `brand_report_${Math.floor(Date.now() / 1000)}.xlsx`; // Use epoch timestamp for filename
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        throw new Error("Failed to download template");
      }
    } catch (error) {
      console.error("Error while exporting brands", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Error while exporting brands",
      });
    }
  };

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              alignItems: "baseline",
            }}
          >
            <MDTypography
              sx={{
                fontFamily: "montserrat",
                color: "#000",
                fontWeight: "700",
              }}
              ml={1}
            >
              Brands
            </MDTypography>
            {user &&
              ["admin", "broadway-rm"].includes(user?.role) &&
              !showBrandsDetails && (
                <Grid item xs={12} sm={6} md={6} textAlign="right">
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <MDButton
                      type="button"
                      variant="contained"
                      color="dark"
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        fontWeight: 400,
                      }}
                      circular={true}
                      startIcon={<AddIcon />}
                      onClick={handleBrandExport}
                    >
                      Export
                    </MDButton>

                    <MDButton
                      type="button"
                      variant="contained"
                      color="dark"
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        fontWeight: 400,
                      }}
                      circular={true}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setBrandData(null);
                        setOpenNewCompanyForm(true);
                      }}
                    >
                      New Company
                    </MDButton>
                  </MDBox>
                </Grid>
              )}
          </MDBox>
        </Grid>
      </Grid>
      {user && ["admin", "broadway-rm"].includes(user.role) ? (
        <MDBox
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          {showBrandsDetails ? (
            <>
              <IconButton
                aria-label="Go Back"
                onClick={() => {
                  setShowBrandsDetails(false);
                  setFormTabValue(0);
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
                >
                  Back
                </Typography>
              </IconButton>
              <BrandDetailsTab
                setSkipDirtyValidation={setSkipDirtyValidation}
                brandData={brandData}
                setShowDialog={setShowDialog}
                formTabValue={formTabValue}
                setFormTabValue={setFormTabValue}
                handleTabChange={handleTabChange}
                setTempTabValue={setTempTabValue}
                setDirtyData={setDirtyData}
                ref={companyFormSubmitRef}
              />
            </>
          ) : (
            <BrandListingPage
              setShowBrandForm={(status) => setOpenNewCompanyForm(status)}
              setBrandData={(brandData) => setBrandData(brandData)}
              setShowBrandsDetails={setShowBrandsDetails}
            />
          )}
        </MDBox>
      ) : (
        <MDBox
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <BrandDetailsTab
            setSkipDirtyValidation={setSkipDirtyValidation}
            setShowDialog={setShowDialog}
            formTabValue={formTabValue}
            setFormTabValue={setFormTabValue}
            handleTabChange={handleTabChange}
            setTempTabValue={setTempTabValue}
            setDirtyData={setDirtyData}
            ref={companyFormSubmitRef}
          />
        </MDBox>
      )}
      <DialogBox
        title={"Alert"}
        description={"There are unsaved data, do you want to save the changes"}
        status={showDialog}
        setStatus={setShowDialog}
        handleDontSave={handleDontSave}
        handleSave={handleSave}
        showDialog={showDialog}
        dirtyData={dirtyData}
      />

      {openNewCompanyForm && (
        <BrandForm
          brandData={brandData}
          handleClose={() => setOpenNewCompanyForm(false)}
        />
      )}
    </DashboardLayout>
  );
}

export default Brands;
