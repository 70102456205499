import React, { useContext, useState } from "react";

// @mui material  components
import { Chip, Icon, IconButton } from "@mui/material";

// Apollo Client
import { useQuery } from "@apollo/client";
import { orderServiceClient } from "graphql/client";
import { GET_ORDER_LISTING } from "services/order-service";

// Material Dashboard 2 React Components
import Loader from "components/MDLoader";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images & Icons
// import { Add as AddIcon } from "@mui/icons-material";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";

// Custom Library
import dayjs from "dayjs";
import { AuthContext } from "context";
import { AgGridReact } from "ag-grid-react";
import { filter } from "jszip";

const columnDefs = [

  {
    field: "orderDate",
    headerName: "Order Date",
    valueGetter: (params) =>
      dayjs(
        params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillDate
      )?.format("DD-MM-YYYY"),
  },
  {
    field: "orderTime",
    headerName: "Order Time",
    valueGetter: (params) =>
      dayjs(
        params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillDate
      )?.format("HH:mm"),
  },
  {
    field: "id",
    headerName: "Order ID",
    valueGetter: (params) =>
      params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillId,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "genisysOrderId",
    headerName: "Ginesys Order ID",
    valueGetter: (params) =>
      params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillGUID,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "orderNumber",
    headerName: "POS Order ID",
    valueGetter: (params) =>
      params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillNo,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "id",
    headerName: "Customer ID",
    valueGetter: (params) => params?.data?.customer?.id || params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.customerId.split("-").reverse()[0].toUpperCase(),
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "customerMobile",
    headerName: "Customer Mobile",
    valueGetter: (params) => params?.data?.customer?.phone || params?.data?.posBill?.billingDetails?.POSCustomer?.[0]?.mobile,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "customerName",
    headerName: "Customer Name",
    valueGetter: (params) => params?.data?.customer?.name || `${params?.data?.posBill?.billingDetails?.POSCustomer?.[0]?.firstfame} ${params?.data?.posBill?.billingDetails?.POSCustomer?.[0]?.lastname}`,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "email",
    headerName: "Email",
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSCustomer?.[0]?.emailid,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },

  {
    field: "cashier",
    headerName: "Cashier Name",
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.CashierName,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },

  {
    field: "grandTotal",
    headerName: "Grand Total",
    valueGetter: (params) =>
      Math.round(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.GrossAmt),
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "mPromoAmt",
    headerName: "Cart Promotion",
    valueGetter: (params) =>
      Math.round(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.MPromoAmount),
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "mCouponAmt",
    headerName: "Coupon Discount",
    valueGetter: (params) =>
      Math.round(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.MCouponAmount),
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "lpDiscountAmt",
    headerName: "D4 - Loyalty Discount",
    valueGetter: (params) =>
      Math.round(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.LPDiscountAmt),
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "netAmt",
    headerName: "Net Total",
    valueGetter: (params) =>
      Math.round(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.NetAmt),
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "netPayable",
    headerName: "Paid Amount",
    valueGetter: (params) =>
      Math.round(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.NetPayable),
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  {
    field: "lpPointsEarned",
    headerName: "Loyalty Points Earned",
    valueGetter: (params) =>
      Math.round(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.LPPointsEarned),
    filterParams: {
      filters: [
        {
          filter: "agNumberColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },

  {
    field: "paymentMode",
    headerName: "Payment Mode",
    valueGetter: (params) =>
      params?.data?.posBill?.billingDetails?.POSBillMOP?.map((item) => item?.MOPName).join(", "),
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
        },
        {
          filter: "agSetColumnFilter",
        },
      ],
    },
  },
  

  // {
  //   field: "action",
  //   headerName: "Actions",
  //   filter: false,
  //   cellRenderer: (params) => {
  //     return (
  //       <IconButton aria-label="preview">
  //         <Icon component={previewIcon} fontSize="small" />
  //       </IconButton>
  //     );
  //   },
  // },
];

function OrderLandingPage({ onRowClick }) {
  const { user } = useContext(AuthContext);
  const [defaultColDef] = useState({
    sortable: true,
    filter: "agSetColumnFilter",
    resizable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    floatingFilter: true,
  });

  const { data, loading, error, refetch } = useQuery(GET_ORDER_LISTING, {
    client: orderServiceClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      brandId: user?.role === "brand_admin" ?? user?.userId,
    },
  });

  if (loading) return <Loader />;
  if (error)
    return (
      <>
        <MDTypography>Error fetching data</MDTypography>
        <MDButton onClick={refetch}>Retry</MDButton>
      </>
    );

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "85vh", width: "100%", marginTop: "20px" }}
    >
      <AgGridReact
        rowData={data?.ordersGet?.results || []}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        groupDisplayType={"groupRows"}
        rowGroupPanelShow={"always"} // Show row group panel
        enableCharts={true} // Enable chart visualizations
        enableRangeSelection={true} // Enable range selection for charts
        animateRows={true} // Smooth transitions
        sideBar={{ toolPanels: ["columns", "filters"] }}
        onRowClicked={onRowClick}
      />
    </div>
  );
}

export default OrderLandingPage;
