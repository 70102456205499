import React, { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormControl,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Custom Components
import PlaylistTable from "../playlistMaster/playlistTable";
import { DropDownSelectionContext } from "..";

// Images & Icons
import { KeyboardArrowDown } from "@mui/icons-material";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Utils
import { sumDurations, zipFiles } from "../utils";

// Custom Libraries
import dayjs from "dayjs";

// Apollo Client
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { vmServiceClient } from "graphql/client";

// GraphQL Queries & Mutations
import {
  LIST_ALL_PLAYLISTS,
  CREATE_PLAYLIST,
  MAP_DEVICE_BRAND_PLAYLIST,
  CREATE_MAP_DEVICE_BRAND_PLAYLIST,
  UPDATE_MAP_DEVICE_BRAND_PLAYLIST,
  REMOVE_MAP_DEVICE_BRAND_PLAYLIST,
} from "services/advertisment-service";
import { GET_ASSET_MAPPING_LIST_NEW } from "services/brand-service";

// Custom Context
import { useNotification } from "context";

// Custom Hooks
import useStoreSelection from "layouts/device-Master/storeSelectionHook";
import { AuthContext } from "context";
import Loader from "components/MDLoader";

const DeviceMapTable = ({
  brandFixtureDevice,
  device,
  selectedBrandId,
  brand,
  user,
}) => {
  const { setNotification } = useNotification();

  const [key, setKey] = useState(brandFixtureDevice?.uuid + Date.now());

  const { data: deviceBrandPlaylistData, refetch: refetchBrandPlaylist } =
    useQuery(MAP_DEVICE_BRAND_PLAYLIST, {
      client: vmServiceClient,
      variables: {
        page: 1,
        limit: 100,
        relations: ["mapDeviceFixtureBrands", "mapDeviceBrandPlaylistVideos"],
        filter: {
          mapDeviceFixtureBrands: {
            uuid: brandFixtureDevice?.uuid,
          },
          deviceId: device?.uuid,
        },
      },
    });

  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const deviceBrandPlaylistMutationOptions = {
    refetchQueries: [
      {
        query: MAP_DEVICE_BRAND_PLAYLIST,
        variables: {
          page: 1,
          limit: 100,
          relations: ["mapDeviceFixtureBrands", "mapDeviceBrandPlaylistVideos"],
          filter: {
            mapDeviceFixtureBrands: {
              uuid: brandFixtureDevice?.uuid,
            },
            deviceId: device?.uuid,
          },
        },
      },
    ],
  };

  const [createDeviceBrandPlaylistMutation] = useMutation(
    CREATE_MAP_DEVICE_BRAND_PLAYLIST,
    {
      client: vmServiceClient,
      ...deviceBrandPlaylistMutationOptions,
    }
  );

  const [updateDeviceBrandPlaylistMutation] = useMutation(
    UPDATE_MAP_DEVICE_BRAND_PLAYLIST,
    {
      client: vmServiceClient,
      ...deviceBrandPlaylistMutationOptions,
    }
  );

  const [removeDeviceBrandPlaylistMutation] = useMutation(
    REMOVE_MAP_DEVICE_BRAND_PLAYLIST,
    {
      client: vmServiceClient,
      ...deviceBrandPlaylistMutationOptions,
    }
  );

  const columns = [
    {
      field: "icon",
      width: 50,
      headerName: "",
    },
    {
      field: "name",
      headerName: "Name",
      width: 350,
      renderCell: (params) => {
        return `${device?.code}_${device?.deviceType}_${brand?.name}_${params?.row?.playlists?.[0]?.name}`;
      },
    },
    {
      field: "videosCount",
      headerName: "Videos",
      width: 150,
      align: "right",
      renderCell: (params) =>
        params?.row?.mapDeviceBrandPlaylistVideos?.length || 0,
    },
    {
      field: "startDateTime",
      headerName: "From time",
      width: 250,
      renderCell: (params) =>
        user && !["admin", "broadway-rm"].includes(user?.role) && false ? ( //remove this false to allow only admin to edit dates
          dayjs(params?.row?.fromTime).format("DD/MM/YYYY hh:mm a ")
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              sx={{ "& fieldset": { p: 0, border: 0 } }}
              onAccept={(value) =>
                handleDateTimeEdit({ fromTime: value }, params?.row)
              }
              value={dayjs(params?.row?.fromTime)}
              format="DD/MM/YYYY hh:mm a "
            />
          </LocalizationProvider>
        ),
    },
    {
      field: "endDateTime",
      headerName: "To time",
      width: 250,
      renderCell: (params) =>
        user && !["admin", "broadway-rm"].includes(user?.role) && false ? ( //remove this false to allow only admin to edit dates
          dayjs(params?.row?.toTime).format("DD/MM/YYYY hh:mm a ")
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              sx={{ "& fieldset": { p: 0, border: 0 } }}
              onAccept={(value) =>
                handleDateTimeEdit({ toTime: value }, params?.row)
              }
              value={dayjs(params?.row?.toTime)}
              format="DD/MM/YYYY hh:mm a "
            />
          </LocalizationProvider>
        ),
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 100,
      align: "right",
      renderCell: (params) => {
        return (
          sumDurations(
            params?.row?.mapDeviceBrandPlaylistVideos?.map(
              (ov) => ov?.video?.duration
            )
          ) || 0
        );
        // return 0;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        if (user && !["admin", "broadway-rm"].includes(user?.role)) {
          return (
            <Chip
              label={params.row.isActive ? "Active" : "Inactive"}
              color={params.row.isActive ? "success" : "error"}
              size="small"
            />
          );
        }

        return (
          <FormControl fullWidth>
            <Select
              onChange={(e) =>
                handleStatusChange(params.row.uuid, e.target.value)
              }
              defaultValue={params.row.isActive}
              value={params.row.isActive}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 6,
                height: 32,
              }}
              id="status-select"
              IconComponent={() => (
                <KeyboardArrowDown
                  sx={{ fontSize: 24, width: 24, height: 24 }}
                />
              )}
            >
              <MenuItem value={true}>ACTIVE</MenuItem>
              <MenuItem value={false}>INACTIVE</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <Box sx={{ "& button": { mr: 1 } }}>
          <IconButton
            aria-label="edit"
            onClick={() =>
              handlePlaylistEditClick(
                params.row?.mapDeviceBrandPlaylistVideos?.map(
                  (plv) => plv.video
                )
              )
            }
          >
            <Icon component={previewIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="save"
            disabled
            sx={{ opacity: 0.25 }}
            onClick={() => alert("Clicked")}
          >
            <Icon component={commentIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="download"
            onClick={() => handlePlaylistDownloadClick(params.row)}
          >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handlePlaylistDeleteClick(params.row, params.parent)}
          >
            <Icon component={deleteIcon} fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const videoTableColumns = [
    { field: "dragHandle", headerName: "", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 350,
    },
    {
      width: 650,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 100,
      align: "right",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            color={
              params.value === "APPROVED"
                ? "success"
                : params.value === "PENDING"
                ? "warning"
                : "error"
            }
            size="small"
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params, parent) => (
        <Box sx={{ "& button": { mr: 1 } }}>
          <IconButton
            aria-label="edit"
            onClick={() => window.open(params.row.publicUrl, '_blank')}
          >
            <Icon component={previewIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="save"
            disabled
            sx={{ opacity: 0.25 }}
            onClick={() => alert("Clicked")}
          >
            <Icon component={commentIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="download"
            onClick={() =>
              handleVideoDownloadClick(params.row.publicUrl, params.row.name)
            }
          >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() =>
              handleVideoDeleteClick(params?.parent, params?.row?.uuid)
            }
          >
            <Icon component={deleteIcon} fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleVideoDownloadClick = (videoUrl, videoName) => {
    // This creates a new anchor element and triggers a download
    const link = document.createElement("a");
    link.href = videoUrl;
    link.download = videoName; // You can set the default file name for the download here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleVideoDeleteClick = (mapDeviceBrandPlaylist, videoId) => {
    updateDeviceBrandPlaylistMutation({
      variables: {
        input: {
          uuid: mapDeviceBrandPlaylist?.uuid,
          videos: mapDeviceBrandPlaylist?.mapDeviceBrandPlaylistVideos
            ?.map((ov) => ov?.video?.uuid)
            ?.filter((id) => id != videoId),
        },
      },
    });
  };

  const handleStatusChange = (uuid, isActive) => {
    updateDeviceBrandPlaylistMutation({
      variables: {
        input: {
          uuid,
          isActive,
        },
      },
    });
  };

  const handlePlaylistEditClick = (videos) => {
    const newTab = window.open("", "_blank");
    newTab.document.body.innerHTML = `
              <div">
              <video id="videoPlayer" controls autoplay style="width: 100vw; height: 80vh;">
                  <source src="${videos[0].publicUrl}" type="video/mp4">
                  Your browser does not support the video tag.
              </video>
              <div id="videoList" style=" display:flex; padding:16px; align-items:center; width: 100vw; padding-left: 20px; height:auto; flex-wrap:wrap; overflow:auto">
                  ${videos
                    .map(
                      (video, index) => `
                  <h4 id="videoItem${index}" style="margin:0 20px; cursor: pointer;">
                      ${video.name}
                  </h4>`
                    )
                    .join("=>")}
              </div>
              </div>
          `;

    const videoPlayer = newTab.document.getElementById("videoPlayer");
    let currentIndex = 0;

    const updateHighlight = (index) => {
      videos.forEach((_, i) => {
        const videoItem = newTab.document.getElementById(`videoItem${i}`);
        videoItem.style.fontWeight = i === index ? "bold" : "normal";
        videoItem.style.color = i === index ? "blue" : "black";
      });
    };

    const playVideo = (index) => {
      if (index < videos.length) {
        videoPlayer.src = videos[index].publicUrl;
        videoPlayer.play();
        currentIndex = index;
        updateHighlight(currentIndex);
      }
    };

    videoPlayer.onended = () => {
      currentIndex++;
      playVideo(currentIndex);
    };

    videos.forEach((_, index) => {
      newTab.document.getElementById(`videoItem${index}`).onclick = () => {
        playVideo(index);
      };
    });

    // Initialize with the first video highlighted
    updateHighlight(currentIndex);

    newTab.focus();
  };

  const handlePlaylistDownloadClick = (playlistRow) => {
    zipFiles(
      playlistRow?.playlists?.[0]?.name,
      playlistRow?.mapDeviceBrandPlaylistVideos
        ?.map((plv) => ({
          url: plv?.video?.publicUrl,
          name: plv?.video?.name,
          order: plv?.sortOrder || plv?.order,
          status: plv?.video?.status,
        }))
        ?.filter((video) => video.status == "APPROVED")
    );
  };

  const handlePlaylistDeleteClick = (row) => {
    removeDeviceBrandPlaylistMutation({
      variables: {
        id: row?.uuid,
      },
    });
  };

  const handleDateTimeEdit = (payload, row) => {
    updateDeviceBrandPlaylistMutation({
      variables: {
        input: {
          uuid: row?.uuid,
          mapDeviceFixtureBrandId: row?.mapDeviceFixtureBrands?.uuid,
          ...payload,
        },
      },
      onError: (err) => {
        setNotification({
          color: "error",
          isVisible: true,
          message: `${capitalizeFirstLetter(err.message)}`,
        });
        setKey(brandFixtureDevice?.uuid + Date.now());
      },
    });
  };

  const [createPlaylist] = useMutation(CREATE_PLAYLIST, {
    client: vmServiceClient,
    fetchPolicy: "network-only",
    refetchQueries: [
      {
        query: LIST_ALL_PLAYLISTS,
        variables: {
          page: 1,
          limit: 100,
          sortBy: "updatedAt",
          order: "DESC",
          relations: ["orderedVideos"],
        },
      },
    ],
  });

  const handleNewPlaylist = async ({ name, selectedVideos, brandId }) => {
    // if (isSubmitting) return; // Prevent multiple submissions
    // setIsSubmitting(true);

    const payload = {
      brandId: selectedBrandId,
      name: name,
      videos: selectedVideos,
    };
    const createPlaylistResult = await createPlaylist({
      variables: {
        input: {
          // startDateTime: playlist.startTime,
          // endDateTime: playlist.endTime,
          // deviceIds: [], // Populate as necessary
          name: payload.name,
          brandId,
          status: "PENDING",
          VideoIds: payload.videos
            ? payload.videos.map((video) => video.uuid)
            : [], // Populate as necessary
          // duration:payload.duration || 0
        },
      },
    });
    createDeviceBrandPlaylistMutation({
      variables: {
        input: {
          fromTime: dayjs(brandFixtureDevice?.start),
          toTime: dayjs(brandFixtureDevice?.end),
          playlistId: createPlaylistResult?.data?.createPlaylist?.uuid,
          mapDeviceFixtureBrandId: brandFixtureDevice?.uuid,
          deviceId: device?.uuid,
          isActive: false,
        },
      },
    });
  };

  const handleAddPlaylist = (selectedPlaylist) => {
    createDeviceBrandPlaylistMutation({
      variables: {
        input: {
          fromTime: dayjs(brandFixtureDevice?.start),
          toTime: dayjs(brandFixtureDevice?.end),
          playlistId: selectedPlaylist?.uuid,
          mapDeviceFixtureBrandId: brandFixtureDevice?.uuid,
          deviceId: device?.uuid,
          isActive: false,
          videos: [],
        },
      },
      onError: (error) => {
        console.error(error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      },
    });
  };

  const handleAddVideo = (video, row) => {
    updateDeviceBrandPlaylistMutation({
      variables: {
        input: {
          uuid: row?.uuid,
          videos: [
            ...row?.mapDeviceBrandPlaylistVideos?.map((ov) => ov?.video?.uuid),
            video?.uuid,
          ],
        },
      },
    });
  };

  const handleVideoRearrange = (videoList, row) => {
    const videos = videoList.map((video) => video.uuid);
    updateDeviceBrandPlaylistMutation({
      variables: {
        input: {
          uuid: row?.uuid,
          videos: videos,
        },
      },
    });
  };

  return (
    <PlaylistTable
      key={key}
      columns={columns}
      handleNewPlaylist={handleNewPlaylist}
      handleAddPlaylist={handleAddPlaylist}
      handleVideoRearrange={handleVideoRearrange}
      handleAddVideo={handleAddVideo}
      rows={
        deviceBrandPlaylistData?.findAllMapDeviceBrandPlaylists?.results || []
      }
      rowTotal={
        deviceBrandPlaylistData?.findAllMapDeviceBrandPlaylists?.total || 0
      }
      refetch={() => {}}
      selectedBrandId={selectedBrandId}
      videoTableColumns={videoTableColumns}
      videoListKey="mapDeviceBrandPlaylistVideos"
      getPlaylistUuids={(rows) => rows?.map((row) => row?.playlists?.[0]?.uuid)}
    />
  );
};

export default function AssignedDevicesPage() {
  const { selectedBrandId, user, Brand, selectedStoreId } = useContext(
    DropDownSelectionContext
  );

  const {
    loading,
    error,
    data: brandDeviceFixtureData,
  } = useQuery(GET_ASSET_MAPPING_LIST_NEW, {
    client: vmServiceClient,
    variables: {
      page: 1,
      limit: 100,
      relations: ["fixtures", "fixtures.devices"],
      filter: {
        brandId: selectedBrandId,
        fixtures: {
          storeId: selectedStoreId,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const { storeIdMap } = useStoreSelection();
  if(loading) return <Loader/>
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "70vh",
        py: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {brandDeviceFixtureData?.findAllMapDeviceFixtureBrands?.results?.map(
        (brandDeviceFixture) => {
          const brand = Brand;
          const store =
            storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.storeId];
          const location =
            storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.locationId];
          const sublocation =
            storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.subLocationId];
          const assetType =
            storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.subLocationTypeId];

          return (
            <Accordion key={brandDeviceFixture?.id}>
              <AccordionSummary
                sx={{ backgroundColor: "#EFF4F9" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel1-content-${brandDeviceFixture.code}`}
                id={`panel1-header-${brandDeviceFixture.code}`}
              >
                <Typography>{`${brand?.name}${
                  store?.name ? " -> " + store.name : ""
                }${location?.name ? " -> " + location.name : ""}${
                  sublocation?.name ? " -> " + sublocation.name : ""
                }${
                  assetType?.name ? " -> " + assetType.name : ""
                } : Start Date - ${dayjs(brandDeviceFixture?.start)?.format(
                  "DD-MM-YYYY"
                )} | End Date - ${dayjs(brandDeviceFixture?.end)?.format(
                  "DD-MM-YYYY"
                )}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {brandDeviceFixture?.fixtures
                  ?.flatMap((fixture) => fixture?.devices)
                  .map((device) => {
                    return (
                      <Box
                        key={device?.id}
                        sx={{
                          borderRadius: 2,
                          my: 2,
                          overflow: "hidden",
                          boxShadow: "0 2px 0 0 rgb(200,200,200)",
                          border: 1,
                          borderColor: "#FAFAFA",
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: "#EFF4F9",
                            py: 1,
                            px: 4,
                            display: "flex",
                            fontSize: 14,
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ fontWeight: 600 }}>
                            {device?.code},{" "}
                            {device?.isShared ? "Shared" : "Dedicated"} -{" "}
                            {device?.deviceType}, {device?.screenResolution},{" "}
                            {device?.orientation == "HORIZONTAL"
                              ? "16:9"
                              : "9:16"}
                          </Box>
                          {/* {false && (
                            <Button sx={{ textDecoration: "underline" }}>
                              View Screenshots
                            </Button>
                          )} */}
                        </Box>
                        <Box sx={{ overflowX: "auto" }}>
                          <DeviceMapTable
                            selectedBrandId={selectedBrandId}
                            device={device}
                            brand={brand}
                            brandFixtureDevice={brandDeviceFixture}
                            user={user}
                          />
                        </Box>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          );
        }
      )}
    </Box>
  );
}
