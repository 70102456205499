import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { gql, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import { Grid } from "@mui/material";

const GET_SIGNED_URL = gql`
  query getSignedUrl($getSignedUrlRequest: GetSignedUrlInput!) {
    getSignedUrl(getSignedUrlRequest: $getSignedUrlRequest) {
      url
      readUrl
    }
  }
`;

export default function ImageUploaderContainer({
  preLoadedFile,
  isMultiple,
  signedUrlCallback,
  fieldName,
  isFieldValid,
  uploadButton=null,
  keyPrefix=''
}) {
  const [componentKey, setComponentKey] = useState(Date.now());

  const preFileInstances =
    (preLoadedFile &&
      preLoadedFile.map((file, index) => ({ id: index, fileName: file }))) ||
    [];
  const stateValue = isMultiple
    ? [...preFileInstances, { id: Date.now() }]
    : preFileInstances.length
    ? [...preFileInstances]
    : [{ id: Date.now() }];

  const [fileInstances, setFileInstances] = useState(stateValue);

  const addNewInstance = () => {
    isMultiple && setFileInstances([...fileInstances, { id: Date.now() }]);
  };



  const deleteInstance = (idToDelete) => {
    setFileInstances(
      fileInstances.filter((instance) => instance.id !== idToDelete)
    );
  };

  return (
    <div key={componentKey}>
      {fileInstances.map((instance, index) => (
        <Grid container key={instance.id}>
        <Grid item xs={10} sm={12} >
        <FileUploader
            id={keyPrefix+"-"+index}
            instance={instance}
            addNewInstance={addNewInstance}
            deleteInstance={deleteInstance}
            // getSignedUrl={getSignedUrl}
            // signedUrl={data?.getSignedUrl?.url}
            // signedReadUrl={data?.getSignedUrl?.readUrl}
            signedUrlCallback={signedUrlCallback}
            fieldName={fieldName}
            isMultiple={isMultiple}
            isFieldValid={isFieldValid}
            uploadButton={uploadButton}
            setComponentKey={setComponentKey}
          />
        </Grid>
        </Grid>
      ))}
    </div>
  );
}

function FileUploader({
  addNewInstance,
  deleteInstance,
  id,
  // getSignedUrl,
  // signedUrl,
  // signedReadUrl,
  instance,
  signedUrlCallback,
  fieldName,
  isMultiple,
  isFieldValid,
  uploadButton,
  setComponentKey
}) {
  const { setNotification } = useNotification();

  const [fileName, setFileName] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploaded, setIsUploaded] = useState(
    instance.fileName ? true : false
  );
  const [fileData, setFileData] = useState(null);
  const [imageMetadata, setImageMetadata] = useState(null);
  const [axiosCallMade, setAxiosCallMade] = useState(false);

  const fileInputId = `${fieldName}-fileInput-${id}`;

  const [getSignedUrl, { data }] = useLazyQuery(GET_SIGNED_URL, {
    client: dashboardServiceClient,
  });

  useEffect(() => {
    if (data?.getSignedUrl?.url && fileName && !axiosCallMade) {
      const uploadFile = async () => {
        await axios.put(data?.getSignedUrl?.url, fileData, {
          headers: { "Content-Type": "application/octet-stream" },
        });
        setUploadProgress(66);
        setAxiosCallMade(true);
        getSignedUrl({
          variables: {
            getSignedUrlRequest: {
              fileName: `dashboard/staging/news/${fileName}`,
              action: "read",
              contentType: "application/octet-stream",
            },
          },
        });
        addNewInstance();
        setIsUploaded(true);
        setUploadProgress(100);
      };
      uploadFile();
    }
  }, [data?.getSignedUrl?.url, fileName]);

  useEffect(() => {
    if (isUploaded && data?.getSignedUrl?.url && fileName && imageMetadata) {
      signedUrlCallback(`dashboard/staging/news/${fileName}`, fileName, data?.getSignedUrl?.readUrl, imageMetadata);
      setComponentKey(Date.now());
    }
  }, [isUploaded, data?.getSignedUrl?.url, imageMetadata]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setNotification({
        color: "warning",
        isVisible: true,
        message: "Selected file format is not allowed.",
      });
      return false;
    }
    if (file.size > 5 * 1024 * 1024) {
      setNotification({
        color: "warning",
        isVisible: true,
        message: "File size exceeds the 5MB limit.",
      });
      return false; 
    }
    if (file) {
      

      const loadImage = (file) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = URL.createObjectURL(file);
        });
      };
      try {
        const img = await loadImage(file);
        
        const dimensions = `${img.width} X ${img.height}`;
  
        // Log or use the results
        
  
        // You can also display this information in the DOM if needed
        setImageMetadata({
          dimension: dimensions,
          format: file.type.split('/')[1],
          size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
        })
        setFileName(file.name);
      setFileData(e.target.files[0]);
        // Clean up the object URL
        URL.revokeObjectURL(img.src);
      } catch (error) {
        console.error('Error loading image:', error);
      }
      
      setUploadProgress(40);
      getSignedUrl({
        variables: {
          getSignedUrlRequest: {
            fileName: `dashboard/staging/news/${file.name}`,
            action: "write",
            contentType: "application/octet-stream",
          },
        },
      });
      setAxiosCallMade(false);
    }
  };

  const handleDelete = () => {
    deleteInstance(instance.id);
    signedUrlCallback(null, id);
  };

  return (
    <Paper
      elevation={0}
      onClick={() =>
        !isUploaded && document.getElementById(fileInputId).click()
      }
      
    >
      <input
        type="file"
        id={fileInputId}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
      />
      {/* {isUploaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              height: "52px",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <img
              src="https://generation-sessions.s3.amazonaws.com/7c71c9772decb49fcc575053870ef5ff/img/tick-circle@2x.png"
              alt="tick"
              style={{ width: "24px", height: "24px" }}
            />
            <div
              style={{
                width: "299px",
                fontFamily: "Inter-Medium, Helvetica",
                fontWeight: 500,
                color: "#000000",
                fontSize: "14px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {fileName ||
                (instance.fileName
                  ? instance.fileName.split("/").reverse()[0]
                  : "")}
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
              gap: "8px",
              padding: "10px 16px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: '"Inter-Bold", Helvetica',
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#6a994e",
              }}
            >
              Uploaded
            </Typography>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <img
            src="https://generation-sessions.s3.amazonaws.com/df5a8cb33081b6df29ee38b7b81444bc/img/gallery-export@2x.png"
            alt="Gallery"
            style={{ width: "24px", height: "24px" }}
          />
          <Typography variant="body2">
            {fileName ? `Uploading ${fileName}` : fieldName}
          </Typography>
        </div>
      )} */}
      {fileName && !isUploaded && (
        <div
          style={{
            width: `${uploadProgress}%`,
            backgroundColor: "red",
            height: "5px",
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        ></div>
      )}
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          gap: "8px",
          padding: "10px 16px",
        }}
      >
        {/* {!isUploaded && (
          <img
            src="https://generation-sessions.s3.amazonaws.com/df5a8cb33081b6df29ee38b7b81444bc/img/icon.svg"
            alt="Icon"
            style={{ width: "16px", height: "16px" }}
          />
        )} */}
        {uploadButton!=null? 
        uploadButton :
        <Button
          sx={{ color: "#344767", pl: 0 }}
          onClick={
            isUploaded
              ? isMultiple
                ? handleDelete
                : () => {
                    document.getElementById(fileInputId).click();
                  }
              : null
          }
        >
          {isUploaded ? (isMultiple ? "DELETE" : "CHANGE") : "CHOOSE FILE"}
        </Button>}
      </div>
    </Paper>
  );
}
