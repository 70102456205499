import { Box, Button, Grid, colors } from "@mui/material";
import { useState } from "react";
import AddSingleProductDetails from "./AddSingleProductDetails";
import AddSingleProductVariantDetails from "./AddSingleProductVariantDetails";

const availableFormParts = {
  product: "Product",
  variant: "Variant",
};

const inactiveButtonStyle = {
  color: "#000000",
  "&:hover": {
    color: "#000000",
  },
  "&:focus": {
    color: "#000000 !important",
  },
};

const activeButtonStyle = {
  color: "#E93165",
  backgroundColor: "#fff",
  "&:hover": {
    color: "#E93165 !important",
    backgroundColor: "#fff !important",
  },
  "&:focus": {
    color: "#E93165 !important",
    backgroundColor: "#fff !important",
  },
};

export default function AddSingleProduct({ setForm }) {
  const [activeForm, setActiveForm] = useState(availableFormParts.product);

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#F0F2F5",
          pb: 2,
          pt: 2,
          pl: "10px",
          pr: "10px",
        }}
      >
        <Button
          sx={
            activeForm === availableFormParts.product
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onMouseDown={() => {
            setActiveForm(availableFormParts.product);
          }}
        >
          Product Details
        </Button>
        {/* <Button
          sx={{
            marginLeft: "10px",
            ...(activeForm === availableFormParts.variant
              ? activeButtonStyle
              : inactiveButtonStyle),
          }}
          onMouseDown={() => {
            setActiveForm(availableFormParts.variant);
          }}
        >
          Variant Details
        </Button> */}
      </Grid>

      <Box
        sx={{
          width: "100%",
          minHeight: "70vh",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        {activeForm === availableFormParts.product ? (
          <AddSingleProductDetails setForm={setForm} />
        ) : (
          <AddSingleProductVariantDetails />
        )}
      </Box>
    </>
  );
}
