import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import {
  Grid,
  Pagination as MuiPagination,
  Box,
  IconButton,
  Icon,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from "react";
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import dayjs from "dayjs";
import { REMOVE_BRAND_CATEGORY_STORE } from "services/brand-service";
import AgGrid from "components/MDGrid";
import { MAP_BRAND_STORE } from "services/brand-service";
import { REMOVE_BRAND_STORE } from "services/brand-service";
import { createOrderByFromOrderModel } from "layouts/cutsomer/utils";
import { useGridContext } from ".";
import { AuthContext } from "context";


const columns = (handleDeleteClick, user) => [
  {
    field: "store",
    headerName: "Store Name",
    filter: "agTextColumnFilter",
    width: 300,
    valueGetter: (params) => params?.data?.store?.name || "",
    getFilterObj: (value) => ({
      store: { name: value?.filter },
    }),
    getSortObj: (sortOrder) => ({ store: { name: sortOrder } }),
    pivot: true,
    enableValue: true,
  },
  {
    field: "start",
    headerName: "Start Date",
    width: 300,
    filter: "agDateColumnFilter",
    getFilterObj: (value) => ({ start: value?.dateFrom }),
    valueGetter: (params) =>
      params?.data?.start
        ? dayjs(params?.data?.start).format("DD-MM-YYYY")
        : "N/A",
  },

  {
    field: "end",
    headerName: "End Date",
    width: 300,
    filter: "agDateColumnFilter",
    getFilterObj: (value) => ({ end: value?.dateFrom }),
    valueGetter: (params) =>
      params?.data?.end ? dayjs(params?.data?.end).format("DD-MM-YYYY") : "N/A",
  },
  { field: "agreementValue", headerName: "Agreement Value" },
  { field: "termSheetId", headerName: "Term Sheet Id" },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["Active", "Upcoming", "Inactive"],
    },
    getFilterObj: (filter) => {
      return { status: filter.values?.map((val) => val?.toLowerCase()) };
    },
    cellRenderer: (params) => {
      const value = params.value;
      const capitalizeFirstLetter = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
      };
      return (
        <Chip
          label={capitalizeFirstLetter(value)}
          color={
            value === "active"
              ? "success"
              : value === "upcoming"
              ? "warning"
              : "error"
          }
          size="small"
        />
      );
    },
  },
  ...(user && ["admin", "broadway-rm"].includes(user?.role)
    ? [
        {
          field: "actions",
          headerName: "Actions",
          width: 140,
          filter: false,
          sortable: false,
          cellRenderer: (params) => (
            <IconButton aria-label="delete">
              <Icon
                component={deleteIcon}
                onClick={(e) => handleDeleteClick(e, params?.data?.id)}
                fontSize="small"
              />
            </IconButton>
          ),
        },
      ]
    : []),
];

function StoreAgreementLandingPage({ onRowClick, brandData }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { externalGridRef } = useGridContext();

  const { user } = useContext(AuthContext);

  const [fetchBrandStores] = useLazyQuery(MAP_BRAND_STORE, {
    client: dashboardServiceClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [removeBrandStore, { loading: removing, error: removeError }] =
    useMutation(REMOVE_BRAND_STORE, {
      client: dashboardServiceClient,
      refetchQueries: [
        {
          query: MAP_BRAND_STORE,
          variables: {
            listBrandStoreFilter: {
              take: 20,
              skip: 0,
              filter: {
                brand: { id: brandData ? brandData?.id : user.userId },
                isDeleted: false,
              },
            },
          },
          fetchPolicy: "network-only",
        },
      ],
      onCompleted: () => {
        if (externalGridRef) {
          externalGridRef.current?.refreshServerSide({ purge: true });
        }
      },
    });

  const handleDeleteClick = async (e, brandStoreId) => {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    try {
      await removeBrandStore({
        variables: { id: brandStoreId },
      });
    } catch (error) {
      console.error("Failed to remove:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;
  if (removeError) return <p>Error: {removeError.message}</p>;

  const columnDefs = columns(handleDeleteClick, user);

  return (
    <AgGrid
      gridProps={{
        rowHeight: 60,
        defaultColDef: {
          floatingFilter: true,
          sortable: true,
          editable: false,
          filter: true,
          flex: 1,
          minWidth: 200,
        },
        pivotMode: false,
        enableRangeSelection: true,
        onRowClicked: (row) => {
          if (row.event.defaultPrevented) {
            return null;
          }
          onRowClick(row);
        }, // Use onRowClicked event
      }}
      readMethod={{
        fetchFn: fetchBrandStores,
        inputParser: ({ startRow, endRow, filter, sortModel }) => {
          return {
            listBrandStoreFilter: {
              take: endRow - startRow,
              skip: startRow,
              search: "",
              filter: {
                brand: { id: brandData ? brandData?.id : user.userId },
                isDeleted: false,
                ...filter,
              },
              orderby: createOrderByFromOrderModel(sortModel, columnDefs),
            },
          };
        },
        resultsParser: ({ data }) => ({
          results: data?.brandStores?.results,
          total: data?.brandStores?.total,
        }),
      }}
      externalGridRef={externalGridRef}
      columns={columnDefs}
    />
  );
}

export default StoreAgreementLandingPage;
