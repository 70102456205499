import React, { useEffect, useState } from "react";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/images/customer.svg";
import { Add as AddIcon } from "@mui/icons-material";
// import ChannelForm from "./channelForm";
import { Monitor as MonitorIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";
import DeviceLandingPage from "./deviceLandingPage";
import DeviceForm from "./deviceForm";

function Device({open,setOpen,createClick,setCreateClick}) {
//   const [open, setOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [pageKey, setPageKey] = useState(Date.now());
  
  useEffect(() => {
    if (createClick) {
      setSelectedDevice(null);
      setCreateClick(false)
    }
  }, [createClick]);
  const handleRowClick = (params) => {
    setSelectedDevice(params.row);
    handleOpen();
  };
  const DeviceDrawer = ({ open, handleClose, selectedDevice, callback }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={MonitorIcon} // Use MonitorIcon here
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            { selectedDevice? "Update Device" : "Create New Device" }
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <DeviceForm deviceData={selectedDevice} handleClose={handleClose} callback={callback} />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const refresh = () => {
    // Dummy update to trigger rerender of DeviceLandingPage
    setPageKey(Date.now()); // or any other state update
  };

  return (
      <>
        <DeviceLandingPage key={pageKey} open={open} setOpen={setOpen} onRowClick={handleRowClick} />
        <DeviceDrawer
          open={open}
          handleClose={handleClose}
          selectedDevice={selectedDevice}
          callback={refresh}
        />
      </>
  );
}

export default Device;
