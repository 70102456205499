import { Box } from "@mui/material";

export default function ErrorDataPlaceHolder({ error }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "red",
        margin: "5rem",
        height: "60vh",
      }}
    >
      {error}
    </Box>
  );
}
