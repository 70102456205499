import { useState, useEffect, useContext } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React components
import Sidenav from "ui/Sidenav";
import Configurator from "ui/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import { adminRoutes, routes, broadwayRm } from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  NotificationProvider,
  SearchProvider,
  FilterProvider,
  AuthContext,
} from "context";

// Images
import braodwayLogo from "./assets/images/broadwayLogo.svg";

// Custom Page Components
// import LandingPage from "layouts/landing";
// import Register from "auth/register";
import ProtectedRoute from "ui/ProtectedRoute";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import Login from "auth/login";
import Home from "layouts/brands/brandHomePage";
import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";

// Custom Services
import { setupAxiosInterceptors } from "./services/interceptor";

export default function App() {
  const authContext = useContext(AuthContext);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  const getUserRoute = () => {
    switch (authContext.user?.role) {
      case "admin":
        return adminRoutes;
      case "broadway-rm":
        return broadwayRm;
      case "brand_admin":
        return routes;
      default:
        return [];
    }
  };

  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <NotificationProvider>
          <FilterProvider>
            <SearchProvider>
              {layout === "dashboard" &&
                pathname !== "/" &&
                pathname !== "/home" && (
                  <>
                    <Sidenav
                      color={sidenavColor}
                      brand={
                        (transparentSidenav && !darkMode) || whiteSidenav
                          ? braodwayLogo
                          : braodwayLogo
                      }
                      brandName="Broadway"
                      routes={getUserRoute()}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                    >
                    {/* We can paas addition child here pathak */}
                    </Sidenav>
                    <Configurator />
                  </>
                )}
              {layout === "vr" && <Configurator />}
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/auth/login" element={<Login />} />
                {/* TODO: we dont allow public to register directly on our portal for now */}
                {/* <Route path="/auth/register" element={<Register />} /> */}
                <Route
                  path="/auth/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route
                  path="/auth/reset-password"
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path="user-profile"
                  element={
                    <ProtectedRoute
                      isAuthenticated={authContext.isAuthenticated}
                    >
                      <UserProfile />
                    </ProtectedRoute>
                  }
                  key="user-profile"
                />
                <Route
                  exact
                  path="user-management"
                  element={
                    <ProtectedRoute
                      isAuthenticated={authContext.isAuthenticated}
                    >
                      <UserManagement />
                    </ProtectedRoute>
                  }
                  key="user-management"
                />
                {getRoutes(getUserRoute())}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </SearchProvider>
          </FilterProvider>
        </NotificationProvider>
      </ThemeProvider>
    </>
  );
}
