import { gql } from "@apollo/client";

export const GET_MASTER_ACTIVITY_LIST = gql`
  query GetMasterActivity($take: Int, $skip: Int, $search: String) {
    masterActivity(
      listMasterActivityFilter: { take: $take, skip: $skip, search: $search }
    ) {
      total
      total
      results {
        id
        name
        displayOrder
        isDeleted
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const CREATE_MASTER_ACTIVITY = gql`
  mutation createMasterActivity(
    $createMasterActivityInput: CreateMasterActivityInput!
  ) {
    createMasterActivity(
      createMasterActivityInput: $createMasterActivityInput
    )  {
        id
        name
        displayOrder
        isDeleted
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
  }
`;

export const UPDATE_MASTER_ACTIVITY = gql`
  mutation updateMasterActivity(
    $updateMasterActivityInput: UpdateMasterActivityInput!
  ) {
    updateMasterActivity(
      updateMasterActivityInput: $updateMasterActivityInput
    ) {
        id
        name
        displayOrder
        isDeleted
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
  }
`;
