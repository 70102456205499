import React, { useState } from "react";
import AgGrid from "./table";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SECTION_COMPONENT,
  CREATE_COMPONENT,
  UPDATE_COMPONENT,
  DELETE_COMPONENT,
} from "services/ecommerce-component-service";
import { feedServiceClient } from "graphql/client";
import { IconButton, Menu, MenuItem, Switch } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";

export const ActionCellRenderer = ({
  params,
  editCallback = () => {},
  deleteCallback = () => {},
}) => {
  const [deleteSection] = useMutation(DELETE_COMPONENT, {
    client: feedServiceClient,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    // alert(`Edit action on ${props}`);
    editCallback();
    handleClose();
  };

  const handleDelete = () => {
    deleteCallback();
    handleClose();
  };

  return (
    <div>
      <IconButton size="small" onClick={handleClick}>
        <GridMenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

function SectionListing({ name = "Home", page = "welcome", handleEdit }) {
  const [key, setKey] = useState(Date.now());
  const [
    getSectionList,
    {
      loading: queryLoading,
      error: queryError,
      data: queryData,
      refetch: queryRefetch,
    },
  ] = useLazyQuery(GET_SECTION_COMPONENT, {
    client: feedServiceClient,
    variables: { input: { page, limit: 100, skip: 0 } },
  });
  const [createComponent] = useMutation(CREATE_COMPONENT, {
    client: feedServiceClient,
  });
  const [updateComponent] = useMutation(UPDATE_COMPONENT, {
    client: feedServiceClient,
  });
  const [deleteSection] = useMutation(DELETE_COMPONENT, {
    client: feedServiceClient,
  });

  const handleStatusChange = (_event, params) => {
    updateComponent({
      variables: {
        input: {
          id: params.data.id,
          sectionId: params.data.sectionId,
          status: _event.target.checked,
        },
      },
      onCompleted: () =>
        queryRefetch({ variables: { input: { page, limit: 100, skip: 0 } } }),
    });
  };

  return (
    <>
      <AgGrid
        key={key}
        columns={[
          {
            headerName: "Actions",
            field: "actions",
            cellRenderer: "customCellRenderer",
            colId: "actions",
            minWidth: 10,
            maxWidth: 50,
            cellRenderer: (params) => {
              return (
                <ActionCellRenderer
                  deleteCallback={() => {
                    deleteSection({ variables: { id: params.data.id } });
                    // queryRefetch({variables:{page}})
                    setKey(Date.now());
                  }}
                  editCallback={() => {
                    handleEdit(params.data);
                  }}
                  params={params}
                />
              );
            },
            filter: false,
          },
          {
            field: "displayOrder",
            headerName: "No",
            minWidth: 70,
            maxWidth: 120,
            rowDrag: true,
            filter: false,
          },
          {
            headerName: "Name",
            valueGetter: (params) => {
              return `${params.data.sectionName} / ${params.data.name}`;
            },
          },
          {
            field: "updatedAt",
            headerName: "Last Update",
            filter: "agDateColumnFilter",
            valueFormatter: (params) => {
              const date = new Date(params.value);
              const day = date.getDate().toString().padStart(2, "0");
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const year = date.getFullYear().toString();
              const formattedDate = `${day}-${month}-${year}`;
              return formattedDate;
            },
          },
          { field: "updatedByUser.username", headerName: "Updated By" },
          {
            field: "status",
            headerName: "Status",
            cellRenderer: (params) => {
              return (
                <Switch
                  checked={params.data.status}
                  color="primary"
                  onChange={(e) => handleStatusChange(e, params)}
                />
              );
            },
          },
        ]}
        readMethod={{
          query: getSectionList,
          key: "sections?.results",
          input: { page },
        }}
        page={page}
        updateMethod={{
          refatch: queryRefetch,
          mutation: updateComponent,
          key: "updateComponent",
        }}
        createMethod={{
          refatch: queryRefetch,
          mutation: createComponent,
          key: "createComponent",
        }}
        deleteMethod={{
          refatch: queryRefetch,
          mutation: deleteSection,
          key: "deleteSection",
        }}
        readOnly={false}
        queryLoading={queryLoading}
        queryError={queryError}
        queryData={queryData?.sections?.results
          .map((section) =>
            section.component?.filter(component=>!component.isDeleted)?.map((comp) => ({
              ...comp,
              sectionId: section.id,
              sectionName: section.name,
              sectionInternalName: section.internalName,
            }))
          )
          .flat().sort((comp1, comp2) => comp1.displayOrder - comp2.displayOrder)}
      />
    </>
  );
}

export default SectionListing;
