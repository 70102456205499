import React, { useState } from "react";
// import AgGrid from "./table";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SECTION_LISTING,
  CREATE_SECTION,
  UPDATE_SECTION,
  DELETE_SECTION,
} from "services/ecommerce-section-service";
import { feedServiceClient } from "graphql/client";
import {
  IconButton,
  Menu,
  MenuItem,
  Switch,
  ToggleButton,
} from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import AgGrid from "components/MDGrid";

const ActionCellRenderer = ({
  params,
  editCallback = () => {},
  deleteCallback = () => {},
}) => {
  const [deleteSection] = useMutation(DELETE_SECTION, {
    client: feedServiceClient,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    editCallback();
    handleClose();
  };

  const handleDelete = () => {
    deleteCallback();
    handleClose();
  };

  return (
    <div>
      <IconButton size="small" onClick={handleClick}>
        <GridMenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

function SectionListing({ name = "Home", page = "welcome", handleEdit }) {
  const [key, setKey] = useState(Date.now());
  const [
    getSectionList,
    {
      loading: queryLoading,
      error: queryError,
      data: queryData,
      refetch: queryRefetch,
    },
  ] = useLazyQuery(GET_SECTION_LISTING, {
    client: feedServiceClient,
    variables: { input: { page, limit: 100, skip: 0 } },
  });
  const [createSection] = useMutation(CREATE_SECTION, {
    client: feedServiceClient,
  });
  const [updateSection] = useMutation(UPDATE_SECTION, {
    client: feedServiceClient,
  });
  const [deleteSection] = useMutation(DELETE_SECTION, {
    client: feedServiceClient,
  });

  const handleStatusChange = (_event, params) => {
    updateSection({
      variables: {
        input: {
          id: params.data.id,
          status: _event.target.checked,
        },
      },
      onCompleted: () => params?.api?.refreshServerSide()
        // queryRefetch({ variables: { input: { page, limit: 100, skip: 0 } } }),
    });
  };

  return (
    <>
      <AgGrid
        key={key}
        columns={[
          {
            headerName: "Actions",
            field: "actions",
            cellRenderer: "customCellRenderer",
            colId: "actions",
            minWidth: 10,
            maxWidth: 50,
            cellRenderer: (params) => {
              return (
                <ActionCellRenderer
                  deleteCallback={() => {
                    deleteSection({ variables: { id: params.data.id } });
                    setKey(Date.now());
                    queryRefetch({
                      variables: { input: { page, limit: 100, skip: 0 } },
                    });
                  }}
                  editCallback={() => {
                    handleEdit(params.data);
                    queryRefetch({
                      variables: { input: { page, limit: 100, skip: 0 } },
                    });
                  }}
                  params={params}
                />
              );
            },
            filter: false,
          },
          {
            field: "displayOrder",
            headerName: "No",
            minWidth: 70,
            maxWidth: 120,
            rowDrag: true,
            filter: false,
          },
          { field: "name", headerName: "Name" },
          {
            field: "updatedAt",
            headerName: "Last Update",
            filter: "agDateColumnFilter",
            valueFormatter: (params) => {
              const date = new Date(params.value);
              const day = date.getDate().toString().padStart(2, "0");
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const year = date.getFullYear().toString();
              const formattedDate = `${day}-${month}-${year}`;
              return formattedDate;
            },
          },
          { field: "updatedByUser.username", headerName: "Updated By" },
          {
            field: "status",
            headerName: "Status",
            cellRenderer: (params) => {
              return (
                <Switch
                  checked={params.data.status}
                  color="primary"
                  onChange={(e) => handleStatusChange(e, params)}
                />
              );
            },
          },
        ]}
        // readMethod={{
        //   query: getSectionList,
        //   key: "sections?.results",
        //   input: { page },
        // }}
        // page={page}
        updateMethod={{
          refatch: queryRefetch,
          mutation: updateSection,
          key: "updateSection",
        }}
        createMethod={{
          refatch: queryRefetch,
          mutation: createSection,
          key: "createSection",
        }}
        deleteMethod={{
          refatch: queryRefetch,
          mutation: deleteSection,
          key: "deleteSection",
        }}
        queryLoading={queryLoading}
        queryError={queryError}
        // queryData={queryData?.sections?.results}
        readMethod={{
          fetchFn: getSectionList,
          inputParser: ({ startRow, endRow }) => ({
            input: { page, limit: endRow-startRow, skip: startRow },
          }),
          resultsParser: ({data}) => ({
            results: data?.sections?.results,
            total: data?.sections?.total,
          }),
        }}
      />
    </>
  );
}

export default SectionListing;
