import { gql } from "@apollo/client";

export const GET_ORDER_LIST = gql`
query GetOrderList($take: Int, $skip: Int, $search: String,$filter:JSONObject){
    orders(listOrderFilter: {take: $take, skip: $skip, search: $search, filter: $filter ,orderby:{orderDate:"DESC"}}) {
      total
      results {
        id
        accountId
        userId
        sessionId
        paymentId
        cartId
        orderNumber
        status
        sla
        additionalInfo
        orderDate
        createdAt
        updatedAt
        createdBy
        updatedBy
          payment {
        id
        amount
        consumed
        source
        txnId
        offerId
        receiptNo
        txnStatus
      }
        customer {
          id
          mobile
          firstName
          lastName
          email
        }
        orderItems {
          id
          orderId
          quantity
          totalPrice
          status
          unionTerritoryGstPercentage
          createdAt
          updatedAt
          productVariant {
            id
            title
          }
        }
      }
    }
  }
`

export const GET_ORDER_LISTING = gql`
query OrdersGet ($take: Int, $skip: Int, $filter: JSONObject){
  ordersGet(listOrderFilter: { take: $take, skip: $skip, filter: $filter }) {
      total
      results {
          id
          orderNumber
          customer
          posBill
          status
      }
  }
}
`

export const GET_ORDER_BY_ID = gql`
query GetOrderById($id: String!) {
  order(id: $id) {
    id
    orderNumber
    customer
    posBill
    status
  }
}
`;

export const GET_TRANSACTION_ORDER = gql`
query GetOrderTransactionsOpt($orderTxnOptInput: OrderTxnOptInput!) {
  getOrderTransactionsOpt(orderTxnOptInput: $orderTxnOptInput) {
    id
    userName
    requestTimestamp
    receiptDiscount
    receiptType
    requestSource
    requestType
    loyaltyRedeem
    loyaltyRedeemRevert
    discountType
    couponCode
    createdAt
    invoiceId
    optTransaction{
    header {
      requestId
      requestDate
      transactionDate
      transactionId
    }
    matchedCustomers {
      customerId
      firstName
      lastName
      emailAddress
      phone
      transactions {
        date
        storeNumber
        receiptNumber
        receiptAmount
        items {
          itemCategory
          departmentCode
          itemClass
          itemSubClass
          DCS
          vendorCode
          skuNumber
          billedUnitPrice
          quantity
        }
        payment {
          mode
          amount
        }
        loyalty {
          type
          amount
          valueCode
        }
      }
    }
    status {
      errorCode
      message
      status
    }
    }
  }
}`

export const GET_ORDER_DETAILS =gql`
query PaginatedOrderDetails($filter : GetOrderDetailsFilter!) {
  PaginatedOrderDetails(filter: $filter) {
      items {
          skuId
          image
          brandName
          paidPrice
          mrp
          sellingPrice
          dOneItemPromotion
          dTwoCartPromotion
          dThreeCouponDiscount
          dFourLoyaltyDiscount
          storeName
          status
          deliveryDate
      }
      total
  }
}

`