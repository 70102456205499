import * as React from "react";
import { Box, IconButton, Icon, Chip, Select, MenuItem, FormControl } from "@mui/material";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { vmServiceClient } from "graphql/client";
import { useQuery, useMutation } from "@apollo/client";
import Loader from "components/MDLoader";
import tab from "assets/theme/components/tabs/tab";
import { SearchContext,FilterContext } from "context";
import { useContext } from 'react';
import { REMOVE_VIDEO } from "services/advertisment-service";
import { UPDATE_VIDEO } from "services/advertisment-service";
import { LIST_ALL_VIDEOS } from "services/advertisment-service";
import { CREATE_VIDEO } from "services/advertisment-service";
import AdsTable from "../adsTable";
import { KeyboardArrowDown, UploadSharp } from "@mui/icons-material";
import VideoUploaderContainer from "./uploadVideoNew";
import MDButton from "components/MDButton";
import { DropDownSelectionContext } from "..";

export const NewVideoButton = ({ callback=null, }) =>{
  const { selectedBrandId, user } = useContext(
    DropDownSelectionContext
  );
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [resetUploader, setResetUploader] = React.useState(false);

  const [createVideoMutation] = useMutation(CREATE_VIDEO, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_VIDEOS,
        variables: {
          "page": 1,
          "limit": 100,
          "sortBy": "updatedAt",
          "order": "DESC",
          "relations": [],
          "searchCols": ["brandId","name"],
          "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId||'_nobrand',searchKeyword] : [selectedBrandId||'',searchKeyword]
        },
      },
    ],
  });

  const handleSubmit = ({ path, name, publicurl, videoMetadata }) => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    
    const payload = {
      brandId: selectedBrandId,
      name: name,
      duration: String(videoMetadata?.duration), // Ensure this is a string. Example: "00:08:19"
      aspectRatio: videoMetadata.aspectRatio, // Provide a default value if null
      orientation: videoMetadata.orientation.toUpperCase(), // Ensure correct enum value
      size: String(videoMetadata.size), // Convert to string if it's numeric
      path: path,
      publicUrl: publicurl,
      status: "PENDING",
      playlistIds: null
    };
    setIsSubmitting(false);
    setResetUploader(true);
    createVideoMutation({
      variables: { input: payload },
      onCompleted: (data) => {
         // Set to true to reset the uploader
        // setTimeout(() => setResetUploader(false), 0); // Optional: reset the state immediately
        if(callback) callback(data?.createVideo);
        refetch({
          variables: {
            "page": 1,
            "limit": 100,
            "sortBy": "updatedAt",
            "order": "DESC",
            "relations": [],
            "searchCols": ["brandId",],
            "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId||'_nobrand',searchKeyword] : [selectedBrandId||'',searchKeyword]
          },
        });
      },
      onError: () => setIsSubmitting(false),
    });
  };
  return (
    <VideoUploaderContainer
      sx={{ height: '2rem' }}
      preLoadedFile={[]}
      isMultiple={false}
      type={"Media"}
      reset={resetUploader}
      signedUrlCallback={(path, name, publicurl, videoMetadata) => {
        handleSubmit({ path, name, publicurl, videoMetadata }); // need to submit an actual payload
        // onSubmit({ path, name, publicurl, videoMetadata });
      }}
      fieldName={"Upload Video"}
      isFieldValid={true}
      isVideo={true}
      uploadButton={<MDButton
        type="button"
        variant="outlined"
        color="dark"
        sx={{ borderRadius: "100px" ,mr:1, width:'max-content'}}
    >
        <UploadSharp/> Upload New Video
    </MDButton>}
    />);}
export default function VideoPage() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const { selectedBrandId, user, Brand} = useContext(DropDownSelectionContext);
  
  const [isLoading, setIsLoading] = React.useState(false);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);

  const columns = [
    { field: "uuid", headerName: "ID", width: 130, flex: 1 },
    {
      field: "brandId",
      headerName: "Brand",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <span>{Brand?.name||'Uknown Brand'}</span>
        );
      },
    },
    { field: "name", headerName: "Name", width: 130, flex: 2 },
    { field: "duration", headerName: "Duration", width: 130, flex: 1 },
    // { field: "orientation", headerName: "Orientation", width: 130 },
    { field: "aspectRatio", headerName: "Orientation", width: 130, flex: 1 },
    { field: "size", headerName: "Size", width: 130, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      flex: 2,
      renderCell: (params) => {
        if (user && !["admin", "broadway-rm"].includes(user?.role)) {
          return (
            <Chip
              label={params.value}
              color={
                params.value === "APPROVED"
                  ? "success"
                  : params.value === "PENDING"
                    ? "warning"
                    : "error"
              }
              size="small"
            />
          );
        }

        return (
          <FormControl fullWidth >
            <Select 
              onChange={(e) =>
                handleStatusChange(params.row.uuid, e.target.value)
              }
              defaultValue={params.value}
              style={{ width: "100%", padding: 10, borderRadius: 6,height:32, }}
              id="status-select"
              IconComponent={()=><KeyboardArrowDown sx={{fontSize:24, width:24, height:24}} />}
              
            >
              <MenuItem value="APPROVED">APPROVED</MenuItem>
              <MenuItem value="PENDING">PENDING</MenuItem>
              <MenuItem value="REJECTED">REJECTED</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      disableColumnMenu: true,
      headerAlign: "left",
      width: 240,
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ "& button": { mr: 1 } }}>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(params.row.publicUrl)}
          >
            <Icon component={previewIcon} fontSize="small" />
          </IconButton>
          <IconButton disabled sx={{opacity: 0.25}} aria-label="save" onClick={() => alert("Clicked")}>
            <Icon component={commentIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="download"
            onClick={() =>
              handleDownloadClick(params.row.publicUrl, params.row.name)
            }
          >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteClick(params.row.uuid)}
          >
            <Icon component={deleteIcon} fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [removeVideoMutation] = useMutation(REMOVE_VIDEO, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_VIDEOS,
        variables: {
          "page": 1,
          "limit": 10,
          "sortBy": "updatedAt",
          "order": "DESC",
          "relations": [],
          "searchCols": ["brandId","name"],
          "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId||'_nobrand',searchKeyword] : [selectedBrandId||'',searchKeyword]        },
      },
    ],
  });
  const [updateVideoPlaylist] = useMutation(UPDATE_VIDEO, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_VIDEOS,
        variables: {
          "page": 1,
          "limit": 10,
          "sortBy": "updatedAt",
          "order": "DESC",
          "relations": [],
          "searchCols": ["brandId","name"],
          "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId||'_nobrand',searchKeyword] : [selectedBrandId||'',searchKeyword]
        },
      },
    ],
  });

  const handleStatusChange = (id, status) => {
    updateVideoPlaylist({
      variables: { input: { uuid: id, status: status } },
      onCompleted: () => {
      },
      onError: (error) => {
        // console.error("Error in Update ", error);
      },
    });
  };

  const handleDeleteClick = (videoId) => {
    removeVideoMutation({
      variables: { id: videoId },
      // onCompleted: () => {
      //   // setIsLoading(false);
      //   // setIsLoading(false); 
      //   refetch();
      // },
      // onError: (error) => {
      //   console.error("Error removing video", error);
      //   // setIsLoading(false);
      // },
    });
  };

  const handleEditClick = (videoUrl) => {
    const newTab = window.open("", "_blank");
    newTab.document.body.innerHTML = `
    <video controls autoplay style="width: 100%; height: auto;">
      <source src="${videoUrl}" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  `;
    newTab.focus();
  };

  const handleDownloadClick = (videoUrl, videoName) => {
    // This creates a new anchor element and triggers a download
    const link = document.createElement("a");
    link.href = videoUrl;
    link.download = videoName; // You can set the default file name for the download here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { loading, error, data, refetch } = useQuery(LIST_ALL_VIDEOS, {
    client: vmServiceClient,
    variables: {
      "page": page+1,
      "limit": rowsPerPage,
      "sortBy": "updatedAt",
      "order": "DESC",
      "relations": [],
      "searchCols": ["brandId","name"],
      "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId||'_nobrand',searchKeyword] : [selectedBrandId||'',searchKeyword]
    },
  });

  const [createVideoMutation] = useMutation(CREATE_VIDEO, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_VIDEOS,
        variables: {
          "page": 1,
          "limit": 100,
          "sortBy": "updatedAt",
          "order": "DESC",
          "relations": ["playlist"],
          "searchCols": ["brandId","name"],
          "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId, searchKeyword] : [searchKeyword]
        },
      },
    ],
  });

  // Define a function that can handle submission
  
  // React.useEffect(() => {
  //   // refetch();
  // }, [user, selectedBrandId, handleDeleteClick]);

  // React.useEffect(() => {
  //   refetch({
  //     variables: {
  //       "page": 1,
  //       "limit": 100,
  //       "sortBy": "updatedAt",
  //       "order": "DESC",
  //       "relations": [],
  //       "searchCols": ["brandId","name"],
  //       "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId,searchKeyword] : [searchKeyword]
  //     },
  //   });
  // }, [user, selectedBrandId, handleDeleteClick, searchKeyword])

  if (loading || isLoading) return <Loader />;
  if (error) return <p>Error: {error?.message}</p>;

  function filterObjectsByField(objects, field, searchTerm) {
    return objects.filter(obj => {
      // Convert the field value to lowercase for case-insensitive search
      const fieldValue = obj[field].toLowerCase();
      // Convert the search term to lowercase as well
      const search = searchTerm.toLowerCase();
      // Check if the field contains the search term
      return fieldValue.includes(search);
    });
  }
  const handlePaginationRefetch = ({page, rowsPerPage}) => {
    refetch({
      "page": page,
      "limit": rowsPerPage,
      "sortBy": "updatedAt",
      "order": "DESC",
      "relations": [],
      "searchCols": ["brandId","name"],
      "searchBy": user && !["admin", "broadway-rm"].includes(user?.role) ? [selectedBrandId||'_nobrand',searchKeyword] : [selectedBrandId||'',searchKeyword]
    });
  }

  // const videoRows = searchKeyword?.length <= 0 ? data?.findAllVideos?.results : filterObjectsByField(data?.findAllVideos.results, "name", searchKeyword);
  const videoRows = data?.findAllVideos?.results
  const totalRows = data?.findAllVideos?.total;
  return (
    <Box
      sx={{
        width: "100%",
        height: '70vh',

        "& .MuiDataGrid-row": { borderBottom: "1px solid #e0e0e0" },
      }}
    >
      <AdsTable rows={videoRows} callback={handlePaginationRefetch} totalRows={totalRows}
        columns={columns} footerCTA={!!selectedBrandId && 
        <Box>
          <NewVideoButton 
            selectedBrandId={selectedBrandId}
            user={user}
          />
          </Box>
        }
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        />
     
      
    </Box>
  )
}
