import { Autocomplete, TextField } from '@mui/material';
import select from 'assets/theme-dark/components/form/select';
import React, { useEffect, useState } from 'react';

export const DropdownFilterComponent = ({ model, onModelChange, options, label, id, gridRef }) => {
    const [selected, setSelected] = useState(model?.filter);
    const [key,setKey] = useState(Date.now())
    const onChange = async (selectedOption) => {
        setSelected(selectedOption);
        onModelChange({...model, filter: selectedOption?.value || null})
        if(id=="select-store"){
            // await gridRef?.current?.setColumnFilterModel("location",null)
            // await gridRef?.current?.setColumnFilterModel("subLocation",null)
            // await gridRef?.current?.setColumnFilterModel("subLocationTypeId",null)
            // await gridRef?.current?.setColumnFilterModel("assetTypeCode",null)
            await gridRef?.current?.setFilterModel({
              ...gridRef.current.getFilterModel(),
              location: null,
              subLocation: null,
              subLocationTypeId: null,
              assetTypeCode: null
            });
        } else if(id=="select-location"){
            await gridRef?.current?.setColumnFilterModel("subLocation",null)
            await gridRef?.current?.setColumnFilterModel("subLocationTypeId",null)
            await gridRef?.current?.setColumnFilterModel("assetTypeCode",null)
        } else if(id=="select-sublocation"){
            await gridRef?.current?.setColumnFilterModel("subLocationTypeId",null)
            await gridRef?.current?.setColumnFilterModel("assetTypeCode",null)
        }
    }

    useEffect(()=>{
      console.log(id, "MODEL", model,selected, options)
      if(!model && id){
        console.log(id,"bukkk")
        setSelected(undefined)
        setKey(Date.now())
      }
    },[model,id])

    return (
      <Autocomplete
      key={key}
        onChange={(event, newValue) => onChange(newValue)}
        // disablePortal
        id={id}
        options={options}
        value={selected?selected.label:null}
        sx={{
          "& .MuiInputBase-root": {
            height: 24,
            borderRadius: "3px",
            backgroundColor: "white.main",
            borderColor: "rgb(186,191,199)",
          },
        }}
        renderInput={(params) => (
          <TextField
            // sx={{ "& .>label": { transform: "translate(14px, 8px) scale(1)" } }}
            {...params}
            label={label}
            InputLabelProps={{
                shrink: true, // Ensure label shrinks when needed
                sx: {
                  fontSize: "12px", // Set the smaller font size for label
                  lineHeight: "24px", // Align label vertically within the smaller input
                  top: '-6px', // Adjust the label's position to fit the smaller input
                },
              }}
            //   InputProps={{
            //     sx: {
            //       paddingTop: 0,
            //       paddingBottom: 0,
            //       height: "100%", // Ensure the input fits the full height of the Autocomplete
            //     },
            //   }}
          />
        )}
      />
    );
};