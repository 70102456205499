import { gql } from "@apollo/client";


export const GET_THEMES = gql`
query listTheme($listThemeFilter: ListThemeFilter!){
    themes(listThemeFilter: $listThemeFilter) {
      total
      results {
        id
        name
        displayOrder
        isDeleted
        createdAt
        updatedAt
        themeBrand {
          isDeleted
          brand {
            id
            name
          }
        }
      }
    }
  }
`
export const CREATE_THEMES_MUTATION= gql`
mutation ($createThemeInput :CreateThemeInput! ){
    createTheme(
      createThemeInput: $createThemeInput
    ) {
      id
    }
  }
`
export const UPDATE_THEMES_MUTATION = gql`
mutation ($updateThemeInput:UpdateThemeInput!){
    updateTheme(
      updateThemeInput: $updateThemeInput
    ) {
      id
    }
  }
`
export const GET_BRAND_LIST = gql`
query {
    brands {
        id
        name
    }
  }
`