import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_THEMES,
  CREATE_THEMES_MUTATION,
  UPDATE_THEMES_MUTATION,
  GET_BRAND_LIST,
} from "services/theme-service";
import { useNotification } from "context";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import Loader from "components/MDLoader";
import MDButton from "components/MDButton";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const validationSchema = Yup.object().shape({
  themeName: Yup.string()
    .required("Theme Name is required")
    .max(50, "Maximum 50 characters allowed"),
  orderNo: Yup.number()
    .required("Order is required")
    .positive("A number must be positive.")
    .integer("Order must be an integer")
    .typeError("Order must be a number"),
  selectedBrands: Yup.array().min(1, "Select at least one brand"),
});

const useThemeMutations = (themeData, handleClose, setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_THEMES, variables: { listThemeFilter: {take: 20, skip: 0,search:""} } },],
  };
  const [createThemeMutation] = useMutation(
    CREATE_THEMES_MUTATION,
    mutationOptions
  );

  const [updateThemeMutation] = useMutation(
    UPDATE_THEMES_MUTATION,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      name: values.themeName,
      displayOrder: +values.orderNo,
      isDeleted: values.isActive,
      brand: values?.selectedBrands,
    };
    const mutation =
      themeData && themeData?.id ? updateThemeMutation : createThemeMutation;
    const mutationInput =
      themeData && themeData?.id
        ? { updateThemeInput: { id: themeData.id, ...payload } }
        : { createThemeInput: payload };

    mutation({ variables: mutationInput })
      .then(() => {
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
            themeData && themeData.id
              ? "Theme  updated successfully"
              : "Theme  created successfully",
        });
      })
      .catch((error) => {
        console.error("error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };
  return handleSubmit;
};
const ThemeForm = ({ handleClose, themeData }) => {
  const {
    loading: themeLoading,
    error: themeError,
    data: data,
  } = useQuery(GET_BRAND_LIST, {
    client: dashboardServiceClient,
    variables: { search: "" },
  });
  const { setNotification } = useNotification();
  const handleSubmit = useThemeMutations(
    themeData,
    handleClose,
    setNotification
  );
  if (themeLoading)
    return (
      <>
        <Loader />
      </>
    );
  if (themeError) return <p>Error: {error?.message}</p>;
  const getBrandId = (themeData) => {
    let filtterdBrand =
      themeData?.themeBrand.filter((brand) => !brand.isDeleted) || [];
    let brands = filtterdBrand.map((brand) => brand.brand.id);
    return brands;
  };
  return (
    <Formik
      initialValues={{
        themeName: themeData?.name || "",
        orderNo: themeData?.displayOrder || "",
        isActive: themeData?.isDeleted || false,
        selectedBrands: getBrandId(themeData) || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Name Of The Theme"
                fullWidth
                name="themeName"
                error={
                  formik.touched.themeName && Boolean(formik.errors.themeName)
                }
                helperText={formik.touched.themeName && formik.errors.themeName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select Brand</InputLabel>
                <Field
                  as={Select}
                  multiple
                  label="Select Brand"
                  name="selectedBrands"
                  error={
                    formik.touched.selectedBrands &&
                    Boolean(formik.errors.selectedBrands)
                  }
                  IconComponent={() => (
                    <GridExpandMoreIcon fontSize="medium" />
                  )}
                  style={{ padding: "10px" }}
                >
                  {data &&
                    data?.brands.map((brand) => (
                      <MenuItem key={brand?.id} value={brand?.id}>
                        {brand?.name}
                      </MenuItem>
                    ))}
                </Field>
                {formik.touched.selectedBrands &&
                formik.errors.selectedBrands ? (
                  <Typography variant="body2" fontSize={12} color="error">
                    {formik.errors.selectedBrands}
                  </Typography>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                fullWidth
                name="orderNo"
                label="Order No."
                error={formik.touched.orderNo && Boolean(formik.errors.orderNo)}
                helperText={formik.touched.orderNo && formik.errors.orderNo}
              />
            </Grid>

            <Grid item xs={12} mt={1}>
              <Field
                as={TextField}
                name="isActive"
                label="Is it active?"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.isActive}
                          onChange={(e) =>
                            formik.setFieldValue("isActive", e.target.checked)
                          }
                        />
                      }
                      labelPlacement="start"
                    />
                  ),
                }}
              />
            </Grid>

            <Grid container spacing={1}>
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
              <MDButton
                type="submit"
                variant="contained"
                circular={true}
                color="dark"
              >
                Save
              </MDButton>
              <MDButton onClick={handleClose} circular={true} variant="outlined" color="dark">
                Cancel
              </MDButton>
            </Grid>
          </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ThemeForm;
