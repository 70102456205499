import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import EcomReview from "./review";
import EcomImageMaster from "./imageSection";
import EcomVideoMaster from "./videoSection";
import SectionListing from "./common/section";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EcommerceMediaPage = [
  {
    name: "Home",
    component: SectionListing,
    showBedgeStatus: true,
    page: "welcom",
  },
  {
    name: "Brand Landing Page",
    component: SectionListing,
    showBedgeStatus: true,
    page: "brandLanding",
  },
  {
    name: "Brand Page",
    component: SectionListing,
    showBedgeStatus: true,
    page: "brandDetail",
  },
  {
    name: "PDP",
    component: SectionListing,
    showBedgeStatus: true,
    page: "pdpPage",
  },
  {
    name: "PLP",
    component: SectionListing,
    showBedgeStatus: true,
    page: "plpPage",
  },
  {
    name: "Event Page",
    component: SectionListing,
    showBedgeStatus: true,
    page: "eventListing",
  },
  {
    name: "Rewards",
    component: SectionListing,
    showBedgeStatus: true,
    page: "rewardListing",
  },
  {
    name: "Video",
    component: EcomVideoMaster,
    showBedgeStatus: false,
  },
  {
    name: "Image",
    component: EcomImageMaster,
    showBedgeStatus: false,
  },
  {
    name: "Reviews",
    component: EcomReview,
    showBedgeStatus: false,
  },
];
Object.freeze(EcommerceMediaPage); // * it is freezing the object so that it can't be modified

export default function EcommerceMediaTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {EcommerceMediaPage.map((tab, index) => (
            <Tab key={tab.name} label={tab.name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>

      {EcommerceMediaPage.map((tab, index) => (
        <>
          <CustomTabPanel key={tab.name} value={value} index={index}>
            {tab.showBedgeStatus && (
              <Typography
                variant="h6"
                sx={{ fontFamily: "Montserrat", color: "#25252E", mb: 2 }}
              >
                <Badge color="success" variant="dot" sx={{ mr: 2, ml: 3 }} />
                Running
                <Badge color="warning" variant="dot" sx={{ mr: 2, ml: 3 }} />
                Scheduled
                <Badge color="error" variant="dot" sx={{ mr: 2, ml: 3 }} />
                Expired
              </Typography>
            )}
            <tab.component {...tab} />
          </CustomTabPanel>
        </>
      ))}
    </Box>
  );
}
