import React, { useContext } from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Custom Components
import CustomerDetailComponent from "./customerDetailForm";
import CustomerWalletForm from "./customerWalletForm";
import CustomerAddressForm from "./customerAddressForm";
import CustomerTransactionForm from "./customerTransactionForm";

// Material Dashboard 2 React Componets
import MDTabPanel from "components/MDTabPanel";
import MDBox from "components/MDBox";

// Custom Context
import { AuthContext } from "context";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomerTabs({ customerData, handleClose }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const { user } = useContext(AuthContext);

  return (
    <MDBox sx={{ width: "100%" }}>
      <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Wallet" {...a11yProps(1)} />
          <Tab label="Address" {...a11yProps(2)} />
          <Tab label="Transaction History" {...a11yProps(3)} />
        </Tabs>
      </MDBox>
      <MDTabPanel value={value} index={0}>
        <MDBox p={3}>
          <CustomerDetailComponent
            customerData={customerData}
            handleClose={handleClose}
            handleTabChange={handleChange}
            user={user}
          />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={value} index={1}>
        <MDBox p={3}>
          <CustomerWalletForm
            customerData={customerData}
            handleClose={handleClose}
            handleTabChange={handleChange}
            user={user}
          />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={value} index={2}>
        <MDBox p={3}>
          <CustomerAddressForm
            customerData={customerData}
            handleClose={handleClose}
            handleTabChange={handleChange}
            user={user}
          />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={value} index={3}>
        <MDBox p={3}>
          <CustomerTransactionForm
            customerData={customerData}
            handleTabChange={handleChange}
          />
        </MDBox>
      </MDTabPanel>
    </MDBox>
  );
}
