export const createOrderByFromOrderModel = (sortModel,columnDefs) => {
    let orderBy = {}
    sortModel.forEach(sortM=>{
        const sortOrder = sortM.sort === 'asc'? 'asc': 'desc'
        const column = columnDefs.find((colDef) => colDef.field == sortM.colId)
        if(column.getSortObject != null){
            orderBy = {...orderBy, ...column.getSortObj(sortOrder)}
        } else {
            orderBy[sortM.colId] = sortOrder;  
        }
        // const sortKey = column.getSortKey? column.getSortKey() : sortM.colId
        // orderBy[sortKey] = sortOrder
    })
    return orderBy;
}

export const createSortByFromOrderModel = (sortModel,columnDefs) => {
    const sortM = sortModel?.[0]
    if(!sortM){
        return null
    }
    const sortOrder = sortM.sort === 'asc'? 'asc': 'desc'
    const column = columnDefs.find((colDef) => colDef.field == sortM.colId)
    if(column.getSortName != null){
        return [column.getSortName(),sortOrder]
    }
    return [sortM.colId,sortOrder];
}