import React, { useEffect, useState } from "react";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/images/customer.svg";
import { Add as AddIcon } from "@mui/icons-material";
import ActivityLandingPage from "./activityLandingPage";
import ActivityForm from "./activityForm";
import MDButton from "components/MDButton";


function Activity() {
  const [open, setOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [brandChange, setBrandChange] = React.useState(false);

  const handleRowClick = (params) => {
    setSelectedActivity(params.row);
    handleOpen();
  };
  const ControlSection = ({ handleOpen ,user}) => (
    user && user?.role === "admin" ?  (<>
      <Grid item xs={12} sm={6} md={6} />
      <Grid item xs={12} sm={6} md={6} textAlign="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
            <MDButton
                type="button"
                variant="contained"
                color='dark'
                circular={true} 
            onClick={() => {
              setOpen(true);
              setSelectedActivity(null);
            }}
            startIcon={<AddIcon />}
          >
            New Activity
          </MDButton>
        </Box>
      </Grid>
    </>) : <></>
  );
  const ActivityDrawer = ({ open, handleClose, selectedActivity, setSelectedActivity, setTabValue, tabValue, setBrandChange, brandChange}) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={CustomerIcon}
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Activity
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <ActivityForm handleClose={handleClose} activityData={selectedActivity} user={user}/>
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false) 
    setTabValue(0)}
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <Grid item xs={12} sm={6} md={3}>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Activity</Typography>
          <ControlSection handleOpen={handleOpen} user={user}/>
        </Grid>
        <ActivityLandingPage onRowClick={handleRowClick}  user={user}/>
        <ActivityDrawer
          open={open}
          handleClose={handleClose}
          selectedActivity={selectedActivity}
          setSelectedActivity={(promotion) => {
            setSelectedActivity(promotion)
          }}
          setTabValue={setTabValue}
          tabValue={tabValue}
          brandChange={brandChange}
          setBrandChange={setBrandChange}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default Activity;
