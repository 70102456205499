import { gql } from "@apollo/client";

export const GET_CHANNELS = gql`
query GetChannelList($take: Int, $skip: Int, $search: String, $filter:JSONObject) {
    channels(listChannelFilter: {take: $take, skip: $skip, search: $search,filter: $filter}) {
      total
      results {
        id
        name
        channelType
        displayOrder
        isDeleted
        createdAt
        updatedAt
        channelStore {
          store {
            id
            name
            code
          }
        }
      }
    }
  }
`;

export const CREATE_CHANNEL = gql`
mutation createChannel($createChannelInput : CreateChannelInput!){
     createChannel(createChannelInput: $createChannelInput){
      id
      name
      channelType
      displayOrder
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CHANNEL = gql`
mutation updateChannel($updateChannelInput : UpdateChannelInput!) {
    updateChannel(updateChannelInput: $updateChannelInput) {
      id
      name
      channelType
      displayOrder
      isDeleted
      createdAt
      updatedAt
      channelStore {
        store {
          id
          name
          code
        }
      }
    }
  }
`;
