import { gql } from "@apollo/client";


export const GET_TRANSACTION_LOYALITY = gql`
query getLoyalityTransactionsOpt($loyalityTxnOptInput: LoyalityTxnOptInput!) {
  getLoyalityTransactionsOpt(loyalityTxnOptInput: $loyalityTxnOptInput) {
    id
    userName
    requestTimestamp
    receiptDiscount
    receiptType
    requestSource
    requestType
    loyaltyRedeem
    loyaltyRedeemRevert
    discountType
    couponCode
    createdAt
    invoiceId
    optTransaction{
    header {
      requestId
      requestDate
      transactionDate
      transactionId
    }
    matchedCustomers {
      customerId
      firstName
      lastName
      emailAddress
      phone
      transactions {
        date
        storeNumber
        subsidiaryNumber
        docSID
        receiptNumber
        eReceiptURL
        amount{
            type
            enteredValue
            receiptAmount
        }
        balances{
            type
            amount
            valueCode
            difference
        }
      }
    }
    status {
      errorCode
      message
      status
    }
    }
  }
}`

export const GET_LOYALITY_CUSTOMER = gql`
query GetLoyaltyCustomer ($filter: GetLoyaltyCustomerFilter!) {
  getLoyaltyCustomer(filter: $filter) {
      total
      results {
         accountId
          name
          mobile
          totalActiveRewards
          totalRewardsEarned
          totalRewardsBurned
          status
      }
  }
}
`
export const GET_LOYALITY_ACTIVITY = gql`
query GetLoyaltyActivity($filter:GetLoyaltyActivityFilter!) {
  getLoyaltyActivity(filter: $filter) {
      activityType
      totalActivites
      rewardsEarned {
          totalRewardPoints
          totalCustomers
      }
      rewardsBurned {
          totalRewardPoints
          totalCustomers
      }
  }
}
`

export const GET_EVENT_ORDER_DETAILS=gql`
query GetLoyaltyEventOrderDetails($orderId:String!) {
  getLoyaltyEventOrderDetails(orderId: $orderId) {
      eventName
      eventType
      ticketName
      ticketPrice
      totalTickets
      totalPrice
      rewardsEarned
      rewardsBurned
      status
  }
}
`
export const GET_CUSTOMER_LOYALITY_DETAILS=gql`
query GetLoyaltyCustomerDetails($filter:GetLoyaltyCustomerDetailsFilter!) {
  getLoyaltyCustomerDetails(
      filter:$filter
  ) {
      total
      totalPages
      currentPage
      results {
          customerId
          accountId
          activityType
          totalRewardsEarned
          totalRewardsBurned
          totalBalanceRewards
          transactionId
          orderDate
          rewardsExpiryDate
          orderTotal
          orderId
          rewardCreditDate
      }
  }
}
`