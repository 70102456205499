// Usage:
import { gql } from "@apollo/client";

export const GET_STORES = gql`
  query listStore($listStoreFilter:ListStoreFilter!) {
    stores(listStoreFilter: $listStoreFilter) {
      total
      results {
        id
        name
        code
        address1
        address2
        city
        description
        genesysId
        optcultureId
        image
        latitude
        longitude
        pincode
        state
        status
        secondaryText
        primaryText
        displayOrder
        storeMetaData {
          key
          value
          isDeleted
          id
        }
        storeLocation {
          id
          code
          locationName
          division {
            id
            title
            metaTitle
            image
            displayOrder
            isDeleted
            isNodeItem
            isMenuItem
            createdAt
            updatedAt
          }
          isDeleted
          subLocations {
              id
              name
              assetTypes {
                  id
                  name
                  batchCode
                  size
                  code
              }
          }
        }
      }
    }
  }
`;

export const CREATE_STORE_MUTATION = gql`
  mutation createStore($createStoreInput: CreateStoreInput!) {
    createStore(createStoreInput: $createStoreInput) {
      id
      name
      code
      description
      secondaryText
      primaryText
      displayOrder
      address1
      address2
      city
      state
      pincode
      longitude
      longitude
      image
    }
  }
`;

export const UPDATE_STORE_MUTATION = gql`
  mutation updateStore($updateStoreInput: UpdateStoreInput!) {
    updateStore(updateStoreInput: $updateStoreInput) {
      id
      name
      code
      description
      primaryText
      secondaryText
      displayOrder
      address1
      address2
      city
      state
      pincode
      longitude
      latitude
      image
    }
  }
`;

export const CREATE_STORE_METADATA = gql`
  mutation createStoreMetaData(
    $createStoreMetaDataInput: CreateStoreMetadataInput!
  ) {
    createStoreMetadata(createStoreMetadataInput: $createStoreMetaDataInput) {
      id
      key
      value
    }
  }
`;

export const UPDATE_STORE_METADATA = gql`
  mutation updateStoreMetadata(
    $updateStoreMetadataInput: UpdateStoreMetadataInput!
  ) {
    updateStoreMetadata(updateStoreMetadataInput: $updateStoreMetadataInput) {
      id
      isDeleted
      key
      value
    }
  }
`;

export const GET_STORES_LIST = gql`
  query listStore($listStoreFilter:ListStoreFilter!) {
    stores(listStoreFilter: $listStoreFilter) {
      total
      results {
        id
        name
        status
        city
        storeMetaData {
          key
          value
          isDeleted
          id
        }
      }
    }
  }
`;

export const GET_STORES_LIST_ADS = gql`
  query listStore($listStoreFilter:ListStoreFilter!) {
    stores(listStoreFilter: $listStoreFilter) {
      total
      results {
        id
        name
        status
        genesysId
        optcultureId
        image
        latitude
        longitude
        pincode
        state
      }
    }
  }
`;

export const GET_STORES_LIST_NEW = gql`
  query findAllStores($input: ListStoreFilter!) {
    stores(listStoreFilter: $input) {
        total
        results {
            id
            name
            code
            address1
            address2
            city
            description
            image
            latitude
            longitude
            pincode
            state
            status
            secondaryText
            primaryText
            displayOrder
            createdAt
            storeMetaData {
            key
            value
            isDeleted
            id
            }
            storeLocation {
                id
                code
                locationName
                division {
                  id
                  title
                  metaTitle
                  image
                  displayOrder
                  isDeleted
                  isNodeItem
                  isMenuItem
                  createdAt
                  updatedAt
                }
                isDeleted
                subLocations {
                    id
                    name
                    assetTypes {
                        id
                        name
                        batchCode
                        size
                        code
                    }
                }
            }
        }
    }
  }
`
/* {
  "input": {
      "take": 100,
      "skip": 0
  }
} */ 

  export const GET_STORE_LOCATION = gql`
  query StoreLocations(
  $skip: Int! = 0,
  $take: Int! = 20,
  $filter: JSONObject! = {}
  ) {
      storeLocations(listStoreLocationFilter: { take: $take, skip: $skip, filter: $filter }) {
          total
          results {
              id
              code
              locationName
              division {
                id
                title
                metaTitle
                isDeleted
                isNodeItem
              }
              store {
                id
                name
                primaryText
                secondaryText
                displayOrder
                code
                status
              }
              subLocations {
                  id
                  name
                  assetTypes {
                      id
                      subLocationId
                      name
                      size
                      batchCode
                  }
              }
          }
      }
  }`;

export const CREATE_STORE_LOCATION = gql`
mutation CreateOrUpdateStoreLocation($input:[CreateOrUpdateStoreLocationInput!]!) {
  createOrUpdateStoreLocation(input:$input ) {
      id
      code
      locationName
      division {
        id
        title
        metaTitle
        image
        displayOrder
        isDeleted
        isNodeItem
        isMenuItem
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
  }
}
`;

export const UPDATE_STORE_LOCATION = gql`
mutation CreateOrUpdateStoreLocation($input:[CreateOrUpdateStoreLocationInput!]!) {
  createOrUpdateStoreLocation(input:$input ) {
      id
      code
      locationName
      division {
        id
        title
        metaTitle
        image
        displayOrder
        isDeleted
        isNodeItem
        isMenuItem
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
  }
}
`;

export const DELETE_STORE_LOCATION = gql`
mutation RemoveStoreLocation($id:String!) {
    removeStoreLocation(id: $id) {
        id
        locationName
        isDeleted
    }
}
`;