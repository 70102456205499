// Usage:
import { gql } from "@apollo/client";

export const PRODUCT_LIST = gql`
  query listProduct($listProductFilter: ListProductFilter!) {
    products(listProductFilter: $listProductFilter) {
      total
      results {
        id
        title
        metaTitle
        slug
        description
        images
        commissionPercentage
        paymentGatewayCharge
        logisticsCost
        additionalInfo
        isDeleted
        status
        isAvailable
        displayOrder
        shippingDays
        createdAt
        updatedAt
        returnPolicy
        saas
        filedNo
        brand {
          name
        }
        productCategory {
          isDeleted
          categoryProduct {
            id
            title
          }
        }
        productVariants {
          id
          title
          productUrl
          displayOrder
          images
          productDescription
          inventory
          sku
          size
          color
          slug
          live
          productPrices {
            mrp
            msp
            listingPrice
            netSellerPayable
          }
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct($createProductInput: CreateProductInput!) {
    createProduct(createProductInput: $createProductInput) {
      id
      title
      metaTitle
      slug
      description
      images
      commissionPercentage
      paymentGatewayCharge
      logisticsCost
      additionalInfo
      isDeleted
      isAvailable
      displayOrder
      shippingDays
      createdAt
      updatedAt
      returnPolicy
      saas
      filedNo
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct($updateProductInput: UpdateProductInput!) {
    updateProduct(updateProductInput: $updateProductInput) {
      id
      title
      metaTitle
      slug
      description
      images
      commissionPercentage
      paymentGatewayCharge
      logisticsCost
      additionalInfo
      isDeleted
      isAvailable
      displayOrder
      shippingDays
      createdAt
      updatedAt
      returnPolicy
      saas
      filedNo
      productCategory {
        isDeleted
        categoryProduct {
          id
          title
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_VARIANT_MUTATION = gql`
  mutation createProductVariant(
    $createProductVariantInput: CreateProductVariantInput!
  ) {
    createProductVariant(
      createProductVariantInput: $createProductVariantInput
    ) {
      id
      images
      productUrl
      title
      sku
      size
      color
      slug
      live
      product {
        id
        title
      }
    }
  }
`;

export const UPDATE_PRODUCT_VARIANT_MUTATION = gql`
  mutation updateProductVariant(
    $updateProductVariantInput: UpdateProductVariantInput!
  ) {
    updateProductVariant(
      updateProductVariantInput: $updateProductVariantInput
    ) {
      id
      images
      productUrl
      title
      sku
      size
      color
      slug
      live
    }
  }
`;

//new product-service queries and mutations starts from here

export const GET_BULK_PRODUCT_UPLOAD = gql`
  query getBulkProductUpload($filter: GetBulkProductInput!) {
    getBulkProductUpload(filter: $filter) {
      total
      bulkProducts {
        id
        numericId
        brand {
          name
          id
        }
        type
        createdAt
        updated_at
        status
        filename
        filePath
        tranformedFileUrl
        tranformedFilePath
        message
        url
        createdByUser {
          name
          username
        }
        node
      }
    }
  }
`;

export const CHANGE_STATUS_BULK_PRODUCT_UPLOAD = gql`
  mutation changeStatusBulkProductUpload(
    $updatedData: UpdateBulkProductInput!
  ) {
    changeStatusBulkProductUpload(updatedData: $updatedData) {
      id
      status
      message
    }
  }
`;

export const CHANGE_STATUS_SUPER_BULK_PRODUCT_UPLOAD = gql`
  mutation changeSuperAdminBulkProductStatus(
    $updatedData: UpdateSuperAdminBulkProductDTO!
  ) {
    changeSuperAdminBulkProductStatus(data: $updatedData) {
      id
      status
      message
    }
  }
`;

export const GET_PRODUCT_VARIANT_LIST = gql`
  query getVariants($filter: GetVariantsInput!) {
    getVariants(filter: $filter) {
      variants {
        id
        product {
          id
          bulkProduct
          parentArticle
          brand {
            name
          }
        }
        barCode
        productPrice {
          mrp
          msp
          netSellerPayable
          listingPrice
          productVariantId
        }
        bulkProductUpload {
          filename
        }
        sampleFlag
        ginesys_response
        articleName
        itemName
        status
        message
        imageUrl
        skuId
        availableQty
        totalQty
        mcCode
        ERPCreatedDate
        broadwayCreatedDate
        isSample
      }
      total
    }
  }
`;

export const ADD_SINGLE_PRODUCT = gql`
  mutation addSingleProduct($data: CreateProductInput!) {
    addProduct(data: $data) {
      product {
        parentArticle
        department
        section
        division
        id
      }
      id
      ginesys_field_map
    }
  }
`;
export const ALL_PRODUCT_VARIANT_LIST = gql`
  query getVariants($limit: Float!, $skip: Float!, $brandId: String) {
    getVariants(filter: { limit: $limit, skip: $skip, brandId: $brandId }) {
      variants {
        id
        product {
          brand {
            id
            name
          }
        }
        vendorArticleNumber
      }
      total
    }
  }
`;

export const GET_SUPER_ADMIN_BULK_PRODUCT = gql`
  query GetSuperAdminBulkProduct($filter: GetSuperAdminBulkProduct!) {
    getSuperAdminBulkProduct(filter: $filter) {
      data {
        id
        numericId
        filename
        createdAt
        updated_at
        createdByUser {
          name
          username
        }
        status
        url
        message
      }
      total
    }
  }
`;

export const GET_BULK_PRICE_UPDATES = gql`
  query GetbulkPriceUpdates($filter: GetBulkPriceUpdateInput!) {
    getbulkPriceUpdates(filter: $filter) {
      total
      bulkPriceUpdates {
        id
        filename
        path
        publicUrl
        createDate
        updateDate
        status
        gSyncStatus
        createdBy
        updatedBy
        brand {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_BULK_PRICE_UPDATE = gql`
  mutation CreateBulkPriceUpdate($input: CreateBulkPriceUpdateInput!) {
    createBulkPriceUpdate(createBulkPriceUpdateInput: $input) {
      id
      filename
      path
      publicUrl
      createDate
      updateDate
      status
    }
  }
`;

export const DELETE_BULK_PRICE_UPDATE = gql`
  mutation RemoveBulkPriceUpdate($id: String!) {
    removeBulkPriceUpdate(id: $id) {
      id
    }
  }
`;

export const CHANGE_STATUS_BULK_PRICE_UPDATE = gql`
  mutation changeStatusBulkPriceUpdate(
    $updatedData: UpdateBulkPriceUpdateInput!
  ) {
    changeStatusBulkPriceUpdate(updatedData: $updatedData) {
      id
      status
      message
    }
  }
`;

export const GET_BULK_PRICE_UPDATE_AUDIT = gql`
  query GetbulkPriceAudits($filter: GetBulkPriceAuditInput!) {
    getbulkPriceAudits(filter: $filter) {
      total
      bulkPriceAudits {
        id
        updatedBy
        updateDate
        brand {
          id
          name
        }
        priceUpdates
      }
    }
  }
`;

export const GET_CYCLE_COUNT_DATA = gql`
  query listCycleCount(
    $take: Int
    $skip: Int
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $filter: JSONObject
  ) {
    cycleCountList(
      listPromotionFilter: {
        take: $take
        skip: $skip
        search: $search
        startDate: $startDate
        endDate: $endDate
        filter: $filter
      }
    ) {
      total
      results {
        id
        isSynced
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
        categories
        brands
        itemCycleCount {
          barcode
          createdAt
          updatedAt
          createdBy
          updatedBy
          variantId
          count {
            floorCount
          }
        }
      }
    }
  }
`;
