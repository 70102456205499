import { useQuery } from '@apollo/client';
import { UploadSharp } from '@mui/icons-material';
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, styled } from '@mui/material';
import MDButton from 'components/MDButton';
import { vmServiceClient } from 'graphql/client';
import React, { useContext, useState } from 'react'
import { LIST_ALL_VIDEOS } from 'services/advertisment-service';
import { DropDownSelectionContext } from '..';
const videoOptions = [
    { label: "Video 1", value: "video1" },
    { label: "Video 2", value: "video2" },
    { label: "Video 3", value: "video3" },
  ];
  
  const Input = styled("input")({
    display: "none",
  });
export default function NewPlaylistForm({ handleClose, onSubmit }) {
  const { selectedBrandId, user, Brand} = useContext(DropDownSelectionContext);
    const { loading, error, data:videoListData, refetch } = useQuery(LIST_ALL_VIDEOS, {
        client: vmServiceClient,
        variables: {
          "page": 1,
          "limit": 100,
          "sortBy": "updatedAt",
          "order": "DESC",
          "relations": [],
          "searchCols": ["brandId"],
          "searchBy":[selectedBrandId],
          "filter":{status: 'approved'}
        },
      });
    const [name, setName] = useState("");
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };
  
    const handleVideoChange = (event) => {
      setSelectedVideos(event.target.value);
    };
  
    const handleFileUpload = (event) => {
      setUploadedFiles(event.target.files);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Process form submission here
      handleClose(); // Close the modal after form submission
      onSubmit({name,selectedVideos});
    };
  
    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={handleNameChange}
              variant="outlined"
            />
          </Grid>
  
          <Grid item xs={12}>
            <FormControl fullWidth  sx={{"& .MuiSelect-select":{height:'100%', overflow:'auto'}}}>
              <InputLabel id="video-select-label">Select New Video</InputLabel>
              <Select
                labelId="video-select-label"
                multiple
                value={selectedVideos}
                onChange={handleVideoChange}
                input={<OutlinedInput sx={{height:100, overflow:'auto'}} id="select-multiple-chip" label="Select New Video" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap:'wrap', gap:0.5,  }}>
                    {selected.map((video) => (
                      <Chip key={video.id} label={video.name} />
                    ))}
                  </Box>
                )}
              >
                {videoListData?.findAllVideos?.results.map((video) => (
                  <MenuItem key={video.id} value={video}>
                    {video.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
  
          {/* <Grid item xs={12}>
            <label htmlFor="upload-button">
              <Input
                id="upload-button"
                type="file"
                multiple
                onChange={handleFileUpload}
              />
              <Button
                fullWidth
                variant="contained"
                component="span"
                startIcon={<UploadSharp />}
              >
                Upload Video
              </Button>
            </label>
          </Grid> */}
  
          <Grid item xs={12}>
          <MDButton
                type="button"
                variant="contained"
                circular={true}
                color="dark"
                sx={{ borderRadius: "100px" }}
                onClick={handleSubmit}
            >
                Create Playlist
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    );
}
