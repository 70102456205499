import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_STORES_LIST_NEW } from 'services/store-service';
import { dashboardServiceClient } from 'graphql/client';
import { getFlatIdMap } from './utils';

export const useStoreSelection = ({
    storeId = '',
    locationId = '',
    subLocationId = '',
    subLocationTypeId = '',
    assetTypeCode='',
} = {}) => {
    const [storeSelection, setStoreSelection] = useState({
        selectedStore: null,
        locations: [],
        selectedLocation: null,
        subLocations: [],
        selectedSubLocation: null,
        assetTypeBatchCodes: [],
        selectedAssetTypeBatchCode: null,
        assetTypeCodes: [],
        selectedAssetTypeCode: null,
        storeIdMap: null,
    });

    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
    } = useQuery(GET_STORES_LIST_NEW, {
        client: dashboardServiceClient,
        variables: { input: { take: 100, skip: 0 } },
    });

    useEffect(() => {
        if (storeList?.stores?.results?.length > 0) {
            const map = getFlatIdMap(storeList.stores.results);
            setStoreSelection((prevState) => ({
                ...prevState,
                storeIdMap: map,
                selectedStore: map[storeId] || null,
                selectedLocation: map[locationId] || null,
                selectedSubLocation: map[subLocationId] || null,
                selectedAssetTypeBatchCode: map[subLocationTypeId]?.batchCode || null,
                selectedAssetTypeCode: map[assetTypeCode]?.code || null,
            }));
        }
    }, [storeList]);

    useEffect(() => {
        if (storeSelection.storeIdMap) {
            setStoreSelection((prevState) => ({
                ...prevState,
                selectedStore: storeSelection.storeIdMap[storeId] || null,
            }));
        }
    }, [storeId, storeSelection.storeIdMap]);

    useEffect(() => {
        if (storeSelection.storeIdMap) {
            setStoreSelection((prevState) => ({
                ...prevState,
                selectedLocation: storeSelection.storeIdMap[locationId] || null,
            }));
        }
    }, [locationId, storeSelection.storeIdMap]);

    useEffect(() => {
        if (storeSelection.storeIdMap) {
            setStoreSelection((prevState) => ({
                ...prevState,
                selectedSubLocation: storeSelection.storeIdMap[subLocationId] || null,
            }));
        }
    }, [subLocationId, storeSelection.storeIdMap]);

    useEffect(() => {
        if (storeSelection.storeIdMap) {
            setStoreSelection((prevState) => ({
                ...prevState,
                selectedAssetTypeBatchCode: storeSelection.storeIdMap[subLocationTypeId]?.batchCode || null,
            }));
        }
    }, [subLocationTypeId, storeSelection.storeIdMap]);
    useEffect(() => {
        if (storeSelection.storeIdMap) {
            setStoreSelection((prevState) => ({
                ...prevState,
                selectedAssetTypeCode: storeSelection.storeIdMap[assetTypeCode]?.code || null,
            }));
        }
    }, [assetTypeCode, storeSelection.storeIdMap]);

    useEffect(() => {
        if (storeSelection.selectedStore && storeSelection.selectedStore.storeLocation) {
            setStoreSelection((prevState) => ({
                ...prevState,
                locations: storeSelection.selectedStore.storeLocation?.filter((item) => !item.isDeleted),
            }));
        } else {
            setStoreSelection((prevState) => ({
                ...prevState,
                locations: [],
            }));
        }
    }, [storeSelection.selectedStore]);

    useEffect(() => {
        if (storeSelection.selectedLocation && storeSelection.selectedLocation.subLocations) {
            setStoreSelection((prevState) => ({
                ...prevState,
                subLocations: storeSelection.selectedLocation.subLocations?.filter((item) => !item.isDeleted),
            }));
        } else {
            setStoreSelection((prevState) => ({
                ...prevState,
                subLocations: [],
            }));
        }
    }, [storeSelection.selectedLocation]);

    useEffect(() => {
        if (storeSelection.selectedSubLocation && storeSelection.selectedSubLocation.assetTypes) {
            setStoreSelection((prevState) => ({
                ...prevState,
                assetTypeBatchCodes: [...new Set(storeSelection.selectedSubLocation.assetTypes
                                        ?.filter((item) => !item.isDeleted)
                                        ?.map((assetType) =>assetType.batchCode))].map((batchCode) =>({
                                            label: batchCode,
                                            value: batchCode,
                                        }))
                ,
            }));
        } else {
            setStoreSelection((prevState) => ({
                ...prevState,
                assetTypeBatchCodes: [],
            }));
        }
    }, [storeSelection.selectedSubLocation]);

    useEffect(() => {
        if (storeSelection.selectedAssetTypeBatchCode && storeSelection.selectedSubLocation && storeSelection.selectedSubLocation.assetTypes) {
            setStoreSelection((prevState) => ({
                ...prevState,
                assetTypeCodes: storeSelection.selectedSubLocation.assetTypes
                                    ?.filter((item) => !item.isDeleted)
                                    ?.filter((assetType=>assetType.batchCode === storeSelection.selectedAssetTypeBatchCode))
                                    ?.map(assetType =>({label:assetType.code, value:assetType.code})),
            }));
        } else {
            setStoreSelection((prevState) => ({
                ...prevState,
                assetTypeCodes: [],
            }));
        }
    }, [storeSelection.selectedAssetTypeBatchCode]);

    const setSelectedStore = (store) => {
        setStoreSelection((prevState) => ({
            ...prevState,
            selectedStore: store,
            selectedLocation: null,
            selectedSubLocation: null,
            selectedAssetTypeBatchCode: null,
            selectedAssetTypeCode: null
        }));
    };

    const setSelectedLocation = (location) => {
        setStoreSelection((prevState) => ({
            ...prevState,
            selectedLocation: location,
            selectedSubLocation: null,
            selectedAssetTypeBatchCode: null,
            selectedAssetTypeCode: null
        }));
    };

    const setSelectedSubLocation = (sublocation) => {
        setStoreSelection((prevState) => ({
            ...prevState,
            selectedSubLocation: sublocation,
            selectedAssetTypeBatchCode: null,
            selectedAssetTypeCode: null
        }));
    };

    const setSelectedAssetTypeBatchCode = (batchCode) => {
        setStoreSelection((prevState) => ({
            ...prevState,
            selectedAssetTypeBatchCode: batchCode,
            selectedAssetTypeCode:null
        }));
    };

    const setSelectedAssetTypeCode = (code) => {
        setStoreSelection((prevState) => ({
            ...prevState,
            selectedAssetTypeCode: code,
        }));
    };

    

    const loading = storeLoading || !storeSelection.storeIdMap;

    return {
        ...storeSelection,
        setSelectedStore,
        setSelectedLocation,
        setSelectedSubLocation,
        setSelectedAssetTypeBatchCode,
        setSelectedAssetTypeCode,
        storeLoading,
        storeError,
        storeList,
        loading,
    };
};

export default useStoreSelection;