import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box, TablePagination, IconButton, Icon, FormControl, Select, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from "@apollo/client";
import { LIST_ALL_DEVICE } from '../../services/advertisment-service';
import { vmServiceClient } from 'graphql/client';
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from 'react';
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";



const columns = [
    // {
    //     field: "id",
    //     headerName: "ID",
    //     width: 250,
    // },
    {
        field: "fileName",
        headerName: "File Name",
        width: 300,
    },
    {
        field: "createdOn",
        headerName: "Created On",
        width: 300,
    },
    {
        field: "updatedOn",
        headerName: "Updated On",
        width: 300,
    },
    
    {
      field: "status",
      headerName: "Status",
      width: 300,
      renderCell: (params) => (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value="approved"
            onChange={(event) =>
              handleStatusChange(params.row.id, event.target.value)
            }
            displayEmpty
            IconComponent={() => (
              <ArrowDropDownIcon style={{ marginRight: "18px" }} />
            )}
            sx={{
              height:"40px"
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="reject">Reject</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 300,
        sortable: false,
        renderCell: (params) => (
            <IconButton aria-label="delete" >
                <Icon component={deleteIcon} fontSize="small" />
            </IconButton>
        )
    },
];

function BulkDevice({ onRowClick }) {
    const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });

    const { loading, error, data, refetch } = useQuery(LIST_ALL_DEVICE, {
        client: vmServiceClient,
        variables: {
            "page": paginationModel.page+1,
            "limit": paginationModel.pageSize,
            "sortBy": "uuid",
            "order": "DESC",
            "relations": ["devicePlaylists", "devicePlaylists.playlistVideos", "deviceLocation"]
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setRowCount(data?.findAllDevices?.total);
        },
    });
    const [rowCount, setRowCount] = React.useState(data?.findAllDevices?.total || 0);

    React.useEffect(() => {
        setRowCount((prevRowCount) =>
            data?.findAllDevices?.total !== undefined
                ? data?.findAllDevices?.total
                : prevRowCount,
        );
    }, [data?.findAllDevices?.total, setRowCount]);
    if (loading)
        return (
            <>
                <Loader />
            </>
        );
    if (error) return <p>Error: {error?.message}</p>;

    const allDevices = data?.findAllDevices?.results;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <DataGrid
            autoHeight
            rows={allDevices}
            columns={columns}
            rowCount={rowCount}
            pageSizeOptions={[10, 20, 50, 100]}
            getRowId={(row) => row.uuid}
            onRowClick={onRowClick}
            loading={loading}
            onPageChange={handleChangePage}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
        />
    );
}

export default BulkDevice;


