import { useLazyQuery, useMutation } from "@apollo/client";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import MDButton from "components/MDButton";
import FileUploaderContainer from "components/MDFileUpload";
import TagsInput from "components/TagsInput/TagsInput";
import { useNotification } from "context";
import dayjs from "dayjs";
import { Field, FieldArray, Form, Formik } from "formik";
import { feedServiceClient } from "graphql/client";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import {
  CREATE_COMPONENT_METADATA,
  UPDATE_COMPONENT_METADATA,
} from "services/ecommerce-component-service";
import {
  CREATE_SECTION,
  CREATE_SECTION_METADATA,
  GET_SECTION_LISTING,
  REMOVE_SECTION_METADATA,
  UPDATE_SECTION,
  UPDATE_SECTION_METADATA,
} from "services/ecommerce-section-service";
import * as Yup from "yup";
import {
  dashboardServiceClient,
  eventServiceClient,
  productServiceClient,
} from "../../../graphql/client";
import { GET_BRANDS_LIST } from "../../../services/brand-service";
import { GET_EVENTS } from "../../../services/events-service";
import { GET_NEWS } from "../../../services/news-service";
import { GET_CATEGORIES } from "../../../services/product-category-service";
import { GET_PRODUCT_VARIANT_LIST } from "../../../services/product-service";
import useDebounce from "./debounce";
import {
  generateInitialValues,
  generateYupSchema,
  getExtraFields,
} from "./utils";

const metadataSchema = Yup.object().shape({
  key: Yup.string().required("Metadata key is required"),
  value: Yup.lazy((value) => {
    try {
      JSON.parse(value);
      return Yup.string().required(
        "Metadata value must be a valid JSON string"
      );
    } catch (error) {
      return Yup.string().test("is-json", "Invalid JSON format", () => false);
    }
  }),
});
export const validationSchema = Yup.object({
  name: Yup.string().required("Section name is required"),
  internalName: Yup.string().required("Section internal name is required"),
  displayOrder: Yup.number().required("Display Order is required"),
  metadata: Yup.array().of(metadataSchema),
});

const addTimeToDate = (date, time) => {
  if (time == null) return date;
  if (date == null) return null;
  const [hours, minutes] = time.split(":").map(Number);

  // Set the time on the parsed date
  const mergedDateTime = date.hour(hours).minute(minutes);
  return mergedDateTime;
};

const dateValidationSchema = Yup.object()
  .shape({
    startDate: Yup.date().nullable().notRequired(),
    startTime: Yup.string().nullable().notRequired(),
    endDate: Yup.date().nullable().notRequired(),
    endTime: Yup.string().nullable().notRequired(),
  })
  .test("start-end-datetime", function (values) {
    const { startDate, startTime, endDate, endTime } = values;
    let startDateObject;
    let startDateTime;
    let endDateObject;
    let endDateTime;
    if (startDate) {
      startDateObject = dayjs(`${startDate}`);
      startDateTime = startTime
        ? addTimeToDate(startDateObject, startTime)
        : startDateObject;
      if (startDateTime.isBefore(dayjs())) {
        return this.createError({
          path: "startDate",
          message: "Start date and time must be before current time",
        });
      }
    }

    if (endDate) {
      endDateObject = dayjs(`${endDate}`);
      endDateTime = endTime
        ? addTimeToDate(endDateObject, endTime)
        : endDateObject;
      if (endDateTime.isBefore(dayjs())) {
        return this.createError({
          path: "endDate",
          message: "End date and time must be before current time",
        });
      }
    }

    if (startDateTime && endDateTime) {
      if (!startDateTime.isBefore(endDateTime)) {
        return this.createError({
          path: "startDate",
          message: "Start date and time must be before end date and time",
        });
      }
    }

    return true;
  });

const user =
  localStorage.getItem("userDetails") &&
  localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {};

const Page = {
  welcom: "Welcome",
  brandLanding: "Brand Landing Page",
  brandDetail: "Brand Page",
  eventListing: "Event Listing",
};
Object.freeze(Page);

const CategoryDropDown = ({
  categoryList,
  setSearchCategoryValues,
  loading,
  schema,
  ...props
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="catgory"
      multiple={schema.type == "array"}
      value={props.values.category || (schema.type == "array" ? [] : null)}
      options={
        categoryList?.map((item) => ({
          id: item.id,
          label: `${
            item.parent?.metaTitle ? `${item.parent?.metaTitle} /` : ""
          } ${item.metaTitle}`,
          metaTitle: item.metaTitle,
        })) || []
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        props.setFieldValue("category", newValue);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchCategoryValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchCategoryValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchCategoryValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Parent Category"
          helperText={props.touched.catgory ? props.errors.catgory : ""}
          error={props.touched.catgory && Boolean(props.errors.catgory)}
        />
      )}
    />
  </Grid>
);

const BrandDropDown = ({
  brandList,
  setSearchBrandValues,
  loading,
  schema,
  ...props
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="brand"
      multiple={schema.type == "array"}
      value={props.values.brand || (schema.type == "array" ? [] : null)}
      options={
        brandList?.map((item) => ({
          id: item.id,
          label: `${item.name}`,
        })) || []
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        props.setFieldValue("brand", newValue);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchBrandValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchBrandValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchBrandValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Brand"
          helperText={props.touched.brand ? props.errors.brand : ""}
          error={props.touched.brand && Boolean(props.errors.brand)}
        />
      )}
    />
  </Grid>
);

const EventDropDown = ({
  eventList,
  setSearchEventValues,
  loading,
  schema,
  ...props
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="event"
      multiple={schema.type == "array"}
      value={props.values.event || (schema.type == "array" ? [] : null)}
      options={
        eventList?.map((item) => ({
          id: item.id,
          label: `${item.name}`,
        })) || []
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        props.setFieldValue("event", newValue);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchEventValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchEventValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchEventValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Event"
          helperText={props.touched.event ? props.errors.event : ""}
          error={props.touched.event && Boolean(props.errors.event)}
        />
      )}
    />
  </Grid>
);

const ProductDropDown = ({
  productList,
  setSearchProductValues,
  loading,
  schema,
  ...props
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="product"
      multiple={schema.type == "array"}
      value={props.values.product || (schema.type == "array" ? [] : null)}
      options={
        productList?.map((item) => ({
          id: item.id,
          label: `${item.skuId} - ${item.itemName}`,
        })) || []
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        props.setFieldValue("product", newValue);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchProductValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchProductValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchProductValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Product"
          helperText={props.touched.product ? props.errors.product : ""}
          error={props.touched.product && Boolean(props.errors.product)}
        />
      )}
    />
  </Grid>
);

const BlogNewsDropDown = ({
  newsList,
  setSearchNewsValues,
  loading,
  schema,
  ...props
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="news"
      multiple={schema.type == "array"}
      value={props.values.news || (schema.type == "array" ? [] : null)}
      options={
        newsList?.map((item) => ({
          id: item.id,
          label: `${item.name}`,
        })) || []
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        props.setFieldValue("news", newValue);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchNewsValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchNewsValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchNewsValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select News"
          helperText={props.touched.news ? props.errors.news : ""}
          error={props.touched.news && Boolean(props.errors.news)}
        />
      )}
    />
  </Grid>
);

const SectionForm = ({
  handleClose = () => {},
  page = Page.brandLanding,
  section = {},
  formDataRows = [],
  component = null,
  selectCategory = false,
  selectBrand = false,
  selectEvent = false,
  selectProduct = false,
  selectNews = false,
  selectMedia = false,
  showCtaText = false,
  showCtaLink = false,
  showStatus = false,
  statusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Live", value: "Live" },
    { label: "Delist", value: "Delist" },
  ],
  formData = {},
  jsonSchema = {},
}) => {
  const { setNotification } = useNotification();

  const getMinMaxDate = (
    eventEndDate = null,
    eventStartDate = null,
    timeFor = "startTime"
  ) => {
    const storeStartDay = new Date();
    const storeEndDay = new Date();
    if (timeFor === "startTime") {
      return {
        min: dayjs(storeStartDay, "YYYY-MM-DD"),
        max: eventEndDate
          ? dayjs(eventEndDate, "YYYY-MM-DD")
          : dayjs(storeEndDay, "YYYY-MM-DD"),
      };
    } else {
      return {
        min: eventStartDate
          ? dayjs(eventStartDate, "YYYY-MM-DD")
          : dayjs(storeStartDay, "YYYY-MM-DD"),
        max: dayjs(storeEndDay, "YYYY-MM-DD"),
      };
    }
  };

  //   * to help with Category Selections
  const [searachCategoryValues, setSearchCategoryValues] = useState("");
  const debouncedCategorySearchTerm = useDebounce(searachCategoryValues, 300);
  const [
    getCategories,
    { loading: categoryLoading, data: categoryData, error: categoryError },
  ] = useLazyQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 20,
        skip: 0,
        search: debouncedCategorySearchTerm,
      },
    },
  });

  // * to help with Brand Selections
  const [searachBrandValues, setSearchBrandValues] = useState("");
  const debouncedBrandSearchTerm = useDebounce(searachBrandValues, 300);
  const [
    getBrandList,
    { loading: brandLoading, data: brandData, error: brandError },
  ] = useLazyQuery(GET_BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: 20,
      skip: 0,
      search: debouncedBrandSearchTerm,
      filter: {},
    },
  });

  // * to help with Event Selections
  const [searchEventValues, setSearchEventValues] = useState("");
  const debouncedEventSearchTerm = useDebounce(searchEventValues, 300);
  const [
    getEventList,
    { loading: eventLoading, data: eventData, error: eventError },
  ] = useLazyQuery(GET_EVENTS, {
    client: eventServiceClient,
    variables: {
      eventListFilter: {
        take: 20,
        skip: 0,
        search: debouncedEventSearchTerm,
        filter: {},
      },
    },
  });

  // * to help with Product Selection
  const [searchProductValues, setSearchProductValues] = useState("");
  const debouncedProductSearchTerm = useDebounce(searchProductValues, 300);
  const [
    getProductList,
    { loading: productLoading, data: productData, error: productError },
  ] = useLazyQuery(GET_PRODUCT_VARIANT_LIST, {
    client: productServiceClient,
    variables: {
      filter: {
        limit: 20,
        skip: 0,
        status: "G_SYNCED",
        search: debouncedProductSearchTerm,
        filter: {},
      },
    },
  });

  // * to help with News Selections
  const [searchNewsValues, setSearchNewsValues] = useState("");
  const debouncedNewsSearchTerm = useDebounce(searchNewsValues, 300);
  const [
    getNewsList,
    { loading: newsLoading, data: newsData, error: newsError },
  ] = useLazyQuery(GET_NEWS, {
    client: dashboardServiceClient,
    variables: {
      listNewsFilter: {
        take: 20,
        skip: 0,
        search: debouncedNewsSearchTerm,
        filter: {},
      },
    },
  });

  useEffect(() => {
    selectCategory && getCategories();
    selectBrand && getBrandList();
    selectEvent && getEventList();
    selectProduct && getProductList();
    selectNews && getNewsList();
  }, []);

  useEffect(() => {
    if (debouncedCategorySearchTerm) {
      getCategories(); // Trigger the search when debounced term changes
    }
  }, [debouncedCategorySearchTerm, getCategories]);

  const [createComponentMetadata] = useMutation(CREATE_COMPONENT_METADATA, {
    client: feedServiceClient,
  });
  const [updateComponentMetadata] = useMutation(UPDATE_COMPONENT_METADATA, {
    client: feedServiceClient,
  });
  const handleSubmit = (values) => {
    if (component) {
      if (values.id) {
        const formData = component.componentMetaData.find(
          (meta) =>
            meta.key === "formData" && !meta.isDeleted && meta.id == values.id
        );
        // const formDataValue = formData?.value?.data
        const payload = {
          id: values.id,
          componentId: component.id,
          key: "formData",
          value: {
            data: values,
            startDate: addTimeToDate(
              values.startDate ? dayjs(values.startDate) : null,
              values.startTime
            ),
            endDate: addTimeToDate(
              values.endDate ? dayjs(values.startDate) : null,
              values.endTime
            ),
          },
        };
        updateComponentMetadata({
          variables: {
            input: payload,
          },
          onCompleted: (data) => {
            setNotification({
              type: "success",
              message: "form data updated successfully",
            });
            handleClose(true);
          },
        });
      } else {
        const payload = {
          componentId: component.id,
          key: "formData",
          value: {
            data: {
              ...values,
              status: "Pending",
              startDate: addTimeToDate(
                values.startDate ? dayjs(values.startDate) : null,
                values.startTime
              ),
              endDate: addTimeToDate(
                values.endDate ? dayjs(values.startDate) : null,
                values.endTime
              ),
            },
          },
        };
        createComponentMetadata({
          variables: {
            input: payload,
          },
          onCompleted: (data) => {
            setNotification({
              type: "success",
              message: "form data created successfully",
            });
            handleClose(true);
          },
        });
      }
    }
  };

  const extras = getExtraFields(jsonSchema);
  const validationSchema = generateYupSchema(jsonSchema) || undefined;
  // const initialValues = generateInitialValues({...jsonSchema, startDate:{},endDate:{},startTime:{},endTime:{}}, formData)
  const initialValues = generateInitialValues(jsonSchema, formData);
  return (
    <Formik
      initialValues={{
        page: Page[page] || page,
        sectionName: section.name,
        internalName: "",
        displayOrder: formData?.displayOrder || formDataRows.length,
        status: formData?.status,
        id: formData?.id,
        ...initialValues,
      }}
      validationSchema={
        validationSchema ? validationSchema.concat(dateValidationSchema) : null
      }
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="page"
                  label="Page"
                  fullWidth
                  error={formikProps.touched.name && !!formikProps.errors.name}
                  helperText={
                    formikProps.touched.name && formikProps.errors.name
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="sectionName"
                  label="Section Name"
                  fullWidth
                  error={
                    formikProps.touched.internalName &&
                    !!formikProps.errors.internalName
                  }
                  helperText={
                    formikProps.touched.internalName &&
                    formikProps.errors.internalName
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  as={TextField}
                  type={"number"}
                  name="displayOrder"
                  label="Display Order"
                  fullWidth
                  error={
                    formikProps.touched.displayOrder &&
                    !!formikProps.errors.displayOrder
                  }
                  helperText={
                    formikProps.touched.displayOrder &&
                    formikProps.errors.displayOrder
                  }
                />
              </Grid>

              {selectCategory && (
                <Grid item xs={12}>
                  <CategoryDropDown
                    categoryList={
                      categoryData?.categoryProduct?.results &&
                      categoryData?.categoryProduct?.results.length
                        ? categoryData?.categoryProduct?.results
                        : []
                    }
                    loading={categoryLoading}
                    name="catgory"
                    schema={jsonSchema.category}
                    setSearchCategoryValues={setSearchCategoryValues}
                    {...formikProps}
                  />
                </Grid>
              )}

              {selectBrand && (
                <Grid item xs={12}>
                  <BrandDropDown
                    brandList={
                      brandData?.brandlist?.results &&
                      brandData?.brandlist?.results.length
                        ? brandData?.brandlist?.results
                        : []
                    }
                    loading={brandLoading}
                    name="brand"
                    schema={jsonSchema.brand}
                    setSearchBrandValues={setSearchBrandValues}
                    {...formikProps}
                  />
                </Grid>
              )}

              {selectEvent && (
                <Grid item xs={12}>
                  <EventDropDown
                    eventList={
                      eventData?.events?.results &&
                      eventData?.events?.results.length
                        ? eventData?.events?.results
                        : []
                    }
                    loading={eventLoading}
                    name="event"
                    schema={jsonSchema.event}
                    setSearchEventValues={setSearchEventValues}
                    {...formikProps}
                  />
                </Grid>
              )}

              {selectProduct && (
                <Grid item xs={12}>
                  <ProductDropDown
                    productList={
                      productData?.getVariants?.variants &&
                      productData?.getVariants?.variants?.length
                        ? productData?.getVariants?.variants
                        : []
                    }
                    loading={eventLoading}
                    name="product"
                    schema={jsonSchema.product}
                    setSearchProductValues={setSearchProductValues}
                    {...formikProps}
                  />
                </Grid>
              )}

              {selectNews && (
                <Grid item xs={12}>
                  <BlogNewsDropDown
                    newsList={
                      newsData?.news?.results && newsData?.news?.results.length
                        ? newsData?.news?.results
                        : []
                    }
                    loading={newsLoading}
                    name="news"
                    schema={jsonSchema.news}
                    setSearchNewsValues={setSearchNewsValues}
                    {...formikProps}
                  />
                </Grid>
              )}

              {selectMedia && (
                <Grid item xs={12}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#000",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                      }}
                    ></span>
                    <span
                      style={{
                        color: "#9E9E9E",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {" "}
                      Please upload JPEG or PNG files only.
                    </span>
                  </Typography>
                  <FileUploaderContainer
                    isMultiple={jsonSchema?.media?.isMultiple}
                    type={jsonSchema?.media?.mediaType}
                    fieldName={"Media file"}
                    preLoadedFile={
                      !!formikProps.values.media
                        ? jsonSchema?.media?.isMultiple
                          ? formikProps.values.media.map((file) => file.path)
                          : [formikProps.values.media.path]
                        : null
                    }
                    signedUrlCallback={(
                      newUrl,
                      index,
                      publicUrl,
                      data,
                      name,
                      metadata
                    ) => {
                      if (jsonSchema?.media?.isMultiple) {
                        const mediaValue = formikProps.values.media || [];
                        const updatedImagePathArray = [...mediaValue];
                        if (publicUrl) {
                          updatedImagePathArray[index] = {
                            path: newUrl,
                            publicUrl,
                            fileName: name,
                            metadata,
                          };
                        } else {
                          updatedImagePathArray.splice(index, 1);
                        }
                        formikProps.setFieldValue(
                          "media",
                          updatedImagePathArray
                        );
                      } else {
                        formikProps.setFieldValue("media", {
                          path: newUrl,
                          publicUrl,
                          fileName: name,
                          metadata,
                        });
                      }
                    }}
                    isFieldValid={
                      formikProps.touched.media && formikProps.errors?.media
                    }
                  />
                  {formikProps.touched.media && formikProps.errors.media && (
                    <Typography fontSize={12} color="error">
                      {formikProps.errors.media}
                    </Typography>
                  )}
                </Grid>
              )}

              {showCtaText && (
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="ctaText"
                    label="CTA Text"
                    fullWidth
                    error={
                      formikProps.touched.ctaText &&
                      !!formikProps.errors.ctaText
                    }
                    helperText={
                      formikProps.touched.ctaText && formikProps.errors.ctaText
                    }
                  />
                </Grid>
              )}

              {showCtaLink && (
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="ctaLink"
                    label="CTA Link"
                    fullWidth
                    error={
                      formikProps.touched.ctaLink &&
                      !!formikProps.errors.ctaLink
                    }
                    helperText={
                      formikProps.touched.ctaLink && formikProps.errors.ctaLink
                    }
                  />
                </Grid>
              )}
              {/* EXTRA FIELDS */}
              {extras.map((fieldName) => {
                const fieldSchema = jsonSchema[fieldName];
                if (
                  fieldSchema?.inputType == "text" ||
                  fieldSchema?.inputType == "number"
                ) {
                  return (
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        type={fieldSchema.inputType || "text"}
                        name={fieldName}
                        label={fieldSchema?.label || fieldName}
                        fullWidth
                        error={
                          formikProps.touched[fieldName] &&
                          !!formikProps.errors[fieldName]
                        }
                        helperText={
                          formikProps.touched[fieldName] &&
                          formikProps.errors[fieldName]
                        }
                      />
                    </Grid>
                  );
                } else if (fieldSchema?.inputType == "richtext") {
                  return (
                    <Grid item xs={12}>
                      <Typography variant="h6">{fieldSchema.label}</Typography>
                      <ReactQuill
                        theme="snow"
                        value={formikProps?.values?.[fieldName]}
                        onChange={(content) => {
                          formikProps.setFieldValue(`${fieldName}`, content);
                        }}
                      />
                      {formikProps.touched?.[fieldName] &&
                        formikProps.errors?.[fieldName] && (
                          <Typography fontSize={12} color="error">
                            {formikProps.errors?.[fieldName]}
                          </Typography>
                        )}
                    </Grid>
                  );
                } else if (fieldSchema?.inputType == "tag") {
                  return (
                    <Grid item xs={12}>
                      <TagsInput
                        label={fieldSchema.label || fieldName}
                        inputValue={formikProps.values?.[fieldName] || []}
                        setInputValue={(value) =>
                          formikProps.setFieldValue(`${fieldName}`, value)
                        }
                        onChange={(value) =>
                          formikProps.setFieldValue(`${fieldName}`, value)
                        }
                      />
                      {formikProps.touched?.[fieldName] &&
                        formikProps.errors?.[fieldName] && (
                          <Typography fontSize={12} color="error">
                            {formikProps.errors?.[fieldName]}
                          </Typography>
                        )}
                    </Grid>
                  );
                } else if (fieldSchema?.inputType == "select") {
                  return (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id={`select-${fieldName}`}>
                          {fieldSchema?.label}
                        </InputLabel>
                        <Select
                          as={Select}
                          labelId={`select-${fieldName}`}
                          id={`item-select-${fieldName}`}
                          name={fieldName}
                          label={fieldSchema?.label}
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px" }}
                            />
                          )}
                          sx={{ height: 40 }}
                          value={formikProps.values?.[fieldName]}
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              `${fieldName}`,
                              e.target.value
                            );
                          }}
                          error={
                            formikProps?.touched?.[fieldName] &&
                            Boolean(formikProps?.errors?.[fieldName])
                          }
                          helperText={
                            formikProps?.touched?.[fieldName] &&
                            formikProps?.errors?.[fieldName]
                          }
                        >
                          {fieldSchema?.options &&
                            fieldSchema?.options?.length > 0 &&
                            fieldSchema?.options?.map((option) => (
                              <MenuItem value={option?.value}>
                                {option?.label}
                              </MenuItem>
                            ))}
                        </Select>
                        {formikProps?.touched?.[fieldName] &&
                        formikProps?.errors?.[fieldName] ? (
                          <Typography fontSize={12} color="error">
                            {formikProps?.errors?.[fieldName]}
                          </Typography>
                        ) : null}
                      </FormControl>
                    </Grid>
                  );
                } else if (fieldSchema?.inputType == "toggle") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <InputLabel sx={{ mr: 1 }} id={`select-${fieldName}`}>
                        {fieldSchema?.label}
                      </InputLabel>
                      <Switch
                        color="primary"
                        as={Switch}
                        labelId={`toggle-${fieldName}`}
                        id={`item-toggle-${fieldName}`}
                        name={fieldName}
                        value={formikProps.values?.[fieldName]}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            `${fieldName}`,
                            e.target.checked
                          );
                        }}
                        error={
                          formikProps?.touched?.[fieldName] &&
                          Boolean(formikProps?.errors?.[fieldName])
                        }
                        helperText={
                          formikProps?.touched?.[fieldName] &&
                          formikProps?.errors?.[fieldName]
                        }
                      />
                      {formikProps?.touched?.[fieldName] &&
                      formikProps?.errors?.[fieldName] ? (
                        <Typography fontSize={12} color="error">
                          {formikProps?.errors?.[fieldName]}
                        </Typography>
                      ) : null}
                    </Grid>
                  );
                }
              })}

              {formikProps.values.status && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-status">Status</InputLabel>
                    <Select
                      as={Select}
                      labelId="select-status"
                      id="item-select-status"
                      name="status"
                      label="Status"
                      IconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                      )}
                      sx={{ height: 40 }}
                      value={formikProps.values.status}
                      onChange={(e) => {
                        formikProps.setFieldValue("status", e.target.value);
                        formikProps.setFieldTouched(`status`, true, false);
                        // formikProps.validateField(`status`, e.target.value);
                      }}
                      error={
                        formikProps.touched.status &&
                        Boolean(formikProps.errors.status)
                      }
                      helperText={
                        formikProps.touched.status && formikProps.errors.status
                      }
                    >
                      {statusOptions &&
                        statusOptions.length > 0 &&
                        statusOptions.map((status) => (
                          <MenuItem value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                    </Select>
                    {formikProps.touched.status && formikProps.errors.status ? (
                      <Typography fontSize={12} color="error">
                        {formikProps.errors.status}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        formikProps.touched && !!formikProps.touched.startDate
                          ? {
                              textField: {
                                error:
                                  formikProps.touched &&
                                  formikProps.touched.startDate &&
                                  formikProps.errors &&
                                  !!formikProps.errors.startDate,
                                helperText:
                                  formikProps.touched &&
                                  formikProps.touched.startDate &&
                                  formikProps.errors &&
                                  formikProps.errors.startDate,
                              },
                            }
                          : null
                      }
                      label="Start Date"
                      name={`startDate`}
                      minDate={dayjs()}
                      value={
                        formikProps.values.startDate
                          ? dayjs(formikProps.values.startDate)
                          : null
                      }
                      onChange={(newValue) => {
                        formikProps.setFieldTouched("startDate", true);
                        formikProps.setFieldValue(
                          `startDate`,
                          dayjs(newValue).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={
                            formikProps.touched?.startDate &&
                            Boolean(errors?.startDate)
                          }
                          helperText={
                            formikProps?.touched?.startDate &&
                            formikProps?.errors?.startDate
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {/* {formikProps.touched.startDate && formikProps.errors.startDate ? (
                  <Typography fontSize={12} color="error">
                    {formikProps.errors.startDate}
                  </Typography>
                ) : null} */}
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      slotProps={
                        formikProps.touched && !!formikProps.touched.startTime
                          ? {
                              textField: {
                                error:
                                  formikProps.touched &&
                                  formikProps.touched.startTime &&
                                  formikProps.errors &&
                                  !!formikProps.errors.startTime,
                                helperText:
                                  formikProps.touched &&
                                  formikProps.touched.startTime &&
                                  formikProps.errors &&
                                  formikProps.errors.startTime,
                              },
                            }
                          : null
                      }
                      label="Start Time"
                      name={`startTime`}
                      value={
                        formikProps.values.startTime
                          ? dayjs(formikProps.values.startTime, "HH:mm")
                          : null
                      }
                      onChange={(newValue) => {
                        formikProps.setFieldValue(
                          `startTime`,
                          dayjs(newValue).format("HH:mm")
                        );
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              {/* {
                formikProps.touched?.startDate &&
                formikProps.errors?.startDate && (
                  <Typography fontSize={12} color="error">
                    {formikProps.errors?.startDate}
                  </Typography>
                )
              } */}

              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        formikProps.touched && !!formikProps.touched.endDate
                          ? {
                              textField: {
                                error:
                                  formikProps.touched &&
                                  formikProps.touched.endDate &&
                                  formikProps.errors &&
                                  !!formikProps.errors.endDate,
                                helperText:
                                  formikProps.touched &&
                                  formikProps.touched.endDate &&
                                  formikProps.errors &&
                                  formikProps.errors.endDate,
                              },
                            }
                          : null
                      }
                      label="End Date"
                      name={`endDate`}
                      minDate={dayjs()}
                      value={
                        formikProps.values.endDate
                          ? dayjs(formikProps.values.endDate)
                          : null
                      }
                      onChange={(newValue) => {
                        formikProps.setFieldTouched("endDate", true);
                        formikProps.setFieldValue(
                          `endDate`,
                          dayjs(newValue).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={
                            touched?.tickets[index]?.endDate &&
                            Boolean(errors?.tickets[index]?.endDate)
                          }
                          helperText={
                            touched?.tickets[index]?.endDate &&
                            errors?.tickets[index]?.endDate
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      slotProps={
                        formikProps.touched && !!formikProps.touched.endTime
                          ? {
                              textField: {
                                error:
                                  formikProps.touched &&
                                  formikProps.touched.endTime &&
                                  formikProps.errors &&
                                  !!formikProps.errors.endTime,
                                helperText:
                                  formikProps.touched &&
                                  formikProps.touched.endTime &&
                                  formikProps.errors &&
                                  formikProps.errors.endTime,
                              },
                            }
                          : null
                      }
                      label="End Time"
                      name={`endTime`}
                      value={
                        formikProps.values.endTime
                          ? dayjs(formikProps.values.endTime, "HH:mm")
                          : null
                      }
                      onChange={(newValue) => {
                        formikProps.setFieldValue(
                          `endTime`,
                          dayjs(newValue).format("HH:mm")
                        );
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <Button
                  color="dark"
                  style={{ backgroundColor: "#FFFFFF" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {/* <p>{JSON.stringify(formikProps.errors)}</p> */}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SectionForm;
