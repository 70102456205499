import { Add, Book } from "@mui/icons-material";
import { Box, Drawer, Grid, Icon, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import InformationGuideForm from "./informationGuideForm";
import InformationGuideListings from "./informationGuideListings";

function InformationGuide() {
  const [open, setOpen] = useState(false);
  const [selectedInformationGuide, setSelectedInformationGuide] = useState(null);
  const [key, setKey] = useState(Date.now())

  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};
  const isAdmin = user && user?.role === "admin" ? true : false;

  const handleItemClick = (item) => {
    setSelectedInformationGuide(item);
    handleOpen();
  };
  const ControlSection = ({ handleOpen, user }) =>
    user && user?.role === "admin" ? (
      <>
        <Grid item xs={12} sm={6} md={6} />
        <Grid item xs={12} sm={6} md={6} textAlign="right">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <Icon sx={{ mr: 2 }} component={SearchIcon} fontSize="large" /> */}
            <MDButton
              type="button"
              variant="contained"
              circular={true}
              color="dark"
              onClick={() => {
                setOpen(true);
                setSelectedInformationGuide(null);
              }}
              startIcon={<Add />}
            >
              New Guide
            </MDButton>
          </Box>
        </Grid>
      </>
    ) : (
      <></>
    );
  const InformationGuideFormDrawer = ({
    open,
    handleClose,
    selectedInformationGuide,
  }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon sx={{ mr: 2, ml: 2 }} component={Book} fontSize="large" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            {selectedInformationGuide
              ? "Update Information Guide"
              : "New Information Guide"}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <InformationGuideForm
          informationGuideData={selectedInformationGuide}
          handleClose={handleClose}
          isAdmin={isAdmin}
        />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = (refresh=false) => {
    setOpen(false);
    setSelectedInformationGuide(null)
    if(refresh) setKey(Date.now())
  }
  

  return (
    <DashboardLayout>
      <Grid item xs={12} sm={6} md={3} key={key}>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }}
            ml={1}
          >
            Information Guides
          </Typography>
          <ControlSection handleOpen={handleOpen} user={user} />
        </Grid>
        {/* <MasterActivityLandingPage onRowClick={handleRowClick} /> */}
        <InformationGuideListings  onItemClick={handleItemClick} isAdmin={isAdmin} />
        <InformationGuideFormDrawer
          open={open}
          handleClose={handleClose}
          selectedInformationGuide={selectedInformationGuide}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default InformationGuide;
