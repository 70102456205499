import { gql } from '@apollo/client';
import axios from 'axios';
import { dashboardServiceClient } from 'graphql/client';

const GET_SIGNED_URL = gql`
  query getSignedUrl($getSignedUrlRequest: GetSignedUrlInput!) {
    getSignedUrl(getSignedUrlRequest: $getSignedUrlRequest) {
      url
      readUrl
    }
  }
`;

export async function uploadFile({ fileData, fileName, signedUrlCallback }) {
  if (!fileData || !fileName) {
    throw new Error("Missing file data or file name");
  }

  // Step 1: Get the signed URL for the upload (write access)
  const { data } = await dashboardServiceClient.query({
    query: GET_SIGNED_URL,
    variables: {
      getSignedUrlRequest: {
        fileName: `dashboard/staging/product/${fileName}`,
        action: "write",
        contentType: "application/octet-stream",
      },
    },
  });

  const uploadUrl = data?.getSignedUrl?.url;
  if (!uploadUrl) {
    throw new Error("Failed to get the signed URL for file upload");
  }

  // Step 2: Upload the file using the signed URL
  await axios.put(uploadUrl, fileData, {
    headers: { "Content-Type": "application/octet-stream" },
  });

  // Step 3: Get the signed URL for reading (read access)
  const { data: readData } = await dashboardServiceClient.query({
    query: GET_SIGNED_URL,
    variables: {
      getSignedUrlRequest: {
        fileName: `dashboard/staging/product/${fileName}`,
        action: "read",
        contentType: "application/octet-stream",
      },
    },
  });

  const readUrl = readData?.getSignedUrl?.readUrl;
  if (!readUrl) {
    throw new Error("Failed to get the read URL after uploading");
  }

  // Step 4: Invoke the callback with the URLs
  signedUrlCallback(`dashboard/staging/product/${fileName}`, fileName, readUrl);
}
