import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import getNewAccessToken from "./refreshToken";
import { setContext } from "@apollo/client/link/context";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions && extensions.code === "UNAUTHENTICATED") {
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          getNewAccessToken(refreshToken)
            .then((newAccessToken) => {
              localStorage.setItem("token", newAccessToken.access_token);
            })
            .catch((e) => {
              console.error("Error refreshing the token", e);
              localStorage.clear();
              window.location.href = "/auth/login";
            });
        } else {
          console.error("No refresh token found");
          localStorage.clear();
          window.location.href = "/auth/login";
        }
      }
    });
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        getNewAccessToken(refreshToken)
          .then((newAccessToken) => {
            localStorage.setItem("token", newAccessToken.access_token);
            localStorage.setItem("refreshToken", newAccessToken.refresh_token);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(newAccessToken.userDetails)
            );
          })
          .catch((e) => {
            console.error("Error refreshing the token", e);
            localStorage.clear();
            window.location.href = "/auth/login";
          });
      } else {
        console.error("No refresh token found");
        localStorage.clear();
        window.location.href = "/auth/login";
      }
      // Handle 401 errors here if necessary...
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const createApolloClient = (uri) => {
  const httpLink = new HttpLink({
    uri: uri,
  });

  const link = ApolloLink.from([errorLink, retryLink, authLink, httpLink]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });
};

const apiBaseUrl =
  process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : "";

export const accountServiceClient = createApolloClient(
  `${apiBaseUrl}/account-service/graphql`
);
export const dashboardServiceClient = createApolloClient(
  `${apiBaseUrl}/dashboard-service/graphql`
);
export const orderServiceClient = createApolloClient(
  `${apiBaseUrl}/order-service/graphql`
);
export const eventServiceClient = createApolloClient(
  `${apiBaseUrl}/event-service/graphql`
);
export const vmServiceClient = createApolloClient(
  `${apiBaseUrl}/vm-service/graphql`
);
export const feedServiceClient = createApolloClient(
  `${apiBaseUrl}/feed-service/graphql`
);
export const productServiceClient = createApolloClient(
  `${apiBaseUrl}/product-service/graphql`
);
export const reportServiceClient = createApolloClient(
  `${apiBaseUrl}/report-service/graphql`
);

// export const accountServiceClient = createApolloClient(
//     `${'http://localhost:3001'}/graphql`
// );
// export const dashboardServiceClient = createApolloClient(
//     `${'http://localhost:3027'}/graphql`
// );
// export const orderServiceClient = createApolloClient(
//     `${'http://localhost:3011'}/graphql`
// );
// export const eventServiceClient = createApolloClient(
//     `${'http://localhost:3015'}/graphql`
// );
// export const vmServiceClient = createApolloClient(
//     `${'http://localhost:3023'}/graphql`
// );
// export const feedServiceClient = createApolloClient(
//     `${'http://localhost:3013'}/graphql`
// );
// export const productServiceClient = createApolloClient(
//     `${'http://localhost:3007'}/graphql`
// );
// export const reportServiceClient = createApolloClient(
//     `${apiBaseUrl}/report-service/graphql`
// );

// export const accountServiceClient = createApolloClient(
//     `${'http://localhost:3001'}/graphql`
// );
// export const dashboardServiceClient = createApolloClient(
//   `${"http://localhost:3002"}/graphql`
// );
// export const orderServiceClient = createApolloClient(
//     `${'http://localhost:3011'}/graphql`
// );
// export const eventServiceClient = createApolloClient(
//     `${'http://localhost:3015'}/graphql`
// );
// export const vmServiceClient = createApolloClient(
//     `${'http://localhost:3023'}/graphql`
// );
// export const feedServiceClient = createApolloClient(
//     `${'http://localhost:3013'}/graphql`
// );
// export const productServiceClient = createApolloClient(
//   `${"http://localhost:3000"}/graphql`
// );
// export const reportServiceClient = createApolloClient(
//     `${apiBaseUrl}/report-service/graphql`
// );
