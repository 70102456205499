import { gql } from "@apollo/client";

export const GET_ATTRIBUTE_LIST = gql `
  query GetAttributeList($take: Int, $skip: Int, $search: String) {
    masterAttributes(
      listMasterAttributeFilter: { take: $take, skip: $skip, search: $search }
    ) {
      total
      results {
        id
        name
        description
        fieldType
        ginesysAttribute
        values
        isFilter
        displayOrder
        isDeleted
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_ATTRIBUTE = gql `
  mutation createMasterAttribute(
    $createMasterAttributeInput: CreateMasterAttributeInput!
  ) {
    createMasterAttribute(
      createMasterAttributeInput: $createMasterAttributeInput
    ) {
      id
      name
      fieldType
      ginesysAttribute
      values
      isFilter
      isDeleted
      displayOrder
      status
    }
  }
`;

export const UPDATE_ATTRIBUTE = gql `
  mutation updateMasterAttribute(
    $updateMasterAttributeInput: UpdateMasterAttributeInput!
  ) {
    updateMasterAttribute(
      updateMasterAttributeInput: $updateMasterAttributeInput
    ) {
      id
      name
      fieldType
      ginesysAttribute
      values
      isFilter
      isDeleted
      displayOrder
      status
    }
  }
`;