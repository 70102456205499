import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Switch,
  Autocomplete,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { FormHelperText } from "@mui/material";
import * as Yup from "yup";
import FileUploader from "components/MDFileUpload";
import { useNotification } from "context";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import ReactQuill from "react-quill";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { eventServiceClient } from "graphql/client";
import { LIST_BRAND_PRODUCT } from "services/brand-service";
import { GET_STORES_LIST } from "services/store-service";
import { GET_CATEGORIES } from "services/product-category-service";
import { CREATE_EVENT_MUTATION } from "services/events-service";
import { UPDATE_EVENT_MUTATION } from "services/events-service";
import { GET_EVENTS } from "services/events-service";
import dayjs from "dayjs";
import Loader from "components/MDLoader";
import "./style.css";
import MDButton from "components/MDButton";
import { GET_PRODUCT_LIST } from "services/events-service";
import { productServiceClient } from "graphql/client";
import useDebounce from "../product-category/useDebounce";

const useEventMutation = (eventData, handleClose, setNotification) => {
  const mutationOptions = {
    client: eventServiceClient,
    refetchQueries: [
      {
        query: GET_EVENTS,
        variables: {
          eventListFilter: { take: 20, skip: 0, search: "", filter: {} },
        },
      },
    ],
  };

  const [createEventMutation] = useMutation(
    CREATE_EVENT_MUTATION,
    mutationOptions
  );
  const [updateEventMutation] = useMutation(
    UPDATE_EVENT_MUTATION,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const payload = {
      name: values?.eventName || "",
      attendeeType: values?.attendeeType || "",
      eventType: values?.eventType || "",
      storeId: values?.storeName || "",
      eventLocation: values?.eventLocation || "",
      sponserTier: values?.sponsorshipTier || [],
      startDate: values?.start || "",
      endDate: values?.end || "",
      startTime: values?.startTime || "",
      endTime: values?.endTime || "",
      categoryId: values?.productCategory || "",
      isSampling: values?.isSampling || false,
      description: values?.eventDescription || "",
      heroImage: values?.heroImagePath || "",
      carouselImages: values?.carouselImagePath || [],
      termsCondition: values?.termsConditions || "",
      productId: values?.productName.map((prdct) => prdct.id) || [],
      isPublished: values?.publishToBrands || false,
      isLive: values?.publishToCustomers || false,
      displayOrder: +values?.displayOrder,
    };

    const mutation =
      eventData && eventData.id ? updateEventMutation : createEventMutation;
    const mutationInput =
      eventData && eventData.id
        ? { updateEventInput: { id: eventData.id, ...payload } }
        : { createEventInput: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
            eventData && eventData?.id
              ? "Event  updated successfully"
              : "Event  created successfully",
        });
      })
      .catch((error) => {
        console.error("Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const EventDetails = ({ eventData, handleClose }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    eventData?.categoryId || ""
  );
  const { setNotification } = useNotification();

  const [searachValues, setSearchValues] = React.useState("");
  const debouncedSearchTerm = useDebounce(searachValues, 300);

  const handleSubmit = useEventMutation(
    eventData,
    handleClose,
    setNotification
  );
  const {
    loading: productLoading,
    error: productError,
    data: productData,
    refetch,
  } = useQuery(GET_PRODUCT_LIST, {
    client: productServiceClient,
    variables: {
      limit: 20,
      skip: 0,
      search: debouncedSearchTerm,
      status: "G_SYNCED",
      isSample: true,
    },
  });

  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 1000,
        skip: 0,
        search: "",
        fetchOnlyCategoryProduct:true,
        filter: {
          isNodeItem: true,
          //  brandCategory: { isDeleted: false }
        },
      },
    },
  });
  const productList =
    productData?.getVariants?.variants?.filter((item) => item?.isSample) || [];
  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      refetch();
    }
  }, [debouncedSearchTerm]);

  if (error || storeError || productError)
    return <>Error: {error.message || "Something went wrong! retry"}</>;

  const categoryList =
    data?.categoryProduct?.results && data?.categoryProduct?.results.length
      ? data?.categoryProduct?.results
      : [];
  const carouselImages =
    (eventData &&
      eventData?.carouselImages &&
      eventData?.carouselImages.length &&
      eventData?.carouselImages?.map((item) => item?.path)) ||
    [];
  // return dayjs(storeTime?.start, "HH:mm");
  const getMinMaxTime = (
    storeId = null,
    eventEndTime = null,
    eventStartTime = null,
    eventStartDate = null,
    eventEndDate = null,
    isMinTime = true,
    timeFor = "startTime"
  ) => {
    // now validate the storeId against the storeList
    const store = storeList?.stores?.results?.find(
      (store) => store.id === storeId
    );
    // get the storemetadata from the store and filter key with value `calendar`
    const storeMetaData = store?.storeMetaData?.find(
      (meta) => meta.key === "calendar"
    );
    // if storeMetaData is not found then return the default time
    if (!storeMetaData) {
      return dayjs("23:59", "HH:mm");
    }
    // if storeMetaData is found then check the eventStartDate if present then check which day of week it is
    const eventStartDay = eventStartDate ? dayjs(eventStartDate).day() : null;
    const eventEndDay = eventEndDate ? dayjs(eventEndDate).day() : null;
    // now get startStoreTIme and endStoreTime from the storeMetaData using the eventStartDay === 'Sun' | 'Mon' like this
    const storeStartDayTime = storeMetaData.value.week[eventStartDay];
    const storeEndDayTime = storeMetaData.value.week[eventEndDay];
    // now return the min and max values allowed will be storeStartDayTime?.start < timeWeNeedToReturn < eventEndTime if present < storeEndDayTime?.end
    if (timeFor === "startTime") {
      return {
        min: dayjs(storeStartDayTime?.start, "HH:mm"),
        max: eventEndTime
          ? dayjs(eventEndTime, "HH:mm")
          : dayjs(storeEndDayTime?.end, "HH:mm"),
      };
    } else {
      return {
        min: eventStartTime
          ? dayjs(eventStartTime, "HH:mm")
          : dayjs(storeStartDayTime?.start, "HH:mm"),
        max: dayjs(storeEndDayTime?.end, "HH:mm"),
      };
    }
  };

  // Validation Schema
  const validationSchema = (data) =>
    Yup.object({
      eventName: Yup.string()
        .required("Event name is required")
        .max(100, "Maximum 100 characters allowed"),
      attendeeType: Yup.string().required("Attendee type is required"),
      eventType: Yup.string().required("Event type is required"),
      storeName: Yup.string().required("Store name is required"),
      eventLocation: Yup.string()
        .required("Event location is required")
        .max(100, "Maximum 100 characters allowed"),
      displayOrder: Yup.number()
        .required("Order is required")
        .positive("A number must be positive.")
        .integer("Order must be an integer")
        .typeError("Order must be a number"),
      sponsorshipTier: Yup.array()
        .of(Yup.string().required("Sponsorship tier is required"))
        .min(1, "At least one sponsorship tier is required"),
      eventDescription: Yup.string()
        .required("Description is required")
        .max(1000, "Maximum 1000 characters allowed"),
      start: Yup.date().required("Start date is required"),
      end: Yup.date()
        .min(Yup.ref("start"), "End date should be after than start date")
        .required("End date is required"),
      startTime: Yup.string()
        .required("Start time is required")
        .test(
          "is-within-store-start-time",
          "Start time should be in store start and end time range",
          function (value) {
            const min = getMinMaxTime(
              this.parent.storeName,
              this.parent.endTime,
              this.parent.startTime,
              this.parent.start,
              this.parent.end,
              true
            ).min;
            const max = getMinMaxTime(
              this.parent.storeName,
              this.parent.endTime,
              this.parent.startTime,
              this.parent.start,
              this.parent.end,
              false
            ).max;

            const timeToCheck = dayjs(value, "HH:mm");
            const minTime = dayjs(min, "HH:mm");
            const maxTime = dayjs(max, "HH:mm");

            // Check if the time is within the range
            return (
              isSameOrAfter(timeToCheck, minTime) &&
              isSameOrBefore(timeToCheck, maxTime)
            );
          }
        ),
      endTime: Yup.string()
        .required("End time is required")
        .test(
          "is-within-store-end-time",
          "End time should be in store start and end time range",
          function (value) {
            const min = getMinMaxTime(
              this.parent.storeName,
              this.parent.endTime,
              this.parent.startTime,
              this.parent.start,
              this.parent.end,
              true,
              "endTime"
            ).min;
            const max = getMinMaxTime(
              this.parent.storeName,
              this.parent.endTime,
              this.parent.startTime,
              this.parent.start,
              this.parent.end,
              false,
              "endTime"
            ).max;

            const timeToCheck = dayjs(value, "HH:mm");
            const minTime = dayjs(min, "HH:mm");
            const maxTime = dayjs(max, "HH:mm");

            // Check if the time is within the range
            return (
              isSameOrAfter(timeToCheck, minTime) &&
              isSameOrBefore(timeToCheck, maxTime)
            );
          }
        ),
      productCategory: Yup.string().required(
        "Product/Event category is required"
      ),
      termsConditions: Yup.string()
        .required("Terms and Conditions are required")
        .test(
          "is-not-empty",
          "Terms and Conditions cannot be empty",
          function (value) {
            // Remove all HTML tags and check if there's any text content left
            const textContent = value?.replace(/<[^>]*>?/gm, "").trim();
            return !!textContent;
          }
        ),
      publishToBrands: Yup.bool(),
      publishToCustomers: Yup.bool(),
      isSampling: Yup.bool(),
      heroImagePath: Yup.string().required("Hero image is required"),
      carouselImagePath: Yup.array()
        .required("At least one image is required")
        .min(1, "At least one image is required"),
      productName: Yup.array().when("isSampling", {
        is: true,
        then: () =>
          Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string().required("Product ID is required"),
                label: Yup.string().required("Product label is required"),
              })
            )
            .required(
              "You need to select at least one product when sampling is on"
            )
            .min(1, "Select at least one product"),
        otherwise: () => Yup.array().notRequired(),
      }),
    });

  const isSameOrAfter = (timeToCheck, compareTime) => {
    return timeToCheck.isSame(compareTime) || timeToCheck.isAfter(compareTime);
  };

  const isSameOrBefore = (timeToCheck, compareTime) => {
    return timeToCheck.isSame(compareTime) || timeToCheck.isBefore(compareTime);
  };
  const mappedProducts = eventData?.product
    ?.map((product) => {
      return { id: product.productId, label: product.itemName || "N/A" };
    })
    .filter((product) => product !== null);

  return (
    <Formik
      initialValues={{
        eventName: (eventData && eventData.name) || "",
        eventType: (eventData && eventData.eventType) || "",
        storeName: (eventData && eventData.storeId) || "",
        attendeeType: (eventData && eventData.attendeeType) || "",
        eventLocation: (eventData && eventData.eventLocation) || "",
        sponsorshipTier: (eventData && eventData.sponserTier) || [],
        eventDescription: (eventData && eventData.description) || "",
        start: (eventData && eventData.startDate) || "",
        end: (eventData && eventData.endDate) || "",
        startTime: (eventData && eventData.startTime) || "",
        endTime: (eventData && eventData.endTime) || "",
        productCategory: (eventData && eventData.categoryId) || "",
        termsConditions: (eventData && eventData.termsCondition?.text) || "",
        publishToBrands: (eventData && eventData.isPublished) || false,
        publishToCustomers: (eventData && eventData.isLive) || false,
        isSampling: (eventData && eventData.isSampling) || false,
        productName: eventData && eventData.isSampling ? mappedProducts : [],
        heroImagePath:
          eventData && eventData.heroImage ? eventData.heroImage[0].path : "",
        carouselImagePath:
          eventData &&
          eventData.carouselImages &&
          eventData.carouselImages.length
            ? carouselImages
            : "",
        displayOrder: eventData ? eventData.displayOrder : "",
      }}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        validateField,
        handleChange,
        handleBlur,
      }) => {
        return (
          <Form>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="eventName"
                    label="Name of the Event"
                    fullWidth
                    error={touched.eventName && Boolean(errors.eventName)}
                    helperText={touched.eventName && errors.eventName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={touched.attendeeType && Boolean(errors.attendeeType)}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Attendee Type
                    </InputLabel>
                    <Select
                      as={Select}
                      name="attendeeType"
                      label="Attendee Type"
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          style={{ marginRight: "18px", marginTop: "10px" }}
                        />
                      )}
                      value={values.attendeeType}
                      onChange={(e) => {
                        setFieldValue("attendeeType", e.target.value);
                        setFieldTouched(`attendeeType`, true, false);
                        validateField(`attendeeType`, e.target.value);
                      }}
                      error={
                        touched.attendeeType && Boolean(errors.attendeeType)
                      }
                      sx={{ height: 40 }}
                    >
                      <MenuItem value="member">Member</MenuItem>
                      <MenuItem value="nonMember">Non-Member</MenuItem>
                    </Select>
                    {touched.attendeeType && errors.attendeeType ? (
                      <FormHelperText>{errors.attendeeType}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={touched.eventType && Boolean(errors.eventType)}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Event Type
                    </InputLabel>
                    <Select
                      as={Select}
                      name="eventType"
                      label="Event Type"
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          style={{ marginRight: "18px", marginTop: "10px" }}
                        />
                      )}
                      value={values.eventType}
                      onChange={(e) => {
                        setFieldValue("eventType", e.target.value);
                        setFieldTouched(`eventType`, true, false);
                        validateField(`eventType`, e.target.value);
                      }}
                      error={touched.eventType && Boolean(errors.eventType)}
                      sx={{ height: 40 }}
                    >
                      <MenuItem value="oneToOne">One To One</MenuItem>
                      <MenuItem value="oneToMany">One To Many</MenuItem>
                    </Select>
                    {touched.eventType && errors.eventType ? (
                      <FormHelperText>{errors.eventType}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={touched.storeName && Boolean(errors.storeName)}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Store Name
                    </InputLabel>
                    <Select
                      as={Select}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="storeName"
                      label="Store Name"
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          style={{ marginRight: "18px", marginTop: "10px" }}
                        />
                      )}
                      value={values.storeName}
                      onChange={(e) => {
                        setFieldValue("storeName", e.target.value);
                        setFieldValue("start", "");
                        setFieldValue("end", "");
                        setFieldValue("startTime", "");
                        setFieldValue("endTime", "");
                      }}
                      onBlur={handleBlur}
                      error={touched.storeName && Boolean(errors.storeName)}
                      sx={{ height: 40 }}
                    >
                      {storeList &&
                        storeList.stores.results
                          ?.filter((item) => item.status === "Active")
                          .map((store) => (
                            <MenuItem key={store.id} value={store.id}>
                              {store.name}
                            </MenuItem>
                          ))}
                    </Select>
                    {touched.storeName && errors.storeName ? (
                      <FormHelperText>{errors.storeName}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    name="eventLocation"
                    label="Event Location"
                    fullWidth
                    error={
                      touched.eventLocation && Boolean(errors.eventLocation)
                    }
                    helperText={touched.eventLocation && errors.eventLocation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    // renderValue={(selected) => selected.join(', ')}
                    error={
                      touched.sponsorshipTier && Boolean(errors.sponsorshipTier)
                    }
                  >
                    <InputLabel id="demo-simple-select-label">
                      Sponsorship Tier
                    </InputLabel>
                    <Select
                      as={Select}
                      name="sponsorshipTier"
                      label="Sponsorship Tier"
                      IconComponent={() => (
                        <ArrowDropDownIcon
                          style={{ marginRight: "18px", marginTop: "10px" }}
                        />
                      )}
                      value={values.sponsorshipTier}
                      onChange={handleChange}
                      multiple
                      error={
                        touched.sponsorshipTier &&
                        Boolean(errors.sponsorshipTier)
                      }
                      sx={{ height: 40 }}
                    >
                      <MenuItem value="gold">Gold</MenuItem>
                      <MenuItem value="silver">Silver</MenuItem>
                      <MenuItem value="bronze">Bronze</MenuItem>
                      <MenuItem value="platinum">Platinum</MenuItem>
                    </Select>
                    {touched.sponsorshipTier && errors.sponsorshipTier ? (
                      <FormHelperText>{errors.sponsorshipTier}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="eventDescription"
                    label="Description"
                    fullWidth
                    error={
                      touched.eventDescription &&
                      Boolean(errors.eventDescription)
                    }
                    helperText={
                      touched.eventDescription && errors.eventDescription
                    }
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        slotProps={
                          errors.start && !!touched.start
                            ? {
                                textField: {
                                  error: !!touched.start && errors.start,
                                  helperText: errors.start,
                                },
                              }
                            : null
                        }
                        format="DD/MM/YYYY"
                        label="Start Date"
                        name="start"
                        value={values.start ? dayjs(values.start) : null}
                        onChange={(newValue) =>
                          setFieldValue(
                            "start",
                            dayjs(newValue).format("YYYY-MM-DD")
                          )
                        }
                        minDate={values.id ? undefined : dayjs().add(0, "day")}
                        disabled={!values.storeName}
                        renderInput={(params) => (
                          <Field
                            as={TextField}
                            {...params}
                            fullWidth
                            error={touched?.start && Boolean(errors?.start)}
                            helperText={touched?.start && errors?.start}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        slotProps={
                          errors.end && !!touched.end
                            ? {
                                textField: {
                                  error: !!touched.end && errors.end,
                                  helperText: errors.end,
                                },
                              }
                            : null
                        }
                        format="DD/MM/YYYY"
                        label="End Date"
                        name="end"
                        minDate={
                          values.start
                            ? dayjs(values.start).add(0, "day")
                            : dayjs().add(0, "day")
                        }
                        value={values.end ? dayjs(values.end) : null}
                        onChange={(newValue) =>
                          setFieldValue(
                            "end",
                            dayjs(newValue).format("YYYY-MM-DD")
                          )
                        }
                        disabled={!values.storeName}
                        renderInput={(params) => (
                          <Field
                            as={TextField}
                            {...params}
                            fullWidth
                            error={touched?.end && Boolean(errors?.end)}
                            helperText={touched?.end && errors?.end}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        slotProps={
                          errors.startTime && !!touched.startTime
                            ? {
                                textField: {
                                  error:
                                    !!touched.startTime && errors.startTime,
                                  helperText: errors.startTime,
                                },
                              }
                            : null
                        }
                        label="Start Time"
                        name="startTime"
                        minTime={
                          getMinMaxTime(
                            values.storeName,
                            values.endTime,
                            values.startTime,
                            values.start,
                            values.end,
                            true
                          ).min
                        }
                        maxTime={
                          getMinMaxTime(
                            values.storeName,
                            values.endTime,
                            values.startTime,
                            values.start,
                            values.end,
                            false
                          ).max
                        }
                        value={
                          values.startTime
                            ? dayjs(values.startTime, "HH:mm")
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "startTime",
                            dayjs(newValue).format("HH:mm")
                          );
                          setFieldTouched("startTime", true, false); // Changed
                          validateField("startTime");
                        }}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        disabled={
                          !values.storeName || !values.start || !values.end
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        slotProps={
                          errors.endTime && !!touched.endTime
                            ? {
                                textField: {
                                  error: !!touched.endTime && errors.endTime,
                                  helperText: errors.endTime,
                                },
                              }
                            : null
                        }
                        label="End Time"
                        name="endTime"
                        minTime={
                          getMinMaxTime(
                            values.storeName,
                            values.endTime,
                            values.startTime,
                            values.start,
                            values.end,
                            true,
                            "endTime"
                          ).min
                        }
                        maxTime={
                          getMinMaxTime(
                            values.storeName,
                            values.endTime,
                            values.startTime,
                            values.start,
                            values.end,
                            false,
                            "endTime"
                          ).max
                        }
                        value={
                          values.endTime ? dayjs(values.endTime, "HH:mm") : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "endTime",
                            dayjs(newValue).format("HH:mm")
                          );
                          setFieldTouched("endTime", true, false);
                          validateField("endTime");
                        }}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        renderInput={(params) => (
                          <Field
                            as={TextField}
                            {...params}
                            fullWidth
                            error={
                              touched.eventDate && Boolean(errors.eventDate)
                            }
                            helperText={touched.eventDate && errors.eventDate}
                          />
                        )}
                        disabled={
                          !values.storeName || !values.start || !values.end
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={touched.productCategory && !!errors.productCategory}
                  >
                    <Autocomplete
                      id="product-category-autocomplete"
                      options={categoryList}
                      getOptionLabel={(option) => option.metaTitle || ""}
                      value={
                        categoryList.find(
                          (category) => category.id === values.productCategory
                        ) || null
                      }
                      // value={values.productCategory || null }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "productCategory",
                          newValue ? newValue.id : ""
                        );
                        setFieldTouched("productCategory", false, false);
                        setSelectedCategory(newValue?.id);
                        // setFieldValue('productName', [])
                        // handleOnChange(event,newValue,setFieldValue);
                      }}
                      loading={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Product/Event Category"
                          error={
                            touched.productCategory &&
                            Boolean(errors.productCategory)
                          }
                          helperText={
                            touched.productCategory && errors.productCategory
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item container spacing={2} alignItems="center" xs={4}>
                  <Grid item>
                    <Typography fontSize={13} fontWeight="bold">
                      Sampling
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={values.isSampling}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setFieldValue("isSampling", isChecked);
                        if (!isChecked) {
                          setFieldValue("productName", []);
                        }
                      }}
                      name="isSampling"
                      color="primary"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    name="productName"
                    value={values.productName}
                    onChange={(event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
                      );
                      setFieldValue("productName", uniqueValues);
                      setFieldTouched("productName", false, false);
                    }}
                    disabled={!values.isSampling}
                    disablePortal={true}
                    disableCloseOnSelect
                    filterOptions={(options, { inputValue }) =>
                      options.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    }
                    options={
                      productList?.map((item) => ({
                        id: item.id,
                        label: item.itemName,
                      })) || []
                    }
                    getOptionDisabled={(option) =>
                      values.productName.some(
                        (product) => product.id === option.id
                      )
                    }
                    onInputChange={(event, newValue) => {
                      if (
                        (event && event?.type === "reset") ||
                        newValue === ""
                      ) {
                        setSearchValues(""); // Clear search when input is cleared
                      } else if (event && event.type === "change") {
                        setSearchValues(newValue);
                      }
                    }}
                    onBlur={() => {
                      setSearchValues("");
                    }}
                    loading={productLoading}
                    loadingText="Loading..."
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Product Name"
                        placeholder="Select"
                        disabled={!values.isSampling}
                        helperText={
                          values?.isSampling && touched.productName
                            ? errors.productName
                            : ""
                        }
                        error={
                          values?.isSampling &&
                          touched.productName &&
                          Boolean(errors.productName)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={15}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Upload Hero Image
                  </Typography>
                  <FileUploader
                    fieldName={"Upload Hero Image"}
                    isMultiple={false}
                    preLoadedFile={
                      values.heroImagePath ? [values.heroImagePath] : []
                    }
                    // signedUrlCallback={(newUrl, index) => {
                    //   const updatedImagePathArray = [...values.heroImagePath];
                    //   if (newUrl) {
                    //     // Update the URL at the given index
                    //     updatedImagePathArray[index] = newUrl;
                    //   } else {
                    //     // Delete the URL at the given index
                    //     updatedImagePathArray.splice(index, 1);
                    //   }
                    //   setFieldValue("heroImagePath", newUrl);
                    // }}
                    signedUrlCallback={(newUrl) => {
                      const updatedImagePathArray = newUrl ? [newUrl] : [];
                      setFieldValue("heroImagePath", newUrl);
                    }}
                    isFieldValid={
                      touched?.heroImagePath && errors?.heroImagePath
                    }
                  />
                  {touched?.heroImagePath && errors?.heroImagePath && (
                    <Typography fontSize={12} color="error">
                      {errors?.heroImagePath}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={12} fontWeight={"bold"}>
                    Upload Image Carousal
                  </Typography>
                  <FileUploader
                    fieldName={"Upload Image Carousal"}
                    isMultiple={true}
                    preLoadedFile={
                      !!values.carouselImagePath
                        ? values.carouselImagePath
                        : null
                    }
                    signedUrlCallback={(newUrl, index) => {
                      const updatedImagePathArray = [
                        ...values.carouselImagePath,
                      ];
                      if (newUrl) {
                        // Update the URL at the given index
                        updatedImagePathArray.push(newUrl);
                      } else {
                        // Delete the URL at the given index
                        updatedImagePathArray.splice(index, 1);
                      }
                      setFieldValue("carouselImagePath", updatedImagePathArray);
                    }}
                    isFieldValid={
                      touched?.carouselImagePath && errors?.carouselImagePath
                    }
                  />
                  {touched?.carouselImagePath && errors?.carouselImagePath && (
                    <Typography fontSize={12} color="error">
                      {errors?.carouselImagePath}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Field
                    name="displayOrder"
                    as={TextField}
                    label="Order"
                    type="number"
                    fullWidth
                    error={touched.displayOrder && !!errors.displayOrder}
                    helperText={touched.displayOrder && errors.displayOrder}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Terms and Conditions</Typography>
                  <ReactQuill
                    theme="snow"
                    value={values.termsConditions}
                    onChange={(content) => {
                      setFieldValue("termsConditions", content);
                    }}
                  />
                  {touched.termsConditions && errors.termsConditions && (
                    <Typography fontSize={12} color="error">
                      {errors.termsConditions}
                    </Typography>
                  )}
                </Grid>
                <Grid item container spacing={2} alignItems="center" xs={4}>
                  <Grid item>
                    <Typography fontSize={13} fontWeight="bold">
                      Publish to Brands
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={values.publishToBrands}
                      onChange={(event) =>
                        setFieldValue("publishToBrands", event.target.checked)
                      }
                      disabled={values.publishToCustomers}
                      name="publishToBrands"
                      color="primary"
                    />
                  </Grid>
                </Grid>
                {values.publishToBrands &&
                  eventData &&
                  eventData.ticket &&
                  eventData.ticket.length > 0 && (
                    <Grid item container spacing={2} alignItems="center" xs={4}>
                      <Grid item>
                        <Typography fontSize={13} fontWeight="bold">
                          Publish to Customers
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={values.publishToCustomers}
                          onChange={(event) => {
                            setFieldValue(
                              "publishToCustomers",
                              event.target.checked
                            );
                            // if (event.target.checked) {
                            //   setFieldValue("publishToBrands", true);
                            // }
                          }}
                          name="publishToCustomers"
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  )}
                <Grid
                  item
                  xs={12}
                  sx={{ mb: 2, display: "flex", justifyContent: "end" }}
                  flexDirection={"row-reverse"}
                  gap={3}
                >
                  <MDButton
                    type="submit"
                    variant="contained"
                    circular={true}
                    color="dark"
                  >
                    Save
                  </MDButton>
                  <MDButton
                    color="dark"
                    variant="outlined"
                    circular={true}
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EventDetails;
