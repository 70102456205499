import { gql } from "@apollo/client";

export const GET_SECTION_COMPONENT = gql `
  query getSection($input: GetSectionsInput!) {
    sections(input: $input) {
      total
      results {
        id
        internalName
        name
        displayOrder
        component {
          id
          name
          displayOrder
          product
          createdBy
          updatedBy
          status
          updatedByUser {
            id
            name
            username
          }
          componentMetaData {
            id
            key
            value
            isDeleted
            createdAt
            updatedAt
          }
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_COMPONENT_LIST = gql `
  query findAllComponents(
    $page: Int! = 1
    $limit: Int! = 10
    $sortBy: String! = "displayOrder"
    $order: String! = "ASC"
    $searchCols: [String!] = []
    $searchBy: [String!] = []
    $relations: [String!] = []
    $filter: JSONObject = {}
  ) {
    components(
      page: $page
      limit: $limit
      sortBy: $sortBy
      order: $order
      searchCols: $searchCols
      searchBy: $searchBy
      relations: $relations
      filter: $filter
    ) {
      results {
        id
        name
        displayOrder
        product
        section {
          id
          name
          internalName
        }
        componentMetaData {
          id
          key
          value
          isDeleted
          createdAt
          updatedAt
        }
        isDeleted
        createdAt
        updatedAt
        updatedByUser {
          id
          name
          username
        }
        status
      }
      total
    }
  }
`;

export const CREATE_COMPONENT = gql `
  mutation createComponent($input: CreateComponentInput!) {
    createComponent(createComponentInput: $input) {
      id
      name
      displayOrder
      section {
        id
        name
        internalName
      }
      status
      createdAt
      createdBy
      updatedBy
    }
  }
`;

export const DELETE_COMPONENT = gql `
  mutation removeComponent($id: String!) {
    removeComponent(id: $id) {
      id
    }
  }
`;

export const UPDATE_COMPONENT = gql `
  mutation updateComponent($input: UpdateComponentInput!) {
    updateComponent(updateComponentInput: $input) {
      id
      name
      displayOrder
      section {
        id
        name
        internalName
      }
      status
      createdAt
      createdBy
      updatedBy
    }
  }
`;

export const UPDATE_COMPONENT_METADATA = gql `
  mutation updateComponentMetadata($input: UpdateComponentMetadatumInput!) {
    updateComponentMetadatum(updateComponentMetadatumInput: $input) {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_COMPONENT_METADATA = gql `
  mutation createComponentMetaData($input: CreateComponentMetadatumInput!) {
    createComponentMetadatum(createComponentMetadatumInput: $input) {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_COMPONENT_METADATA = gql `
  mutation removeComponentMetadatum($id: String!) {
    removeComponentMetadatum(id: $id) {
      id
    }
  }
`;