import { Tab, Tabs } from "@mui/material";
import { AvailableTabs } from "./tableData";
import { useQuery } from "@apollo/client";
import { MAP_BRAND_STORE } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import useProductContext from "../context/useProductContext";
import { useEffect } from "react";

const getTabStyles = (tabName, activeTab) => {
  const commonStyle = { padding: "0.5rem 1rem", padding: "0.5rem 1rem" };

  if (tabName === activeTab) {
    return { ...commonStyle, color: "#E91F63 !important" };
  }

  return { ...commonStyle, color: "black !important" };
};

export default function TableTabs({ selectedTab, onChange }) {
  const user = localStorage.getItem("userDetails");

  const userJson = user ? JSON.parse(user) : {};

  const role = userJson.role;

  const isAdmin = role === "admin";

  const { productContext, setProductContext } = useProductContext();

  const {data:brandStoreData, loading, error} = useQuery(MAP_BRAND_STORE,{
    client: dashboardServiceClient,
    variables: {
      listBrandStoreFilter: {
        take: 10000,
        skip: 0,
        search: "",
        filter: {
          brand: { id: isAdmin ? undefined : userJson?.userId},
          isDeleted: false,
          status: ["active", "upcoming"]
        },
      },
    }
  })
  
  useEffect(()=>{
    if(brandStoreData?.brandStores?.results){
      setProductContext({...productContext, brandStores: brandStoreData?.brandStores?.results})
    }
  },[brandStoreData])
  return (
    <Tabs
      sx={{
        width: "fit-content",
      }}
      value={selectedTab}
      onChange={onChange}
    >
      <Tab
        label="Single Product"
        value={AvailableTabs.singleProduct}
        sx={{
          marginRight: "1rem",
          fontSize: "13px",
          border: "1px solid #f0e6e6c9" ,marginRight:"5px" 
          // ...getTabStyles(AvailableTabs.singleProduct, selectedTab),
        }}
      />
      {/* <Tab
        label="Node wise bulk Product List"
        value={AvailableTabs.nodeWiseBulkProduct}
        sx={{
          marginRight: "1rem",
          fontSize: "13px",
          border: "1px solid #f0e6e6c9" ,marginRight:"5px" 
          // ...getTabStyles(AvailableTabs.singleProduct, selectedTab),
        }}
        // sx={{ ...getTabStyles(AvailableTabs.nodeWiseBulkProduct, selectedTab) }}
      /> */}

      <Tab sx={{fontSize:"13px",  border: "1px solid #f0e6e6c9" ,marginRight:"5px" }} label="Bulk Product List" value={AvailableTabs.bulkProduct} />
      {(isAdmin || productContext.brandStores?.length>0) && <Tab sx={{fontSize:"13px",  border: "1px solid #f0e6e6c9" ,marginRight:"5px" }} label="Bulk Price Upload" value={AvailableTabs.bulkPriceUpload} />}
      {(isAdmin || productContext.brandStores?.length>0) && <Tab sx={{fontSize:"13px",  border: "1px solid #f0e6e6c9" ,marginRight:"5px" }} label="Products Price List" value={AvailableTabs.productsPriceList} />}
      {/* <IconButton onClick={refresh}>
        <Refresh fontSize="medium" />
      </IconButton> */}
    </Tabs>
  );
}
