import { Grid, Typography, Avatar } from "@mui/material";
import React from "react";
import { SmartDisplay, FitScreen, VideoFile, SnippetFolder, AccessTime, AspectRatio } from "@mui/icons-material";

function AvatarCard({name, value, icon:Icon, iconColor}) {
    return (
        <Grid item xs={2} minWidth={"170px"} borderRight={"1px solid #EAEAEA"}>
            <Grid display={"flex"}>
                <Avatar sx={{ bgcolor: iconColor + "26", display: "flex", border: "1px solid", borderColor: iconColor }}>
                    <Icon sx={{ color: iconColor }} />
                </Avatar>
                <Grid ml={2}>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: "12px", color: "#000" }}>{name}</Typography>
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: "14px", color: "#000", fontWeight: 600 }}>{value}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

function Header() {

    return (
        <Grid sx={{bgcolor:"#fff", mt:2, borderRadius:3, width:"100vw"}}>
            <Grid sx={{ p: 2 }}>
                <Grid>
                    <Typography sx={{ color: "#000", fontFamily: "Montserrat", fontWeight: 600, fontSize: "15px" }}>File Specification for Upload</Typography>
                </Grid>
                <Grid container display={"flex"} spacing={2} marginTop={"1px"}>
                    <AvatarCard name={"Dimension"} value={"1080X1920p"} icon={FitScreen} iconColor={"#AE85E9"} />
                    <AvatarCard name={"Aspect Ratio"} value={"9:16"} icon={AspectRatio} iconColor={"#EC9884"} />
                    <AvatarCard name={"Resolution"} value={"HD or 1080p"} icon={SmartDisplay} iconColor={"#8AACDD"} />
                    <AvatarCard name={"Format"} value={"MP4 only"} icon={VideoFile} iconColor={"#EACB16"} />
                    <AvatarCard name={"File Size"} value={"< 100 mb"} icon={SnippetFolder} iconColor={"#83DCB9"} />
                    <AvatarCard name={"Duration"} value={"30 or 60 sec"} icon={AccessTime} iconColor={"#8BC256"} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Header;