import React, { useState } from 'react'
import AdsTableCollapsible from '../adsTableCollapsible'
import { Box, Dialog, DialogContent, DialogTitle, FormControl, Icon, IconButton, MenuItem, Select } from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid'
import NewPlaylistForm from './newPlaylistForm'
import MDButton from 'components/MDButton'
import { NewVideoButton } from '../videoMaster'
import AddVideoSelect from '../videoMaster/addVideoSelect'
import AdsTableWithDraggable from '../adsTableWithDraggable'
import PlaylistSelect from './playlistSelect'

export default function PlaylistTable({
    columns,
    handleAddVideo,
    handleVideoRearrange,
    handleAddPlaylist=null,
    handleNewPlaylist=null,
    parent=null,
    refresh,
    rows,
    rowTotal,
    selectedBrandId,
    user,
    videoTableColumns,
    videoListKey='orderedVideos',
    getPlaylistUuids=()=>{} 
}) {

  const [dialogOpen, setDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetUploader, setResetUploader] = useState(false);

    const handleClickOpen = () => {
        setDialogOpen(true);
        
    };
    const handleClose = () => {
        setDialogOpen(false);
    };

    const defaultRefetchProps = {
        "page": 1,
        "limit": 100,
        "sortBy": "updatedAt",
        "order": "DESC",
        "relations": ["orderedVideos","orderedVideos.video"],
        "searchCols": ["name"],
        "searchBy": user && user?.role!== "admin" ? [selectedBrandId] : []
      }
    
    
    const footer = 
    !!selectedBrandId && <Box sx={{display:'flex'}}>
        {handleAddPlaylist && <PlaylistSelect sx={{mr:1}} playlists={getPlaylistUuids(rows)} onSubmit={handleAddPlaylist} />}
        {handleNewPlaylist && <MDButton
            type="button"
            variant="contained"
            circular={true}
            color="dark"
            sx={{ borderRadius: "100px" }}
            onClick={handleClickOpen}
        >
            + New Playlist
        </MDButton>}
    </Box>

  function sumDurations(timesArray) {
    if (!Array.isArray(timesArray)) {
        console.error("Invalid input: timesArray is not an array", timesArray);
        return "0s"; // Return a default or error value
    }

    // Convert the input to a string if it's not already, and make sure it's a string representation of time
    const toSeconds = (timeStr) => {
        timeStr = String(timeStr);
        if (timeStr.includes("s")) {
            return parseFloat(timeStr);
        } else if (timeStr.includes(":")) {
            const [hours, minutes, seconds] = timeStr.split(":").map(parseFloat);
            return hours * 3600 + minutes * 60 + seconds;
        }
        return parseFloat(timeStr);
    };

    // Convert all time strings to seconds and sum them
    const totalSeconds = timesArray.reduce((sum, time) => {
        if (typeof time === "string" || typeof time === "number") {
            return sum + toSeconds(time);
        } else {
            console.error("Invalid time value:", time);
            return sum; // Ignore invalid input and continue accumulating
        }
    }, 0);

    // Return the total in seconds with one decimal place
    return `${totalSeconds.toFixed(1)}s`;
  }
   
    
  // const handleSubmit = useCreateOrUpdatePlaylist(playlistData);

  
  const VideoTable = ({videoTableColumns, row, index, handleAddVideo, handleVideoRearrange}) =>{
    return (<Box sx={{backgroundColor:'#FAFAFA'}}>
            <AdsTableWithDraggable  showHeader={false} columns={videoTableColumns} id={row?.uuid}
                dragCallback={(videoList)=>handleVideoRearrange(videoList,row)}
                parent={row}
                rows={row?.[videoListKey]?.map(ov=>({...ov?.video,parentIdx:index}))}
                footer={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1,px:4 }}>
                        <Box sx={{display:'flex',alignItems:'center'}}>
                            <AddVideoSelect brandId={row?.brandId} onSubmit={(video)=>handleAddVideo(video,row)} videos={row?.[videoListKey]?.map(ov=>ov?.video?.uuid)}/>
                            <NewVideoButton user={user} callback={(video)=>handleAddVideo(video,row)} selectedBrandId={row?.brandId} resetUploader={resetUploader} setResetUploader={setResetUploader} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
                        </Box>
                    </Box>
                }
            />
    </Box>);
}

  return (
    <>
        <AdsTableCollapsible
            columns={columns}
            getCollapseContent={(row,index)=><VideoTable
                row={row}
                // parent={parent}
                index={index}
                handleAddVideo={handleAddVideo}
                handleVideoRearrange={handleVideoRearrange}
                videoTableColumns={videoTableColumns} />}
            footer={footer}
            refetchProps={defaultRefetchProps}
            refetch={refresh}
            rowData={rows}
            rowDataTotalCount={rowTotal}
            parent={parent}
        />
        <Dialog open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
            New Playlist
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ position: "absolute", right: 8, top: 8 }}
            >
                <GridCloseIcon />
            </IconButton>
            </DialogTitle>
            <DialogContent>
            <NewPlaylistForm selectedBrandId={selectedBrandId} handleClose={handleClose} onSubmit={(formValues)=>handleNewPlaylist({...formValues, brandId:selectedBrandId}, parent)} />
            </DialogContent>
            
        </Dialog>
    </>
  )
}
