import React, { useRef, useState } from "react";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import ProductNavbar from "./ProductNavbar";
import ProductData from "./productTable/ProductData";
import AddProductForms from "./forms/AddProductForms";
import { ProductContextProvider } from "./context/productContext";
import { AvailableTabs } from "./productTable/tableData";

function Product() {
  const [form, setForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState(AvailableTabs.singleProduct);

  const gridRef = useRef(null);

  const [productContext, setProductContext] = useState({
    addedSingleProduct: false,
    addedBulkProduct: false,
  });

  return (
    <DashboardLayout>
      <ProductContextProvider value={{ productContext, setProductContext }}>
        <ProductNavbar
          setForm={setForm}
          gridRef={gridRef}
          selectedTab={selectedTab}
        />
        <ProductData
          setForm={setForm}
          gridRef={gridRef}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <AddProductForms form={form} setForm={setForm} />
      </ProductContextProvider>
    </DashboardLayout>
  );
}

export default Product;
