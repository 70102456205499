import { Drawer, Grid, Icon, Modal, Typography } from "@mui/material";
import { AvailableForms } from "../productTable/tableData";
import AddBulkProduct from "./AddBulkProduct";
import AddSingleProduct from "./addSingleProduct";
import addSingleProductIcon from "../../../assets/images/add-single-product.svg";
import AddSuperAdminBulkProduct from "./addSuperAdminBulkProduct/AddSuperAdminBulkProduct";
import AddBulkPriceUpdate from "./addBulkPriceUpdate";
import BulkPriceUpdatePreview from "./bulkPriceUpdatePreview";
import { useState } from "react";
import useProductContext from "../context/useProductContext";

export default function AddProductForms({ form, setForm, refreshPage }) {
  const [previewBulkPriceUpdate, setPreviewBulkPriceUpdate] = useState(false);
  const [previewBulkPriceUpdateProps, setPreviewBulkPriceUpdateProps] = useState({})
  const { productContext, setProductContext } = useProductContext();
  const getFormTitle = ()=>{
    switch (form) {
      case AvailableForms.singleProduct:
        return "Add Single Product";
      // case AvailableForms.nodeWiseBulkProduct:
      //   return "Node Wise Bulk Product Upload";
      case AvailableForms.bulkProduct:
        return "Bulk Product Upload";
      case AvailableForms.superProduct:
        return "Mass Bulk Product Upload";
      case AvailableForms.bulkPriceUpload:
        return "Update Bulk Price";
      default:
        return "Add Single Product";
    }
  }

  const handleBulkPriceUpdatePreview = (values) => {
    setForm(null);
    setPreviewBulkPriceUpdate(true);
    const regex = "(.+?)(\.[^.]*$|$)"
    const fileName = values.fileUpload?.filename?.match(regex)?.[1];
    const props = {
      brand: values.brandOption,
      fileName,
      file: values.fileUpload.file,
    }
    setPreviewBulkPriceUpdateProps(props);
  }

  const getFormContent = () => {
    switch(form) {
      case AvailableForms.singleProduct:
        return <AddSingleProduct setForm={setForm} />;
      case AvailableForms.nodeWiseBulkProduct:
        return <AddBulkProduct setForm={setForm} />;
      case AvailableForms.bulkPriceUpload:
        return <AddBulkPriceUpdate setForm={setForm} onPreview={handleBulkPriceUpdatePreview} />;
      default:
        return <AddSuperAdminBulkProduct setForm={setForm} />;
    }
  }
  return (
    <>
    <Modal open={previewBulkPriceUpdate} onClose={()=>setPreviewBulkPriceUpdate(false)}>
      <BulkPriceUpdatePreview {...previewBulkPriceUpdateProps} callback={(isUpdated=true)=>{
        setPreviewBulkPriceUpdate(false);
        setProductContext({...productContext, updatedBulkPriceUpdate: isUpdated});
      }} />
    </Modal>
    <Drawer
      anchor="right"
      open={form}
      onClose={() => {
        setForm(false);
      }}
      PaperProps={{
        style: {
          width: "50%",
          height: "100vh",
          margin: "0px",
          borderRadius: "0px",
        },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "white",
          pb: 2,
          pt: 5,
          pl: "10px",
          pr: "10px",
        }}
      >
        <Grid item xs={12} display={"flex"} alignItems={"center"} pl={"20px"}>
          <Icon
            component={() => <img src={addSingleProductIcon} />}
            fontSize="large"
          />
          <Typography
            variant="h5"
            sx={{ display: "inline", marginLeft: "10px" }}
          >
            {/* {form === AvailableForms.singleProduct
              ? "Add Single Product"
              : form === AvailableForms.nodeWiseBulkProduct
              ? "Add Bulk Product"
              : "Add Mask Bulk Product"} */}
            {getFormTitle()}
          </Typography>
        </Grid>
      </Grid>

      {/* {form === AvailableForms.singleProduct ? (
        <AddSingleProduct setForm={setForm} />
      ) : form === AvailableForms.nodeWiseBulkProduct ? (
        <AddBulkProduct setForm={setForm} />
      ) : (
        <AddSuperAdminBulkProduct setForm={setForm} />
      )} */}
      {getFormContent()}
    </Drawer>
    </>
  );
}
