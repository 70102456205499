import { Box, TablePagination } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'

export default function AdsTable({
  rows,
  totalRows,
  columns,
  footerCTA,
  showPagination = true,
  callback = null,
  page=0,
  setPage=null,
  rowsPerPage=100,
  setRowsPerPage=null,
}) {
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(100);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // if (callback) callback({ page: newPage + 1, rowsPerPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    // if (callback) callback({ page: 1, rowsPerPage: +event.target.value });
  };
  useEffect(() => {
  }, [page, rowsPerPage]);
  return (
    <DataGrid
      sx={{ height: "100%", overflow: "auto", border: 0 }}
      // autoHeight
      rows={rows}
      columns={columns}
      getRowId={(row) => row.uuid}
      slots={{
        footer: () => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box>{footerCTA}</Box>
            {showPagination && (
              <TablePagination
                page={page}
                count={totalRows}
                rowsPerPageOptions={[10, 20, 50, 100]}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            {/* <MDPagination /> */}
          </Box>
        ),
      }}
    />
  );
}
