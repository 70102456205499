import React, { useContext, useEffect, useState } from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Custom Component
import VideoPage from "./videoMaster";
import ImagesPage from "./imageMaster";
import PlaylistsPage from "./playlistMaster";
import AssignedDevicesPage from "./assignedDevicesMaster";
import FixturesPage from "./assignedFixturesMaster";
import { DropDownSelectionContext } from ".";

// Material Dashboard 2 React components
import MDTabPanel from "components/MDTabPanel";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Apollo query and mutations
import { useLazyQuery } from "@apollo/client";
import { GET_ASSET_MAPPING_LIST_LENGTH } from "services/brand-service";
import { vmServiceClient } from "graphql/client";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdsTabs({ selectedStore, selectedBrand }) {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const { selectedBrandId, user, key,setKey, selectedStoreId } = useContext(
    DropDownSelectionContext
  );
 // we are maintaining these two flag for conditionally showing tabs 
  const [isFixtureLength, setIsFixtureLength] = useState(true);
  const [isDeviceLength, setIsDeviceLength] = useState(true);

  const [getAssetMappingList, { loading, error, data }] = useLazyQuery(GET_ASSET_MAPPING_LIST_LENGTH, {
    fetchPolicy: "network-only",
    client: vmServiceClient,
    onCompleted: (data) => {
      const results = data?.findAllMapDeviceFixtureBrands?.results;

      if(results && results?.length === 0){
        setIsFixtureLength(false)
        setIsDeviceLength(false)
        setTabValue(2)
      }
    },
    onError: (err) => {
      console.log("error", err);
      console.error("Error fetching asset mapping list:", err);
    },
  });

  useEffect(()=> {
    console.log("data pathak", data, error, loading)
  }, [data, error, loading])
  useEffect(() => {
    if (user && user.role === 'brand_admin') {
      console.log("Fetching asset mapping list with variables:", {
        page: 1,
        limit: 100,
        relations: ["fixtures", "fixtures.devices"],
        filter: {
          brandId: selectedBrandId,
          fixtures: {
            storeId: selectedStoreId,
          },
        },
      });
      getAssetMappingList({
        variables: {
          page: 1,
          limit: 100,
          relations: ["fixtures", "fixtures.devices"],
          filter: {
            brandId: selectedBrandId,
            fixtures: {
              storeId: selectedStoreId,
            },
          },
        },
      });
    }
    setKey(new Date());
  }, [user, selectedBrandId, selectedStoreId, getAssetMappingList]);

  if (error) {
    console.error("GraphQL Error:", error);
  }
  
 

  return selectedStore && selectedBrand ? (
    <>
      <Tabs
        sx={{ px: 2 }}
        value={tabValue}
        onChange={handleChange}
        aria-label="ant example"
      >
       <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main",display: isDeviceLength ? "block" : "none",  }}
          label="Assigned Devices"
          {...a11yProps(0)}
        />
       <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" ,display: isFixtureLength ? "block" : "none",}}
          label="Assigned Fixtures"
          {...a11yProps(1)}
        />
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Playlists"
          {...a11yProps(2)}
        />
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Videos"
          {...a11yProps(3)}
        />
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Images"
          {...a11yProps(4)}
        />
      </Tabs>
          {/* Keep all MDTabPanels rendered, just hide their content conditionally */}
          <MDTabPanel value={tabValue} index={0}>
        {isDeviceLength ? (
          <MDBox sx={{ p: 2 }}>
            <AssignedDevicesPage />
          </MDBox>
        ) : null}
      </MDTabPanel>

      <MDTabPanel value={tabValue} index={1}>
        {isFixtureLength ? (
          <MDBox sx={{ p: 2 }}>
            <FixturesPage tabValue={tabValue} />
          </MDBox>
        ) : null}
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={2}>
        <MDBox sx={{ p: 2 }}>
          <PlaylistsPage tabValue={tabValue} />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={3}>
        <MDBox sx={{ p: 2 }}>
          <VideoPage tabValue={tabValue} />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={4}>
        <MDBox sx={{ p: 2 }}>
          <ImagesPage />
        </MDBox>
      </MDTabPanel>
    </>
  ) : (
    <MDBox>
      <MDTypography
        variant="h5"
        fontWeight="medium"
        color="dark"
        sx={{ padding: "24px" }}
      >
        Please select store as well as brand to see mapped advertisments art
        work and assets
      </MDTypography>
    </MDBox>
  );
}
